import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Loadable from '../../components/data/loadable';
import Frame from '../../components/layout/frame';
import useJourneyData from '../../hooks/use-journey-data';



// This will either redirect the user to the members most recent journey, OR to the member profile page.

export default function CoachMemberRecentJourney() {
  const { getMemberJourneyData } = useJourneyData();
  const { memberId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (!memberId) {
      navigate(`/coach/members`, { replace: true });
      return;
    }
    const load = async () => {
      const journeys = await getMemberJourneyData(memberId);
      if (!journeys || !journeys.length) {
        // Just go to the member page
        navigate(`/coach/member/${memberId}/view`, { replace: true });
        return;
      }
      // console.log(journeys, 'journeys');
      const sortedJourneys = journeys.sort((a, b) => b.updatedAt._seconds - a.updatedAt._seconds);
      // console.log('would navigate to', `/coach/member/journey/${sortedJourneys[0]._id}`);
      navigate(`/coach/member/journey/${sortedJourneys[0]._id}`, { replace: true });
    }
    load();
  }, [memberId]);


  return (
    <Frame fullscreen classes="p-0">
      <Loadable loading>
        <div />
      </Loadable>
    </Frame>
  );
}
