import React from 'react';
import { FaPen } from 'react-icons/fa';
import { ReactComponent as SkillHabitGrey } from '../../../../assets/skill-habit-grey.svg';
import { ReactComponent as SkillMentalGrey } from '../../../../assets/skill-mental-grey.svg';
import { ReactComponent as SkillSelfGrey } from '../../../../assets/skill-self-grey.svg';
import Avatar from '../../../common/avatar';
import { formatUnixDate, getWeekFromJourney, pluralize, timezonePrettyName } from '../../../../utils/helpers';
import { Link } from 'react-router-dom';

function getExerciseTypeNumber(exerciseTypes, type) {
  return !!exerciseTypes && !!exerciseTypes[type] ? exerciseTypes[type] : 0;
}
function getTotalExercises(types) {
  if (!types) {
    return 0;
  }
  const keys = Object.keys(types);
  let total = 0;
  for (let i = 0; i < keys.length; i++) {
    const type = types[keys[i]];
    total += parseInt(type);
  }
  return total;
}
function getLastExerciseTime(completions) {
  if (!completions) {
    return 0;
  }
  const keys = Object.keys(completions);
  let last = 0;
  for (let i = 0; i < keys.length; i++) {
    const exercise = completions[keys[i]];
    const { completedAt } = exercise;
    const { _seconds: seconds } = completedAt;
    if (seconds > last) {
      last = seconds;
    }
  }
  return last;
}

const StatusControls = ({ setIsJourneyControlModalOpen, setIsCheckInModalOpen, summary }) => {

  if (!summary) {
    return null;
  }

  const { profile, journey, journeyData, chat } = summary;

  const { day, frequency, guide: guideNotifications, member: memberNotifications, time } = chat || {};

  const { photo, name, pronouns, timezone } = profile;
  const { lastMessageTime, toolkitName, completed, paused, goal, uid, weeks, _id: journeyId } = journey;
  let maxWeeks = 0;
  for (let i = 0; i < weeks.length; i++) {
    if (weeks[i].week > maxWeeks) {
      maxWeeks = weeks[i].week;
    }
  }

  const { exerciseCompletions, exerciseTypes, goalScoreAverage, moodAverage } = journeyData || {};
  const weekInfo = getWeekFromJourney(journey);
  const goalName = goal?.name || 'none';

  const totalExercises = getTotalExercises(exerciseTypes);
  const lastTimestamp = getLastExerciseTime(exerciseCompletions);
  const lastExerciseTime = lastTimestamp ? formatUnixDate(lastTimestamp, 'M/D/YY') : 'never';
  const lastMessageFormatted = lastMessageTime ? `${formatUnixDate(lastMessageTime._seconds, 'M/D/YY')} at ${formatUnixDate(lastMessageTime._seconds, 'h:mm a')}` : 'never';
  const habitNum = getExerciseTypeNumber(exerciseTypes, 'Habit-building');
  const mentalNum = getExerciseTypeNumber(exerciseTypes, 'Mental flexibility');
  const selfNum = getExerciseTypeNumber(exerciseTypes, 'Self-expression');

  const journeyStatus = completed ? 'Complete' : paused ? 'Paused' : 'Active';

  const flowWeek = weekInfo.weeks > maxWeeks ? maxWeeks : weekInfo.weeks;

  return (
    <>
      <div className="flex flex-col items-center lg:w-[23%] w-full">
        <div className="text-center">
          {/* <img src={photo} alt={name} /> */}
          <Avatar xl photo={photo} name={name} bg="bg-secondary-500" rounded />
          {!!pronouns && <p className="text-[18px] italic font-[600] mt-1">{pronouns}</p>}
        </div>
        <Link
          className="bg-[#A38DF2] text-center py-4 rounded-lg text-white font-[700] w-[75%] mb-5 mt-8 px-8 shadow-lg text-[20px] hover:opacity-70"
          to={`/coach/member/${uid}/chat`}
        >
          Chat
        </Link>
        <div className="bg-[#C5C4C2] px-8 py-4 rounded-lg w-[75%] text-center cursor-pointer shadow-lg hover:opacity-70">
          <a
            className="text-[20px] font-[500]"
            href={`/coach/member/journey/${journeyId}/analyze#checkins`}
          >
            Perspective Survey
          </a>
        </div>
      </div>
      <div className="rounded-lg overflow-hidden border border-[#807F7E] lg:w-[35%] w-full my-5 lg:my-0">
        <div className="rounded-md flex flex-col">
          <div className="flex flex-row p-4 items-end justify-between bg-[#342F3B]">
            <p className="text-white text-[20px]">Status</p>
          </div>
          <div className="flex flex-row items-center justify-start p-4 border-b border-b-[#C5C4C2] h-[20%]">
            <p className="text-[18px] mr-8 lg:w-[30%]">Timeline:</p>
            <div className="flex flex-row items-center">
              <p className="text-[16px]">{weekInfo.weeks} Weeks ({toolkitName})</p>
              <Link
                className="text-[16px] text-[#A38DF2] underline ml-[33px] cursor-pointer"
                to={`/coach/member/journey/${journeyId}/flow/activities/${flowWeek}`}
              >
                Thought #{flowWeek}
              </Link>
            </div>
          </div>
          <div className="flex flex-row items-center lg:justify-start justify-between p-4 border-b border-b-[#C5C4C2] h-[20%]">
            <p className="text-[18px] mr-8 lg:w-[30%]">Progress:</p>
            <div className="flex flex-col items-start">
              <p className="text-[16px] flex flex-row lg:ml-0">{`${totalExercises} ${pluralize('exercise', totalExercises)} (${toolkitName})`}</p>
              <p className="text-[14px] text-[#807F7E]">Last exercise: {lastExerciseTime}</p>
            </div>
          </div>
          <div className="flex flex-row items-center p-4 border-b border-b-[#C5C4C2] h-[20%]">
            <p className="text-[18px] mr-8 lg:w-[30%]">Skills:</p>
            <div className="flex flex-row items-start lg:items-center justify-between w-full lg:w-[70%] mr-auto lg:ml-5 ml-0">
              <div className="relative mr-5 mb-2 lg:mb-0">
                {/* <img src="/skill-1.png" alt="Skill 1" className="w-12 lg:w-auto" /> */}
                <SkillHabitGrey className="w-[25px] h-[25px] lg:w-[42px] lg:h-[42px]" />
                <div className="bg-[#7162D9] p-2 w-5 h-5 rounded-full text-white flex items-center justify-center absolute -bottom-1 -right-3">
                  <p className="text-[12px]">{habitNum}</p>
                </div>
              </div>
              <div className="relative mr-5 mb-2 lg:mb-0">
                {/* <img src="/skill-2.png" alt="Skill 2" className="w-12 lg:w-auto" /> */}
                <SkillSelfGrey className="w-[25px] h-[25px] lg:w-[42px] lg:h-[42px]" />
                <div className="bg-[#7162D9] p-2 w-5 h-5 rounded-full text-white flex items-center justify-center absolute -bottom-1 -lg:right-1 -right-2">
                  <p className="text-[12px]">{selfNum}</p>
                </div>
              </div>
              <div className="relative mr-5 mb-2 lg:mb-0">
                {/* <img src="/skill-3.png" alt="Skill 3" className="w-12 lg:w-auto" /> */}
                <SkillMentalGrey className="w-[25px] h-[25px] lg:w-[42px] lg:h-[42px]" />
                <div className="bg-[#7162D9] p-2 w-5 h-5 rounded-full text-white flex items-center justify-center absolute -bottom-2 -right-3">
                  <p className="text-[12px]">{mentalNum}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-row items-center p-4 border-b border-b-[#C5C4C2] h-[20%]">
            <p className="text-[18px] mr-8 lg:w-[30%]">Scores:</p>
            <p className="text-[16px] mr-8 flex flex-row items-center">
              Mood Avg
              <p className="w-[26px] lg:w-5 lg:h-5 rounded-full bg-[#7162D9] text-white flex items-center justify-center mr-2 text-[12px] ml-2">{moodAverage ? Math.round(moodAverage) : 0}</p>
            </p>
            <p className="text-[16px] flex flex-row items-center">
              Theme Avg <p className="w-[26px] lg:w-5 lg:h-5 rounded-full bg-[#7162D9] text-white flex items-center justify-center mr-2 text-[12px] ml-2">{goalScoreAverage ? Math.round(goalScoreAverage) : 0}</p>
            </p>
          </div>
          <div className="flex flex-row items-center p-4 h-[20%]">
            <p className="text-[18px] mr-8 lg:w-[30%]">Last Message:</p>
            <p className="text-[16px] mr-8">{lastMessageFormatted}</p>
          </div>
        </div>
      </div>
      <div className="rounded-lg overflow-hidden border border-[#807F7E]">
        <div className="rounded-md flex flex-col">
          <div className="flex flex-row p-4 items-end justify-between bg-[#342F3B]">
            <p className="text-white text-[20px]">Controls</p>
            <div className="w-7 h-7 rounded-full flex flex-row justify-center items-center bg-white cursor-pointer" onClick={() => setIsJourneyControlModalOpen(true)}>
              <FaPen width={10} height={10} className="text-[#A38DF2]" />
            </div>
          </div>
          <div className="flex flex-row items-center justify-start p-4 border-b border-b-[#C5C4C2] h-[20%]">
            <p className="text-[18px] mr-8 lg:w-[35%]">Journey Status:</p>
            <p className="text-[16px]">{toolkitName} ({journeyStatus})</p>
          </div>
          <div className="flex flex-row items-center justify-start p-4 border-b border-b-[#C5C4C2] h-[20%]">
            <p className="text-[18px] mr-8 lg:w-[35%]">Chat Notifications:</p>
            <div>
              <p className="text-[16px] lg:ml-0">{memberNotifications ? 'Yes' : 'No'} (Member)</p>
              <p className="text-[16px] lg:ml-0">{guideNotifications ? 'Yes' : 'No'} (Guide)</p>
            </div>
          </div>
          <div className="flex flex-row items-center p-4 border-b border-b-[#C5C4C2] h-[20%]">
            <p className="text-[18px] mr-8 lg:w-[35%]">Check-in Day:</p>
            <p className="text-[16px]">{day || '--'}</p>
          </div>
          <div className="flex flex-row items-center p-4 border-b border-b-[#C5C4C2] h-[20%]">
            <p className="text-[18px] mr-8 lg:w-[35%]">Check-in Time:</p>
            <div>
              <p className="text-[16px] mr-8">{time ? `${time} ${timezonePrettyName(timezone, 'short')}` : '--'}</p>
              {!!frequency && <p className="text-[16px]">{frequency}</p>}
              {/* <p className="text-[16px]">1:00pm ET (Guide)</p> */}
            </div>
          </div>
          <div className="flex flex-row items-center p-4 h-[20%]">
            <p className="text-[18px] mr-8 lg:w-[47%]">Theme:</p>
            <p className="text-[16px]">{goalName}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default StatusControls;
