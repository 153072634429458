
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import queryString from 'query-string';
import moment from 'moment';
import { useBreadcrumbs } from '../../contexts/breadcrumbs-provider';
import Heading from '../../components/layout/admin/heading';
import { capFirstLetter, makeSureHttps } from '../../utils/helpers';
import Loadable from '../../components/data/loadable';
import AdminTable from '../../components/common/admin-table';
import AutoForm from '../../components/form/auto-form';
import Frame from '../../components/layout/frame';
import { convertResultsToAdminResults, searchContent } from '../../utils/search';


function formatRow(item) {
  const { _id: id, updatedAt: updated, steps, title, status, thumbnailImage } = item;
  const photoUrl = `${makeSureHttps(thumbnailImage?.url)}?w=200`;
  return {
    title,
    thumbnail: <img src={photoUrl} alt={title} className="h-16 w-16 rounded-lg" />,
    steps: steps?.length || 0,
    status: capFirstLetter(status),
    updated: moment.unix(updated._seconds).format('M/D/Y'),
    id,
  };
}

export default function AdminPathways() {
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(true);
  const [queryParamsString, setQueryParamsString] = useState(null);
  const { setBreadcrumbs } = useBreadcrumbs();
  useEffect(() => {
    setBreadcrumbs([
      {
        href: '/admin/pathways',
        label: 'Pathways',
      },
    ]);
  }, []);

  const query = queryString.parse(window.location.search);
  const currentQueryParamsString = queryString.stringify(query || {});

  const loadPathways = async () => {
    if (!loading) {
      setLoading(true);
    }
    let filters = `internal_content_type:pathways`;
    if (query?.toolkitId) {
      filters += ` AND toolkitId:"${query?.toolkitId}"`;
    }
    const searchOptions = {
      filters,
      hitsPerPage: 20,
    };
    const result = await searchContent('', searchOptions, query?.page || 0);
    setResults(convertResultsToAdminResults(result));
    // console.log(result, 'this is the result');
    // setResults(result);
    setLoading(false);
  };

  useEffect(() => {
    if (currentQueryParamsString !== queryParamsString) {
      setQueryParamsString(currentQueryParamsString);
    }
  }, [currentQueryParamsString]);


  useEffect(() => {
    if (queryParamsString !== null && queryParamsString === currentQueryParamsString) {
      loadPathways();
    }
  }, [queryParamsString]);

  const columns = [
    {
      key: 'thumbnail',
      label: 'Thumbnail',
    },
    {
      key: 'title',
      label: 'Title',
      isBold: true,
    },
    {
      key: 'steps',
      label: '# Steps',
    },
    {
      key: 'status',
      label: 'Status',
    },
    {
      key: 'updated',
      label: 'Last Updated',
    },
    {
      key: 'actions',
      label: '',
      isRight: true,
      render: (row) => (
        <>
          <Link
            to={`/admin/pathway/${row.id}`}
            className="ml-1 inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Edit
          </Link>
        </>
      ),
    },
  ];

  // const actionLinks = [
  //   {
  //     label: 'Create Pathway',
  //     href: '/admin/pathway',
  //   },
  // ];

  const baseUrl = '/admin/pathways';

  const searchForm = {
    type: 'get',
    noContainer: true,
    successRedirect: '/admin/pathways',
    fields: [
      {
        name: 'toolkitId',
        label: 'Toolkit',
        type: 'select',
        optionsUrl: '/options/toolkits',
      },
    ],
    submitLabel: 'Search Pathways',
  };

  return (
      <Frame>
        
        <Heading title="Pathways" />
        <p className="px-4 md:px-8 text-sm text-gray-700">Note: Pathways are first added in Contentful. Once added, they will show up in this list.</p>
        <Loadable loading={loading}>
          <AdminTable results={results} columns={columns} baseUrl={baseUrl} rowFormatter={formatRow} searchTitle="Search Pathways" searchForm={<AutoForm {...searchForm} />} />
        </Loadable>
      </Frame>
  );
}
