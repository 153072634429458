import React, { useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import queryString from 'query-string';
import { LockClosedIcon } from '@heroicons/react/solid';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useAuth } from '../contexts/auth-provider';
import Container from '../components/layout/default/container';
import Panel from '../components/common/panel';

const Login = () => {
  const navigate = useNavigate();
  const { signout, signin, user } = useAuth();

  useEffect(() => {
    if (user) {
      signout();
    }
  }, []);


  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().email('Invalid email address.').required('Email is a required field.'),
      password: Yup.string().required('Password is a required field.'),
    }),
    onSubmit: async (values, actions) => {
      const { email, password } = values;
      try {
       await signin(email, password);
       const parsed = window.location.search ? queryString.parse(window.location.search) : {};
       const redir = parsed && parsed.redir ? parsed.redir : '/dashboard';
       // @ts-ignore
       navigate(redir);
      } catch (err) {
        actions.setErrors({
          email: 'Invalid email or password, please try again.',
        });
      }
      actions.setSubmitting(false);
    },
  });

  const regularInputClasses = 'border-gray-300 placeholder-gray-500 text-gray-900 focus:ring-indigo-500 focus:border-indigo-500';
  const errorInputClasses = 'border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500';

  const hasEmailError = formik.touched.email && formik.errors.email;
  const hasPasswordError = formik.touched.password && formik.errors.password;

  return (

    <Container classes="bg-gradient-to-b from-[#7abdc4] via-[#A6D7E8] to-[#FCB484]">
      <div className="flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-sm w-full space-y-8">
          <div className="pb-24 pt-48">
            <div>
              <img
                className="mx-auto h-12 w-auto"
                src="/dds-logo-cream-2x.png"
                alt="Daydreamer Space"
              />
              <h2 className="mt-24 sm:px-8 text-left text-2xl font-medium text-cream">Log In</h2>
              
            </div>
            <form className="mt-2 space-y-6 sm:px-8" onSubmit={formik.handleSubmit}>
              <input type="hidden" name="remember" defaultValue="true" />
              <div className="">
                <div className="rounded-md shadow-sm mb-4">
                  <label htmlFor="email-address" className="sr-only">
                    Email address
                  </label>
                  <input
                    {...formik.getFieldProps('email')}
                    id="email-address"
                    name="email"
                    type="email"
                    autoComplete="email"
                    className={`${hasEmailError ? errorInputClasses : regularInputClasses} bg-white relative block w-full px-3 py-4 border rounded-lg focus:outline-none focus:z-10 sm:text-sm`}
                    placeholder="Email Address"
                  />

                  {hasEmailError && (
                    <p className="mt-2 text-sm text-red-600" id="email-error">
                      {formik.errors.email}
                    </p>
                  )}
                </div>
                <div className="rounded-md shadow-sm mb-4">
                  <label htmlFor="password" className="sr-only">
                    Password
                  </label>
                  <input
                    {...formik.getFieldProps('password')}
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    className={`${hasPasswordError ? errorInputClasses : regularInputClasses} bg-white relative block w-full px-3 py-4 border rounded-lg focus:outline-none focus:z-10 sm:text-sm`}
                    placeholder="Password"
                  />
                  {hasPasswordError && (
                    <p className="mt-2 text-sm text-red-600" id="email-error">
                      {formik.errors.password}
                    </p>
                  )}
                </div>
              </div>

              

              <div>
                <button
                  type="submit"
                  disabled={formik.isSubmitting}
                  className="group relative w-full flex justify-center py-4 px-4 border border-transparent text-lg font-medium rounded-lg text-cream bg-primary-500 hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-800"
                >
                  Log In
                </button>
              </div>
              <div className="w-full">
                <div className="text-sm text-center mt-12">
                  <Link to="/forgot-password" className="font-medium text-center underline text-primary-500 hover:text-primary-600">
                    trouble logging in?
                  </Link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Login;
