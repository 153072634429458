import { useEffect, useState } from "react";
import Nl2br from "../../common/nl2br";

function getGoalName(id, goals) {
  // console.log(id, goals, 'searching for id in goals');
  if (!goals) {
    return null;
  }
  for (let i = 0; i < goals.length; i++) {
    if (goals[i]._id === id) {
      return goals[i].name;
    }
  }
  return null;
}

function SurveyQuestionWithResponse({ question, response }) {
  const { question: questionText, goals } = question;
  let responseValue = response;
  if (!!goals) {
    // The response is a goal id, grab it from our list of goal options
    responseValue = getGoalName(response, goals);
  }

  return (
      <div className="pt-5 pb-5 sm:pt-0">
        <dl className="space-y-8 sm:space-y-6">
          <div>
            <dt className="text-sm font-medium text-gray-500">{questionText}</dt>
            <dd className="mt-1 text-sm text-gray-900 ">{<Nl2br str={responseValue || 'no response received'} />}</dd>
          </div>
        </dl>
      </div>
  );
}


export default function MemberSurveyResponse({ response }) {
  const [sections, setSections] = useState(null);

  useEffect(() => {
    if (!response) {
      return null;
    }
    // const keys = Object.keys(response.data);
    // const allResponses = [];
    // for (let i = 0; i < keys.length; i++) {
    //   const key = keys[i];
    //   allResponses.push(response.data[key]);
    // }
    
    // const newSections = [
    //   {
    //     key: 'Engagement',
    //     questions: allResponses.filter((item) => item.question?.type === 'Engagement'),
    //   },
    //   {
    //     key: 'Activity',
    //     questions: allResponses.filter((item) => item.question?.type === 'Activity'),
    //   },
    //   {
    //     key: 'Mood',
    //     questions: allResponses.filter((item) => item.question?.type === 'Mood'),
    //   },
    //   {
    //     key: 'General Well Being',
    //     questions: allResponses.filter((item) => item.question?.type === 'General Well Being'),
    //   },
    //   {
    //     key: 'Perspective',
    //     questions: allResponses.filter((item) => item.question?.type === 'Perspective'),
    //   },
    //   {
    //     key: 'Goal Choice',
    //     questions: allResponses.filter((item) => item.question?.goalChoice === true),
    //   },
    // ];

    const newSections = [];
    const { instance } = response;

    const { sections: instanceSections } = instance;


    for (let i = 0; i < instanceSections.length; i++) {
      const section = instanceSections[i];
      const { questions, title } = section;

      const newQuestions = [];
      questions.forEach(question => {
        const questionData = response.data[question._id];
        if (typeof questionData !== 'undefined') {
          newQuestions.push(questionData);
        }
      })
      
      newSections.push({
        type: title,
        key: title,
        questions: newQuestions,
      });
    }

    setSections(newSections);
  }, [response]);

  if (!sections || !sections.length) {
    return null;
  }
// console.log(sections, 'sections');
  return (
    <div className="pb-4 border-b-2 border-primary-500 mb-4">
      {sections.map(section => {
        const { key, questions } = section;
        if (!questions.length) {
          return null;
        }
        return (
          <div className="px-4 pt-5 pb-5 sm:px-0 sm:pt-0" key={key}>
            <h4 className="text-lg text-gray-700 my-4">{key}</h4>
            <dl className="space-y-4 sm:space-y-2">
              {questions.map(question => <SurveyQuestionWithResponse key={question.question._id || 'goal'} question={question.question} response={question.value} />)}
            </dl>
          </div>
        );
      })}
    </div>
  )
}
