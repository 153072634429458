import moment from 'moment';
import { useEffect } from 'react';


function MessageType({ fontColor, content, openModal }) {
  const { type } = content;
  switch (type) {
    case 'image':
      return <img onClick={() => openModal(content.image)} className="cursor-pointer w-full aspect-[4/3] rounded object-cover" src={content.image} alt="message-pic" />
    case 'text':
    default:
      return <span className={fontColor}>{content.text}</span>;
  }
}

export default function Message({ message, fromMe, openModal, markAsRead }) {

  const { read, id } = message || {};
  useEffect(() => {
    if (!fromMe && typeof read !== 'undefined' && !read) {
      // When it's rendered, it's having been read
      markAsRead(id);
    }
  }, [fromMe, read, id]);

  const bgColor = fromMe ? 'bg-primary-500' : 'bg-white';
  const fontColor = fromMe ? 'text-white' : 'text-openroad';
  const align = fromMe ? 'justify-end' : 'justify-start';
  const textAlign = fromMe ? 'text-right' : 'text-left';
  const timeColor = fromMe ? 'text-gray-300' : 'text-gray-400';
  const { content } = message;
  const momentTime = message.createdAt ? moment.unix(message.createdAt?.seconds) : moment();
  const localizedTime = momentTime.format('lll');

  return (
    <div className={`flex ${align}`}>
      <div className={`${bgColor} px-3 pt-3 pb-2 w-2/3 xl:w-1/2 rounded-lg shadow-md`}>
        <MessageType fontColor={fontColor} content={content} openModal={openModal} />
        <p className={`${timeColor} text-xs mt-1 ${textAlign}`}>{localizedTime}</p>
      </div>
    </div>
  );
}
