import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export default function MoodBar({ moods, toolkitName }) {
  const options = {
    responsive: true,
  };  

  const labels = moods.map((m, i) => `Week ${i}`);
  const data = {
    labels,
    datasets: [
      {
        label: toolkitName,
        backgroundColor: '#7fc6ce',
        borderColor: '#4fafb9',
        data: moods,
      },
    ],
  };

  return (
    <Bar
      options={options}
      data={data}
    />
  );
}