import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { setDoc, getDoc, doc } from 'firebase/firestore';
import objEqual from 'fast-deep-equal/es6/react';
import { useBreadcrumbs } from '../../contexts/breadcrumbs-provider';
import Heading from '../../components/layout/admin/heading';
import Frame from '../../components/layout/frame';
import { firestore } from '../../config/firebase';
import { useNotifications } from '../../contexts/notifications-provider';
import Loadable from '../../components/data/loadable';
import SaveBar from '../../components/pathways/save-bar';
import Step from '../../components/pathways/step';
import { capFirstLetter, findIndexById, findIndexOfItemByValue, makeSureHttps } from '../../utils/helpers';
import RightSlideOver from '../../components/overlays/right-slide-over';
import ContentSearch from '../../components/pathways/content-search';
import AddButton from '../../components/buttons/add-button';
import Panel from '../../components/common/panel';
import Nl2br from '../../components/common/nl2br';
import Alert from '../../components/alerts/alert';

const AdminPathway = () => {
  const [loading, setLoading] = useState(true);
  const [init, setInit] = useState(false);

  const [pathway, setPathway] = useState({ steps: [] });
  const [initialValues, setInitialValues] = useState(null);
  const [hasChanged, setHasChanged] = useState(false);
  const [activeStep, setActiveStep] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const { setBreadcrumbs } = useBreadcrumbs();
  const { addNotification } = useNotifications();
  const { id } = useParams();

  useEffect(() => {
    setBreadcrumbs([
      {
        href: '/admin/pathways',
        label: 'Pathways',
      },
      {
        href: `/admin/pathway/${id}`,
        label: `Update Pathway`,
      },
    ]);
  }, [id]);

  const loadPathway = async () => {
    if (!id) {
      return null;
    }
    setLoading(true);
    // Let's load the profile from firestore
    const pathwaySnap = await getDoc(doc(firestore, 'pathways', id));
    
    if (pathwaySnap.exists()) {
      // Fow now this is enough info, but we can always add more
      const newPathway = {
        ...pathwaySnap.data(),
        _id: id,
      };
      setPathway(newPathway);
      setInitialValues(newPathway);
      setInit(true);
    }
    setLoading(false);
  };

  const savePathway = async () => {
    // Validate the pathway first
    let errorMessage = '';
    const { steps } = pathway;
    if (!steps?.length) {
      errorMessage = 'You cannot save a pathway with no Steps.';      
    } else {
      steps.forEach(step => {
        if (!step.items?.length) {
          errorMessage = 'You cannot save a Published pathway with empty Steps.';
        }
      });
    }
    if (errorMessage) {
      addNotification({
        type: 'error',
        body: errorMessage || 'There was an unknown error saving the pathway.',
      });
      return;
    }
    try {
      const docRef = doc(firestore, 'pathways', pathway._id);
      await setDoc(docRef, pathway);
      // Quick clone of the pathway for initial values...
      setInitialValues({
        ...pathway,
        steps: pathway.steps.map(step => {
          const { items } = step;
          return {
            ...step,
            items: [...items],
          };
        }),
      });
      addNotification({
        type: 'success',
        body: 'The pathway was saved!',
      });
    } catch (err) {
      console.log(err, 'there was an error saving the pathway');
      addNotification({
        type: 'error',
        body: err.message || 'There was an unknown error saving the pathway.',
      });
    }
  };

  useEffect(() => {
    if (!id) {
      return;
    }
    loadPathway(id);
    
  }, [id]);

  useEffect(() => {
    if (!init) {
      return;
    }
    // Check to see if the pathway has changed
    const hasChangedFromInitial = !objEqual(initialValues, pathway);
    // if (hasChanged !== hasChangedFromInitial) {
      setHasChanged(hasChangedFromInitial);
    // }
    
  }, [init, initialValues, pathway]);


  // Add a step, also open the sidebar
  const addStep = () => {
    const newPathway = {...pathway};
    // If we don't have any steps yet, initialize the array
    if (!newPathway.steps?.length) {
      newPathway.steps = [];
    }
    const index = newPathway.steps.length + 1;
    newPathway.steps.push({
      _id: uuidv4(),
      index,
      complete: false,
      items: [],
    });
    setPathway(newPathway);
  };

  const updateStep = (newStep) => {
    // Find the step in the map and update the map
    const stepIndex = findIndexOfItemByValue(pathway.steps, newStep._id, '_id');
    const newPathway = {...pathway};
    newPathway.steps[stepIndex] = newStep;
    setPathway(newPathway);
  };

  const addItem = (item) => {
    const newStep = {...activeStep};
    let formattedType = 'explore';
    if (item.internal_content_type === 'exercises') {
      formattedType = 'exercise';
    } else if (item.internal_content_type === 'activities2') {
      formattedType = 'activity';
    }
    // Update the step itself, adding the item
    const formattedItem = {
      id: item._id,
      complete: false,
      doses: 1,
      completedDoses: 0,
      type: formattedType,
    };
    newStep.items.push(formattedItem);
    // Make sure our active step stays in sync
    setActiveStep(newStep);
    updateStep(newStep);
  };

  const removeItem = (step, item) => {
    const newStep = {...step};
    // Find the item
    const itemIndex = findIndexById(newStep.items, item.id);
    newStep.items.splice(itemIndex, 1);
    // Make sure our active step stays in sync
    if (step._id === activeStep?._id) {
      setActiveStep(newStep);
    }
    updateStep(newStep);
  };

  const updateStepItems = (step, newItems) => {
    const newStep = {...step};
    newStep.items = newItems;
    // Make sure our active step stays in sync
    if (activeStep?.id === step.id) {
      setActiveStep(newStep);
    }
    updateStep(newStep);
  };

  const removeStep = (step) => {
    if (step.items.length) {
      return;
    }
    const newPathway = {...pathway};
    // Find the step
    const stepIndex = findIndexById(pathway.steps, step._id, '_id');
    newPathway.steps.splice(stepIndex, 1);
    newPathway.steps = newPathway.steps.map((item, i) => {
      return {
        ...item,
        index: i + 1,
      };
    });
    // Make sure we didn't just delete the active step, if so null it out.
    if (step._id === activeStep?._id) {
      setActiveStep(null);
    }
    setPathway(newPathway);
  }

  const openItemsSearchForStep = (step) => {
    setActiveStep(step);
    setIsDrawerOpen(true);
  };
  
  const { steps } = pathway || {};
  const hasSteps = !!steps?.length;

  // We only allow content from the current toolkit for Pathways...
  const toolkitOptions = [{
    value: pathway?.toolkitId,
    label: 'Mood...',
  }];

  return (
    <div className="w-full">
      <Frame fullscreen classes="p-4">
        <Loadable loading={loading}>
          <Heading title="Update Pathway" />
          <div className="my-8 px-4 mx-auto space-y-8">
            {!!pathway && (
              <>
                <Alert
                  type="info"
                  title="Pathway Meta Info"
                  body={<>To manage meta information, <a href={`https://app.contentful.com/spaces/xizpvw7sqc10/entries/${pathway._id}`} className="underline text-blue-700" target="_blank" rel="nofollow noreferrer">edit this Pathway in Contentful &raquo;</a></>}
                />
                <Panel>
                  <div className="flex p-4">
                    <div className="mr-4 flex-shrink-0 self-center">
                      <img alt={pathway.title} className="h-48 w-48 object-cover rounded-md border border-gray-300 bg-white text-gray-300" src={`${makeSureHttps(pathway.thumbnailImage?.url)}?w=300`} />
                    </div>
                    <div>
                      <h4 className="text-lg font-bold">{pathway.title}</h4>
                      <h5 className="text-sm text-gray-600">
                        {pathway.toolkitName}
                        {` `}&bull;{` `}
                        {capFirstLetter(pathway.status)}
                        {` `}&bull;{` `}
                        Weight {pathway.listWeight}
                      </h5>
                      <p className="mt-1 text-gray-700 text-">
                        <Nl2br str={pathway.description} />
                      </p>
                    </div>
                  </div>
                </Panel>

              </>
            )}
            <h2 className="text-xl">Pathway Content</h2>
            <SaveBar submit={savePathway} changed={hasChanged} label="Save Pathway" hideStatus />
            {hasSteps && steps.map((step) => {
              const { _id: stepId } = step;
              return (
                <Step key={stepId} updateStepItems={updateStepItems} addItems={openItemsSearchForStep} removeItem={removeItem} step={step} removeStep={removeStep} />
              );
            })}
            <div className="border-b border-gray-100 py-4 my-2">
                <AddButton buttonText="Add Step" onClick={addStep} />
              </div>
          </div>
        </Loadable>
      </Frame>

      <RightSlideOver darkBg isOpen={isDrawerOpen} close={() => setIsDrawerOpen(false)} title={`Add Content To Step ${activeStep?.index}`} large>
        {isDrawerOpen && <ContentSearch addItem={addItem} step={activeStep} toolkitIds={toolkitOptions} currentToolkitId={pathway?.toolkitId} />}
      </RightSlideOver>
    </div>
  );
}

export default AdminPathway;
