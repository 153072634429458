
export default function SearchSelect({ options, value, onChange, name }) {
  const classes = 'border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500';
  return (
    <select
      value={value}
      onChange={onChange}
      className={`mt-1 block w-full pl-3 pr-10 py-2 text-base sm:text-sm rounded-md ${classes}`}
      id={name}
      name={name}
    >
      {options.map((option) => (<option key={`${option.value}_${option.label}`} value={option.value}>{option.label}</option>))}
    </select>
  );
}
