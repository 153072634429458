import { useState, useEffect } from 'react';
import Bugsnag from '@bugsnag/browser';
import { useParams, useNavigate } from 'react-router-dom';
import { setDoc, getDoc, doc } from 'firebase/firestore';
import objEqual from 'fast-deep-equal/es6/react';
import { v4 as uuidv4 } from 'uuid';
import { getApiRequest } from '../../../utils/request';
import Loadable from '../../../components/data/loadable';
import Frame from '../../../components/layout/frame';
import MemberHeader from '../../../components/layout/coach/member-header';
import { useBreadcrumbs } from '../../../contexts/breadcrumbs-provider';
import RightSlideOver from '../../../components/overlays/right-slide-over';
import { findIndexById, findIndexOfItemByValue } from '../../../utils/helpers';
import AddButton from '../../../components/buttons/add-button';
import { firestore } from '../../../config/firebase';
import { useNotifications } from '../../../contexts/notifications-provider';
import SaveBar from '../../../components/pathways/save-bar';
import ContentSearch from '../../../components/pathways/content-search';
import Step from '../../../components/pathways/step';


export default function CoachMemberJourneyMap() {
  const { id, memberId } = useParams();
  const [summary, setSummary] = useState(null);
  const [loading, setLoading] = useState(true);
  const [init, setInit] = useState(false);

  const [map, setMap] = useState({ steps: [] });
  const [initialValues, setInitialValues] = useState(null);
  const [hasChanged, setHasChanged] = useState(false);
  const [activeStep, setActiveStep] = useState(null);

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const { addNotification } = useNotifications();
  const navigate = useNavigate();
  const { setBreadcrumbs } = useBreadcrumbs();
  useEffect(() => {
    setBreadcrumbs([
      {
        href: '/coach/members',
        label: 'Your Members',
      },
    ]);
  }, []);

  const loadJourneyMap = async (mapId, uid) => {
    if (!mapId || !uid) {
      return null;
    }
    // Let's load the profile from firestore
    const mapSnap = await getDoc(doc(firestore, 'journey_maps', mapId));
    
    if (mapSnap.exists()) {
      // Fow now this is enough info, but we can always add more
      return {
        ...mapSnap.data(),
        _id: mapId,
      };
    }
    // Return the default
    return {
      steps: [],
      status: 'unpublished',
      _id: mapId,
      journeyId: mapId,
      uid,
    };
  };

  const load = async (id) => {
    if (!loading) {
      setLoading(true);
    }
    try {
      const result = await getApiRequest(`/coach/journey/${id}`);
      setSummary(result);
      const mapResult = await loadJourneyMap(id, result?.profile?._id);
      // Save the initial value here
      setInitialValues({
        ...mapResult,
        steps: mapResult.steps.map(step => {
          const { items } = step;
          return {
            ...step,
            items: [...items],
          };
        }),
      });
      setMap(mapResult);
      setInit(true);
    } catch (err) {
      Bugsnag.notify(err);
      console.log(err, 'there was an error loading the summary');
    }
    setLoading(false);
  };

  const saveMap = async () => {
    // Validate the map first
    let errorMessage = '';
    const { steps, status } = map;
    if (!steps?.length) {
      errorMessage = 'You cannot save a map with no Steps.';      
    } else if (status === 'published') {
      steps.forEach(step => {
        if (!step.items?.length) {
          errorMessage = 'You cannot save a Published map with empty Steps.';
        }
      });
    }
    if (errorMessage) {
      addNotification({
        type: 'error',
        body: errorMessage || 'There was an unknown error saving the journey map.',
      });
      return;
    }
    try {
      const docRef = doc(firestore, 'journey_maps', map._id);
      await setDoc(docRef, { ...map, guideUpdated: true });
      // Quick clone of the map for initial values...
      setInitialValues({
        ...map,
        steps: map.steps.map(step => {
          const { items } = step;
          return {
            ...step,
            items: [...items],
          };
        }),
      });
      addNotification({
        type: 'success',
        body: 'The map was saved!',
      });
    } catch (err) {
      console.log(err, 'there was an error saving the map');
      addNotification({
        type: 'error',
        body: err.message || 'There was an unknown error saving the journey map.',
      });
    }
  };

  const fetchJourney = async () => {
    try {
      const result = await getApiRequest(`/coach/members?uid=${memberId}`);
      // setProfiles(result.data);
      if (!!result && !!result.results && !!result.results.length) {
        // Just go to the first journey
        navigate(`/coach/member/journey/${result.results[0]._id}`, { replace: true });
        return;
      }
      navigate('/coach/members', { replace: true });
    } catch (err) {
      navigate('/coach/members', { replace: true });
    }
  };


  useEffect(() => {
    if (id) {
      load(id);
    } else if (memberId) {
      // We only have a member id, let's try to grab a journey!
      fetchJourney();
    } else {
      navigate('/coach/members', { replace: true });
    }
  }, [id, memberId]);

  useEffect(() => {
    if (!init) {
      return;
    }
    // Check to see if the map has changed
    const hasChangedFromInitial = !objEqual(initialValues, map);
    // if (hasChanged !== hasChangedFromInitial) {
      setHasChanged(hasChangedFromInitial);
    // }
    
  }, [init, initialValues, map]);

  // Add a step, also open the sidebar
  const addStep = () => {
    const newMap = {...map};
    const index = newMap.steps.length + 1;
    newMap.steps.push({
      _id: uuidv4(),
      index,
      complete: false,
      items: [],
    });
    setMap(newMap);
  };

  const updateStep = (newStep) => {
    // Find the step in the map and update the map
    const stepIndex = findIndexOfItemByValue(map.steps, newStep._id, '_id');
    const newMap = {...map};
    newMap.steps[stepIndex] = newStep;
    setMap(newMap);
  };

  const addItem = (item) => {
    const newStep = {...activeStep};
    let formattedType = 'explore';
    if (item.internal_content_type === 'exercises') {
      formattedType = 'exercise';
    } else if (item.internal_content_type === 'activities2') {
      formattedType = 'activity';
    }
    // Update the step itself, adding the item
    const formattedItem = {
      id: item._id,
      complete: false,
      doses: 1,
      completedDoses: 0,
      type: formattedType,
    };
    newStep.items.push(formattedItem);
    // Make sure our active step stays in sync
    setActiveStep(newStep);
    updateStep(newStep);
  };

  const removeItem = (step, item) => {
    const newStep = {...step};
    // Find the item
    const itemIndex = findIndexById(newStep.items, item.id);
    newStep.items.splice(itemIndex, 1);
    // Make sure our active step stays in sync
    if (step._id === activeStep?._id) {
      setActiveStep(newStep);
    }
    updateStep(newStep);
  };

  const updateStepItems = (step, newItems) => {
    const newStep = {...step};
    newStep.items = newItems;
    // Make sure our active step stays in sync
    if (activeStep?.id === step.id) {
      setActiveStep(newStep);
    }
    updateStep(newStep);
  };

  const removeStep = (step) => {
    if (step.items.length) {
      return;
    }
    const newMap = {...map};
    // Find the step
    const stepIndex = findIndexById(map.steps, step._id, '_id');
    newMap.steps.splice(stepIndex, 1);
    newMap.steps = newMap.steps.map((item, i) => {
      return {
        ...item,
        index: i + 1,
      };
    });
    // Make sure we didn't just delete the active step, if so null it out.
    if (step._id === activeStep?._id) {
      setActiveStep(null);
    }
    setMap(newMap);
  }

  const openItemsSearchForStep = (step) => {
    setActiveStep(step);
    setIsDrawerOpen(true);
  };

  const updatePublished = (newVal) => {
    if (map.status !== newVal) {
      setMap({
        ...map,
        status: newVal,
      });
    }
  };
  const updateDescription = (description) => {
    if (map.description !== description) {
      setMap({
        ...map,
        description,
      });
    }
  };

  const { steps, description } = map || {};

  const journeyToolkitId = summary?.journey?.toolkitId;
  const toolkitOptions = [{
    value: '',
    label: 'Select Mood...',
  }];
  if (summary?.userData?.toolkitIds) {
    const toolkitKeys = Object.keys(summary.userData.toolkitIds);
    for (let i = 0; i < toolkitKeys.length; i++) {
      const tkit = summary.userData.toolkitIds[toolkitKeys[i]];
      toolkitOptions.push({
        value: tkit.toolkitId,
        label: tkit.toolkitName,
      });
    }
  }

  // console.log(map, 'the map');

  return (
    <div className="w-full">

      <Frame fullscreen classes="p-4">
        <Loadable loading={loading}>
        {!!summary && !!map && (
          <>
            <MemberHeader memberUid={summary.journey.uid} journeyId={id} which="map" name={summary.profile.name} toolkitName={summary.journey.toolkitName} />
            <div className="my-8 px-4 mx-auto space-y-8">
              <SaveBar submit={saveMap} changed={hasChanged} published={map.status} updatePublished={updatePublished} />
              <div className="max-w-md">
                <label htmlFor="description" className="block text-sm font-bold text-gray-700 mb-1">
                  Description
                </label>
                <textarea
                  value={description || ''}
                  onChange={(event) => updateDescription(event.target.value)}
                  className={`shadow-sm block w-full sm:text-sm border rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300`}
                  id="description"
                  name="description"
                  rows="2"
                  placeholder="Leave blank to use default"
                />
                <p id={`${id}-description`} className="mt-2 text-sm text-gray-500">
                  Defaults to: [guide] created a personalized roadmap for your [mood] journey based on your goals and kick-off assessment.
                </p>
              </div>
              {steps.map((step) => {
                const { _id: stepId } = step;
                return (
                  <Step key={stepId} updateStepItems={updateStepItems} addItems={openItemsSearchForStep} removeItem={removeItem} step={step} removeStep={removeStep} />
                );
              })}
              <div className="border-b border-gray-100 py-4 my-2">
                <AddButton buttonText="Add Step" onClick={addStep} />
              </div>
            </div>
          </>
        )}
        </Loadable>
      </Frame>

      <RightSlideOver darkBg isOpen={isDrawerOpen} close={() => setIsDrawerOpen(false)} title={`Add Content To Step ${activeStep?.index}`} large>
        {isDrawerOpen && <ContentSearch addItem={addItem} step={activeStep} toolkitIds={toolkitOptions} currentToolkitId={journeyToolkitId} />}
      </RightSlideOver>

    </div>
  );
}
