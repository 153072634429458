import React from 'react';
import HabitProgressEngagement from './habit-progress-engagement';
import HabitProgressSnapshot from './habit-progress-snapshot';

const HabitProgress = ({ data, switchJourneys, userData, journeyData, journey }) => {
  return (
    <div className="mb-20">
      <h3 className="text-[32px] font-bold mb-4">Habit Progress</h3>
      <div className="flex lg:flex-row flex-col items-stretch">
        <div className="lg:w-1/2 w-full mr-7 rounded-md">
          <p className="text-[20px] bg-white pb-3">Snapshot</p>
          <div className="bg-white overflow-hidden rounded-lg border border-[#807F7E]">
            <HabitProgressSnapshot journey={journey} switchJourneys={switchJourneys} userData={userData} journeyData={journeyData} data={data}  />
          </div>
        </div>
        <div className="lg:w-1/2 w-full rounded-md">
          <p className="text-[20px] pb-3 lg:pt-0 pt-3">Engagement Breakdown</p>
          <div className="bg-[#D9D9D9] p-4 h-[90%] rounded-lg">
            <HabitProgressEngagement journeyData={journeyData} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HabitProgress;
