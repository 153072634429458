
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import queryString from 'query-string';
import moment from 'moment';
import { useBreadcrumbs } from '../../contexts/breadcrumbs-provider';
import Heading from '../../components/layout/admin/heading';
import { getApiRequest } from '../../utils/request';
import Loadable from '../../components/data/loadable';
import AdminTable from '../../components/common/admin-table';
import AutoForm from '../../components/form/auto-form';
import Frame from '../../components/layout/frame';


function formatRow(item) {
  const { _id: id, uid, coachUid, updatedAt: updated, email, toolkitName, userName, coachName } = item;
  return {
    toolkitName,
    userName: userName || 'none',
    coachName: coachName || 'none',
    email,
    updated: moment.unix(updated._seconds).format('M/D/Y'),
    uid,
    coachUid,
    id,
  };
}

export default function AdminProfiles() {
  const [results, setResults] = useState([]);
  const [nextPageToken, setNextPageToken] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const [queryParamsString, setQueryParamsString] = useState(null);
  const { setBreadcrumbs } = useBreadcrumbs();
  useEffect(() => {
    setBreadcrumbs([
      {
        href: '/admin/toolkit-assignments',
        label: 'Toolkit Assignments',
      },
    ]);
  }, []);

  const query = queryString.parse(window.location.search);
  const currentQueryParamsString = queryString.stringify(query || {});

  const loadToolkitAssignments = async () => {
    if (!loading) {
      setLoading(true);
    }
    const result = await getApiRequest(`/toolkit-assignments${queryParamsString ? `?${queryParamsString}` : ''}`);
    // setProfiles(result.data);
    setResults(result);
    setNextPageToken(result.pageToken);
    setLoading(false);
  };

  useEffect(() => {
    if (currentQueryParamsString !== queryParamsString) {
      setQueryParamsString(currentQueryParamsString);
    }
  }, [currentQueryParamsString]);


  useEffect(() => {
    if (queryParamsString !== null && queryParamsString === currentQueryParamsString) {
      loadToolkitAssignments();
    }
  }, [queryParamsString]);

  const columns = [
    {
      key: 'toolkitName',
      label: 'Toolkit',
    },
    {
      key: 'email',
      label: 'Email',
    },
    {
      key: 'userName',
      label: 'Member Name',
    },
    {
      key: 'coachName',
      label: 'Coach Name',
    },
    {
      key: 'updated',
      label: 'Last Updated',
    },
    {
      key: 'actions',
      label: '',
      isRight: true,
      render: (row) => {
        if (row.uid && !row.coachUid) {
          return (
            <Link
              to={`/admin/toolkit-assignment/${row.id}/assign-coach`}
              className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Assign Coach
            </Link>
          );
        }
        return null;
      },
    },
  ];

  const actionLinks = [
    {
      label: 'Create Toolkit Assignment',
      href: '/admin/toolkit-assignment',
    },
  ];

  const baseUrl = '/admin/toolkit-assignments';

  const searchForm = {
    type: 'get',
    noContainer: true,
    successRedirect: '/admin/toolkit-assignments',
    fields: [
      {
        name: 'email',
        label: 'Email',
        type: 'email',
      },
      {
        name: 'toolkitId',
        label: 'Choose Toolkit',
        type: 'select',
        optionsUrl: '/options/toolkits',
      },
      {
        name: 'coachUid',
        label: 'Choose Coach',
        type: 'select',
        optionsUrl: '/options/coaches',
      },
    ],
    submitLabel: 'Search Toolkit Assignments',
  };

  return (
      <Frame>
        
        <Heading title="Toolkit Assignments" links={actionLinks} />
        <Loadable loading={loading}>
          <AdminTable results={results} columns={columns} baseUrl={baseUrl} rowFormatter={formatRow} searchTitle="Search Toolkit Assignments" searchForm={<AutoForm {...searchForm} />} />
        </Loadable>
      </Frame>
  );
}
