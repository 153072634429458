import React, { useEffect, useState } from 'react';
import JourneyControlCard from './journey-control-card';
import AutoForm from '../../../form/auto-form';
import useJourneyData from '../../../../hooks/use-journey-data';
import { getMomentFromUnix } from '../../../../utils/helpers';
import DataSideBySideDisplay from '../../../data/data-side-by-side-display';
import { postApiRequest } from '../../../../utils/request';
import { useNotifications } from '../../../../contexts/notifications-provider';

function NewMoodJourney({ hideCard, memberUid, loadJourneys }) {
  console.log(memberUid, 'member uid');
  const newJourneyForm = {
    type: 'post',
    sideBySide: true,
    // fetchRoute: id ? `/toolkit-assignment/${id}` : null,
    // fetchHydrate: (fetched => fetched.toolkit),
    // fetchErrorRedirect: '/admin/toolkit-assignment',
    // fetchErrorMessage: 'There was an error while attempting to load the requested toolkit.',
    beforePost: (values) => {
      return {
        ...values,
        uid: memberUid,
      };
    },
    postRoute: `/guide/toolkit-assignment`,
    successHandler: () => {
      loadJourneys();
      hideCard();
    },
    successNotification: 'The new journey was added. It may take a minute for it to show up.',
    fields: [
      {
        name: 'toolkitId',
        label: 'Step 1: Journey Type',
        helpText: <>Choose the new mood for this for this Journey. <span className="ml-1 text-[#BF41AA]">This can NOT be changed once assigned.</span></>,
        type: 'select',
        optionsUrl: '/options/toolkits',
        required: true,
      },
      {
        name: 'goalId',
        label: 'Step 2: Theme for Journey',
        helpText: 'The theme you confirmed with the Member for this Journey.',
        type: 'select',
        fullWidth: true,
        options: [
          {
            value: '',
            label: `TBD. Auto-populated based on Member's initial selection...`,
          },
        ],
        initialValue: () => '',
      }
    ],
    submitLabel: `Activate`,
  };

  const menu = [
    {
      label: 'Cancel',
      onClick: hideCard,
    },
  ];

  return (
    <JourneyControlCard title="Assign New Mood" menu={menu}>
      <p className="text-[#807F7E] text-[16px] text-left">Started: TBD (based on Member intro assessment completion).</p>
      <AutoForm {...newJourneyForm} />
    </JourneyControlCard>
  );
}

function JourneyCard({ journey, loadJourneys }) {
  const [mode, setMode] = useState('view');
  const isComplete = journey.complete;
  const completedDate = isComplete ? getMomentFromUnix(journey.completedAt._seconds).format(`MMM D, Y`) : null;
  const isPaused = journey.paused;
  const pausedDate = isPaused ? getMomentFromUnix(journey.pausedAt._seconds).format(`MMM D, Y`) : null;
  const isStarted = journey.introSurveyCompletedAt;
  const startedDate = isStarted ? getMomentFromUnix(journey.introSurveyCompletedAt._seconds).format(`MMM D, Y`) : null;
  const isActive = !isComplete && !isPaused;
  const { addNotification } = useNotifications();

  const { goal, toolkitName, toolkitId, _id: journeyId } = journey;
  const { name: goalName, _id: goalId } = goal || {};

  const items = [
    {
      title: 'Step 1: Journey Type',
      description: <span className="ml-1 text-[#BF41AA]">This can NOT be changed once assigned.</span>,
      type: 'text',
      value: toolkitName,
    },
    {
      title: 'Step 2: Theme for Journey',
      description: 'The theme you confirmed with the Member for this Journey.',
      type: 'text',
      fullWidth: true,
      value: goalName || `TBD. Auto-populated based on Member's initial selection...`,
    },
  ];

  const menu = [];
  if (mode === 'edit') {
    menu.push({
      label: 'Cancel',
      onClick: () => setMode('view'),
    });
  } else {
    // If active, you can edit
    if (isActive) {
      menu.push({
        label: 'Edit',
        onClick: () => setMode('edit'),
      });
      if (!isStarted) {
        menu.push({
          label: <span className="text-[#BF41AA]">Delete</span>,
          onClick: async () => {
            try {
              await postApiRequest(`/journey/delete/${journeyId}`);
              addNotification({
                type: 'success',
                body: 'The journey has been deleted.',
              });
              loadJourneys();
              setMode('view');
            } catch (err) {
              console.log(err, 'error');
              addNotification({
                type: 'error',
                body: 'There was an error deleting the journey.',
              });
            }
          },
        });
      }
    } else {
      menu.push({
        label: 'Re-activate',
        onClick: async () => {
          try {
            await postApiRequest(`/journey/activate/${journeyId}`);
            addNotification({
              type: 'success',
              body: 'The journey has been activated.',
            });
            loadJourneys();
            setMode('view');
          } catch (err) {
            console.log(err, 'error');
            addNotification({
              type: 'error',
              body: 'There was an error activating the journey.',
            });
          }
        },
      });
    }
  }

  const journeyEditForm = {
    type: 'post',
    sideBySide: true,
    // fetchRoute: id ? `/toolkit-assignment/${id}` : null,
    // fetchHydrate: (fetched => fetched.toolkit),
    // fetchErrorRedirect: '/admin/toolkit-assignment',
    // fetchErrorMessage: 'There was an error while attempting to load the requested toolkit.',
    postRoute: `/coach/journey/${journeyId}/set-goal2`,
    successNotification: 'The journey was updated.',
    successHandler: () => {
      loadJourneys();
      setMode('view');
    },
    fields: [
      {
        name: 'toolkitId',
        label: 'Step 1: Journey Type',
        helpText: <><span className="ml-1 text-[#BF41AA]">This can NOT be changed once assigned.</span></>,
        type: 'select',
        options: [
          {
            value: toolkitId,
            label: toolkitName,
          },
        ],
        initialValue: () => toolkitId,
        required: true,
      },
      {
        name: 'goalId',
        label: 'Step 2: Theme for Journey',
        helpText: 'The theme you confirmed with the Member for this Journey.',
        type: 'select',
        fullWidth: true,
        optionsUrl: '/options/goals2',
        initialValue: () => goalId || '',
      }
    ],
    submitLabel: `Save`,
  };

  return (
    <JourneyControlCard title="Member" menu={menu}>
      <div className="flex space-x-10">
        {!isStarted && <p className="text-[#807F7E] text-[16px] text-left">Started: TBD</p>}
        {!!isStarted && <p className="text-[#807F7E] text-[16px] text-left">Started: {startedDate}</p>}

        {!!isActive && <p className="text-green-600 text-[16px] text-left">Active</p>}
        {!!isComplete && <p className="text-[#807F7E] text-[16px] text-left">Completed: {completedDate}</p>}
        {!!isPaused && <p className="text-[#807F7E] text-[16px] text-left">Paused: {pausedDate}</p>}
      </div>
      {mode === 'view' && <DataSideBySideDisplay items={items} />}
      {mode === 'edit' && <AutoForm {...journeyEditForm} />}
    </JourneyControlCard>
  );
}

const JourneyControlsModalMood = ({ memberUid }) => {
  const [showNewCard, setShowNewCard] = useState(false);
  const [journeys, setJourneys] = useState(null);
  const { getMemberJourneys } = useJourneyData();

  const loadJourneys = async () => {
    const newJourneys = await getMemberJourneys(memberUid);
    setJourneys(newJourneys);
  };

  useEffect(() => {
    loadJourneys();
  }, []);

  const hasJourneys = !!journeys?.length;

  return (
    <div>
      {!showNewCard && (
        <button
          type="button"
          onClick={() => setShowNewCard(true)}
          className={`bg-[#A38DF2] text-[20px] p-3 rounded-lg text-white w-full lg:w-[98%] ml-0 lg:ml-4 mb-8 text-center shadow-lg hover:opacity-70`}
        >
          New Mood Journey Assignment
        </button>
      )}
      {showNewCard && <NewMoodJourney hideCard={() => setShowNewCard(false)} loadJourneys={loadJourneys} memberUid={memberUid} />}
      {hasJourneys && journeys.map((journey) => <JourneyCard journey={journey} key={journey._id} loadJourneys={loadJourneys} />)}
    </div>
  );
};

export default JourneyControlsModalMood;
