import { useEffect } from 'react';
import Bugsnag from '@bugsnag/browser';
import { useNavigate, useParams } from 'react-router-dom';
import { useBreadcrumbs } from '../../contexts/breadcrumbs-provider';
import { getApiRequest } from '../../utils/request';
import Loadable from '../../components/data/loadable';
import Frame from '../../components/layout/frame';
import MessagePane from '../../components/chat/message-pane';
import ConversationList from '../../components/chat/conversation-list';
import { useConversations } from '../../contexts/conversations-provider';




export default function CoachChat() {
  const { setBreadcrumbs } = useBreadcrumbs();
  const { id, memberId } = useParams();
  const navigate = useNavigate();
  const { conversations, loadingConversations, getConversation } = useConversations();

  useEffect(() => {
    setBreadcrumbs([
      {
        href: '/coach/chat',
        label: 'Chat',
      },
    ]);
  }, []);

  useEffect(() => {
    const loadId = async () => {
      if (memberId) {
        // Fetch the conversation id between this coach and member, then redirect
        try {
          const convoResults = await getApiRequest(`/coach/conversation-id/${memberId}`);
          if (convoResults.conversationId) {
            navigate(`/coach/chat/${convoResults.conversationId}`, { replace: true });
            return;
          }
        } catch (err) {
          Bugsnag.notify(err);
          console.log(err, 'error loading conversation id');
        }
  
        navigate(`/coach/chat`, { replace: true });
      }
    }
    loadId();
  }, [memberId]);


  const hasConversations = !!conversations && !!conversations.length;
  const conversation = getConversation(id, conversations);
  return (
    <Frame fullscreen classes="p-0">
      <Loadable loading={loadingConversations}>
        <div className="h-screen overflow-hidden">
          <div className="grid grid-cols-12 w-full bg-white">
            <div className="col-span-4 h-screen overflow-y-scroll scrollbar-hide border-r border-gray-300">
              <div className="fixed w-full h-16 px-4 bg-white border-b border-gray-300">
                <h2 className="text-3xl font-bold text-primary-500 pt-4">Conversations</h2>
              </div>
              <div className="pt-16">
                {!hasConversations && <p className="p-4 text-gray-700">You don't have any conversations yet.</p>}
                {hasConversations && <ConversationList conversations={conversations} activeId={id} />}
              </div>
            </div>
            <div className="col-span-8 h-screen bg-gray-50 overflow-y-scroll scrollbar-hide">
              <div className="h-16 fixed w-full px-4 bg-white border-b border-gray-300">
              </div>
              <div className="pt-16">
                {!conversation && <p className="p-8 text-center text-gray-600">Select a conversation to chat.</p>}
                {!!conversation && <MessagePane id={id} conversation={conversation} />}
              </div>
            </div>
          </div>
        </div>
      </Loadable>
    </Frame>
  );
}
