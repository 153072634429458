export const radarChartData = [
  {
    date: '12/14/2022 07:45:49',
    goals: [{ GoalA: 6.5, GoalB: 5.3, GoalC: 3.2, GoalD: 8.1, GoalE: 4.6, GoalF: 5.9, GoalG: 3.0, GoalH: 4.7 }],
  },
  {
    date: '11/18/2022 18:50:49',
    goals: [{ GoalA: 4.8, GoalB: 3.6, GoalC: 8.6, GoalD: 7.5, GoalE: 6.3, GoalF: 1.8, GoalG: 8.2, GoalH: 5.6 }],
  },
  {
    date: '01/07/2023 07:45:49',
    goals: [{ GoalA: 9, GoalB: 4, GoalC: 3, GoalD: 1, GoalE: 5, GoalF: 9, GoalG: 7, GoalH: 9 }],
  },
  {
    date: '01/06/2023 18:50:49',
    goals: [{ GoalA: 2, GoalB: 5, GoalC: 7, GoalD: 3, GoalE: 9, GoalF: 3, GoalG: 4, GoalH: 3 }],
  },
];
