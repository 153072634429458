import { useEffect, useState } from 'react';
import { VictoryArea, VictoryChart, VictoryGroup, VictoryPolarAxis, VictoryScatter, VictoryTheme } from 'victory';
import { getRadarAverage, getRadarData } from '../../utils/helpers';

const PerspectiveMeter = ({ filterDate1, filterDate2, closeDrawer, isFilterClicked, setAverage, setSelectedPoint, setSelectedScore, combinedData, goalId, goalMeta }) => {
  const [chartData, setChartData] = useState([]);
  const [maxima, setMaxima] = useState({});
  const [data1, setData1] = useState([]);

  // const getChartData = (date1, date2) => {
  //   var chartDataTemp = [];
  //   if (date1 !== '') {
  //     chartDataTemp.push(getRadarData(date1));
  //   }
  //   if (date2 !== '') {
  //     chartDataTemp.push(getRadarData(date2));
  //   }
  //   setAverage(getRadarAverage(chartDataTemp));
  //   setPrevAvg(average);
  //   chartDataTemp.push({ GoalA: 9, GoalB: 9, GoalC: 9, GoalD: 9, GoalE: 9, GoalF: 9, GoalG: 9, GoalH: 9 });
  //   setMaxima(getMaxima(chartDataTemp));
  //   setData1(processData(chartDataTemp));
  //   setChartData(chartDataTemp);
  // };

  const getChartData = (date1, date2) => {
    var chartDataTemp = [];
    if (date1 !== '') {
      chartDataTemp.push(getRadarData(date1, combinedData));
    }
    if (date2 !== '') {
      chartDataTemp.push(getRadarData(date2, combinedData));
    }
    setAverage(getRadarAverage(chartDataTemp));
    // Not sure what this was for, but going to simulate it
    // chartDataTemp.push({ GoalA: 9, GoalB: 9, GoalC: 9, GoalD: 9, GoalE: 9, GoalF: 9, GoalG: 9, GoalH: 9 });
    const fakeDataWithNinesIndices = Object.keys(combinedData[0].goals[0]);
    const fakeDataWithNines = {};
    fakeDataWithNinesIndices.forEach(key => fakeDataWithNines[key] = 9);
    chartDataTemp.push(fakeDataWithNines);
    setMaxima(getMaxima(chartDataTemp));
    const processedData = processData(chartDataTemp);
    setData1(processedData);
    setChartData(chartDataTemp);
    if (date1 !== date2) {
      setSelectedPoint('');
      setSelectedScore(0);
    } else {
      setSelectedPoint(goalId);
      setSelectedScore(chartDataTemp[1][goalId]);
    }
  }

  const scatter = (data, color) => {
    return (
      <VictoryScatter
        data={data}
        size={2.5}
        style={{
          data: {
            fill: color,
            stroke: color,
            strokeWidth: 4.5,
          },
        }}
        events={[
          {
            target: 'data',
            eventHandlers: {
              onClick: () => {
                return [
                  {
                    target: 'data',
                    mutation: (props) => {
                      setSelectedPoint(props.datum.x);
                      setSelectedScore(chartData[1][props.datum.x]);
                    },
                  },
                ];
              },
            },
          },
        ]}
      />
    );
  };

  const getMaxima = (data) => {
    const groupedData = Object.keys(data[0]).reduce((memo, key) => {
      memo[key] = 9;
      return memo;
    //   memo[key] = Math.max.apply(
    //     Math,
    //     data.map((d) => d[key])
    //   );
    //   return memo;
    }, {});
    return groupedData;
  };

  const processData = (data) => {
    const maxByGroup = getMaxima(data);
    const makeDataArray = (d) => {
      return Object.keys(d).map((key) => {
        return { x: key, y: d[key] / maxByGroup[key] };
      });
    };
    return data.map((datum) => makeDataArray(datum));
  };

  useEffect(() => {
    if (isFilterClicked) {
      getChartData(filterDate1, filterDate2);
      closeDrawer();
      // setSelectedPoint('');
      // setSelectedScore(0);
    }
  }, [isFilterClicked]);

  // useEffect(() => {
  //   getChartData('11/18/22', '11/18/22');
  // }, []);
  useEffect(() => {
    getChartData(filterDate1, filterDate2);
  }, []);

  return (
    <div>
      {data1.length > 0 ? (
        <div className="items-center mt-0">
          <VictoryChart
            polar
            height={500}
            theme={{
              axis: {
                style: {
                  tickLabels: {
                    fill: '#342F3B',
                    padding: 10,
                  },
                  axis: {
                    stroke: '#807F7E',
                  },
                  grid: {
                    stroke: '#807F7E',
                    strokeDasharray: '0',
                    // fill:'white'
                  },
                },
              },
            }}
            style={{
              background: { fill: '#F2F2F2' },
            }}
            domain={{ y: [0, 1.2] }}
            startAngle={90}
            endAngle={450}
          >
            <VictoryPolarAxis
              labelPlacement="vertical"
              tickFormat={(t) => goalMeta[t].shortTitle}
              style={{
                grid: { stroke: '#C3B2FF' },
                tickLabels: {
                  padding: 8,
                  fontSize: 10,
                  //    fontFamily:'freight'
                },
              }}
            />
            <VictoryPolarAxis dependentAxis theme={VictoryTheme.material} style={{ tickLabels: { fill: 'transparent' }, axis: { stroke: 'transparent' } }} />
            <VictoryGroup colorScale={filterDate1 === filterDate2 ? ['#C3B2FF', '#C3B3FF'] : ['#C3B3FF', '#3D3380']} style={{ data: { fillOpacity: 0.2, strokeWidth: 2 } }}>
              <VictoryArea data={data1[0]} interpolation="cardinal" />
              {filterDate2 !== '' ? <VictoryArea data={data1[1]} interpolation="cardinal" /> : <div />}
              {scatter(data1[0], '#A38DF2')}
              {filterDate2 !== '' ? scatter(data1[1], filterDate1 === filterDate2 ? '#A38DF2' : '#3D3380') : <div />}
            </VictoryGroup>
          </VictoryChart>
        </div>
      ) : (
        <div />
      )}
    </div>
  );
};

export default PerspectiveMeter;
