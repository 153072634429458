import React from 'react';
import Label from '../form/label';
import HelpText from '../form/help-text';
import Nl2br from '../common/nl2br';

function DataSwitch({ type, value, extra }) {
  const hasValue = typeof value !== 'undefined' && value !== '';

  const classes = 'w-full bg-[#D9D9D9] border border-[#C5C4C2] py-2  px-4 rounded-md';
  switch (type) {
    case 'textarea':
      return (
        <div className={classes}>
          <Nl2br str={hasValue ? value : '--'} />
        </div>
      );
    case 'switch':
      return (
        <div className={classes}>
          {value ? 'Yes' : 'No'}
        </div>
      );
    case 'time':
      return (
        <div className={classes}>
          {value ? `${value} ${extra?.timezone ? extra.timezone : ''}` : '--'}
        </div>
      );
    default:
      return (
        <div className={classes}>
          {hasValue ? value : '--'}
        </div>
      );
  }
}

function Row({ type, title, description, value, hideLabel = false, fullWidth = false, extra = null }) {
  return (
    <div className={`mt-5 ${!fullWidth ? 'flex flex-row justify-between' : ''} text-left mb-5`}>
      {!hideLabel && (
        <div className="w-[75%]">
          <Label id={title} label={title} sideBySide />
          <HelpText helpText={description} sideBySide />
        </div>
      )}
      <div className={`${!fullWidth ? 'w-[23%] flex flex-row items-center' : 'w-full'} ${hideLabel ? 'w-full' : ''}`}>
        <DataSwitch type={type} value={value} extra={extra} />
      </div>
      {!!hideLabel && !!description && <HelpText helpText={description} sideBySide />}
    </div>
  );
}

function DataSideBySideDisplay({ items }) {
  return (
    <div>
      {items.map((item, i) => {
        return <Row key={`${item.value}_${i}`} {...item} />;
      })}
    </div>
  );
}

export default DataSideBySideDisplay;
