import { Fragment, useState, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import {
  HomeIcon,
  MenuIcon,
  ChatIcon,
  UserIcon,
  LogoutIcon,
  UserGroupIcon,
  XIcon,
} from '@heroicons/react/outline';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { useBreadcrumbs } from '../../../contexts/breadcrumbs-provider'
import { useAuth } from '../../../contexts/auth-provider';
import SimpleNotification from '../../alerts/simple-notification';
import { useConversations } from '../../../contexts/conversations-provider';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Container() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [isSmall, setIsSmall] = useState(false);
  const { hasUnread } = useConversations();
  const location = useLocation();

  // Swap out to the small version of the nav if we are on an analyze screen
  const { pathname } = location || {};
  useEffect(() => {
    // if (!pathname || !pathname.includes('/analyze')) {
    //   setIsSmall(false);
    //   return;
    // }
    setIsSmall(true);

  }, [pathname]);

  const { isActive } = useBreadcrumbs();
  const { user } = useAuth();

  const { displayName: userName } = user || {};

  const nav = [
    { name: 'Dashboard', href: '/coach', icon: HomeIcon },
    { name: 'Your Members', href: '/coach/members', icon: UserGroupIcon },
    { name: `Chat`, href: '/coach/chat', icon: ChatIcon, showIndicator: hasUnread },
  ];
  const bottomNav = [
    { name: userName, href: '/coach/profile', icon: UserIcon },
    { name: 'Logout', href: '/logout', icon: LogoutIcon },
  ];

  return (
    <>
      <div>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog as="div" className="fixed inset-0 flex z-40 md:hidden" onClose={setSidebarOpen}>
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <div className="relative flex-1 flex flex-col max-w-xs w-full bg-primary-500">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 right-0 -mr-12 pt-2">
                    <button
                      type="button"
                      className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                    </button>
                  </div>
                </Transition.Child>
                <div className="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
                  <div className="flex-shrink-0 flex items-center">
                  <Link to="/" className="text-white text-xl font-semibold"><img
                      className="w-auto h-12"
                      src="/dds-logo-cream-2x.png"
                      alt="DDHQ Logo"
                    />
                    </Link>
                  </div>
                  <nav className="mt-5 px-2 space-y-1">
                    {nav.map((item) => (
                      <Link
                        key={item.href}
                        to={item.href}
                        className={classNames(
                          isActive(item.href) ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                          'group flex items-center px-2 py-2 text-base font-medium rounded-md relative'
                        )}
                      >
                        <item.icon
                          className={classNames(
                            isActive(item.href) ? 'text-gray-300' : 'text-gray-400 group-hover:text-gray-300',
                            'mr-4 flex-shrink-0 h-6 w-6'
                          )}
                          aria-hidden="true"
                        />
                        {item.name}
                    {!!item.showIndicator && <span className={`absolute left-2 h-2.5 w-2.5 rounded-full bg-red-400`} />}
                      </Link>
                    ))}
                  </nav>
                </div>
                <div className="flex-shrink-0 flex bg-gray-700 pb-4">
                  <Link to="/logout" className="flex-shrink-0 group block">
                    <div className="flex items-center">
                      {/* <div>
                        <img
                          className="inline-block h-10 w-10 rounded-full"
                          src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                          alt=""
                        />
                      </div> */}
                      <div className="ml-3">
                        <p className="text-base font-medium text-white">{userName}</p>
                        <p className="text-sm font-medium text-gray-400 group-hover:text-gray-300">Sign Out</p>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </Transition.Child>
            <div className="flex-shrink-0 w-14">{/* Force sidebar to shrink to fit close icon */}</div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className={`hidden md:flex ${!isSmall ? 'md:w-64' : 'md:w-20'} md:flex-col md:fixed md:inset-y-0`}>
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex-1 flex flex-col min-h-0 bg-primary-500">
            <div className="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
              <div className="flex items-center flex-shrink-0 px-4 py-4 border-b border-cream">
                
                <Link to="/" className="block w-full text-center">
                  {!isSmall && (
                    <img
                      className="w-48 mx-auto"
                      src="/dds-logo-cream-2x.png"
                      alt="DDHQ Logo"
                    />
                  )}
                  {isSmall && (
                    <span className="text-white text-xl font-semibold">DD</span>
                  )}
                </Link>
              </div>
              <nav className="mt-5 flex-1 px-2 space-y-1">
                {nav.map((item) => (
                  <Link
                    key={item.href}
                    to={item.href}
                    className={classNames(
                      isActive(item.href) ? 'text-white bg-primary-600' : 'text-cream hover:text-white hover:bg-primary-600',
                      'group flex items-center py-4 text-sm font-medium rounded-md relative',
                      !isSmall ? 'pl-6 pr-2' : 'px-5',
                    )}
                  >
                    <item.icon
                      className={classNames(
                        isActive(item.href) ? 'text-white' : 'text-cream group-hover:text-white',
                        'flex-shrink-0 h-6 w-6',
                        !isSmall ? 'mr-3' : '',
                      )}
                      aria-hidden="true"
                    />
                    {!isSmall ? item.name : ''}
                    {!!item.showIndicator && <span className={`absolute left-2 h-2.5 w-2.5 rounded-full bg-red-400`} />}
                  </Link>
                ))}
              </nav>
            </div>
            <div className="flex-shrink-0 flex bg-primary-500 px-2 py-4">
              <nav className="mt-5 flex-1 space-y-1">
                {bottomNav.map((item) => (
                  <Link
                    key={item.href}
                    to={item.href}
                    className={classNames(
                      isActive(item.href) ? 'text-white bg-primary-600' : 'text-cream hover:text-white hover:bg-primary-600',
                      'group flex items-center py-4 text-sm font-medium rounded-md',
                      !isSmall ? 'pl-6 pr-2' : 'px-5',
                    )}
                  >
                    <item.icon
                      className={classNames(
                        isActive(item.href) ? 'text-white' : 'text-cream group-hover:text-white',
                        'flex-shrink-0 h-6 w-6',
                        !isSmall ? 'mr-3' : '',
                      )}
                      aria-hidden="true"
                    />
                    {!isSmall ? item.name : ''}
                  </Link>
                ))}
              </nav>
            </div>
          </div>
        </div>
        <div className={`${!isSmall ? 'md:pl-72' : 'md:pl-24' } flex flex-col flex-1`}>
          <div className="sticky top-0 z-10 md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3 bg-gray-100">
            <button
              type="button"
              className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <MenuIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <main className="flex-1">
            <Outlet />
          </main>
        </div>
        <SimpleNotification />
      </div>
    </>
  )
}