import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { LockClosedIcon } from '@heroicons/react/solid';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useAuth } from '../contexts/auth-provider';
import Container from '../components/layout/default/container';
import Panel from '../components/common/panel';
import Alert from '../components/alerts/alert';

const ForgotPassword = () => {
  const [sent, setSent] = useState(false);
  const { sendForgotPassword } = useAuth();


  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().email('Invalid email address.').required('Email is a required field.'),
    }),
    onSubmit: async (values, actions) => {
      const { email } = values;
      try {
       await sendForgotPassword(email);
       setSent(true);
      } catch (err) {
        actions.setErrors({
          email: 'Invalid email, please try again.',
        });
      }
      actions.setSubmitting(false);
    },
  });

  const regularInputClasses = 'border-gray-300 placeholder-gray-500 text-gray-900 focus:ring-indigo-500 focus:border-indigo-500';
  const errorInputClasses = 'border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500';

  const hasEmailError = formik.touched.email && formik.errors.email;

  return (

    <Container classes="bg-gradient-to-b from-[#7abdc4] via-[#A6D7E8] to-[#FCB484]">
      <div className="flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-sm w-full space-y-8">
          <div className="pb-24 pt-48">
            <div>
              <img
                className="mx-auto h-12 w-auto"
                src="/dds-logo-cream-2x.png"
                alt="Daydreamer Space"
              />
              <h2 className="mt-24 sm:px-8 text-left text-2xl font-medium text-cream">Request A Password Reset</h2>
                
            </div>
            {sent && (
              <div className="py-8">
                <Alert type="success" title="Email sent!" body="Please check your email for the reset password link." />

                <div className="text-center">
                  <Link to="/login" className="font-medium text-indigo-600 hover:text-indigo-500">
                    Ready to login?
                  </Link>
                </div>
              </div>
            )}
            {!sent && (
              <form className="mt-8 sm:px-8 space-y-6" onSubmit={formik.handleSubmit}>
                <input type="hidden" name="remember" defaultValue="true" />
                <div className="rounded-md shadow-sm -space-y-px">
                  <div>
                    <label htmlFor="email-address" className="sr-only">
                      Email address
                    </label>
                    <input
                      {...formik.getFieldProps('email')}
                      id="email-address"
                      name="email"
                      type="email"
                      autoComplete="email"
                      className={`${hasEmailError ? errorInputClasses : regularInputClasses} bg-white relative block w-full px-3 py-4 border rounded-lg focus:outline-none focus:z-10 sm:text-sm`}
                      placeholder="Email Address"
                    />
                  </div>
                  <div>
                    {hasEmailError && (
                      <p className="mt-2 text-sm text-red-600" id="email-error">
                        {formik.errors.email}
                      </p>
                    )}
                  </div>
                </div>


                <div>
                  <button
                    type="submit"
                    disabled={formik.isSubmitting}
                    className="group relative w-full flex justify-center py-4 px-4 border border-transparent text-lg font-medium rounded-lg text-cream bg-primary-500 hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-800"
                  >
                    Send Password Reset
                  </button>
                </div>

                <div className="w-full">
                  <div className="text-sm text-center mt-12">
                    <Link to="/login" className="font-medium text-center underline text-primary-500 hover:text-primary-600">
                      ready to log in?
                    </Link>
                  </div>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    </Container>
  );
};

export default ForgotPassword;
