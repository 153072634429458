import { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import Container from '../components/layout/default/container';
import Frame from '../components/layout/frame';
import { useAuth } from '../contexts/auth-provider';

export default function About() {
  const [loading, setLoading] = useState(true);
  const { hasRole } = useAuth();
  const navigate = useNavigate();
  useEffect(() => {
    if (hasRole(['admin'])) {
      navigate('/admin', { replace: true });
      return;
    }

    if (hasRole(['coach'])) {
      navigate('/coach', { replace: true });
      return;
    }

    setLoading(false);
  }, []);

  // Ensure we don't render anything until we either leave or the user does not have either of the roles we care about
  if (loading) {
    return null;
  }

  return (
    <Container>
      <Frame>
        <div className="py-24 text-center">
          <h1 className="text-4xl">
            Nothing to see here, this is a regular user's dashboard. <Link to="/">go back home</Link>
          </h1>
          <p className="my-24"><Link to="/logout">Logout</Link></p>
        </div>
      </Frame>
    </Container>
  );
}