import moment from 'moment';
import { useEffect, useState } from 'react';
import Nl2br from '../../../common/nl2br';
import RightSlideOver from '../../../overlays/right-slide-over';
import ReflectionsCard from '../reflections/reflections-card';
import MoodMeter from './mood-meter';
import MoodOverTime from './mood-over-time-ported';

const MoodProgress = ({ data, journeyData }) => {
  const [isReflectionDrawerOpen, setIsReflectionDrawerOpen] = useState(false);
  const [reflectionData, setReflectionData] = useState([]);
  const [singleReflectionData, setSingleReflectionData] = useState();
  const [windowWidth, setWindowWidth] = useState();

  useEffect(() => {
    setWindowWidth(window.innerWidth);
  }, []);


  // console.log(data, 'this is data');

  return (
    <div className="mb-20">
      <h3 className="text-[32px] font-bold mb-4">Mood Progress</h3>
      {windowWidth > 1200 ? (
        <>
          <div className="flex lg:flex-row flex-col items-center justify-between w-full">
            <div className="flex flex-row items-center justify-between w-full">
              <div className="flex flex-row items-center justify-between w-1/2 pb-3">
                <p className="text-[20px] bg-white">Mood over time</p>
                <button className="bg-[#A38DF2] w-[40%] px-3 py-1 rounded-lg text-white shadow-xl lg:mr-[1rem] hover:opacity-70" onClick={() => setIsReflectionDrawerOpen(true)}>
                  View All Reflections
                </button>
              </div>
              <p className="text-left w-1/2 text-[20px] pb-3 md:pt-0 pt-3 pl-[1rem]">Mood meter</p>
            </div>
          </div>
          <div className="flex lg:flex-row flex-col items-stretch">
            <div className="lg:w-1/2 w-full lg:mr-7 mr-0 rounded-md bg-[#D9D9D9] p-4">
              <MoodOverTime
                journeyData={journeyData}
                setReflectionData={setReflectionData}
                setSingleReflectionData={setSingleReflectionData}
                setIsReflectionDrawerOpen={setIsReflectionDrawerOpen}
              />
            </div>
            <div className="lg:w-1/2 w-full p-10 rounded-md bg-[#D9D9D9]">
              <MoodMeter moodAvg={data?.moodScore} moodMetric={data?.moodMetric} lastWeek={data?.previousMoodScore} average={data?.moodAverage} />
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="flex lg:flex-row flex-col items-stretch">
            <div className="flex flex-row items-center justify-between w-full lg:w-1/2 pb-3">
              <p className="text-[20px] bg-white">Mood over time</p>
              <button className="bg-[#A38DF2] w-[40%] px-3 py-1 rounded-lg text-white shadow-xl hover:opacity-70" onClick={() => setIsReflectionDrawerOpen(true)}>
                Reflections
              </button>
            </div>
            <div className="lg:w-1/2 w-full lg:mr-7 mr-0 rounded-md bg-[#D9D9D9] p-4">
              <MoodOverTime
                journeyData={journeyData}
                setReflectionData={setReflectionData}
                setSingleReflectionData={setSingleReflectionData}
                setIsReflectionDrawerOpen={setIsReflectionDrawerOpen}
              />
            </div>
            <p className="text-left w-1/2 text-[20px] pb-3 lg:pt-0 pt-3 lg:pl-7">Mood meter</p>
            <div className="lg:w-1/2 w-full p-10 rounded-md bg-[#D9D9D9]">
              <MoodMeter moodAvg={data?.moodScore} moodMetric={data?.moodMetric} lastWeek={data?.previousMoodScore} average={data?.moodAverage} />
            </div>
          </div>
        </>
      )}
      <RightSlideOver
        isOpen={isReflectionDrawerOpen}
        close={() => {
          setSingleReflectionData();
          setIsReflectionDrawerOpen(false);
        }}
        title="Member Reflections"
        darkBg
        isNew
      >
        <div>
          {singleReflectionData ? (
            <ReflectionsCard
              text={<Nl2br str={singleReflectionData?.reflections?.join("\n")} />}
              date={moment(singleReflectionData.x).format('MMM DD, YYYY')}
              score={singleReflectionData.y}
            />
          ) : reflectionData.length > 0 ? (
            reflectionData
              .sort((a, b) => new Date(a.x) - new Date(b.x))
              .map((item) => (
                <ReflectionsCard
                  key={item.x}
                  text={<Nl2br str={item?.reflections?.join("\n")} />}
                  date={moment(item.x).format('MMM DD, YYYY')}
                  score={item.y}
                />
              ))
          ) : (
            <p>No reflections yet</p>
          )}
        </div>
      </RightSlideOver>
    </div>
  );
};

export default MoodProgress;
