import { useState, useEffect } from 'react';
import ReactPlayer from 'react-player';
import { getApiRequest } from '../../utils/request';
import { findSmallestVideo } from '../../utils/helpers';

function Videplayer({ videoUrl }) {
  const [video, setVideo] = useState(null);

  const loadVideoInfo = async (url) => {
    try {
      const videoIdParts = url.split('/');
      const videoId = videoIdParts[videoIdParts.length - 1];
      const result = await getApiRequest(`/video/${videoId}`);
      // Extract the relevant video info
      const { files, pictures } = result.response;
      // For now just take the smallest video file, which should be the first one
      const smallestVideo = findSmallestVideo(files);
      const firstPicture = pictures.base_link;
      // console.log(files, 'the files');
      // console.log(smallestVideo, 'smallest video');
      setVideo({
        file: smallestVideo,
        image: firstPicture,
      });
    } catch (err) {
      console.log(err, 'error loading video');
    }
  };

  useEffect(() => {
    // Fetch our real video url if we have one
    if (!videoUrl) {
      if (video) {
        return;
      }
    }
    loadVideoInfo(videoUrl);
  }, [videoUrl]);

  return (
    <div>
      <ReactPlayer width="100%" height="100%" controls url={video?.file?.link || ''} />
    </div>
  );
}

export default Videplayer;
