import { initialsFromName } from "../../utils/helpers";



// Placeholder avatar if we have no image
function PlaceholderAvatar({ initials, size, curve, bg, withBubble, bubbleSize }) {
  return (

    <span className="inline-block relative">
      <span className={`inline-flex items-center justify-center ${size} ${curve} ${bg}`}>
        <span className="font-medium leading-none text-white">{initials}</span>
      </span>
      {!!withBubble && <span className={`absolute top-0 right-0 block ${bubbleSize} rounded-full ring-2 ring-white bg-red-400`} />}
    </span>
  );
}

export default function Avatar({ photo, name, lg = false, bg = 'bg-gray-500', xl = false, hidePlaceholder = false, withBubble = false, rounded = false }) {
  const size = lg ? 'h-14 w-14' : xl ? 'w-48 h-48' : 'h-10 w-10';
  const bubbleSize = lg ? 'h-3.5 w-3.5' : xl ? 'h-4 w-4' : 'h-2.5 w-2.5';
  const curve = xl && !rounded ? 'rounded-lg' : 'rounded-full';
  if (!photo && hidePlaceholder) {
    return null;
  }
  if (!photo) {
    const initials = initialsFromName(name);
    return <PlaceholderAvatar initials={initials} size={size} curve={curve} bg={bg} withBubble={withBubble} bubbleSize={bubbleSize} />;
  }
  return (
    <span className="inline-block relative">
      <img
        className={`${size} ${curve}`}
        src={photo}
        alt={`Avatar for ${name}`}
      />
      {!!withBubble && <span className={`absolute top-0 right-0 block ${bubbleSize} rounded-full ring-2 ring-white bg-red-400`} />}
    </span>
  );
}
