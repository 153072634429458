import algoliasearch from "algoliasearch/lite";
import { findIndexById } from "./helpers";

// example options
// {
//   hitsPerPage: 10,
//   filters: objectID:123 OR objectID:456
// }
export function fetchFromAlgolia(indexName, options, query, page, facets) {
  const client = algoliasearch(process.env.REACT_APP_ALGOLIA_APP_ID, process.env.REACT_APP_ALGOLIA_KEY);
  const index = client.initIndex(indexName);

  const searchParams = !!facets ? {
    ...options,
    facets
  } : { ...options };
  if (!searchParams.page) {
    searchParams.page = page || 0;
  }
  return index.search(query, searchParams);

}

export function searchContent(query, options, page, facets) {
  return fetchFromAlgolia('content', options, query, page, facets);
}

// Takes an array of object ids, and returns results from the content index in the order of the original list
export async function fetchContentItems(items) {
  if (!items?.length) {
    return [];
  }
  const filters = `status:published AND (${items.map(item => `objectID:${item}`).join(' OR ')})`;
  const options = {
    filters,
    hitsPerPage: filters.length,
  };
  const results = await searchContent('', options);
  const { hits } = results;
  // Now put the results into the same order as the original items array
  const ordered = [];
  for (let i = 0; i < items.length; i++) {
    const id = items[i];
    const index = findIndexById(hits, id, '_id');
    if (index > -1) {
      ordered.push(hits[index]);
    }
  }
  return ordered;
}

/**
 * Convert the results from an algolia request to one that matches the format of admin results.
 * @param {object} results Results from an algolia request 
 */
export function convertResultsToAdminResults(results) {
  // const { from, to, total, next_page_url: next, prev_page_url: prev, current_page: current } = results;
  const { hits, nbHits, nbPages, page, hitsPerPage } = results;
  const from = nbHits > 0 ? 1 + (page * hitsPerPage) : 0;
  const to = nbHits > 0 ? (page * hitsPerPage) + hitsPerPage : 0;
  const hasPrev = page > 0;
  const hasNext = (page + 1) > nbPages;
  return {
    from,
    to: to > nbHits ? nbHits : to,
    prev_page_url: hasPrev ? `?page=${page - 1}` : null,
    next_page_url: hasNext ? `?page=${page + 1}` : null,
    total: nbHits,
    current_page: page,
    data: hits,
  };
}
