import React from 'react';

function Label({ id, label, hidden, sideBySide = false }) {
  if (!label) {
    return null;
  }
  const className = hidden ? 'sr-only' : sideBySide ? 'text-[18px]' : 'block text-sm font-medium text-gray-700 mb-1';
  return (
    <label htmlFor={id} className={className}>
      {label}
    </label>
  );
}

export default Label;
