import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useBreadcrumbs } from '../../contexts/breadcrumbs-provider';
import Heading from '../../components/layout/admin/heading';
import AutoForm from '../../components/form/auto-form';
import Frame from '../../components/layout/frame';
import { timezoneOptions, statesOptions, daysOfWeek } from '../../utils/helpers';

const CoachProfileEdit = () => {
  const { setBreadcrumbs } = useBreadcrumbs();
  const { id } = useParams();

  useEffect(() => {
    setBreadcrumbs([
      {
        href: '/coach/profile',
        label: 'My Profile',
      },
      {
        href: `/coach/profile-edit`,
        label: `Update My Profile`,
      },
    ]);
  }, [id]);


  const fields = [
    {
      name: 'name',
      label: 'Name',
      type: 'text',
      required: true,
    },
    {
      name: 'email',
      label: 'Email',
      type: 'email',
      required: true,
      helpText: 'Changing this email will have no effect on your login credentials.',
    },
    {
      name: 'calendly',
      label: 'Calendly Link',
      type: 'text',
      helpText: 'Please include the full URL to your Calendly page.',
    },
    {
      name: 'phone',
      label: 'Phone',
      type: 'phone',
      // required: true,
    },
    {
      name: 'pronouns',
      label: 'Pronouns',
      type: 'select',
      options: [
        {
          value: '',
          label: 'Select...',
        },
        {
          value: 'he/him',
          label: 'He/Him',
        },
        {
          value: 'she/her',
          label: 'She/Her',
        },
        {
          value: 'they/them',
          label: 'They/Them',
        },
        {
          value: 'other',
          label: 'Other',
        },
      ],
      // required: true,
    },
    {
      name: 'photo',
      type: 'cloudinary-direct-upload',
      label: 'Profile Photo',
      presetName: 'profile',
    },
    {
      name: 'shippingAddress',
      label: 'Shipping Address',
      type: 'text',
      // required: true,
    },
    {
      name: 'shippingAddress2',
      label: 'Address Line 2',
      type: 'text',
      // required: true,
    },
    {
      name: 'shippingCity',
      label: 'Shipping City',
      type: 'text',
      // required: true,
    },
    {
      name: 'shippingState',
      label: 'Shipping State',
      type: 'select',
      options: statesOptions(),
      // required: true,
    },
    {
      name: 'shippingZip',
      label: 'Shipping Zip',
      type: 'text',
      // required: true,
    },
    {
      name: 'birthday',
      label: 'Birthday',
      type: 'date',
      // required: true,
    },
    {
      name: 'description',
      label: 'Overview / Description',
      type: 'textarea',
      helpText: 'Write a short bio about yourself that members can read.',
    },
    {
      name: 'interests',
      label: 'Interests',
      type: 'text',
      helpText: `Write a short list of things you're into for your members to read, like reading, coffee, etc.`,
    },
    {
      name: 'experience',
      label: 'Experience',
      type: 'textarea',
      helpText: `Write about your relevant professional experiences. We recommend including things like school, other programs, how long you've been a DD coach for, etc.`,
    },

    {
      name: 'timezone',
      label: 'Timezone',
      type: 'select',
      options: timezoneOptions(),
      required: true,
    },
    
  ];


  daysOfWeek().forEach(day => {
    const start = `${day.toLowerCase()}_start`;
    const end = `${day.toLowerCase()}_end`;

    fields.push({
      name: start,
      label: `Availibility Start Time For ${day}`,
      type: 'time',
      helpText: `If you are not available on ${day}, just leave this blank.`,
    });
    fields.push({
      name: end,
      label: `Availibility End Time For ${day}`,
      type: 'time',
      helpText: `If you are not available on ${day}, just leave this blank.`,
    });
  });

  const profileForm = {
    type: 'post',
    fetchRoute: `/my-profile`,
    fetchHydrate: (fetched => fetched.profile),
    fetchErrorRedirect: '/coach/profile',
    fetchErrorMessage: 'There was an error while attempting to load your profile.',
    postRoute: `/my-profile`,
    successRedirect: '/coach/profile',
    successNotification: 'Your profile was saved.',
    fields,
    submitLabel: `Update Profile`,
  };

  return (
    <Frame>
      <Heading title="Update Profile" />
      <div className="max-w-2xl mx-auto py-12">
        <AutoForm {...profileForm} />
      </div>
    </Frame>
  );
}

export default CoachProfileEdit;
