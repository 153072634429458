
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import queryString from 'query-string';
import moment from 'moment';
import { useBreadcrumbs } from '../../contexts/breadcrumbs-provider';
import Avatar from '../../components/common/avatar';
import Heading from '../../components/layout/admin/heading';
import { getApiRequest } from '../../utils/request';
import { capFirstLetter, statesOptions, timezoneOptions } from '../../utils/helpers';
import Loadable from '../../components/data/loadable';
import AdminTable from '../../components/common/admin-table';
import AutoForm from '../../components/form/auto-form';
import Frame from '../../components/layout/frame';


function formatRow(item) {
  const { _id: id, updatedAt: updated, email, phone, name, shippingState, role, photo, active } = item;
  const capRole = capFirstLetter(role);
  return {
    name,
    photo: <Avatar photo={photo} name={name} />,
    info: <>{email}<br />{phone}{!!shippingState ? <><br />State: {shippingState}</> : ''}</>,
    // shippingState,
    // email,
    updated: moment.unix(updated._seconds).format('M/D/Y'),
    // phone,
    active: capRole !== 'Member' ? 'N/A' : active ? 'Active' : 'Inactive',
    role: capRole,
    id,
  };
}

export default function AdminProfiles() {
  const [results, setResults] = useState([]);
  const [nextPageToken, setNextPageToken] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const [queryParamsString, setQueryParamsString] = useState(null);
  const { setBreadcrumbs } = useBreadcrumbs();
  useEffect(() => {
    setBreadcrumbs([
      {
        href: '/admin/profiles',
        label: 'Profiles',
      },
    ]);
  }, []);

  const query = queryString.parse(window.location.search);
  const currentQueryParamsString = queryString.stringify(query || {});

  const loadProfiles = async () => {
    if (!loading) {
      setLoading(true);
    }
    const result = await getApiRequest(`/profiles${queryParamsString ? `?${queryParamsString}` : ''}`);
    // setProfiles(result.data);
    setResults(result);
    // setNextPageToken(result.pageToken);
    setLoading(false);
  };

  useEffect(() => {
    if (currentQueryParamsString !== queryParamsString) {
      setQueryParamsString(currentQueryParamsString);
    }
  }, [currentQueryParamsString]);


  useEffect(() => {
    if (queryParamsString !== null && queryParamsString === currentQueryParamsString) {
      loadProfiles();
    }
  }, [queryParamsString]);

  const columns = [
    {
      key: 'photo',
      label: 'Photo',
    },
    {
      key: 'name',
      label: 'Name',
      isBold: true,
    },
    {
      key: 'info',
      label: 'Contact Info',
    },
    // {
    //   key: 'email',
    //   label: 'Email',
    // },
    // {
    //   key: 'phone',
    //   label: 'Phone',
    // },
    // {
    //   key: 'shippingState',
    //   label: 'State',
    // },
    {
      key: 'active',
      label: 'Active',
      isBold: true,
    },
    {
      key: 'role',
      label: 'Role',
      isBold: true,
    },
    {
      key: 'updated',
      label: 'Last Updated',
    },
    {
      key: 'actions',
      label: '',
      isRight: true,
      render: (row) => (
        <>

          <Link
            to={`/admin/profile-view/${row.id}`}
            className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            View
          </Link>
          <Link
            to={`/admin/profile/${row.id}`}
            className="ml-1 inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Edit
          </Link>
          <Link
            to={`/admin/guide-assign/${row.id}`}
            className="ml-1 inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            New Guide
          </Link>

        </>
      ),
    },
  ];

  // const actionLinks = [
  //   {
  //     label: 'Create Profile',
  //     href: '/admin/profile',
  //   },
  // ];

  const baseUrl = '/admin/profiles';

  const searchForm = {
    type: 'get',
    noContainer: true,
    successRedirect: '/admin/profiles',
    fields: [
      {
        name: 'email',
        label: 'Email',
        type: 'email',
      },
      {
        name: 'role',
        label: 'Role',
        type: 'select',
        options: [
          {
            value: '',
            label: 'Select...',
          },
          {
            value: 'admin',
            label: 'Admin',
          },
          {
            value: 'coach',
            label: 'Coach',
          },
          {
            value: 'member',
            label: 'Member',
          },
        ],
      },
      {
        name: 'shippingState',
        label: 'Shipping State',
        type: 'select',
        options: statesOptions(),
      },
      {
        name: 'timezone',
        label: 'Timezone',
        type: 'select',
        options: timezoneOptions(),
      }
    ],
    submitLabel: 'Search Profiles',
  };

  return (
      <Frame>
        
        <Heading title="Profiles" />
        <Loadable loading={loading}>
          <AdminTable results={results} columns={columns} baseUrl={baseUrl} rowFormatter={formatRow} searchTitle="Search Profiles" searchForm={<AutoForm {...searchForm} />} />
        </Loadable>
      </Frame>
  );
}
