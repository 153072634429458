
import { CircularProgressBar } from "@tomik23/react-circular-progress-bar";
import DataWidget from './data-widget';


export default function MoodMeter({ moodAverage, moodMetric, average, lastWeek }) {

  const percentForChart = moodAverage ? Math.round(moodAverage * 10) : 0;

  const moodAverageToDisplay = moodAverage ? moodAverage.toFixed(1).replace('.0', '') : '--';
  const averageToDisplay = average ? average.toFixed(1).replace('.0', '') : '--';
  const lastWeekToDisplay = lastWeek ? lastWeek.toFixed(1).replace('.0', '') : '--';


  const circleProps = {
    percent: percentForChart, // is require
    colorSlice: "#333333",
    colorCircle: "#cccccc",
    // fontColor: "#365b74",
    // fontSize: "1.6rem",
    // fontWeight: 400,
    size: 150,
    stroke: 6,
    strokeBottom: 6,
    speed: 70,
    cut: 0,
    rotation: 127,
    fill: "transparent",
    unit: "",
    textPosition: "0.35em",
    animationOff: false,
    strokeDasharray: "10,1",
    inverse: false,
    round: true,
    number: false,
    cut: 20,
    linearGradient: ["#08517b", "#08517b"],
    // styles: {
    //   borderRadius: "50%",
    //   boxShadow: "inset 0 0 25px 10px #a2caff"
    // }
  };

  return (
    <DataWidget title="Mood Meter" description={moodMetric?.short}>
      <div className="grid grid-cols-4 my-2">
        <div className="col-span-1 text-center relative">
          <div className="absolute top-12 pt-2 text-darkblue w-full">
            <p className="text-darkblue font-bold text-md">{lastWeekToDisplay}</p>
            <p className="text-darkblue text-xs">previous</p>
          </div>
        </div>
        <div className="col-span-2 text-center relative">
          <CircularProgressBar {...circleProps}>
            <div className="absolute top-12 pt-1 text-darkblue w-full">
              <p className="text-4xl font-semibold text-center ">{moodAverageToDisplay}</p>
            </div>
          </CircularProgressBar>
        </div>
        <div className="col-span-1 text-center relative">
          <div className="absolute top-12 pt-2 text-darkblue w-full">
            <p className="text-darkblue font-bold text-md">{averageToDisplay}</p>
            <p className="text-darkblue text-xs">average</p>
          </div>
        </div>
      </div>
    </DataWidget>
        
  );
}

