import React, { useEffect } from 'react';
import { useBreadcrumbs } from '../../contexts/breadcrumbs-provider';
import Heading from '../../components/layout/admin/heading';
import AutoForm from '../../components/form/auto-form';
import Frame from '../../components/layout/frame';
import Loadable from '../../components/data/loadable';
import Panel from '../../components/common/panel';

const AdminSwitchGuide = () => {
  const { setBreadcrumbs } = useBreadcrumbs();

  useEffect(() => {
    setBreadcrumbs([
      {
        href: '/admin/switch-guide',
        label: `Switch Guide`,
      },
    ]);
  }, []);


  const guideSwitchForm = {
    type: 'post',
    postRoute: `/switch-guide`,
    successRedirect: '/admin/dashboard',
    successNotification: 'The guide was switched out with the new one.',
    fields: [
      {
        name: 'existingCoachUid',
        label: 'Choose An Existing Guide',
        type: 'select',
        required: true,
        optionsUrl: '/options/coaches',
        helpText: 'This is the guide that will be replaced.',
      },
      {
        name: 'newCoachUid',
        label: 'Choose A New Guide',
        type: 'select',
        required: true,
        optionsUrl: '/options/coaches',
        helpText: 'This is the coach that will replace the existing one.',
      },
    ],
    submitLabel: `Switch Guide`,
  };


  return (
    <Frame>
      <Heading title={`Switch Guide`} />
      <Loadable>
        <div className="max-w-2xl mx-auto py-12">
        <div className="p-8">
            <Panel>
              <div className="px-4 py-5 sm:p-0">
                <p className="text-gray-700 p-4">Note: Please be patient when submitting this form.  Depending on the number of journeys this guide is associated with, this may take some time.  If the process times out, please try it again (it will progress each time, so eventually it will get through everything if it does timeout).</p>
              </div>
            </Panel>
          </div>
          <AutoForm {...guideSwitchForm} />
        </div>
      </Loadable>
    </Frame>
  );
}

export default AdminSwitchGuide;
