import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useBreadcrumbs } from '../../contexts/breadcrumbs-provider';
import Heading from '../../components/layout/admin/heading';
import AutoForm from '../../components/form/auto-form';
import Frame from '../../components/layout/frame';
import { timezoneOptions, statesOptions } from '../../utils/helpers';

const AdminProfile = () => {
  const { setBreadcrumbs } = useBreadcrumbs();
  const { id } = useParams();

  useEffect(() => {
    setBreadcrumbs([
      {
        href: '/admin/profiles',
        label: 'Profiles',
      },
      {
        href: `/admin/profile${id ? `/${id}` : ''}`,
        label: `${id ? 'Update' : 'Create'} Profile`,
      },
    ]);
  }, [id]);


  const profileForm = {
    type: 'post',
    fetchRoute: id ? `/profile/${id}` : null,
    fetchHydrate: (fetched => {
      const p = {
        ...fetched.profile,
        active: fetched.profile.active ? 'yes' : 'no',
      };
      return p;
    }),
    fetchErrorRedirect: '/admin/profiles',
    fetchErrorMessage: 'There was an error while attempting to load the requested profile.',
    postRoute: `/profile${id ? `/${id}` : ''}`,
    successRedirect: '/admin/profiles',
    successNotification: 'The profile was saved.',
    fields: [
      {
        name: 'name',
        label: 'Name',
        type: 'text',
        required: true,
      },
      {
        name: 'active',
        label: 'Membership Active',
        type: 'select',
        options: [
          {
            value: 'yes',
            label: 'Yes',
          },
          {
            value: 'no',
            label: 'No',
          },
        ],
        // required: true,
      },
      {
        name: 'email',
        label: 'Email',
        type: 'email',
        required: true,
        helpText: 'Changing this email will have no effect on the login credentials.',
      },
      {
        name: 'phone',
        label: 'Phone',
        type: 'phone',
        // required: true,
      },
      {
        name: 'pronouns',
        label: 'Pronouns',
        type: 'select',
        options: [
          {
            value: '',
            label: 'Select...',
          },
          {
            value: 'he/him',
            label: 'He/Him',
          },
          {
            value: 'she/her',
            label: 'She/Her',
          },
          {
            value: 'they/them',
            label: 'They/Them',
          },
          {
            value: 'other',
            label: 'Other',
          },
        ],
        // required: true,
      },

      {
        name: 'shippingAddress',
        label: 'Shipping Address',
        type: 'text',
        // required: true,
      },
      {
        name: 'shippingAddress2',
        label: 'Address Line 2',
        type: 'text',
        // required: true,
      },
      {
        name: 'shippingCity',
        label: 'Shipping City',
        type: 'text',
        // required: true,
      },
      {
        name: 'shippingState',
        label: 'Shipping State',
        type: 'select',
        options: statesOptions(),
        // required: true,
      },
      {
        name: 'shippingZip',
        label: 'Shipping Zip',
        type: 'text',
        // required: true,
      },
      {
        name: 'birthday',
        label: 'Birthday',
        type: 'date',
        // required: true,
      },
      {
        name: 'timezone',
        label: 'Timezone',
        type: 'select',
        options: timezoneOptions(),
        required: true,
      },
    ],
    submitLabel: `${id ? 'Update' : 'Create'} Profile`,
  };

  return (
    <Frame>
      <Heading title={`${id ? 'Update' : 'Create'} Profile`} />
      <div className="max-w-2xl mx-auto py-12">
        <AutoForm {...profileForm} />
      </div>
    </Frame>
  );
}

export default AdminProfile;
