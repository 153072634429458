import React from 'react';

export default function DataWidget({ title, description, children }) {

  return (
    <div className="bg-gray-50 p-3 rounded-lg shadow">
      <h6 className="text-gray-400 text-sm font-semibold mb-2">{title}</h6>
      {!!description && <p className="text-xs text-gray-400 mb-2">{description}</p>}
      {children}
    </div>
  );
}
