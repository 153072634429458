import React, { useState, useEffect } from 'react';
import { daysOfWeekOptions, timezonePrettyName, transformFormToView } from '../../../../utils/helpers';
import AutoForm from '../../../form/auto-form';
import moment from 'moment';
import JourneyControlCard from './journey-control-card';
import DataSideBySideDisplay from '../../../data/data-side-by-side-display';
import Loadable from '../../../data/loadable';
import { getApiRequest } from '../../../../utils/request';

const JourneyControlsModalChat = ({ memberUid, summary }) => {
  const [mode, setMode] = useState('view');
  const [loading, setLoading] = useState(true);
  const [settings, setSettings] = useState(null);

  const load = async () => {
    setLoading(true);
    try {
      const result = await getApiRequest(`/chat-settings/${memberUid}`);
      setSettings(result.settings);
    } catch (err) {
      console.log(err, 'there was an error loading the summary');
    }
    setLoading(false);
  };

  useEffect(() => {
    load();
  }, []);

  if (loading) {
    return <Loadable loading />;
  }
  const timezone = summary?.profile?.timezone;
  const prettyTimezone = timezone ? timezonePrettyName(timezone, 'short') : null;

  const chatForm = {
    type: 'post',
    postRoute: `/chat-settings/${memberUid}`,
    sideBySide: true,
    successNotification: 'The chat settings have been updated.',
    successHandler: () => {
      load();
      setMode('view');
    },
    fields: [
      {
        name: 'member',
        label: 'Step 1: Member App Notification to Chat',
        type: 'switch',
        helpText: 'Is this Member having chat check-ins? If no, then questions below will serve as internal guidance and organization only.',
        initialValue: () => !!settings?.member,
      },
      {
        name: 'guide',
        label: 'Step 2: Guide Notification to Chat',
        type: 'switch',
        helpText: 'Do you (the Guide) want to be notified to stay organized and check-in with this Member? If no, then questions below will serve as internal guidance and organization only.',
        initialValue: () => !!settings?.guide,
      },
      {
        name: 'frequency',
        label: 'Step 3: Frequency of Check-ins',
        type: 'select',
        helpText: 'How often are the app chat notifications to the Member and/or check-in notifications to the Guide?',
        options: [
          {
            value: 'Every Other Week',
            label: 'Every Other Week',
          },
          {
            value: 'Weekly',
            label: 'Weekly',
          },
          {
            value: 'None',
            label: 'None',
          },
        ],
        initialValue: () => settings?.frequency || 'every other week',
      },
      {
        name: 'startDate',
        label: 'Step 4: First Check-in Date',
        type: 'date',
        helpText: 'Set the date for when the first notification to Member and/or Guide will be. This will not change going forward (only applicable for first ever check-in).',
        // required: true,
        initialValue: () => {
          // return startDate ? startDate : moment().format('MM/DD/Y');
          return settings?.startDate ? settings?.startDate : moment().format('MM/DD/Y');
        },
      },
      {
        name: 'day',
        label: 'Step 5: Check-in Day',
        type: 'select',
        options: daysOfWeekOptions(),
        helpText: `This should correspond to the same 'day' as in Step 3 unless there is a change. Represents the day for check-in notifications to either Member and Guide.`,
        initialValue: () => settings?.day || '',
      },
      {
        name: 'time',
        label: 'Step 6: Check-in Time',
        // type: 'browser-time',
        type: 'time',
        timezone: prettyTimezone,
        helpText: `Represents the time for check-in notifications to either Member and/or Guide. Set this in intervals of 15 minutes. Keep in mind the time is listed in the Member's timezone.`,
        initialValue: () => settings?.time || '',
      },
    ],
    submitLabel: 'Save',
  };

  console.log(prettyTimezone, 'prett');

  const items = transformFormToView(chatForm, settings);

  const menu = mode === 'view' ? 
    [
      {
        label: 'Edit',
        onClick: () => setMode('edit'),
      },
    ]
    : [
      {
        label: 'View',
        onClick: () => setMode('view'),
      },
    ];

  return (
    <div>
      {mode === 'edit' && (
        <JourneyControlCard title="Chat" menu={menu}>
          <AutoForm {...chatForm} />
        </JourneyControlCard>
      )}

      {mode === 'view' && (
        <JourneyControlCard title="Chat" menu={menu}>
          <DataSideBySideDisplay items={items} />
        </JourneyControlCard>
      )}
    </div>
  );
};

export default JourneyControlsModalChat;
