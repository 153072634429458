export const habitChartData = [
  {
    date: '02/06/2022 04:21:51',
    toolkit: 'Energize',
    day: 'Sun',
    frequency: 3,
    duration: 183,
    time: 549,
  },
  {
    date: '02/18/2022 18:31:33',
    toolkit: 'Energize',
    day: 'Fri',
    frequency: 4,
    duration: 16,
    time: 64,
  },
  {
    date: '02/2022/2022 10:00:35',
    toolkit: 'Focus',
    day: 'Tue',
    frequency: 6,
    duration: 5,
    time: 30,
  },
  {
    date: '02/23/2022 21:49:01',
    toolkit: 'Focus',
    day: 'Wed',
    frequency: 3,
    duration: 5,
    time: 15,
  },
  {
    date: '03/03/2022 22:08:05',
    toolkit: 'Chill',
    day: 'Thu',
    frequency: 5,
    duration: 78,
    time: 390,
  },
  {
    date: '03/09/2022 22:52:16',
    toolkit: 'Focus',
    day: 'Wed',
    frequency: 4,
    duration: 135,
    time: 540,
  },
  {
    date: '03/13/2022 18:31:37',
    toolkit: 'Energize',
    day: 'Sun',
    frequency: 10,
    duration: 100,
    time: 1000,
  },
  {
    date: '03/14/2022 01:04:07',
    toolkit: 'Chill',
    day: 'Mon',
    frequency: 9,
    duration: 124,
    time: 1116,
  },
  {
    date: '03/15/2022 03:46:32',
    toolkit: 'Energize',
    day: 'Tue',
    frequency: 10,
    duration: 207,
    time: 2070,
  },
  {
    date: '03/16/2022 18:19:44',
    toolkit: 'Savor',
    day: 'Wed',
    frequency: 8,
    duration: 226,
    time: 1808,
  },
  {
    date: '03/18/2022 22:09:27',
    toolkit: 'Focus',
    day: 'Fri',
    frequency: 5,
    duration: 113,
    time: 565,
  },
  {
    date: '03/21/2022 05:25:40',
    toolkit: 'Energize',
    day: 'Mon',
    frequency: 10,
    duration: 80,
    time: 800,
  },
  {
    date: '03/23/2022 13:26:53',
    toolkit: 'Energize',
    day: 'Wed',
    frequency: 3,
    duration: 49,
    time: 147,
  },
  {
    date: '03/31/2022 13:22:17',
    toolkit: 'Focus',
    day: 'Thu',
    frequency: 6,
    duration: 38,
    time: 228,
  },
  {
    date: '04/02/2022 11:01:00',
    toolkit: 'Chill',
    day: 'Sat',
    frequency: 8,
    duration: 5,
    time: 40,
  },
  {
    date: '04/06/2022 12:12:59',
    toolkit: 'Energize',
    day: 'Wed',
    frequency: 6,
    duration: 124,
    time: 744,
  },
  {
    date: '04/07/2022 08:16:20',
    toolkit: 'Chill',
    day: 'Thu',
    frequency: 8,
    duration: 238,
    time: 1904,
  },
  {
    date: '04/08/2022 03:23:58',
    toolkit: 'Savor',
    day: 'Fri',
    frequency: 10,
    duration: 15,
    time: 150,
  },
  {
    date: '04/08/2022 16:44:16',
    toolkit: 'Energize',
    day: 'Fri',
    frequency: 10,
    duration: 170,
    time: 1700,
  },
  {
    date: '04/13/2022 19:45:51',
    toolkit: 'Energize',
    day: 'Wed',
    frequency: 9,
    duration: 182,
    time: 1638,
  },
  {
    date: '04/25/2022 21:42:03',
    toolkit: 'Savor',
    day: 'Mon',
    frequency: 7,
    duration: 138,
    time: 966,
  },
  {
    date: '05/01/2022 01:21:42',
    toolkit: 'Savor',
    day: 'Sun',
    frequency: 3,
    duration: 27,
    time: 81,
  },
  {
    date: '05/05/2022 03:46:01',
    toolkit: 'Focus',
    day: 'Thu',
    frequency: 9,
    duration: 30,
    time: 270,
  },
  {
    date: '05/08/2022 11:17:22',
    toolkit: 'Savor',
    day: 'Sun',
    frequency: 1,
    duration: 30,
    time: 30,
  },
  {
    date: '05/09/2022 09:16:43',
    toolkit: 'Energize',
    day: 'Mon',
    frequency: 6,
    duration: 59,
    time: 354,
  },
  {
    date: '05/09/2022 12:05:38',
    toolkit: 'Savor',
    day: 'Mon',
    frequency: 8,
    duration: 235,
    time: 1880,
  },
  {
    date: '05/12/2022 13:41:10',
    toolkit: 'Chill',
    day: 'Thu',
    frequency: 5,
    duration: 23,
    time: 115,
  },
  {
    date: '05/17/2022 00:47:37',
    toolkit: 'Energize',
    day: 'Tue',
    frequency: 1,
    duration: 138,
    time: 138,
  },
  {
    date: '05/20/2022 03:51:27',
    toolkit: 'Chill',
    day: 'Fri',
    frequency: 8,
    duration: 169,
    time: 1352,
  },
  {
    date: '05/25/2022 07:23:14',
    toolkit: 'Energize',
    day: 'Wed',
    frequency: 9,
    duration: 115,
    time: 1035,
  },
  {
    date: '05/25/2022 15:00:48',
    toolkit: 'Chill',
    day: 'Wed',
    frequency: 10,
    duration: 76,
    time: 760,
  },
  {
    date: '05/26/2022 18:04:28',
    toolkit: 'Chill',
    day: 'Thu',
    frequency: 9,
    duration: 109,
    time: 981,
  },
  {
    date: '06/01/2022 16:06:31',
    toolkit: 'Savor',
    day: 'Wed',
    frequency: 4,
    duration: 127,
    time: 508,
  },
  {
    date: '06/04/2022 07:42:11',
    toolkit: 'Energize',
    day: 'Sat',
    frequency: 1,
    duration: 16,
    time: 16,
  },
  {
    date: '06/06/2022 15:56:40',
    toolkit: 'Chill',
    day: 'Mon',
    frequency: 2,
    duration: 157,
    time: 314,
  },
  {
    date: '06/12/2022 03:17:04',
    toolkit: 'Chill',
    day: 'Sun',
    frequency: 9,
    duration: 196,
    time: 1764,
  },
  {
    date: '06/14/2022 02:16:59',
    toolkit: 'Focus',
    day: 'Tue',
    frequency: 6,
    duration: 195,
    time: 1170,
  },
  {
    date: '06/16/2022 20:49:05',
    toolkit: 'Chill',
    day: 'Thu',
    frequency: 7,
    duration: 173,
    time: 1211,
  },
  {
    date: '06/23/2022 07:24:31',
    toolkit: 'Chill',
    day: 'Thu',
    frequency: 5,
    duration: 108,
    time: 540,
  },
  {
    date: '06/24/2022 23:11:41',
    toolkit: 'Focus',
    day: 'Fri',
    frequency: 3,
    duration: 158,
    time: 474,
  },
  {
    date: '06/25/2022 02:59:35',
    toolkit: 'Energize',
    day: 'Sat',
    frequency: 3,
    duration: 177,
    time: 531,
  },
  {
    date: '06/28/2022 03:09:50',
    toolkit: 'Energize',
    day: 'Tue',
    frequency: 9,
    duration: 234,
    time: 2106,
  },
  {
    date: '07/05/2022 21:24:19',
    toolkit: 'Energize',
    day: 'Tue',
    frequency: 7,
    duration: 226,
    time: 1582,
  },
  {
    date: '07/08/2022 04:55:51',
    toolkit: 'Focus',
    day: 'Fri',
    frequency: 4,
    duration: 9,
    time: 36,
  },
  {
    date: '07/13/2022 01:40:03',
    toolkit: 'Savor',
    day: 'Wed',
    frequency: 9,
    duration: 18,
    time: 162,
  },
  {
    date: '07/17/2022 18:45:54',
    toolkit: 'Chill',
    day: 'Sun',
    frequency: 1,
    duration: 240,
    time: 240,
  },
  {
    date: '07/28/2022 00:46:12',
    toolkit: 'Savor',
    day: 'Thu',
    frequency: 3,
    duration: 73,
    time: 219,
  },
  {
    date: '07/28/2022 04:41:21',
    toolkit: 'Savor',
    day: 'Thu',
    frequency: 8,
    duration: 9,
    time: 72,
  },
  {
    date: '07/30/2022 04:48:27',
    toolkit: 'Chill',
    day: 'Sat',
    frequency: 1,
    duration: 116,
    time: 116,
  },
  {
    date: '07/31/2022 14:49:15',
    toolkit: 'Savor',
    day: 'Sun',
    frequency: 8,
    duration: 140,
    time: 1120,
  },
  {
    date: '08/05/2022 05:07:06',
    toolkit: 'Focus',
    day: 'Fri',
    frequency: 5,
    duration: 103,
    time: 515,
  },
  {
    date: '08/06/2022 11:47:30',
    toolkit: 'Savor',
    day: 'Sat',
    frequency: 8,
    duration: 33,
    time: 264,
  },
  {
    date: '08/07/2022 09:00:59',
    toolkit: 'Chill',
    day: 'Sun',
    frequency: 8,
    duration: 225,
    time: 1800,
  },
  {
    date: '08/08/2022 17:02:27',
    toolkit: 'Focus',
    day: 'Mon',
    frequency: 8,
    duration: 189,
    time: 1512,
  },
  {
    date: '08/10/2022 12:40:19',
    toolkit: 'Savor',
    day: 'Wed',
    frequency: 7,
    duration: 154,
    time: 1078,
  },
  {
    date: '08/15/2022 07:08:43',
    toolkit: 'Savor',
    day: 'Mon',
    frequency: 4,
    duration: 95,
    time: 380,
  },
  {
    date: '08/23/2022 10:52:12',
    toolkit: 'Savor',
    day: 'Tue',
    frequency: 6,
    duration: 213,
    time: 1278,
  },
  {
    date: '08/23/2022 10:58:20',
    toolkit: 'Chill',
    day: 'Tue',
    frequency: 7,
    duration: 220,
    time: 1540,
  },
  {
    date: '08/23/2022 11:32:53',
    toolkit: 'Focus',
    day: 'Tue',
    frequency: 8,
    duration: 43,
    time: 344,
  },
  {
    date: '08/24/2022 13:43:09',
    toolkit: 'Energize',
    day: 'Wed',
    frequency: 8,
    duration: 11,
    time: 88,
  },
  {
    date: '08/24/2022 23:01:20',
    toolkit: 'Energize',
    day: 'Wed',
    frequency: 4,
    duration: 6,
    time: 24,
  },
  {
    date: '08/26/2022 03:55:26',
    toolkit: 'Focus',
    day: 'Fri',
    frequency: 9,
    duration: 33,
    time: 297,
  },
  {
    date: '08/26/2022 13:12:53',
    toolkit: 'Chill',
    day: 'Fri',
    frequency: 10,
    duration: 229,
    time: 2290,
  },
  {
    date: '08/28/2022 15:00:34',
    toolkit: 'Focus',
    day: 'Sun',
    frequency: 10,
    duration: 207,
    time: 2070,
  },
  {
    date: '08/29/2022 11:56:38',
    toolkit: 'Focus',
    day: 'Mon',
    frequency: 7,
    duration: 26,
    time: 182,
  },
  {
    date: '09/04/2022 13:33:19',
    toolkit: 'Focus',
    day: 'Sun',
    frequency: 10,
    duration: 144,
    time: 1440,
  },
  {
    date: '09/05/2022 04:04:44',
    toolkit: 'Savor',
    day: 'Mon',
    frequency: 6,
    duration: 131,
    time: 786,
  },
  {
    date: '09/06/2022 09:53:13',
    toolkit: 'Energize',
    day: 'Tue',
    frequency: 3,
    duration: 67,
    time: 201,
  },
  {
    date: '09/07/2022 03:53:39',
    toolkit: 'Savor',
    day: 'Wed',
    frequency: 5,
    duration: 237,
    time: 1185,
  },
  {
    date: '09/12/2022 06:05:04',
    toolkit: 'Savor',
    day: 'Mon',
    frequency: 10,
    duration: 205,
    time: 2050,
  },
  {
    date: '09/13/2022 09:39:30',
    toolkit: 'Energize',
    day: 'Tue',
    frequency: 4,
    duration: 237,
    time: 948,
  },
  {
    date: '09/25/2022 08:36:13',
    toolkit: 'Focus',
    day: 'Sun',
    frequency: 3,
    duration: 118,
    time: 354,
  },
  {
    date: '09/26/2022 07:25:25',
    toolkit: 'Focus',
    day: 'Mon',
    frequency: 9,
    duration: 16,
    time: 144,
  },
  {
    date: '10/08/2022 06:05:16',
    toolkit: 'Chill',
    day: 'Sat',
    frequency: 5,
    duration: 143,
    time: 715,
  },
  {
    date: '10/10/2022 18:12:26',
    toolkit: 'Chill',
    day: 'Mon',
    frequency: 10,
    duration: 19,
    time: 190,
  },
  {
    date: '10/12/2022 16:33:16',
    toolkit: 'Savor',
    day: 'Wed',
    frequency: 9,
    duration: 163,
    time: 1467,
  },
  {
    date: '10/16/2022 15:52:07',
    toolkit: 'Savor',
    day: 'Sun',
    frequency: 3,
    duration: 112,
    time: 336,
  },
  {
    date: '10/19/2022 21:09:01',
    toolkit: 'Focus',
    day: 'Wed',
    frequency: 9,
    duration: 36,
    time: 324,
  },
  {
    date: '10/2022/2022 15:41:05',
    toolkit: 'Savor',
    day: 'Sat',
    frequency: 6,
    duration: 41,
    time: 246,
  },
  {
    date: '11/01/2022 21:13:48',
    toolkit: 'Chill',
    day: 'Tue',
    frequency: 8,
    duration: 164,
    time: 1312,
  },
  {
    date: '11/07/2022 10:50:51',
    toolkit: 'Focus',
    day: 'Mon',
    frequency: 7,
    duration: 225,
    time: 1575,
  },
  {
    date: '11/16/2022 18:09:20',
    toolkit: 'Focus',
    day: 'Wed',
    frequency: 6,
    duration: 221,
    time: 1326,
  },
  {
    date: '11/19/2022 03:15:09',
    toolkit: 'Savor',
    day: 'Sat',
    frequency: 8,
    duration: 52,
    time: 416,
  },
  {
    date: '11/2022/2022 08:47:03',
    toolkit: 'Focus',
    day: 'Tue',
    frequency: 1,
    duration: 204,
    time: 204,
  },
  {
    date: '11/25/2022 02:55:37',
    toolkit: 'Energize',
    day: 'Fri',
    frequency: 4,
    duration: 12,
    time: 48,
  },
  {
    date: '11/25/2022 09:58:19',
    toolkit: 'Chill',
    day: 'Fri',
    frequency: 4,
    duration: 189,
    time: 756,
  },
  {
    date: '11/26/2022 00:22:06',
    toolkit: 'Focus',
    day: 'Sat',
    frequency: 3,
    duration: 171,
    time: 513,
  },
  {
    date: '12/01/2022 06:08:29',
    toolkit: 'Savor',
    day: 'Thu',
    frequency: 4,
    duration: 22,
    time: 88,
  },
  {
    date: '12/05/2022 15:08:35',
    toolkit: 'Chill',
    day: 'Mon',
    frequency: 7,
    duration: 121,
    time: 847,
  },
  {
    date: '12/06/2022 12:52:33',
    toolkit: 'Chill',
    day: 'Tue',
    frequency: 7,
    duration: 46,
    time: 322,
  },
  {
    date: '12/08/2022 00:14:06',
    toolkit: 'Chill',
    day: 'Thu',
    frequency: 6,
    duration: 168,
    time: 1008,
  },
  {
    date: '12/10/2022 08:22:35',
    toolkit: 'Focus',
    day: 'Sat',
    frequency: 3,
    duration: 175,
    time: 525,
  },
  {
    date: '12/17/2022 03:14:53',
    toolkit: 'Energize',
    day: 'Sat',
    frequency: 1,
    duration: 32,
    time: 32,
  },
  {
    date: '12/17/2022 14:03:47',
    toolkit: 'Chill',
    day: 'Sat',
    frequency: 3,
    duration: 226,
    time: 678,
  },
  {
    date: '12/23/2022 07:30:07',
    toolkit: 'Focus',
    day: 'Fri',
    frequency: 6,
    duration: 95,
    time: 570,
  },
  {
    date: '12/23/2022 18:57:26',
    toolkit: 'Savor',
    day: 'Fri',
    frequency: 5,
    duration: 4,
    time: 20,
  },
  {
    date: '12/25/2022 11:49:51',
    toolkit: 'Chill',
    day: 'Sun',
    frequency: 4,
    duration: 222,
    time: 888,
  },
  {
    date: '12/26/2022 01:11:46',
    toolkit: 'Savor',
    day: 'Mon',
    frequency: 1,
    duration: 186,
    time: 186,
  },
  {
    date: '01/04/2023 00:13:29',
    toolkit: 'Energize',
    day: 'Wed',
    frequency: 1,
    duration: 27,
    time: 27,
  },
  {
    date: '01/06/2023 15:01:01',
    toolkit: 'Chill',
    day: 'Fri',
    frequency: 7,
    duration: 60,
    time: 420,
  },
];
