import { findIndexById } from '../utils/helpers';
import { getApiRequest } from '../utils/request';

// Collection of functions  to facilitate getting journey data for a given member
export default function useJourneyData() {

  const getMemberJourneyData = async (memberUid) => {
    try {
      const result = await getApiRequest(`/coach/member/${memberUid}/journey-data`);
      return result.data || [];
    } catch (err) {
      console.log(err, 'error getting member journey data');
    }
    return [];
  };

  const getMemberJourneys = async (memberUid) => {
    try {
      const result = await getApiRequest(`/coach/member/${memberUid}/journeys`);
      return result.data || [];
    } catch (err) {
      console.log(err, 'error getting member journey data');
    }
    return [];
  };

  // Find the data for a toolkit journey id
  const getDataForJourney = (data, id) => {
    if (!data || !data.length) {
      return null;
    }
    const dataIndex = findIndexById(data, id, '_id');
    if (dataIndex > -1) {
      return data[dataIndex];
    }
    return null;
  };

  // Find the count of views for an exercise
  const getExerciseCount = (data, journeyId, id) => {
    const d = getDataForJourney(data, journeyId);
    const { exerciseIds } = d || {};
    if (exerciseIds && exerciseIds[id]) {
      return exerciseIds[id];
    }
    return 0;
  };

  // Find the count of views for an exercise type
  const getExerciseTypeCount = (data, journeyId, type) => {
    const d = getDataForJourney(data, journeyId);
    const { exerciseTypes } = d || {};
    if (exerciseTypes && exerciseTypes[type]) {
      return exerciseTypes[type];
    }
    return 0;
  };

  const isExerciseRecommended = (data, journeyId, id) => {
    const d = getDataForJourney(data, journeyId);
    const { recommendedExercises } = d || {};
    if (recommendedExercises && recommendedExercises[id]) {
      return true;
    }
    return false;
  };

  // Helper function to get the total unique exercise count for all exercises for this journey
  const getTotalExerciseCount = (data, journeyId) => {
    const d = getDataForJourney(data, journeyId);
    const { exerciseIds } = d || {};
    return !!exerciseIds ? Object.keys(exerciseIds).length : 0;
  };

  return {
    getMemberJourneyData,
    getDataForJourney,
    getExerciseCount,
    getExerciseTypeCount,
    isExerciseRecommended,
    getTotalExerciseCount,
    getMemberJourneys,
  }
}