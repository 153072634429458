import { useState, useEffect } from 'react';
import queryString from 'query-string';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { useBreadcrumbs } from '../../contexts/breadcrumbs-provider';
import Avatar from '../../components/common/avatar';
import Heading from '../../components/layout/admin/heading';
import { getApiRequest } from '../../utils/request';
import { getCurrentWeekForJourney } from '../../utils/helpers';
import Loadable from '../../components/data/loadable';
import AdminTable from '../../components/common/admin-table';
import AutoForm from '../../components/form/auto-form';
import Frame from '../../components/layout/frame';
import ActionLinks from '../../components/data/action-links';


function JourneyCell({ checkinDay, checkinTime, lastMessageTime, startDate, week }) {
  const checkin = !!checkinDay && !!checkinTime ? `${checkinDay} at ${checkinTime}` : 'Not Set';
  const lastMessage = lastMessageTime ? moment.unix(lastMessageTime._seconds).format('lll') : 'Never';
  
  return (
    <>
      <span className="font-bold">Check In Time: </span>{` ${checkin}`}<br />
      <span className="font-bold">Last Message: </span>{` ${lastMessage}`}<br />
      <span className="font-bold">Week: </span>{` ${week}`}
    </>
  );
}


function formatRow(item) {
  const { _id: id, complete, lastMessageTime, startDate, checkinDay, checkinTime, toolkitName, profile } = item;
  const { photo, name, pronouns } = profile;
  const week = getCurrentWeekForJourney(item).weeks;
  return {
    name: <><span className="font-bold text-primary-500 text-lg">{name}</span>{!!pronouns && <div>{pronouns}</div>}</>,
    nameRaw: name,
    photo: <Avatar photo={photo} name={name} bg="bg-secondary-500" />,
    toolkit: toolkitName || 'Chill',
    journey: <JourneyCell checkinDay={checkinDay} checkinTime={checkinTime} lastMessageTime={lastMessageTime} startDate={startDate} week={week} />,
    // checkin: !!checkinDay && !!checkinTime ? <span>{checkinDay} at {checkinTime}</span> : 'Not Set',
    // lastMessageSent: lastMessageTime ? moment.unix(lastMessageTime._seconds).format('lll') : 'Never',
    // week: `Week ${getCurrentWeekBasedOnStartDate(startDate).weeks}`,
    week,
    complete: complete ? 'Yes' : 'No',
    id,
    actionLinks: [
      {
        label: 'Surveys',
        href: `/admin/member/journey/${id}/survey-responses`,
      },
    ],
  };
}

export default function AdminMembers() {
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(true);
  const [queryParamsString, setQueryParamsString] = useState(null);
  const { setBreadcrumbs } = useBreadcrumbs();
  const navigate = useNavigate();
  useEffect(() => {
    setBreadcrumbs([
      {
        href: '/admin/members',
        label: 'Member Journeys',
      },
    ]);
  }, []);

  const query = queryString.parse(window.location.search);
  const currentQueryParamsString = queryString.stringify(query || {});

  const load = async () => {
    if (!loading) {
      setLoading(true);
    }
    const result = await getApiRequest(`/admin/members${queryParamsString ? `?${queryParamsString}` : ''}`);
    // setProfiles(result.data);
    console.log(result, 'the result from the server');
    setResults(result);
    setLoading(false);
  };

  useEffect(() => {
    if (currentQueryParamsString !== queryParamsString) {
      setQueryParamsString(currentQueryParamsString);
    }
  }, [currentQueryParamsString]);


  useEffect(() => {
    if (queryParamsString !== null && queryParamsString === currentQueryParamsString) {
      load();
    }
  }, [queryParamsString]);

  const columns = [
    {
      key: 'photo',
      label: '',
    },
    {
      key: 'name',
      label: 'Name',
    },
    {
      key: 'toolkit',
      label: 'Toolkit',
    },
    {
      key: 'journey',
      label: 'Journey Info',
    },
    // {
    //   key: 'week',
    //   label: 'Week',
    // },
    // {
    //   key: 'checkin',
    //   label: 'Check In Time',
    // },
    // {
    //   key: 'lastMessageSent',
    //   label: 'Last Message',
    // },
    {
      key: 'complete',
      label: 'Complete',
    },
    {
      key: 'actions',
      label: '',
      isRight: true,
      render: (row) => { return !!row.actionLinks ? <ActionLinks links={row.actionLinks} /> : null; },
    },
  ];


  const baseUrl = '/admin/members';

  const clientSortOptions = [
    {
      label: 'Default Sort',
      comparison: () => load(),
    },
    {
      label: 'Sort By Week (Ascending)',
      comparison: (rows) => [...rows].sort((a, b) => a.week - b.week),
    },
    {
      label: 'Sort By Week (Descending)',
      comparison: (rows) => [...rows].sort((a, b) => b.week - a.week),
    },
    {
      label: 'Sort By Name',
      comparison: (rows) => [...rows].sort((a, b) => a.nameRaw < b.nameRaw ? -1 : a.nameRaw > b.nameRaw ? 1 : 0),
    },
  ];

  const searchForm = {
    type: 'get',
    noContainer: true,
    successRedirect: '/admin/members',
    fields: [
      {
        name: 'toolkitId',
        label: 'Toolkit',
        type: 'select',
        optionsUrl: '/options/toolkits',
      },
      {
        name: 'complete',
        label: 'Complete',
        type: 'select',
        options: [
          {
            value: '',
            label: 'Select...',
          },
          {
            value: '0',
            label: 'Incomplete',
          },
          {
            value: '1',
            label: 'Complete',
          }
        ],
      },
    ],
    submitLabel: 'Search Member Journeys',
  };

  return (
      <Frame fullscreen classes="p-4">        
        <Heading title="Member Journeys" />
        <Loadable loading={loading}>
          <AdminTable results={results} columns={columns} clientSortOptions={clientSortOptions} baseUrl={baseUrl} rowFormatter={formatRow} searchTitle="Search Member Journeys" searchForm={<AutoForm {...searchForm} />} />
        </Loadable>
      </Frame>
  );
}
