import { useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { useNotifications } from "../contexts/notifications-provider";
import { useAuth } from "../contexts/auth-provider";
import Frame from './layout/frame';

const PrivateRoute = ({ component: Component, roles = null, redirectOn403 = null, ...rest }) => {
  const { addNotification } = useNotifications();
  const { user, initialized, hasRole } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (initialized && !user) {
      // If we’re not logged in, redirect to the login page.
      addNotification({
        type: 'info',
        body: "You must log in to continue.",
      });
      navigate(`/login?redir=${window.location.pathname}`, { replace: true });
    }
  }, [user, addNotification, navigate, initialized]);

  if (!user) {
    // navigate(`/signin`);
    return null;
  }
  if (roles && !hasRole(roles)) {
    if (redirectOn403) {
      setTimeout(() => {
        navigate(redirectOn403, { replace: true });
      }, 50);
      return null;
    }
    return (
      <Frame>
        <section className="">
          <div className="container px-5 py-24 mx-auto flex flex-wrap items-center">
            <div className="lg:w-2/6 md:w-1/2 flex text-center flex-col md:mx-auto w-full f">
              <h1 className="text-lg text-gray-700">Permission Denied.</h1>
            </div>
          </div>
        </section>
      </Frame>
    );
  }

  return (
    <Component {...rest} />
  );
}


export default PrivateRoute