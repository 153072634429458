import { useState, useEffect } from 'react';
import Bugsnag from '@bugsnag/browser';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { useBreadcrumbs } from '../../../contexts/breadcrumbs-provider';
import { getApiRequest } from '../../../utils/request';
import { getMomentFromUnix, getSurveyName, getWeekFromJourney } from '../../../utils/helpers';
import Loadable from '../../../components/data/loadable';
import Frame from '../../../components/layout/frame';
import Heading from '../../../components/layout/admin/heading';
import MemberSection from '../../../components/layout/coach/member-section';
import RightSlideOver from '../../../components/overlays/right-slide-over';
import MemberSurveyResponse from '../../../components/layout/coach/member-survey-response';
import ActionLinks from '../../../components/data/action-links';
import AdminTable from '../../../components/common/admin-table';



function SurveyResponsesSection({ journeyId, journey, isV2, showResponse }) {
  const [responses, setResponses] = useState(null);
  const [loading, setLoading] = useState(true);

  const loadResponses = async () => {
    if (!loading) {
      setLoading(true);
    }
    try {
      const result = await getApiRequest(`/admin/journey/${journeyId}/survey-responses${isV2 ? '2' : ''}`);
      setResponses(result.responses);
    } catch (err) {
      Bugsnag.notify(err);
      console.log(err, 'there was an error loading the survey responses');
    }
    setLoading(false);
  };

  useEffect(() => {
    if (journeyId) {
      loadResponses();
    }
  }, [journeyId]);


  const formatRow = (item) => {
    const { _id: id, week, instance, updatedAt, exerciseToolkitName, exerciseTitle, toolkitName } = item;
    const updatedMoment = getMomentFromUnix(updatedAt._seconds);
    const weeksData = getWeekFromJourney(journey, updatedMoment);
    const { type } = instance;
    const name = getSurveyName(type);
    return {
      name,
      toolkit: exerciseToolkitName ? `${exerciseToolkitName} - ${exerciseTitle}` :  (toolkitName || '--'),
      week: isV2 ? weeksData.weeks : week,
      completed: updatedAt ? moment.unix(updatedAt._seconds).format('lll') : '--',
      id,
      actionLinks: [
        {
          label: 'View Survey',
          onClick: () => showResponse(item),
        },
      ],
    };
  };

  const columns = [
    {
      key: 'name',
      label: 'Name',
      isBold: true,
    },
    {
      key: 'toolkit',
      label: 'Mood/Exercise',
    },
    {
      key: 'completed',
      label: 'Completed On',
    },
    {
      key: 'week',
      label: 'Week',
    },
    {
      key: 'actions',
      label: '',
      isRight: true,
      render: (row) => { return !!row.actionLinks ? <ActionLinks links={row.actionLinks} /> : null; },
    },
  ];

  const hasResponses = !!responses && !!responses.length;
  const reversedResponses = hasResponses ? [...responses].reverse() : [];


  return (
    <MemberSection>
      <Loadable loading={loading}>
        <div className="grid grid-cols-5 mt-8">
          <div className="col-span-2">
            {/* <h3 className="text-lg font-semibold text-primary-500">Surveys</h3> */}
          </div>
          
        </div>
        <div className="space-y-3 divide-y divide-gray-300">
          {!hasResponses && <p className="text-md text-gray-500 my-4 text-sm">There are no completed surveys.</p>}
          {hasResponses && <AdminTable fullWidth alternate results={{ data: reversedResponses }} columns={columns} rowFormatter={formatRow} />}
          {/* {hasResponses && notes.map(note => {
            const { title, description, week, date, _id } = note;
            return (
              <div key={_id} className="pb-2 pt-4">
                <h4 className="font-semibold text-gray-700 text-lg mb">{title}</h4>
                <h4 className="font-semibold text-primary-500 mb-3">{`Week ${week}`}</h4>
                <p className="text-gray-500 text-sm"><Nl2br str={description} /></p>
                <p className="text-gray-400 text-sm mt-1 text-right">{formatUtcUnixDate(date._seconds)}</p>
              </div>
            )
          })} */}
        </div>
      </Loadable>
    </MemberSection>
  );
}



export default function AdminMemberJourneySurveys() {
  const { id } = useParams();
  const [sideOpen, setSideOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [response, setResponse] = useState(null);
  const [goals, setGoals] = useState(null);
  const [name, setName] = useState('');
  const [isV2, setIsV2] = useState(false);
  const [journey, setJourney] = useState(null);
  const { setBreadcrumbs } = useBreadcrumbs();
  useEffect(() => {
    setBreadcrumbs([
      {
        href: '/admin/members',
        label: 'Member Journeys',
      },
      {
        label: 'Surveys',
        href: `/admin/member/journey/${id}/survey-responses`,
      },
    ]);

  }, []);

  
  const openSide = () => setSideOpen(true);
  const closeSide = () => {
    setSideOpen(false);
    setResponse(null);
  };

  const showResponse = (response) => {
    setResponse(response);
    openSide();
  };


  useEffect(() => {
    const load = async () => {
      setLoading(true);
      try {
        const journeyResult = await getApiRequest(`/admin/journey/${id}`);
        setName(journeyResult.name);
        setIsV2(!!journeyResult.journey?.v2);
        setJourney(journeyResult.journey);
        const goalsResult = await getApiRequest(`/options/goals/${journeyResult?.journey?.toolkitId}`);
        setGoals(goalsResult.options);
      } catch (err) {
        console.log(err, 'error loading goals');
      }
      setLoading(false);
    };
    load();
  }, [id]);

  const sidebarTitle = response ? `Survey Response - ${isV2 ? `${getSurveyName(response.surveyType)} - ${response.exerciseToolkitName || response.toolkitName}` : `Week ${response.week}`}` : '';

  return (
      <Frame fullscreen classes="p-4">
        <Loadable loading={loading}>
        {!!goals && (
          <>
            <Heading title={`Surveys${name ? ` - ${name}` : ''}`} />
            <div className="max-w-7xl my-8 px-4 mx-auto">
              <SurveyResponsesSection isV2={isV2} journeyId={id} journey={journey} showResponse={showResponse} />
            </div>
          </>
        )}
          <RightSlideOver darkBg large isOpen={sideOpen} close={closeSide} title={sidebarTitle}>
            {!!sideOpen && !!response && <MemberSurveyResponse isV2={isV2} goals={goals} response={response} />}
          </RightSlideOver>
        </Loadable>
      </Frame>
  );
}
