import React from 'react';
import { makeSureHttps } from "../../utils/helpers";

function textClassesFromMarks(marks, defaultProps) {
  let classes = '';
  for (let i = 0; i < marks.length; i++) {
    if (marks[i].type === 'bold') {
      classes = `${classes} font-bold`;
    }
    if (marks[i].type === 'italic') {
      classes = `${classes} italic`;
      
    }
    if (marks[i].type === 'underline') {
      classes = `${classes} underline`;
    }
  }
  return classes;
}

// this is the text that goes within paragraphs, lists, h2's, etc.
function RenderText({ item, textProps = null }) {
  const { value, marks } = item;
  const classes = textClassesFromMarks(marks);
  return <span className={classes}>{value}</span>;
}

// Links should only contain text
function LinkElement({ item }) {
  const { data, content } = item;
  const text = content.length ? content[0].value : null;
  if (!text) {
    return null;
  }
  return <a className="text-purple-600 cursor-pointer" href={data.uri}>{text}</a>;
}

// block quote block
function BlockquoteElement({ item }) {
  const { content } = item;
  return (
    <div className="px-4 pt-5 pb-1 my-4 bg-gray-50 shadow text-gray-800">
      <RenderLeaves items={content} />
    </div>
  );
}

// H1 - H4 components.  If we need others, we can add them easily.  Here you can match the styles as necessary to the existing UI.
function Header1Element({ item }) {
  const { content } = item;
  return (
    <h1 className="mb-4 text-4xl font-bold">
      <RenderLeaves items={content} />
    </h1>
  );
}
function Header2Element({ item }) {
  const { content } = item;
  return (
    <h1 className="mb-4 text-3xl font-bold">
      <RenderLeaves items={content} />
    </h1>
  );
}
function Header3Element({ item }) {
  const { content } = item;
  return (
    <h1 className="mb-4 text-2xl font-bold">
      <RenderLeaves items={content} />
    </h1>
  );
}


// // Lists, should be simple to match styles to the existing standard
function OrderedListElement({ item }) {
  const { content } = item;
  if (!content || !content.length) {
    return null;
  }
  return (
    <ol className="mb-4 list-decimal list-inside">
      {content.map((item, i) => {
        return (
          <li key={i}>
            <Paragraph item={item} />
          </li>
        );
      })}
    </ol>
  );
}
function UnorderedListElement({ item }) {
  const { content } = item;
  if (!content || !content.length) {
    return null;
  }
  return (
    <ol className="mb-4 list-disc list-inside">
      {content.map((item, i) => {
        return (
          <li key={i}>
            <Paragraph item={item} />
          </li>
        );
      })}
    </ol>
  );
}

// The default component, a paragraph, match text color, size, margin, etc.
function Paragraph({ item }) {
  const { content } = item;
  return (
    <p className="mb-4">
      <RenderLeaves items={content} />
    </p>
  );
}

// Receives an item with a url, (optional) description, and an (optional) title for the image
function ImageBlock({ item }) {
  const { image } = item;
  if (!image) {
    return null;
  }
  return (
    <img w="full" src={makeSureHttps(image.url)} alt={image.title || 'photo'} />
  );
}

// Passed content of other elements, loops over them, rendering leaf nodes (RenderText)
// or full nodes (ItemSwitch) depending on the value of the nodeType property
function RenderLeaves({ items, textProps = null }) {
  return (
    <>
      {items.map((child, i) => {
        const { nodeType } = child;
        if (nodeType !== 'text') {
          return <ItemSwitch key={i} item={child} />;
        }
        return <RenderText key={i} item={child} textProps={textProps} />;
      })}
    </>
  );
}

// Switch for rendering an element based on type, which is present on nodes that are NOT leaves, aka they
// can wrap other nodes (an h1 could have text in it that is in italics, for instance)
function ItemSwitch({ item }) {
  const { nodeType } = item;

  switch (nodeType) {
    case 'embedded-asset-block':
      return <ImageBlock item={item} />;

    case 'heading-1':
      return <Header1Element item={item} />;

    case 'heading-2':
      return <Header2Element item={item} />;

    case 'heading-3':
      return <Header3Element item={item} />;

    case 'hyperlink':
      return <LinkElement item={item} />;

    case 'ordered-list':
      return <OrderedListElement item={item} />;

    case 'unordered-list':
      return <UnorderedListElement item={item} />;
    
    case 'blockquote':
      return <BlockquoteElement item={item} />;

    case 'paragraph':
    default:
      return <Paragraph item={item} />;
  }
}

export default function RichTextRenderer({ content }) {
  if (!content || !content.length) {
    return null;
  }
  return (
    <div className="space-y-4">
      {content.map((item, i) => (
        <ItemSwitch item={item} key={i} />
      ))}
    </div>
  );
}
