import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/auth-provider';

export default function Home() {
  const { user, initialized } = useAuth();
  const navigate = useNavigate();
  useEffect(() => {
    if (initialized) {
      navigate(user ? '/dashboard' : '/login');
    }
  }, [initialized]);
  return null;
}
