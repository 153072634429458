import React, { useEffect, useState } from 'react';
import { SearchIcon, XIcon } from '@heroicons/react/outline';
import { Link } from 'react-router-dom';
import queryString from 'query-string';
import Table from '../data/table';
import SimplePaginator from '../data/simple-paginator';
import RightSlideOver from '../overlays/right-slide-over';
import SectionTitle from '../layout/admin/section-title';
import { ucwords } from '../../utils/helpers';
import Badge from '../badges/badge';

function CurrentFilters({ filters }) {
  
  return (
    <div className="py-2"><span className="text-sm text-gray-600 font-semibold mr-2">Current Filters: </span>
      {filters.map(filter => <Badge className="mr-2" text={`${filter.label}: ${filter.value}`} color="blue" key={filter.label} />)}
    </div>
  );
}

// Provide client side sort functionality
function ClientSort({ options, setRows, rows }) {
  // Options contains an array of labels and sort comparison functions
  if (!options || !rows || !options.length || !rows.length) {
    return null;
  }

  const changeSort = (e) => {
    for (let i = 0; i < options.length; i++) {
      const option = options[i];
      if (option.label === e.target.value) {
        const newRows = option.comparison(rows);
        if (newRows) {
          setRows(newRows);
        }
        return;
      }
    }
  };

  return (
    <select
      onChange={changeSort}
      className={`inline-flex pl-3 pr-10 text-base sm:text-sm rounded-md border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500`}
      id="sort-options"
      name="sort"
    >
      {options.map((option) => (<option key={`${option.label}`} value={option.label}>{option.label}</option>))}
    </select>
  )
}

// Add query string
function addQueryStringForPage(url, pageNum) {
  let existingQuery = queryString.parse(window.location.search);
  if (existingQuery) {
    existingQuery.page = pageNum;
  } else {
    existingQuery = { page: pageNum };
  }
  
  return `${url}?${queryString.stringify(existingQuery)}`;
}

function formatNextUrl(baseUrl, next, current) {
  if (!next) {
    return null;
  }
  return addQueryStringForPage(baseUrl, current + 1);
}

function formatPrevUrl(baseUrl, prev, current) {
  if (!prev) {
    return null;
  }
  return addQueryStringForPage(baseUrl, current - 1);
}

export default function AdminTable({ results, columns, rowFormatter, baseUrl, searchForm, searchTitle, sectionTitle, sectionLinks, alternate = false, fullWidth = false, emptyMessage = 'There are no results to display.', clientSortOptions = null }) {
  const [rows, setRows] = useState([]);
  const [searchOpen, setSearchOpen] = useState(false);

  useEffect(() => {
    // Run the formatter on our results
    const { data } = results;
    if (data) {
      const newRows = data.map((row) => rowFormatter(row));
      setRows(newRows);
    }
  }, [results, rowFormatter]);

  const closeSearch = () => {
    setSearchOpen(false);
  }

  // Grab info for paginator
  // TODO: UPDATE PAGINATION HANDLING
  const { from, to, total, next_page_url: next, prev_page_url: prev, current_page: current } = results;
  // console.log(results, 'results');
  const hasData = rows && rows.length > 0;
  const widthClasses = fullWidth ? 'w-full my-8' : 'px-0 md:px-8 my-8 max-w-max';
  const params = queryString.parse(window.location.search);
  const fields = !!searchForm ? searchForm.props.fields : [];
  const filters = [];
  fields.forEach(field => {
    const { name, label } = field;
    if (params[name]) {
      filters.push({
        label: label || ucwords(name),
        value: params[name],
      });
    }
  });
  const hasFilters = !!filters.length;
  return (
    <>
      {!!searchForm && (
        <RightSlideOver isOpen={searchOpen} close={closeSearch} title={searchTitle || 'Search'}>
          {searchForm}
        </RightSlideOver>
      )}
      <div className={widthClasses}>
        {!!sectionTitle && <SectionTitle title={sectionTitle} links={sectionLinks} />}
        <div className="flex justify-between mb-2">
          {!!searchForm && (
            <div>
              <div className="pb-2">
                <button
                  type="button"
                  onClick={() => setSearchOpen(true)}
                  className="inline-flex items-center px-3 py-3 border border-primary-300 shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-primary-500 hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                >
                  <SearchIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
                  {searchTitle || 'Search'}
                </button>
                {hasFilters && (
                  <Link
                    to={baseUrl}
                    className="ml-4 inline-flex items-center px-3 py-3 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-600 bg-white hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    <XIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
                    Clear Filters
                  </Link>
                )}
              </div>
              {hasFilters && <CurrentFilters filters={filters} />}
            </div>
          )}
          {!!clientSortOptions && (
            <ClientSort
              options={clientSortOptions}
              rows={rows}
              setRows={setRows}
            />
          )}
        </div>
        <div className={`${alternate ? '' : 'bg-white overflow-hidden shadow rounded-lg border border-gray-200'}`}>
          {!hasData && <p className="text-gray-700 text-sm py-4 px-8 md:px-16">{emptyMessage}</p>}
          {hasData && <Table columns={columns} rows={rows} alternate={alternate} />}
          {hasData && !!from && !!to && (
            <SimplePaginator
              from={from}
              to={to}
              total={total}
              nextUrl={formatNextUrl(baseUrl, next, current)}
              previousUrl={formatPrevUrl(baseUrl, prev, current)}
            />
          )}
        </div>
      </div>
    </>
  )
}
