// @ts-nocheck
import { Route, Routes } from 'react-router-dom';
import PrivateRoute from './components/private-route';
import AdminContainer from './components/layout/admin/container';
import Home from './pages/home';
import Dashboard from './pages/dashboard';
import Login from './pages/login';
import ForgotPassword from './pages/forgot-password';
import Logout from './pages/logout';
import AdminDashboard from './pages/admin/dashboard';
import AdminUsers from './pages/admin/users';
import AdminUser from './pages/admin/user';
import AdminProfiles from './pages/admin/profiles';
import AdminProfile from './pages/admin/profile';
import AdminProfileView from './pages/admin/profile-view';
import AdminToolkitAssignments from './pages/admin/toolkit-assignments';
import AdminToolkitAssignment from './pages/admin/toolkit-assignment';
import AdminToolkitAssignmentAssignCoach from './pages/admin/toolkit-assignment-assign-coach';
import AdminMembers from './pages/admin/members';
import AdminMemberJourneySurveys from './pages/admin/journey/survey-responses';


import CoachContainer from './components/layout/coach/container';
import CoachDashboard from './pages/coach/dashboard';
import CoachMembers from './pages/coach/members';
import CoachChat from './pages/coach/chat';
import CoachMemberJourney from './pages/coach/member/journey';
import CoachMemberFlow from './pages/coach/member/flow';
import CoachMemberConnect from './pages/coach/member/connect';
import CoachMemberAnalyze from './pages/coach/member/analyze';
import CoachMemberInfo from './pages/coach/member/info';
import CoachMemberJourneyMap from './pages/coach/member/journey-map';
import CoachProfile from './pages/coach/profile';
import CoachProfileEdit from './pages/coach/profile-edit';
import FlowDetails from './pages/coach/member/flow-details';
import AdminSwitchGuide from './pages/admin/switch-guide';
import AdminAssignNewGuide from './pages/admin/assign-new-guide';
import CoachMemberRecentJourney from './pages/coach/member-recent-journey';
import AdminPathways from './pages/admin/pathways';
import AdminPathway from './pages/admin/pathway';


export default function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/login" element={<Login />} />
      <Route path="/logout" element={<Logout />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />

      <Route path="/dashboard" element={<PrivateRoute component={Dashboard}/>} />

      <Route element={<AdminContainer />} path="/admin">
        <Route index element={<PrivateRoute roles={['admin']} redirectOn403="/dashboard" component={AdminDashboard} />} />
        <Route path="users" element={<PrivateRoute roles={['admin']} component={AdminUsers} />} />
        <Route path="user" element={<PrivateRoute roles={['admin']} component={AdminUser} />} />
        <Route path="user/:id" element={<PrivateRoute roles={['admin']} component={AdminUser} />} />
        <Route path="profiles" element={<PrivateRoute roles={['admin']} component={AdminProfiles} />} />
        <Route path="profile/:id" element={<PrivateRoute roles={['admin']} component={AdminProfile} />} />
        <Route path="profile-view/:id" element={<PrivateRoute roles={['admin']} component={AdminProfileView} />} />
        <Route path="toolkit-assignments" element={<PrivateRoute roles={['admin']} component={AdminToolkitAssignments} />} />
        <Route path="switch-guide" element={<PrivateRoute roles={['admin']} component={AdminSwitchGuide} />} />
        <Route path="guide-assign/:id" element={<PrivateRoute roles={['admin']} component={AdminAssignNewGuide} />} />
        <Route path="toolkit-assignment" element={<PrivateRoute roles={['admin']} component={AdminToolkitAssignment} />} />
        <Route path="toolkit-assignment/:id/assign-coach" element={<PrivateRoute roles={['admin']} component={AdminToolkitAssignmentAssignCoach} />} />
        <Route path="members" element={<PrivateRoute roles={['admin']} component={AdminMembers} />} />
        <Route path="member/journey/:id/survey-responses" element={<PrivateRoute roles={['admin']} component={AdminMemberJourneySurveys} />} />
        <Route path="pathways" element={<PrivateRoute roles={['admin']} component={AdminPathways} />} />
        <Route path="pathway/:id" element={<PrivateRoute roles={['admin']} component={AdminPathway} />} />
      </Route>

      <Route element={<CoachContainer />} path="/coach">
        <Route index element={<PrivateRoute roles={['coach']} redirectOn403="/dashboard" component={CoachDashboard} />} />
        <Route path="members" element={<PrivateRoute roles={['coach']} component={CoachMembers} />} />
        <Route path="chat" element={<PrivateRoute roles={['coach']} component={CoachChat} />} />
        <Route path="chat/:id" element={<PrivateRoute roles={['coach']} component={CoachChat} />} />
        <Route path="profile" element={<PrivateRoute roles={['coach']} component={CoachProfile} />} />
        <Route path="profile-edit" element={<PrivateRoute roles={['coach']} component={CoachProfileEdit} />} />
        <Route path="member/:memberId/chat" element={<PrivateRoute roles={['coach']} component={CoachChat} />} />
        <Route path="member/:memberId/view" element={<PrivateRoute roles={['coach']} component={CoachMemberJourney} />} />
        <Route path="member/:memberId/recent-journey" element={<PrivateRoute roles={['coach']} component={CoachMemberRecentJourney} />} />
        <Route path="member/journey/:id" element={<PrivateRoute roles={['coach']} component={CoachMemberJourney} />} />
        <Route path="member/journey/:id/map" element={<PrivateRoute roles={['coach']} component={CoachMemberJourneyMap} />} />
        <Route path="member/journey/:id/flow" element={<PrivateRoute roles={['coach']} component={CoachMemberFlow} />} />
        <Route path="member/journey/:id/connect" element={<PrivateRoute roles={['coach']} component={CoachMemberConnect} />} />
        <Route path="member/journey/:id/analyze" element={<PrivateRoute roles={['coach']} component={CoachMemberAnalyze} />} />
        <Route path="member/journey/:id/info" element={<PrivateRoute roles={['coach']} component={CoachMemberInfo} />} />
        <Route path="member/journey/:id/flow/activities/:weekNumber" element={<PrivateRoute roles={['coach']} component={FlowDetails} />} />
      </Route>
    </Routes>
  );
}
