import { CircularProgressBar } from '@tomik23/react-circular-progress-bar';
import React from 'react';
import { ReactComponent as GuageSvg } from '../../../../assets/guage.svg';
import { formatScoreForDisplay } from '../../../../utils/helpers';


const MoodMeter = ({ moodAvg, moodMetric, average, lastWeek }) => {
  const percentForChart = moodAvg ? Math.round(moodAvg * 10) : 0;
  const moodAverageToDisplay = formatScoreForDisplay(moodAvg);
  const lastWeekToDisplay = formatScoreForDisplay(lastWeek);
  const averageToDisplay = formatScoreForDisplay(average);

  const circleProps = {
    percent: percentForChart, // is require
    colorSlice: '#333333',
    colorCircle: '#cccccc',
    size: 150,
    stroke: 6,
    strokeBottom: 6,
    speed: 70,
    cut: 20,
    rotation: 127,
    fill: 'transparent',
    unit: '',
    // textPosition: '0.35em',
    animationOff: false,
    strokeDasharray: '10,1',
    inverse: false,
    round: true,
    number: false,
    linearGradient: ['#A38DF2', '#A38DF2'],
    zIndex: 10,
  };

  // console.log(moodMetric, 'metric');

  const metricToDisplay = !!moodMetric && !!moodMetric?.description ? moodMetric.description : null;

  return (
    <>
      <div className="flex flex-row items-center justify-center">
        <div className="text-center md:mr-10 mr-5">
          <p className="text-[20px] font-bold">{lastWeekToDisplay}</p>
          <p className="text-[#807F7E] text-[14px]">previous</p>
        </div>
        <div className="relative">
          {/* <img src="/gauge.svg" className="w-[135px] absolute bottom-[8px] left-[7px] opacity-[1]" alt="Gauge" /> */}
          <div className="absolute bottom-[1.45rem] left-5">
            <GuageSvg />
          </div>
          <CircularProgressBar {...circleProps}>
            <div className="absolute top-1/2 left-1/2" style={{ transform: 'translate(-50%, -50%)' }}>
              <p className=" text-[36px] font-semibold text-center text-black">{moodAverageToDisplay}</p>
              <p className="text-[14px] text-[#807F7E] -mt-2 text-center">Chill</p>
            </div>
          </CircularProgressBar>
        </div>
        <div className="text-center md:ml-10 ml-5">
          <p className="text-[20px] font-bold">{averageToDisplay}</p>
          <p className="text-[#807F7E] text-center text-[14px]">average</p>
        </div>
      </div>
      <hr className="border-[#807F7E] mt-8" />
      {!! metricToDisplay && (
        <div className="mt-8">
          <p className="font-[18px] mb-8">What you need to know</p>
          <p className="font-[16px]">
            {metricToDisplay}
          </p>
        </div>
      )}
    </>
  );
};

export default MoodMeter;
