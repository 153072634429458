import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useBreadcrumbs } from '../../contexts/breadcrumbs-provider';
import Heading from '../../components/layout/admin/heading';
import AutoForm from '../../components/form/auto-form';
import Frame from '../../components/layout/frame';
import { timezoneOptions, statesOptions } from '../../utils/helpers';

const AdminToolkit = () => {
  const { setBreadcrumbs } = useBreadcrumbs();
  const { id } = useParams();

  useEffect(() => {
    setBreadcrumbs([
      {
        href: '/admin/toolkit-assignments',
        label: 'Toolkit Assignments',
      },
      {
        href: `/admin/toolkit-assignment${id ? `/${id}` : ''}`,
        label: `${id ? 'Update' : 'Create'} Toolkit Assignment`,
      },
    ]);
  }, [id]);


  const toolkitForm = {
    type: 'post',
    fetchRoute: id ? `/toolkit-assignment/${id}` : null,
    fetchHydrate: (fetched => fetched.toolkit),
    fetchErrorRedirect: '/admin/toolkit-assignment',
    fetchErrorMessage: 'There was an error while attempting to load the requested toolkit.',
    postRoute: `/toolkit-assignment${id ? `/${id}` : ''}`,
    successRedirect: '/admin/toolkit-assignments',
    successNotification: 'The toolkit was saved.',
    fields: [
      {
        name: 'email',
        label: 'Email',
        type: 'email',
        required: true,
        helpText: 'This email will be used to assign the Toolkit.',
      },
      {
        name: 'toolkitId',
        label: 'Choose Toolkit',
        type: 'select',
        optionsUrl: '/options/toolkits',
        required: true
      },
    ],
    submitLabel: `${id ? 'Update' : 'Create'} Toolkit Assignment`,
  };

  return (
    <Frame>
      <Heading title={`${id ? 'Update' : 'Create'} Toolkit Assignment`} />
      <div className="max-w-2xl mx-auto py-12">
        <AutoForm {...toolkitForm} />
      </div>
    </Frame>
  );
}

export default AdminToolkit;
