import React from 'react';

// Columns look like: [key, label, hideLabel, isBold, isRight (default false)]
// Rows are just objects with the values matching the appropriate key in columns
// keyCol is the designated key for a given row, defaults to id

export default function Table({ columns, rows, keyCol = undefined, alternate = false }) {
  if (!rows || !rows.length) {
    return null;
  }
  const realKeyCol = keyCol || 'id';

  // Some classes switch up based on the alternate bool
  const tableDivider = alternate ? 'divide-gray-300' : 'divide-gray-200';
  const thead = alternate ? '' : 'bg-gray-50';
  const th = alternate ? 'py-3 px-6 text-md font-semibold text-primary-500 text-left tracking-wider' : 'px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider';

  return (
    <div className={`flex flex-col ${alternate ? 'py-4' : ''}`}>
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="overflow-hidden">
            <table className={`min-w-full divide-y ${tableDivider}`}>
              <thead className={thead}>
                <tr>
                  {columns.map((column) => {
                    const { key, label, hideLabel } = column;
                    if (hideLabel) {
                      return (
                        <th key={key} scope="col" className="relative px-6 py-3">
                          <span className="sr-only">{label}</span>
                        </th>
                      );
                    }
                    return (
                      <th
                        key={key}
                        scope="col"
                        className={th}
                      >
                        {label}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {rows.map((row, i) => (
                  <tr key={row[realKeyCol]} className={alternate || i % 2 === 0 ? `bg-white ${alternate ? 'divide-x divide-gray-100 border-b border-gray-300' : ''}` : 'bg-gray-50'}>
                    {columns.map((column) => {
                      const { key, isBold, isRight, render } = column;
                      const cellKey = `${row[realKeyCol]}_${key}`;
                      return (
                        <td
                          key={cellKey}
                          className={`${alternate ? 'px-6 py-4' : 'px-6 py-4'} whitespace-nowrap text-sm text-gray-400 ${isBold ? 'font-semibold' : ''} ${isRight ? 'text-right' : ''}`}
                        >
                          {!!render && render(row)}
                          {!render && row[key]}
                        </td>
                      );
                    })}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
);
}