import { useState, useEffect } from 'react';
import Bugsnag from '@bugsnag/browser';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import { getApiRequest, postApiRequest } from '../../../utils/request';
import { formatUnixDate, getCurrentWeekForJourney, getCurrentWeekBasedOnStartDate, getSurveyName } from '../../../utils/helpers';
import Loadable from '../../../components/data/loadable';
import Frame from '../../../components/layout/frame';
import MemberHeader from '../../../components/layout/coach/member-header';
import MemberSection from '../../../components/layout/coach/member-section';
import RightSlideOver from '../../../components/overlays/right-slide-over';
import MemberSurveyResponse from '../../../components/layout/coach/member-survey-response';
import ActionLinks from '../../../components/data/action-links';
import AdminTable from '../../../components/common/admin-table';
import MoodBar from '../../../components/charts/mood-bar';
import QuarterlyGoal from '../../../components/charts/quarterly-goal';
import ActivitySummary from '../../../components/charts/activity-summary';
import MoodMeter from '../../../components/charts/mood-meter';
import GoalScore from '../../../components/charts/goal-score';
import Nl2br from '../../../components/common/nl2br';
import { useNotifications } from '../../../contexts/notifications-provider';
import ThemeProgress from '../../../components/coach/member/theme-progress/theme-progress';
import HabitProgress from '../../../components/coach/member/habit-progress/habit-progress';
import MoodProgress from '../../../components/coach/member/mood-progress/mood-progress';
import PerspectiveCheckin from '../../../components/coach/member/perpective-checkin/perspective-checkin';
import useJourneyData from '../../../hooks/use-journey-data';


function findSpecificWeekSeries(data, week) {
  if (!data || !data.series || week < 0) {
    return null;
  }
  for (let i = 0; i < data.series.length; i++) {
    const seriesData = data.series[i];
    if (seriesData.week === week) {
      return seriesData;
    }
  }
  return null;
}


function findMostRecentSeries(data, week) {
  let ret = null;
  let priorWeek = -1;
  for (let i = 0; i < data.series.length; i++) {
    const seriesData = data.series[i];
    if (seriesData.week > priorWeek && seriesData.week <= week) {
      ret = seriesData;
    }
  }
  return ret;
}

function CommentsList({ comments }) {
  if (!comments.length) {
    return (
      <p className="text-gray-500 text-center m-8">There are no member annotations to display.</p>
    );
  }
  return (
    <div className="m-4 px-8">
      {comments.map(comment => {
        const { value, week, updatedAt, question } = comment;
        const key = `${question._id}_${week}`;
        return (
          <div key={key} className="shadow-lg mb-8 border border-gray-100 rounded px-4 py-4">
            <p className="text-gray-500 mb-4 text-sm"><Nl2br str={value} /></p>
            <div className="flex justify-between">
              <p className="text-gray-400 text-xs">Date: {formatUnixDate(updatedAt._seconds)}</p>
              <p className="text-gray-400 text-xs">{`Week ${week}`}</p>
            </div>
          </div>
        );
      })}
    </div>
  )
}

function ChartsSection({ journey, data, showComments }) {
  if (!journey) {
    return null;
  }

  const { startDate, toolkitName } = journey;

  const { weeks: currentWeek, days: currentDay } = getCurrentWeekBasedOnStartDate(startDate);

  // We want to show the most recent weekly data, not actually the current week (we might not have done our survey yet this week)
  const currentWeekData = !!data && !!data.series ? findMostRecentSeries(data, currentWeek) : null;
  // Get the prior week data, if it exists
  const priorWeekData = !!currentWeekData ? findSpecificWeekSeries(data, currentWeekData.week - 1) : null;

  // Grab the data for the engagement box
  const { engagementFrequencyTotal, engagementTime } = data || {};
  // const engagementFrequencyTotal = 32;
  // const engagementTime = 410;

  const { goal } = journey || {};

  // Prepare any data that will be used in charts by looping over our series
  const hasComments = [];
  const moodOverTime = [];
  const goalOverTime = [];
  let hasMoodGraphData = false;
  let hasGoalGraphData = false;
  for (let i = 0; i < 12; i++) {
    const series = findSpecificWeekSeries(data, i);
    const goalData = series?.goalAverage || 0;
    const moodData = series?.moodAverage || 0;
    goalOverTime.push(goalData);
    moodOverTime.push(moodData);
    if (goalData) {
      hasGoalGraphData = true;
    }
    if (moodData) {
      hasMoodGraphData = true;
    }
    const { comments } = series || {};
    const filteredComments = !!comments && !!comments.length ? comments.filter((comment) => !!comment.value) : [];
    if (filteredComments && filteredComments.length) {
      hasComments.push(i);
    }
  }

  // const hasComments = [0, 3, 6, 10, 11];
  const goalScore = currentWeekData?.goalAverage ? currentWeekData.goalAverage : 0;
  const goalName = goal?.name;
  // const goalShort = 'Lately, I’m feeling less overwhelmed by my responsibilities.';
  const goalMetric = currentWeekData?.goalMetric;
  const goalRecentAvg = data?.goalAverage ? data.goalAverage : 0;

  return (
    <MemberSection>
      <h3 className="text-lg mb-4 font-semibold text-primary-500">Engagement Overview</h3>
      <div className="grid grid-cols-3 mb-8">
        <div className="pr-4">
          <ActivitySummary engagementFrequencyTotal={engagementFrequencyTotal} engagementTime={engagementTime} />
        </div>

        <div className="px-4">
          <MoodMeter moodAverage={currentWeekData?.moodAverage} moodMetric={currentWeekData?.moodMetric} lastWeek={priorWeekData?.moodAverage} average={data?.moodAverage} />
        </div>

        <div className="pl-4">
          <GoalScore goalName={goalName} goalMetric={goalMetric} goalRecentAvg={goalRecentAvg} goalScore={goalScore} />
        </div>
      </div>
      <div className="grid grid-cols-2 mb-8">
        <div className="pr-2">
          <h4 className="text-primary-500 font-bold mb-2">Mood</h4>
          <div className="bg-gray-50 p-3 rounded-l shadow">
            {!hasMoodGraphData && <p className="pt-8 pb-32 px-10 text-center text-gray-500 text-sm">Mood graph data will show up here as the member continues their journey.</p>}
            {!!hasMoodGraphData && <MoodBar moods={moodOverTime} toolkitName={toolkitName} />}
          </div>
        </div>

        <div className="pl-2">
          <h4 className="text-primary-500 font-bold mb-2">Quarterly Goal</h4>
          <div className="bg-gray-50 p-3 rounded-lg shadow">
            {!hasGoalGraphData && (
              <p className="pt-8 pb-32 px-10 text-center text-gray-500 text-sm">Quarterly goal graph data will show up here as the member continues their journey.</p>
            )}
            {!!hasGoalGraphData && <QuarterlyGoal goalOverTime={goalOverTime} hasComments={hasComments} goalName={goalName} />}
          </div>
          <button onClick={showComments} className="mt-4 px-6 py-4 rounded-xl bg-darkblue text-white text-sm font-semibold">
            View Member Annotations
          </button>
        </div>
      </div>
    </MemberSection>
  );
}




function SurveyResponsesSection({ journeyId, showResponse }) {
  const [responses, setResponses] = useState(null);
  const [loading, setLoading] = useState(true);
  const { addNotification } = useNotifications();

  const loadResponses = async () => {
    if (!loading) {
      setLoading(true);
    }
    try {
      const result = await getApiRequest(`/coach/journey/${journeyId}/survey-responses/v2`);
      setResponses(result.responses);
    } catch (err) {
      Bugsnag.notify(err);
      console.log(err, 'there was an error loading the survey responses');
    }
    setLoading(false);
  };

  const requestMonthlySurvey = async () => {
    try {
      await postApiRequest(`/coach/request-monthly-survey/${journeyId}`);
      addNotification({
        type: 'success',
        body: "The member now has a Perspective Check-in. They were notified if it was new.",
      });
    } catch (err) {
      addNotification({
        type: 'error',
        body: "There was an error creating the Perspective Check-in for the member.",
      });
      console.log(err, 'perspective create error');
    }
  };

  useEffect(() => {
    if (journeyId) {
      loadResponses();
    }
  }, [journeyId]);


  const formatRow = (item) => {
    const { _id: id, instance, updatedAt } = item;
    const { type } = instance;
    const name = getSurveyName(type);
    return {
      name,
      // week,
      completed: updatedAt ? moment.unix(updatedAt._seconds).format('lll') : '--',
      id,
      actionLinks: [
        {
          label: 'View Survey',
          onClick: () => showResponse(item),
        },
      ],
    };
  };

  const columns = [
    {
      key: 'name',
      label: 'Name',
      isBold: true,
    },
    // {
    //   key: 'week',
    //   label: 'Week',
    // },
    {
      key: 'completed',
      label: 'Completed On',
    },
    {
      key: 'actions',
      label: '',
      isRight: true,
      render: (row) => { return !!row.actionLinks ? <ActionLinks links={row.actionLinks} /> : null; },
    },
  ];

  const hasResponses = !!responses && !!responses.length;

  const reversedResponses = hasResponses ? responses.sort((a, b) => b.updatedAt._seconds - a.updatedAt._seconds) : [];


  return (
    <MemberSection>
      <Loadable loading={loading}>
        <div className="grid grid-cols-5 mt-8">
          <div className="col-span-3">
            <h3 className="text-lg font-semibold text-primary-500">Surveys</h3>
            <button onClick={requestMonthlySurvey} className="mt-4 px-6 py-4 rounded-xl bg-darkblue text-white text-sm font-semibold">Send Perspective Check-in</button>
          </div>
          
        </div>
        <div className="space-y-3 divide-y divide-gray-300">
          {!hasResponses && <p className="text-md text-gray-500 my-4 text-sm">There are no completed surveys.</p>}
          {hasResponses && <AdminTable fullWidth alternate results={{ data: reversedResponses }} columns={columns} rowFormatter={formatRow} />}
          {/* {hasResponses && notes.map(note => {
            const { title, description, week, date, _id } = note;
            return (
              <div key={_id} className="pb-2 pt-4">
                <h4 className="font-semibold text-gray-700 text-lg mb">{title}</h4>
                <h4 className="font-semibold text-primary-500 mb-3">{`Week ${week}`}</h4>
                <p className="text-gray-500 text-sm"><Nl2br str={description} /></p>
                <p className="text-gray-400 text-sm mt-1 text-right">{formatUtcUnixDate(date._seconds)}</p>
              </div>
            )
          })} */}
        </div>
      </Loadable>
    </MemberSection>
  );
}



export default function CoachMemberAnalyze() {
  const { id } = useParams();
  const [sideOpen, setSideOpen] = useState(false);
  const [summary, setSummary] = useState(null);
  const [comments, setComments] = useState(null);
  const [displayComments, setDisplayComments] = useState(false);
  const [loading, setLoading] = useState(true);
  const [response, setResponse] = useState(null);
  const [goals, setGoals] = useState(null);
  const [responses, setResponses] = useState();
  const [journeyData, setJourneyData] = useState(null);
  const { getMemberJourneyData } = useJourneyData();
  const navigate = useNavigate();

  const load = async () => {
    if (!loading) {
      setLoading(true);
    }
    try {
      const result = await getApiRequest(`/coach/journey/${id}`);
      setSummary(result);
      const goalsResult = await getApiRequest(`/options/goals/${result.journey?.toolkitId}`);
      setGoals(goalsResult.options);
    } catch (err) {
      Bugsnag.notify(err);
      console.log(err, 'there was an error loading the summary');
    }
    setLoading(false);
  };

  const loadJourneyData = async (memberUid) => {
    const d = await getMemberJourneyData(memberUid);
    setJourneyData(d);
  };

  const switchJourneys = (newId) => {
    if (id !== newId) {
      navigate(`/coach/member/journey/${newId}/analyze`);
    }
  }

  const loadJourney = async () => {
    try {
      // Load responses based on version of journey
      const v2Text = summary?.journey?.v2 ? '/v2' : '';
      const result = await getApiRequest(`/coach/journey/${id}/survey-responses${v2Text}`);
      if (summary?.journey?.v2) {
        // Filter out responses to only show perspective check-ins and intro/kick off surveys
        const newResponses = !!result.responses ? result.responses : [];
        newResponses.reverse();
        // console.log(newResponses, 'new responses');
        const filteredResponses = newResponses.sort((a, b) => b?.createdAt?._seconds - a?.createdAt?._seconds).filter((item) => ['Kick Off', 'Intro', 'Monthly', 'Exercise'].indexOf(item.surveyType) !== -1);
        setResponses(filteredResponses.length ? filteredResponses : null);
      } else {
        setResponses(result.responses);
      }
    } catch (err) {
      console.log('ERROR:: ', err);
    }
  };

  const openSide = () => setSideOpen(true);
  const closeSide = () => {
    setSideOpen(false);
    setResponse(null);
  };

  const showResponse = (response) => {
    setResponse(response);
    setDisplayComments(false);
    openSide();
  };

  const showComments = () => {
    setResponse(null);
    setDisplayComments(true);
    openSide();
  };

  useEffect(() => {
    load();
    // if (id) {
    //   loadJourney();
    // }
  }, [id]);

  useEffect(() => {
    if (summary?.journey) {
      loadJourney();
      loadJourneyData(summary.journey.uid);
    }
  }, [summary]);



  const { data } = summary || {};


  useEffect(() => {
    if (!!data && data.series) {
      const newComments = [];
      data.series.forEach((d) => {
        if (d.comments && d.comments.length) {
          d.comments.forEach((comment) => {
            if (comment && comment.value) {
              newComments.push({
                ...comment,
                week: d.week,
                updatedAt: d.updatedAt,
              });
            }
          });
        }
      });
      setComments([...newComments].reverse());
    }
  }, [data]);

  const sidebarTitle = response ? `Survey Response Week ${response.week}` : !!comments ? 'Member Annotations' : '';

  const isV2 = !!summary?.journey?.v2;


  

  return (
    <Frame fullscreen classes="p-4">
      <Loadable loading={loading}>
      {!!summary && (
        <>

          <MemberHeader memberUid={summary.journey.uid} journeyId={id} which="analyze" name={summary.profile.name} toolkitName={summary.journey.toolkitName} />
          <div className="w-full my-8 px-4 mx-auto">
            {isV2 && !!journeyData && (
              <MoodProgress
                journeyData={journeyData}
                data={summary.journeyData}
              />
            )}
            {isV2 && !!journeyData && (
              <HabitProgress switchJourneys={switchJourneys} journey={summary?.journey} userData={summary.userData} journeyData={journeyData} data={summary?.journeyData || summary?.data} />
            )}
            {isV2 && !!journeyData && <ThemeProgress data={summary?.journeyData || summary?.data} journeyData={journeyData} />}
            {/* {isV2 && !responses && <p>No check ins completed yet</p>} */}
            {isV2 && !!journeyData && <PerspectiveCheckin journey={summary?.journey} responses={responses} />}
            {!isV2 && <ChartsSection journey={summary.journey} data={summary.data} goals={goals} showComments={showComments} />}
            {!isV2 && <SurveyResponsesSection journeyId={id} showResponse={showResponse} />}
          </div>
        </>
      )}
        <RightSlideOver darkBg large isOpen={sideOpen} close={closeSide} title={sidebarTitle}>
          {!!sideOpen && !!response && <MemberSurveyResponse goals={goals} response={response} />}
          {!!sideOpen && !!comments && displayComments && <CommentsList comments={comments} />}
        </RightSlideOver>
      </Loadable>
    </Frame>
  );
}




