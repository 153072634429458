import { useState, useEffect } from 'react';
import Bugsnag from '@bugsnag/browser';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import Avatar from '../../../components/common/avatar';
import { getApiRequest } from '../../../utils/request';
import { timezonePrettyName } from '../../../utils/helpers';
import Loadable from '../../../components/data/loadable';
import Frame from '../../../components/layout/frame';
import MemberHeader from '../../../components/layout/coach/member-header';
import MemberSection from '../../../components/layout/coach/member-section';

function InfoSection({ left, right }) {
  return (
    <div className="grid grid-cols-4 my-10">
      <div className="col-span-2">
        <h4 className="text-sm text-gray-400 font-medium mb-1">{left.label}</h4>
        <p className="text-md text-gray-700">{left.value || '--'}</p>
      </div>

      <div>
        <h4 className="text-sm text-gray-400 font-medium mb-1">{right.label}</h4>
        <p className="text-md text-gray-700">{right.value || '--'}</p>
      </div>
    </div>
  );
}

function ProfileSection({ profile }) {

  const { photo, name, pronouns, timezone, birthday, shippingAddress, shippingAddress2, shippingCity, shippingState, shippingZip, phone, email } = profile;

  const birthdayMoment = birthday ? moment(birthday) : null;
  const age = birthdayMoment ? Math.floor(moment().diff(birthdayMoment, 'year', true)) : null;

  const shippingAddressFormatted = !!shippingAddress ? (
    <>
      {shippingAddress}{`${shippingAddress2 ? `, ${shippingAddress2}` : ''}`}<br />
      {shippingCity}, {`${shippingState} ${shippingZip}`}
    </>
  ) : '';
  

  return (
    <MemberSection>
      <div className="grid grid-cols-4">
        <div className="col-span-3">
          <h3 className="text-lg font-semibold text-primary-500">Member Information</h3>
          <InfoSection left={{ label: 'Full Name', value: name }} right={{ label: 'Pronouns', value: pronouns }} />
          <InfoSection left={{ label: 'Email Address', value: email }} right={{ label: 'Phone Number', value: phone }} />
          <InfoSection left={{ label: 'Shipping Address', value: shippingAddressFormatted }} right={{ label: 'Time Zone', value: timezonePrettyName(timezone) }} />
          <InfoSection left={{ label: 'Birthday', value: birthday || '--' }} right={{ label: 'Age', value: age || '--' }} />

        </div>
        <div className="col-span-1 text-center">
          <div className="mb-4">
            <Avatar xl photo={photo} name={name} bg="bg-secondary-500" />
          </div>
        </div>
      </div>
    </MemberSection>
  );
}

export default function CoachMemberInfo() {
  const { id } = useParams();
  const [summary, setSummary] = useState(null);
  const [loading, setLoading] = useState(true);
  const load = async () => {
    if (!loading) {
      setLoading(true);
    }
    try {
      const result = await getApiRequest(`/coach/journey/${id}`);
      setSummary(result);
    } catch (err) {
      Bugsnag.notify(err);
      console.log(err, 'there was an error loading the summary');
    }
    setLoading(false);
  };


  useEffect(() => {
    load();
  }, [id]);


  return (
      <Frame fullscreen classes="p-4">
        <Loadable loading={loading}>
        {!!summary && (
          <>
            <MemberHeader memberUid={summary.journey.uid} journeyId={id} which="info" name={summary.profile.name} toolkitName={summary.journey.toolkitName} />
            <div className="max-w-7xl my-8 px-4 mx-auto">
              <ProfileSection profile={summary.profile} />
            </div>
          </>
        )}
        </Loadable>
      </Frame>
  );
}
