// Turn newlines into line breaks
export default function Nl2br({ str }) {
  if (!str) {
    return null;
  }
  if (typeof str !== 'string') {
    return str;
  }
  return <>{str.split('\n').map((item, key) => (<span key={key}>{item}<br /></span>))}</>;
}
