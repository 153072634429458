import { useState, useEffect } from 'react';
import Loadable from '../data/loadable';
import { searchContent } from '../../utils/search';
import SimplePaginator from '../data/simple-paginator';
import { useDebouncedCallback } from 'use-debounce';
import AddButton from '../buttons/add-button';
import SearchSelect from './search-select';
import ItemDisplay from './item-display';


function SearchItem({ item, addItem }) {
  // console.log(item, 'this is the item');
  return (
    <div className="flex py-4">
      <ItemDisplay item={item} />
      <div className="ml-4 flex-shrink-0 self-center bg-gray-50 p-1">
        <AddButton onClick={() => addItem(item)} />
      </div>
    </div>
  );
}

function SearchResults({ items, addItem }) {
  if (!items?.length) {
    return (
      <div className="py-4 text-gray-700">
        <p>
          No results matching your query were found.
        </p>
      </div>
    );
  }

  return (
    <div className="py-4 divide-y">
      {items.map(item => <SearchItem item={item} key={item._id} addItem={addItem} />)}
    </div>
  );
}


function QueryInput({ value, onChange }) {
  const [localValue, setLocalValue] = useState('');
  useEffect(() => {
    setLocalValue(value);
  }, []);
  
  const debounced = useDebouncedCallback((value) => {
    onChange(value);
  }, 300);
  const handleChange = (e) => {
    const newVal = e.target.value;
    setLocalValue(newVal);
    debounced(newVal);
  };
  return (
    <input
      onChange={handleChange}
      value={localValue}
      className={`block w-full rounded-md sm:text-sm shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300`}
      id="query"
      name="query"
      type="text"
      placeholder="Search DD content..."
    />
  );
}

export default function ContentSearch({ step, addItem, toolkitIds, currentToolkitId }) {
  const [filters, setFilters] = useState({});
  const [query, setQuery] = useState('');
  const [toolkitId, setToolkitId] = useState('');
  const [category, setCategory] = useState('');
  const [exerciseType, setExerciseType] = useState('');
  const [exploreType, setExploreType] = useState('');
  const [contentType, setContentType] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [items, setItems] = useState([]);
  const [itemIds, setItemIds] = useState([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);

  const perPage = 12;
  const from = items.length === 0 ? 0 : (currentPage * perPage) + 1;
  const to = (currentPage * perPage) + items.length;

  const buildFilters = () => {
    const filterKeys = Object.keys(filters);
    const filterParts = ['status:published'];

    for (let i = 0; i < filterKeys.length; i++) {
      const filterName = filterKeys[i];
      const { values, join, op, negate } = filters[filterName];
      const parts = values.map(val => {
        let filterString = negate ? 'NOT ' : '';
        return `${filterString}${filterName}${op}"${val}"`;
      });
      if (parts.length) {
        filterParts.push(`(${parts.join(join)})`);
      }
    }
    // Filter out item ids
    if (itemIds.length) {
      const itemIdParts = itemIds.map(val => {
        return `NOT objectID:"${val}"`;
      });
      if (itemIdParts.length) {
        filterParts.push(`(${itemIdParts.join(' AND ')})`);
      }
    }
    // Always make sure that we limit to toolkits that we have access to (as long as this is not explore content)
    if ((filterKeys.indexOf('internal_content_type') === -1 || filters.internal_content_type.values.indexOf('explore_content') === -1) && filterKeys.indexOf('toolkitId') === -1) {
      const toolkitFilterParts = [];
      toolkitIds.forEach(opt => {
        if (opt.value) {
          toolkitFilterParts.push(`toolkitId:${opt.value}`);
        }
      });
      filterParts.push(`(${toolkitFilterParts.join(' OR ')})`);
    }
    // Make sure we only send back one of our three types
    if (filterKeys.indexOf('internal_content_type') === -1) {
      filterParts.push(`(internal_content_type:exercises OR internal_content_type:activities2 OR internal_content_type:explore_content)`);
    }
    return filterParts.join(' AND ');
  }

  const search = async (page) => {
    setLoading(true);
    try {
      const options = {
        hitsPerPage: perPage,
        filters: buildFilters(),
      };
      console.log(options, 'search options');
      const results = await searchContent(query, options, page);
      setTotal(results.nbHits);
      setItems(results.hits);
      console.log(results, 'results from algolia');
    } catch (err) {
      console.log(err, 'error searching content');
    }
    setLoading(false);
  };

  useEffect(() => {
    setCurrentPage(0);
    search(0);
  }, [filters, query]);

  useEffect(() => {
    if (step.items.length === itemIds.length) {
      return;
    }
    setItemIds(step.items.map(item => item.id));
  }, [step]);

  useEffect(() => {
    search(currentPage);
  }, [itemIds]);

  const changeSelectFilter = (newVal, filterKey) => {
    const newFilters = {...filters};
    // If nothing has changed, just leave
    if (newFilters[filterKey] && newFilters[filterKey].values.indexOf(newVal) !== -1) {
      return;
    }
    if (filterKey === 'internal_content_type') {
      if (newVal === 'explore_content') {
        // Make sure that we don't include toolkitId, and that we reset type
        if (newFilters.toolkitId) {
          setToolkitId('');
          delete newFilters.toolkitId;
        }
      } else if (newFilters['category.title']) {
        delete newFilters['category.title'];
        setCategory('');
      }
      // Clear out exercise and explore types if we are switching away from them
      if (newVal !== 'explore_content') {
        setExploreType('');
      }
      if (newVal !== 'exercises') {
        setExerciseType('');
      }
      // Always reset type when switching between content types
      if (newFilters.type) {
        delete newFilters.type;
      }
    }

    if (newVal === '') {
      if (newFilters[filterKey]) {
        delete newFilters[filterKey];
        setFilters(newFilters);
      }
      return;
    }
    newFilters[filterKey] = {
      values: [newVal],
      join: ' OR ',
      op: ':',
    };

    setFilters(newFilters);
  };

  useEffect(() => {
    if (contentType === 'explore_content') {
      return;
    }
    changeSelectFilter(toolkitId, 'toolkitId');
  }, [toolkitId]);

  useEffect(() => {
    if (contentType !== 'explore_content') {
      return;
    }
    changeSelectFilter(category, 'category.title');
  }, [category]);

  useEffect(() => {
    changeSelectFilter(contentType, 'internal_content_type');
  }, [contentType]);

  useEffect(() => {
    if (contentType !== 'exercises') {
      return;
    }
    changeSelectFilter(exerciseType, 'type');
  }, [exerciseType]);

  useEffect(() => {
    if (contentType !== 'explore_content') {
      return;
    }
    changeSelectFilter(exploreType, 'type');
  }, [exploreType]);

  const nextPage = () => {
    if (to >= total) {
      return;
    }
    const newPage = currentPage + 1;
    setCurrentPage(newPage);
    search(newPage);
  };
  const prevPage = () => {
    if (currentPage === 0) {
      return;
    }
    const newPage = currentPage - 1;
    setCurrentPage(newPage);
    search(newPage);
  };

  if (!step) {
    return null;
  }

  const categoryOptions = [
    {
      value: '',
      label: 'Select Category...',
    },
    {
      value: 'Perspectives',
      label: 'Perspectives',
    },
    {
      value: 'Science Library',
      label: 'Science Library',
    },
    {
      value: 'The Daydreamers Universe',
      label: 'The Daydreamers Universe',
    },
    {
      value: 'DD in the Wild',
      label: 'DD in the Wild',
    },
    {
      value: 'Head in the Clouds',
      label: 'Head in the Clouds',
    },
    {
      value: 'The DD POV',
      label: 'The DD POV',
    },
  ];
  const contentTypeOptions = [
    {
      value: '',
      label: 'Select Content Type...',
    },
    {
      value: 'exercises',
      label: 'Exercises',
    },
    {
      value: 'explore_content',
      label: 'Explore',
    },
    {
      value: 'activities2',
      label: 'Thoughts',
    },
  ];
  const exerciseTypeOptions = [
    {
      value: '',
      label: 'Select Type...',
    },
    {
      value: 'Habit-building',
      label: 'Habit-building',
    },
    {
      value: 'Mental flexibility',
      label: 'Mental flexibility',
    },
    {
      value: 'Self-expression',
      label: 'Self-expression',
    },
  ];
  const exploreTypeOptions = [
    {
      value: '',
      label: 'Select Type...',
    },
    {
      value: 'Reading',
      label: 'Reading',
    },
    {
      value: 'Video',
      label: 'Video',
    },
  ];

  const changeToolkit = (e) => {
    setToolkitId(e.target.value || '');
  };
  const changeContentType = (e) => {
    setContentType(e.target.value || '');
  };
  const changeCategory = (e) => {
    setCategory(e.target.value || '');
  };
  const changeExerciseType = (e) => {
    setExerciseType(e.target.value || '');
  };
  const changeExploreType = (e) => {
    setExploreType(e.target.value || '');
  };

  return (
    <div className="space-y-2">
      <QueryInput onChange={(val) => setQuery(val)} value={query} />
      <div className="flex space-x-4">
        <SearchSelect name="contentType" options={contentTypeOptions} value={contentType} onChange={changeContentType} />
        {contentType !== 'explore_content' && toolkitIds?.length > 1 && <SearchSelect name="toolkitId" options={toolkitIds} value={toolkitId} onChange={changeToolkit} />}
        {contentType === 'explore_content' && <SearchSelect name="category" options={categoryOptions} value={category} onChange={changeCategory} />}
        {contentType === 'exercises' && <SearchSelect name="exerciseType" options={exerciseTypeOptions} value={exerciseType} onChange={changeExerciseType} />}
        {contentType === 'explore_content' && <SearchSelect name="exploreType" options={exploreTypeOptions} value={exploreType} onChange={changeExploreType} />}
      </div>
      <Loadable loading={loading}>
        <SearchResults items={items} addItem={addItem} />
      </Loadable>
      <SimplePaginator from={from} to={to} total={total} previousUrl={prevPage} nextUrl={nextPage} />
    </div>
  );
}
