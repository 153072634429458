import Nl2br from "../../../common/nl2br";

const ThemeProgressJourney = ({ average, prevAvg, goalName, goalMetric, lastGoalDate }) => {
  return (
    <div>
      <div className="flex flex-row items-center justify-center">
        <div className="mr-5">
          <div className="rounded-full w-20 h-20 border-[1px] border-black relative">
            <div
              className="rounded-full w-[4.5rem] h-[4.5rem] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 flex items-center justify-center"
              style={{ backgroundImage: 'radial-gradient(#C3B2FF, #D6E3F5)' }}
            >
              <p className="text-[32px] font-bold">{average}</p>
            </div>
          </div>
          <p className="text-[16px] text-center mt-1">{lastGoalDate || '--'}</p>
        </div>
        <div>
          <p className="text-[20px] font-normal mb-4">{goalName}</p>
          <p>Recent average: {prevAvg}</p>
        </div>
      </div>
      <div className="border-t-[1px] border-t-[#807F7E] mt-10" />
      <div className="mt-5">
        <p className="text-[18px] mb-4">Here's how to think about it</p>
        <p className="text-[16px] mb-6">{<Nl2br str={goalMetric?.description} />}</p>
      </div>
    </div>
  );
};

export default ThemeProgressJourney;
