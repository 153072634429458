import moment from 'moment';
import ThemeProgressCreative from './theme-progress-creative';
import ThemeProgressJourney from './theme-progress-journey';
import { formatScoreForDisplay } from '../../../../utils/helpers';


const ThemeProgress = ({ data, journeyData }) => {


  const { goalScore, goalScoreAverage, goalName, lastGoalDate, goalId, goalMetric } = data || {};
  const formattedDate = lastGoalDate ? moment(lastGoalDate).format('M/DD/YY') : null;

  return (
    <div className="mb-20">
      <h3 className="text-[32px] font-bold mb-4">Theme Progress</h3>
      <div className="flex lg:flex-row flex-col items-stretch">
        <div className="lg:w-1/2 w-full mr-7 rounded-md">
          <p className="text-[20px] bg-white pb-3">Creative Perspective</p>
          <div className="bg-[#D9D9D9] p-4 rounded-lg h-full">
            <ThemeProgressCreative
              journeyData={journeyData}
              goalId={goalId}
            />
          </div>
        </div>
        <div className="lg:w-1/2 w-full rounded-md">
          <p className="text-[20px] pb-3 lg:pt-0 pt-3">Theme for Journey</p>
          <div className="bg-[#D9D9D9] p-10 rounded-lg h-full">
            <ThemeProgressJourney
              average={formatScoreForDisplay(goalScore)}
              prevAvg={formatScoreForDisplay(goalScoreAverage)}
              goalName={goalName}
              goalMetric={goalMetric}
              lastGoalDate={formattedDate}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThemeProgress;
