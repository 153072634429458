import React, { useEffect, useState } from 'react';
import { AdjustmentsIcon } from '@heroicons/react/outline';
import { FiSliders } from 'react-icons/fi';
import RightSlideOver from '../../../overlays/right-slide-over';
import {
  formatUnixDate,
  getAllDataWithFilterHabit,
  getAvg,
  getFavDay,
  getStreak,
  getToolkitDataWithFilterHabit,
  // getToolkitListForFilter,
  // getToolkitListForFilterHabit,
} from '../../../../utils/helpers';
import moment from 'moment';

const filterDateData = [
  { isSelected: false, filter: '2 Weeks', duration: '2 Weeks', id: '01' },
  { isSelected: false, filter: '1 Month', duration: '1 Month', id: '02' },
  { isSelected: true, filter: '3 Months', duration: '3 Months', id: '03' },
  { isSelected: false, filter: '6 Months', duration: '6 Months', id: '04' },
  { isSelected: false, filter: 'All', duration: '10 Years', id: '05' },
];

const HabitProgressEngagement = ({ journeyData }) => {

  const [allData, setAllData] = useState(null);
  const [init, setInit] = useState(false);
  const [combinedData, setCombinedData] = useState(null);
  const [totalDays, setTotalDays] = useState(null);
  const [earliestStart, setEarliestStart] = useState(null);

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [habitFilterData, setHabitFilterData] = useState([]);
  const [timeHorizons, setTimeHorizons] = useState(filterDateData);
  const [journeyArrayData, setJourneyArrayData] = useState([]);
  const [isJourneyItemSelectedZero, setIsJourneyItemSelectedZero] = useState([]);
  const [timeHorizonSelected, setTimeHorizonSelected] = useState(filterDateData[2]);

  useEffect(() => {
    if (!journeyData || !journeyData.length) {
      return;
    }


    // Assemble all of our mood data
    const newAllData = [];
    // Loop over journeys with data
    for (let i = 0; i < journeyData.length; i++) {
      const d = journeyData[i];
      // Mood
      if (d && d.habitSeries && d.habitSeries.length) {
        newAllData.push({
          _id: d._id,
          toolkitName: d.toolkitName,
          toolkitId: d.toolkitId,
          startTimestamp: d.createdAt._seconds,
          endTimestamp: d.toolkitComplete ? d.updatedAt._seconds : null,
          data: d.habitSeries,
        });
      }
    }

    setAllData(newAllData);
    // console.log(journeyData, 'this is journey data');
  }, [journeyData]);

  useEffect(() => {
    if (!allData || !allData.length || !journeyData || !journeyData.length) {
      setCombinedData(null);
      return;
    }
    const newCombinedData = [];
    const eachDay = [];
    let newEarliestStart = moment().unix();
    for (let i = 0; i < allData.length; i++) {
      const d = allData[i];
      const { startTimestamp } = d;
      if (startTimestamp < newEarliestStart) {
        newEarliestStart = startTimestamp;
      }
      for (let j = 0; j < d.data.length; j++) {
        // Transform our data to match the format that the functions expect...
        const toTransform = d.data[j];
        const { journeyToolkitName: toolkit, journeyId, id, hasReflection: isReflection, score: y, isoTime, toolkitName: otherToolkit, reflections, dateShort, exerciseType, exerciseTitle } = toTransform;
        const hasDifferentToolkit = !!otherToolkit && (toolkit !== otherToolkit);
        const x = moment(isoTime).format('MM/DD/YYYY HH:mm:ss');
        newCombinedData.push({
          ...toTransform,
          x,
          y,
          date: x,
          isReflection,
          hasDifferentToolkit,
          otherToolkit,
          index: id,
          journeyId,
          toolkit,
          reflections,
          dateShort,
          exerciseType,
          exerciseTitle,
        });
        if (eachDay.indexOf(dateShort) === -1) {
          eachDay.push(dateShort);
        }
      }
    }
    setCombinedData(newCombinedData);
    setTotalDays(eachDay.length);
    setEarliestStart(newEarliestStart);

    // Build our array of filters from our journeys
    const newJourneyFilters = [];

    // Loop over journeys with data
    for (let i = 0; i < journeyData.length; i++) {
      const d = journeyData[i];
      // Filters
      newJourneyFilters.push({
        index: d._id,
        journeyId: d._id,
        toolkit: d.toolkitName,
        x: formatUnixDate(d.createdAt._seconds, 'MM/DD/YYYY HH:mm:ss'),
        date: formatUnixDate(d.createdAt._seconds, 'MM/DD/YYYY HH:mm:ss'),
        sortBy: d.createdAt._seconds,
        subtext: formatUnixDate(d.createdAt._seconds, 'M/YY'),
        isSelected: i === 0, // select the first one by default
      });
    }

    newJourneyFilters.push({
      index: 'All',
      toolkit: 'All',
      isSelected: false,
    });
    setJourneyArrayData(newJourneyFilters);
    setIsJourneyItemSelectedZero(!newJourneyFilters?.find((item) => item.isSelected))

    setInit(true);
  }, [allData, journeyData]);


  const onToolkitSelect = (selectedItem, index) => {
    let updateData = [...journeyArrayData];

    if (index === journeyArrayData.length - 1 || index === 0) {
      updateData.forEach((item) => (item.isSelected = false));
      updateData[index].isSelected = !updateData[index].isSelected;
    } else {
      updateData[0].isSelected = false;
      updateData[updateData.length - 1].isSelected = false;
      updateData[index].isSelected = !selectedItem.isSelected;
    }

    setJourneyArrayData(updateData);
  };

  const onToolkitDateSelect = (selectedItem) => {
    setTimeHorizonSelected(selectedItem);
  };

  const gotItClick = () => {
    if (!isJourneyItemSelectedZero && timeHorizonSelected) {
      console.log('in if');
      const selectedTimeHorizon = timeHorizonSelected;
      const selectedFilter = journeyArrayData.find((item) => item.isSelected);

      if (selectedFilter.toolkit === 'Latest' || selectedFilter.toolkit === 'All') {
        setHabitFilterData(getAllDataWithFilterHabit(selectedTimeHorizon.duration, combinedData));
      } else {
        setHabitFilterData(
          ...journeyArrayData.reduce((res, item) => {
            if (item.isSelected) {
              res.push(getToolkitDataWithFilterHabit(item.toolkit, selectedTimeHorizon.duration, combinedData));
            }
            return res;
          }, [])
        );
      }
      setIsDrawerOpen(false);
    }
  };

  useEffect(() => {
    if (!init) {
      return;
    }
    setHabitFilterData(getAllDataWithFilterHabit(timeHorizons?.filter((item) => item.isSelected)[0].duration, combinedData));
    // return () => {
    //   onToolkitSelect(getToolkitListForFilter()[0], 0);
    // };
  }, [init]);

  if (!init) {
    return null;
  }


  return (
    <div className="w-full">
      <div className="flex flex-row items-center justify-end w-full">
        <div className="flex flex-row items-center -scale-x-[1] mr-4">
          {[...new Set([timeHorizonSelected, ...journeyArrayData.filter((item) => item.isSelected)])].map((item, i) =>
            !item.date ? (
              <p key={i} className="text-[14px] px-4 py-2 rounded-full border border-[#342F3B] text-black mr-4 -scale-x-[1]">{item.toolkit ? item.toolkit : item.filter}</p>
            ) : (
              <p key={i} className="text-center text-[14px] px-4 py-2 rounded-full border border-[#342F3B] font-[500] -scale-x-[1] mr-2 minW-[150px]">
                {item.toolkit ? item.toolkit + ' ' + moment(item?.date, 'MM/DD/YYYY HH:mm:ss').format('MMM ‘YY') : item.filter}
              </p>
            )
          )}
        </div>
        <div className="w-8 h-8 rounded-full flex items-center justify-center bg-black cursor-pointer" onClick={() => setIsDrawerOpen(true)}>
          <FiSliders className="h-4 w-4 text-white" />
        </div>
      </div>
      <div className="flex flex-col md:flex-row items-center lg:items-end justify-center mt-10">
        <div className="md:mr-5 mr-1 mb-2 lg:mb-0">
          <div className="rounded-full w-[7rem] h-[7rem] border-[1px] border-black relative">
            <div
              className="rounded-full w-[6.5rem] h-[6.5rem] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 flex items-center justify-center"
              style={{ backgroundImage: 'radial-gradient(#C3B2FF, #3D3380)' }}
            >
              <div>
                <p className="text-[20px] font-bold text-center">{getAvg('duration', habitFilterData)}</p>
                {habitFilterData.length === 0 ? <div /> : <p className="text-[12px] font-[500] text-center -mt-[6px]">min</p>}
              </div>
            </div>
          </div>
          <p className="text-[14px] text-center mt-1">avg time</p>
        </div>
        <div className="md:mr-5 mr-1 mb-2 lg:mb-0">
          <div className="rounded-full w-[10rem] h-[10rem] relative">
            <div
              className="rounded-full w-[9.5rem] h-[9.5rem] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 flex items-center justify-center"
              style={{ backgroundImage: 'radial-gradient(closest-side at 50% 50%, rgba(195, 178, 255, 1), rgba(214, 227, 245, 1))' }}
            >
              <p className="text-[32px] font-bold">{getFavDay(habitFilterData)}</p>
            </div>
          </div>
          <p className="text-[14px] text-center mt-1">fav day</p>
        </div>
        <div className="lg:mr-5 mr-0">
          <div className="rounded-full w-[7rem] h-[7rem] border-[1px] border-black relative">
            <div
              className="rounded-full w-[6.5rem] h-[6.5rem] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 flex items-center justify-center"
              style={{ backgroundImage: 'radial-gradient(#C3B2FF, #3D3380)' }}
            >
              <div>
                <p className="text-[20px] font-bold">{habitFilterData.length === 0 ? getAvg('frequency', habitFilterData) : getAvg('frequency', habitFilterData) + 'x'}</p>
              </div>
            </div>
          </div>
          <p className="text-[14px] text-center mt-1">avg freq</p>
        </div>
      </div>
      {!!totalDays && !!earliestStart && (
        <p className="bg-white rounded-md md:w-[70%] w-[90%] text-center mt-8 mx-auto px-4 py-2">
          {totalDays} creative day{totalDays === 1 ? '' : 's'} since joining {`(`}{formatUnixDate(earliestStart, 'M/D/YY')}{`)`}
        </p>
      )}
      <RightSlideOver isOpen={isDrawerOpen} darkBg close={() => setIsDrawerOpen(false)} title="Filters" isNew>
        <p className="font-[500] mb-10 text-[20px]">Engagement Breakdown</p>
        <div>
          <p className="mb-5 text-[#807F7E]">Select 1 or more journeys</p>
          <div className="flex flex-row justify-center items-start flex-wrap">
            {journeyArrayData.map((item, idx) => (
              <div
                className="rounded-full px-4 py-1 border border-black mr-5 mb-5 cursor-pointer h-[45px] w-[100px] flex flex-col items-center justify-center text-center"
                style={{ backgroundColor: item.isSelected ? '#A38DF2' : '#ffffff' }}
                onClick={() => onToolkitSelect(item, idx)}
                key={idx}
              >
                <p>{item?.toolkit}</p>
                {item?.date ? <p className="text-[#807F7E] text-xs text-center">{moment(item?.date, 'MM/DD/YYYY HH:mm:ss').format('MMM YY')}</p> : null}
              </div>
            ))}
          </div>
          {isJourneyItemSelectedZero ? <p className="text-[#bf41aa] text-[12px] text-center mb-3 font-bold">choose at least 1 journey to display</p> : null}
        </div>
        <hr />
        <div className="mt-8">
          <p className="mb-5 text-[#807F7E]">Select time horizon going back from today.</p>
          <div className="flex flex-row justify-center flex-wrap">
            {timeHorizons.map((item) => (
              <p
                className="rounded-full px-3 py-1 border border-black mr-5 mb-5 cursor-pointer"
                style={{ backgroundColor: timeHorizonSelected?.id === item.id ? '#A38DF2' : '#ffffff' }}
                onClick={() => onToolkitDateSelect(item)}
                key={item.id}
              >
                {item?.filter}
              </p>
            ))}
          </div>
        </div>
        <div className="flex justify-center items-center mt-8">
          <button className="bg-[#342F3B] px-4 py-2 rounded-md text-white w-[40%]" onClick={gotItClick}>
            got it
          </button>
        </div>
      </RightSlideOver>
    </div>
  );
};

export default HabitProgressEngagement;
