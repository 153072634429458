import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { ReactComponent as SkillHabitGrey } from '../../../../assets/skill-habit-grey.svg';
import { ReactComponent as SkillMentalGrey } from '../../../../assets/skill-mental-grey.svg';
import { ReactComponent as SkillSelfGrey } from '../../../../assets/skill-self-grey.svg';
import { convertMinutesToHoursMinutes, getMomentFromUnix, getWeekFromJourney, pluralize } from '../../../../utils/helpers';
import useJourneyData from '../../../../hooks/use-journey-data';

const HabitProgressSnapshot = ({ data, switchJourneys, userData, journeyData, journey }) => {
  const [journeyOptions, setJourneyOptions] = useState([]);
  const [week, setWeek] = useState(0);
  const { getTotalExerciseCount, getExerciseTypeCount } = useJourneyData();

  const getLastExercise = (arr) => {
    if (arr && arr.length) {
      const start = arr.length - 1;
      for (let i = start; i >= 0; i--) {
        if (arr[i].exerciseType) {
          return arr[i];
        }
      }
    }
    return null;
  };

  useEffect(() => {
    if (!journeyData) {
      return;
    }
    const newOptions = [];
    for (let i = 0; i < journeyData.length; i++) {
      const d = journeyData[i];
      newOptions.push({
        value: d._id,
        label: `${d.toolkitName} ${getMomentFromUnix(d.createdAt._seconds).format(`MMM 'YY`)}`,
      });
    }
    setJourneyOptions(newOptions);
  }, [journeyData]);

  useEffect(() => {
    if (!journey) {
      return;
    }
    const weekInfo = getWeekFromJourney(journey);
    setWeek(weekInfo.weeks);
  }, [journey]);


  const handleJourneyChange = (e) => {
    switchJourneys(e.target.value)
  };

  const totalExercises = getTotalExerciseCount(journeyData, journey?._id);
  // 'Habit-building', 'Self-expression', 'Mental flexibility'
  const habitBuilding = getExerciseTypeCount(journeyData, journey?._id, 'Habit-building');
  const self = getExerciseTypeCount(journeyData, journey?._id, 'Self-expression');
  const mental = getExerciseTypeCount(journeyData, journey?._id, 'Mental flexibility');

  const { totalTime, totalFrequency, habitSeries } = data || {};

  const hasHabitSeries = !!habitSeries && !!habitSeries.length;
  const lastExercise = getLastExercise(habitSeries, '--');
  const lastExerciseType = lastExercise ? lastExercise.exerciseType : '--';
  const lastExerciseTime = lastExercise ? moment(lastExercise.dateShort, 'M/D/YYYY').format('MM/DD/YY') : '--';
  const lastHabit = hasHabitSeries ? habitSeries[habitSeries.length - 1] : null;
  const lastSession = lastHabit && lastHabit.frequency ? lastHabit.frequency : 0;
  const lastDuration = lastHabit && lastHabit.time ? lastHabit.time : 0;


  return (
    <div className="rounded-md flex flex-col">
      <div className="flex flex-row p-4 items-end justify-between bg-[#342F3B]">
        <p className="text-white text-[20px]">Journey</p>
        <select value={journey._id} onChange={handleJourneyChange} className="w-[33%] bg-[#C5C4C2] rounded-md">
          {/* <option>Energize Feb' 22</option> */}
          {journeyOptions && journeyOptions.map((opt, i) => {
            return (
              <option key={opt.value} value={opt.value}>{opt.label}</option>
            );
          })}
        </select>
      </div>
      <div className="flex flex-row items-center justify-start p-4 border-b border-b-[#C5C4C2] h-[20%]">
        <p className="text-[18px] mr-8 lg:w-[20%]">Timeline:</p>
        <p className="text-[16px] text-left">{journey?.complete ? 'Complete' : `Week ${week}`}</p>
      </div>
      <div className="flex flex-row items-center lg:justify-start justify-between p-4 border-b border-b-[#C5C4C2] h-[20%]">
        <p className="text-[18px] mr-8 lg:w-[20%]">Progress:</p>
        <div className="text-[16px] flex flex-row lg:ml-0 ml-auto lg:w-[30%]">
          <p className="w-6 h-6 rounded-full bg-[#7162D9] text-white flex items-center justify-center mr-2">{totalExercises}</p> {pluralize('exercise', totalExercises)}
        </div>
        <p className="ml-6">Last exercise: {lastExerciseTime}</p>
      </div>
      <div className="flex flex-row items-center p-4 border-b border-b-[#C5C4C2] h-[20%]">
        <p className="text-[18px] mr-8 lg:w-[20%]">Skills:</p>
        <div className="flex flex-col lg:flex-row items-center lg:w-[30%]">
          <div className="relative mr-5 mb-2 lg:mb-0">
            {/* <img src="/skill-1.png" alt="Skill 1" className="w-12 lg:w-auto" /> */}
            <SkillHabitGrey className="w-[25px] h-[25px] lg:w-[40px] lg:h-[40px]" />
            <div className="bg-[#7162D9] p-2 w-5 h-5 rounded-full text-white flex items-center justify-center absolute -bottom-1 -right-3">
              <p>{habitBuilding}</p>
            </div>
          </div>
          <div className="relative mr-5 mb-2 lg:mb-0">
            {/* <img src="/skill-2.png" alt="Skill 2" className="w-12 lg:w-auto" /> */}
            <SkillSelfGrey className="w-[25px] h-[25px] lg:w-[40px] lg:h-[40px]" />
            <div className="bg-[#7162D9] p-2 w-5 h-5 rounded-full text-white flex items-center justify-center absolute -bottom-1 lg:right-0 -right-2">
              <p>{self}</p>
            </div>
          </div>
          <div className="relative mr-5 mb-2 lg:mb-0">
            {/* <img src="/skill-3.png" alt="Skill 3" className="w-12 lg:w-auto" /> */}
            <SkillMentalGrey className="w-[28px] h-[28px] lg:w-[50px] lg:h-[50px]" />
            <div className="bg-[#7162D9] p-2 w-5 h-5 rounded-full text-white flex items-center justify-center absolute -bottom-2 -right-2">
              <p>{mental}</p>
            </div>
          </div>
        </div>
        <p className="ml-6 text-right lg:text-left">Last exercise: {lastExerciseType}</p>
      </div>
      <div className="flex flex-row items-center p-4 border-b border-b-[#C5C4C2] h-[20%]">
        <p className="text-[18px] mr-8 lg:w-[20%]">Frequency:</p>
        <p className="text-[16px] mr-8 lg:w-[30%]">{totalFrequency} {pluralize('Session', totalTime)}</p>
        <p className="text-[16px]">Last session: {lastSession}x</p>
      </div>
      <div className="flex flex-row items-center p-4 border-b border-b-[#C5C4C2] h-[20%]">
        <p className="text-[18px] mr-8 lg:w-[20%]">Duration:</p>
        <p className="text-[16px] mr-8 lg:w-[30%]">{convertMinutesToHoursMinutes(totalTime, true)}</p>
        <p className="text-[16px]">Last session: {convertMinutesToHoursMinutes(lastDuration, true)}</p>
      </div>
    </div>
  );
};

export default HabitProgressSnapshot;
