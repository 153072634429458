import React, { useCallback, useState } from 'react';
import { useFormik } from "formik";
import { useDropzone } from 'react-dropzone';
import { ArrowUpIcon, PlusIcon } from "@heroicons/react/solid";
import Field from "../form/field";
import { useNotifications } from '../../contexts/notifications-provider';
import LoadingModal from '../overlays/loading-modal';

function UploadButton({ sendMessage }) {
  const [uploading, setUploading] = useState(false);
  const { addNotification } = useNotifications();

  const onDropAccepted = useCallback(async (acceptedFiles) => {
    const cloudName = process.env.REACT_APP_CLOUDINARY_NAME;
    // console.log(acceptedFiles, 'accepted files');
    setUploading(true);
    const formData = new FormData();
    acceptedFiles.forEach(file => {
      formData.append('file', file);
    });
    formData.append('upload_preset', 'messaging');
    formData.append('cloud_name', cloudName);
    fetch(`https://api.cloudinary.com/v1_1/${cloudName}/image/upload`, {
      method: 'post',
      body: formData,
    })
    .then(resp => resp.json())
    .then(data => {
      sendMessage({ image: data.url }, 'image');
      setUploading(false);
    })
    .catch(err => {
      console.log(err, 'error uploading');
      addNotification({
        type: 'error',
        body: "There was an error uploading the image.",
      });
    })

  }, [setUploading]);

  const onDropRejected = useCallback((e) => {
    console.log(e, 'failure');
    addNotification({
      type: 'error',
      body: e[0].errors[0].message,
    });
    // setError(e[0].errors[0].message);
  }, []);

  // Setup the props to pass to dropzone
  const dropzoneProps = {
    onDropAccepted,
    onDropRejected,
    noClick: true,
    maxFiles: 1,
    accept: ['image/png', 'image/gif', 'image/jpeg', 'image/svg+xml'],
    multiple: false,
  };
  const { getRootProps, getInputProps } = useDropzone(dropzoneProps);

  return (
    <>
      <div {...getRootProps()}>
        <label
          htmlFor="messagefileinput"
          className="inline-flex items-center cursor-pointer p-2 border border-secondary-500 rounded-full shadow-sm text-secondary-500 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary-500"
        >
          <PlusIcon className="h-6 w-6" aria-hidden="true" />
          <input id="messagefileinput" name="messagefileinput" type="file" className="sr-only" {...getInputProps()} />
        </label>
      </div>

      <LoadingModal loading={uploading} text="Uploading..." />
    </>
  );

}


export default function MessageForm({ sendMessage }) {

  const onSubmit = async (values, actions) => {
    // console.log(values, 'these are the values that we would submit');
    if (values.text !== '') {
      sendMessage(values);
      actions.resetForm();
    }
  };

  const formik = useFormik({
    initialValues: {
      text: '',
    },
    onSubmit,
  });

  const { submitForm } = formik;

  // Trigger submit when Enter is pressed (but not Shift + Enter)
  const handleKeyPress = (e) => {
    // console.log(e.key, e.shiftKey, 'key and shift key');
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      submitForm();
    }
  };


  const textField = {
    type: 'textarea',
    hideLabel: true,
    placeholder: 'compose message',
    name: 'text',
    onKeyPress: handleKeyPress,
  };

  const { handleSubmit, isSubmitting } = formik;

  return (
    <div className="px-4 py-2">
      <div className="grid grid-cols-12">
        <div className="col-span-1 flex justify-end">
          <div className="p-3">
            <UploadButton sendMessage={sendMessage} />
          </div>
        </div>
        <div className="col-span-4">
          <Field field={textField} formik={formik} />
        </div>
        <div className="col-span-1">
          <div className="p-3">
            <button
              type="button"
              onClick={isSubmitting ? () => {} : handleSubmit}
              className="inline-flex items-center p-2 border border-transparent rounded-full shadow-sm text-white bg-primary-500 hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
            >
              <ArrowUpIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
