import React from 'react';
import { ExclamationCircleIcon } from '@heroicons/react/solid';

function BrowserTime({ formik, name, id, placeholder, hasError, timezone }) {
  const classes = hasError ? 'pr-10 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500' : 'shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300';
  const wrapperClasses = hasError ? 'relative rounded-md shadow-sm' : '';
  const ariaProps = hasError ? { 'aria-invalid': 'true', 'aria-describedby' : `${id}-error` } : { 'aria-describedby' : `${id}-description` };
  return (
    <div className="flex flex-row items-center">
      <div className={wrapperClasses}>
        <input
          {...formik.getFieldProps(name)}
          {...ariaProps}
          className={`block w-full rounded-md text-[18px] py-3 sm:text-sm ${classes}`}
          id={id}
          name={name}
          type="time"
          placeholder={placeholder}
        />
        {hasError && (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
          </div>
        )}
      </div>
      {!!timezone && (
        <div className="border border-[#C5C4C2] py-2 px-4 rounded-md text-[18px] text-center ml-2 text-[#A38DF2]">
          <p>{timezone}</p>
        </div>
      )}
    </div>
  );
}

export default BrowserTime;
