import { useState, createContext, useContext, useEffect } from 'react';
import { collection, query, where, orderBy, limit, serverTimestamp, setDoc, doc, onSnapshot } from 'firebase/firestore';
import { firestore } from '../config/firebase';
import { useAuth } from './auth-provider';

const ServerNotificationsContext = createContext({
  notifications: [],
  addNotification: null,
  shiftNotification: null,
});


export function ProvideServerNotifications({ children }) {
  const [loading, setLoading] = useState([]);
  const [serverNotifications, setServerNotifications] = useState([]);
  const [unreadNotifications, setUnreadNotifications] = useState([]);
  const [notDismissed, setNotDismissed] = useState([]);
  const { user, hasRole } = useAuth();
  const notificationsRef = collection(firestore, 'notifications');

  const uid = user ? user.uid : null;

  useEffect(() => {
    if (uid) {
      // We use the users uid for coaches, however for ADMINS, we use the flag ADMIN
      const uidToUse = hasRole(['admin']) ? 'ADMIN' : uid;
      const q = query(notificationsRef, where('uid', '==', uidToUse), orderBy('created', 'desc'), limit(100));
      const unsub = onSnapshot(q, (snapshot => {
        const newNotifications = [];
        const newUnread = [];
        const newNotDismissed = [];
        snapshot.forEach(doc => {
          const data = {
            ...doc.data(),
            id: doc.id,
          };
          newNotifications.push(data);
          if (!data.read) {
            newUnread.push(data);
          }
          if (!data.dismissed) {
            newNotDismissed.push(data);
          }
        });
        setServerNotifications(newNotifications);
        setUnreadNotifications(newUnread);
        setNotDismissed(newNotDismissed);
        setLoading(false);
      }));
      return () => unsub();
    }
  }, [uid]);

  const markAsRead = async (id) => {
    const docRef = doc(firestore, 'notifications', id);
    await setDoc(docRef, {
      read: true,
      readAt: serverTimestamp(),
      updatedAt: serverTimestamp(),
    }, { merge: true });
  };


  const dismiss = async (id) => {
    const docRef = doc(firestore, 'notifications', id);
    await setDoc(docRef, {
      read: true,
      readAt: serverTimestamp(),
      dismissed: true,
      updatedAt: serverTimestamp(),
    }, { merge: true });
  };

  const dismissAll = async () => {
    if (!notDismissed || !notDismissed.length) {
      return;
    }
    const ids = notDismissed.map(notification => notification.id);
    for (let i = 0; i < ids.length; i++) {
      const id = ids[i];
      await dismiss(id);
    }
  };
  
  const numUnread = !!unreadNotifications ? unreadNotifications.length : 0;

  const state = {
    unreadNotifications,
    notDismissed,
    numUnread,
    serverNotifications,
    notificationsLoading: loading,
    markAsRead,
    dismiss,
    dismissAll,
  };

  return <ServerNotificationsContext.Provider value={state}>{children}</ServerNotificationsContext.Provider>;
}

export const useServerNotifications = () => {
  return useContext(ServerNotificationsContext);
};
