import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { ChevronRightIcon } from '@heroicons/react/outline';
import { FiSliders } from 'react-icons/fi';
import { findOccurance, formatUnixDate, getAllDataWithFilter, getReflectionWithFilter, getToolkitDataWithFilter, getToolkitListForFilter } from '../../../../utils/helpers';
import LineChart from '../../../charts/line-chart';
import RightSlideOver from '../../../overlays/right-slide-over';

const filterDateData = [
  { isSelected: false, filter: '2 Weeks', duration: '2 Weeks', id: '01' },
  { isSelected: false, filter: '1 Month', duration: '1 Month', id: '02' },
  { isSelected: true, filter: '3 Months', duration: '3 Months', id: '03' },
  { isSelected: false, filter: '6 Months', duration: '6 Months', id: '04' },
  { isSelected: false, filter: 'All', duration: '10 Years', id: '05' },
];

const MoodOverTimePorted = ({ setReflectionData, setSingleReflectionData, setIsReflectionDrawerOpen, isAnalyzeDrawer, journeyData }) => {
  // const [journeyArrayData, setJourneyArrayData] = useState(getToolkitListForFilter());




  // New code to handle data
  const [allData, setAllData] = useState(null);
  const [init, setInit] = useState(false);
  const [combinedData, setCombinedData] = useState(null);






  const [gotItClicked, setIsGotItClicked] = useState(false);
  // const [reflectionClicked, setReflectionClicked] = useState();

  const [isOpen, setIsOpen] = useState(false);
  const [journeyArrayData, setJourneyArrayData] = useState([]);
  const [timeHorizons, setTimeHorizons] = useState(filterDateData);
  const [confirmedJourneyArrayData, setConfirmedJourneyArrayData] = useState([]);
  const [confirmedTimeHorizons, setConfirmedTimeHorizons] = useState(filterDateData);
  const [chartData, setChartData] = useState([]);
  const [isAll, setisAll] = useState(false);
  const [zoomDomain, setZoomDomain] = useState();
  const [isEmpty, setisEmpty] = useState(false);
  const [isJourneyItemSelectedZero, setIsJourneyItemSelectedZero] = useState(false);
  const [timeHorizonSelected, setTimeHorizonSelected] = useState(filterDateData[2]);
  const [selectedTookitList, setSelectedTookitList] = useState([]);


  const clickReflection = (d) => {
    setIsReflectionDrawerOpen(true);
    setSingleReflectionData(d);
  }


  useEffect(() => {
    if (!journeyData || !journeyData.length) {
      return;
    }


    // Assemble all of our mood data
    const newAllData = [];
    // Loop over journeys with data
    for (let i = 0; i < journeyData.length; i++) {
      const d = journeyData[i];
      // Mood
      if (d && d.moodSeries && d.moodSeries.length) {
        newAllData.push({
          _id: d._id,
          toolkitName: d.toolkitName,
          toolkitId: d.toolkitId,
          startTimestamp: d.createdAt._seconds,
          endTimestamp: d.toolkitComplete ? d.updatedAt._seconds : null,
          data: d.moodSeries,
        });
      }
    }

    setAllData(newAllData);
    // console.log(journeyData, 'this is journey data');
  }, [journeyData]);

  useEffect(() => {
    if (!allData || !allData.length || !journeyData || !journeyData.length) {
      setCombinedData(null);
      return;
    }
    const newCombinedData = [];
    for (let i = 0; i < allData.length; i++) {
      const d = allData[i];
      for (let j = 0; j < d.data.length; j++) {
        // Transform our data to match the format that the functions expect...
        const toTransform = d.data[j];
        const { journeyToolkitName: toolkit, journeyId, id, hasReflection: isReflection, score: y, isoTime, toolkitName: otherToolkit, reflections, dateShort, exerciseType, exerciseTitle } = toTransform;
        const hasDifferentToolkit = !!otherToolkit && (toolkit !== otherToolkit);
        const x = moment(isoTime).format('MM/DD/YYYY HH:mm:ss');
        newCombinedData.push({
          ...toTransform,
          x,
          y,
          isReflection,
          hasDifferentToolkit,
          otherToolkit,
          index: id,
          journeyId,
          toolkit,
          reflections,
          dateShort,
          exerciseType,
          exerciseTitle,
        });
      }
    }
    setCombinedData(newCombinedData);

    // Build our array of filters from our journeys
    const newJourneyFilters = [];

    // Loop over journeys with data
    for (let i = 0; i < journeyData.length; i++) {
      const d = journeyData[i];
      // Filters
      newJourneyFilters.push({
        index: d._id,
        journeyId: d._id,
        toolkit: d.toolkitName,
        x: formatUnixDate(d.createdAt._seconds, 'MM/DD/YYYY HH:mm:ss'),
        sortBy: d.createdAt._seconds,
        subtext: formatUnixDate(d.createdAt._seconds, 'M/YY'),
        isSelected: i === 0, // select the first one by default
      });
    }

    newJourneyFilters.push({
      index: 'All',
      toolkit: 'All',
      isSelected: false,
    });
    // This is just to match the existing code
    setConfirmedJourneyArrayData(newJourneyFilters);
    setJourneyArrayData(newJourneyFilters);

    setInit(true);
  }, [allData, journeyData]);

  // console.log(combinedData, 'combined');





  // useEffect(() => {
  //   onGotItClick();
  //   return () => {
  //     onToolkitDateSelect(filterDateData[2]);
  //     onToolkitSelect(getToolkitListForFilter()[0], 0);
  //   };
  // }, []);

  useEffect(() => {
    if (!init) {
      return;
    }
    onGotItClick();
  }, [init]);


  useEffect(() => {
    setSelectedTookitList(
      confirmedJourneyArrayData.reduce((res, item) => {
        if (item.isSelected) {
          // res.push(item.toolkit);
          res.push(item.journeyId || item.toolkit);
        }

        return res;
      }, [])
    );
  }, [JSON.stringify(confirmedJourneyArrayData)]);

  const onToolkitSelect = (selectedItem, index) => {
    let updateData = [...journeyArrayData];

    if (index === journeyArrayData.length - 1) {
      updateData.forEach((item) => (item.isSelected = false));
      updateData[index].isSelected = !updateData[index].isSelected;
    } else {
      updateData[updateData.length - 1].isSelected = false;
      updateData[index].isSelected = !selectedItem.isSelected;
    }

    setJourneyArrayData(updateData);
    setIsJourneyItemSelectedZero(updateData.filter((item) => item.isSelected).length === 0);
  };


  const onToolkitDateSelect = (selectedItem) => {
    setTimeHorizonSelected(selectedItem);
  };

  const onFilterClick = () => {
    setIsOpen(true);
  };


  // From original...
  useEffect(() => {
    setReflectionData(getReflectionWithFilter(selectedTookitList, timeHorizonSelected?.duration, combinedData));
    setSingleReflectionData(null);
  }, [selectedTookitList, timeHorizonSelected, combinedData]);

  useEffect(() => {
    setSelectedTookitList(
      journeyArrayData.reduce((res, item) => {
        if (item.isSelected) {
          // res.push(item.toolkit);
          // console.log(item.toolkit, 'toolkit');
          res.push(item.journeyId || item.toolkit);
        }

        return res;
      }, [])
    );
  }, [JSON.stringify(journeyArrayData)]);


  const onGotItClick = () => {
    if (!isJourneyItemSelectedZero && timeHorizonSelected) {
      const selectedTookit = journeyArrayData.find((item) => item.isSelected);
      const selectedTookits = journeyArrayData.filter((item) => item.isSelected);
      const selectedTimeHorizon = timeHorizonSelected;
      if (selectedTookit.toolkit === 'All') {
        setisAll(true);

        const data = getAllDataWithFilter(selectedTimeHorizon?.duration, combinedData);

        let moments = data.map((item) => moment(item.x));
        let maxDate = moment.max(moments);
        let minDate = moment.min(moments);
        // console.log(maxDate, 'max date');
        setZoomDomain({ x: [minDate, maxDate] });

        setChartData(data);

        if (data.length < 2) {
          setisEmpty(true);
        }
        else {
          setisEmpty(false);
        }

      } else {
        setisAll(false);

        // const arrayToUse = selectedTookit?.toolkit === 'Latest' ? journeyArrayData : selectedTookits;
        const arrayToUse = selectedTookits;

        const data = [];
        arrayToUse.map((item, index) => {
          data.push(getToolkitDataWithFilter(item.journeyId, selectedTimeHorizon.duration, combinedData));
        });

        const moments = data.flat().map((item) => moment(item.x));
        const maxDate = moment.max(moments);
        const minDate = moment.min(moments);

        setZoomDomain({ x: [minDate, maxDate] });

        setChartData(data);

        if (findOccurance(data.flat(), 'toolkit').filter((i) => i.occurrence > 1).length > 0) {
          setisEmpty(false);
        }
        else {
          setisEmpty(true);
        }
      }
      setConfirmedJourneyArrayData(journeyArrayData);
      setConfirmedTimeHorizons(timeHorizons);
      setJourneyArrayData(journeyArrayData);
      setTimeHorizons(timeHorizons);
      setIsOpen(false);
      setIsGotItClicked(true);
    }
  };



  // const onToolkitDateSelect = (selectedItem) => {
  //   setTimeHorizonSelected(selectedItem);
  // };

  // const onToolkitSelect = (selectedItem, index) => {
  //   let updateData = [...journeyArrayData];

  //   if (index === journeyArrayData.length - 1 || index === 0) {
  //     updateData.forEach((item) => (item.isSelected = false));
  //     updateData[index].isSelected = !updateData[index].isSelected;
  //   } else {
  //     updateData[0].isSelected = false;
  //     updateData[updateData.length - 1].isSelected = false;
  //     updateData[index].isSelected = !selectedItem.isSelected;
  //   }

  //   setJourneyArrayData(updateData);
  //   setIsJourneyItemSelectedZero(updateData.filter((item) => item.isSelected).length === 0);
  // };

  // const onGotItClick = () => {
  //   if (!isJourneyItemSelectedZero && timeHorizonSelected) {
  //     var selectedTookit = journeyArrayData.find((item) => item.isSelected);
  //     var selectedTookits = journeyArrayData.filter((item) => item.isSelected);
  //     var selectedTimeHorizon = timeHorizonSelected;
  //     if (selectedTookit.toolkit === 'All') {
  //       setisAll(true);
  //       const data = getAllDataWithFilter(selectedTimeHorizon?.duration);
  //       let moments = data.map((item) => moment(item.x));
  //       let maxDate = moment.max(moments);
  //       let minDate = moment.min(moments);
  //       setZoomDomain({ x: [minDate, maxDate] });
  //       setChartData(data);
  //       if (data.length < 2) setisEmpty(true);
  //       else setisEmpty(false);
  //     } else if (selectedTookit.toolkit === 'Latest') {
  //       setisAll(false);
  //       var data = [];
  //       journeyArrayData.forEach((item, index) => {
  //         data.push(getToolkitDataWithFilter(item.toolkit, selectedTimeHorizon.duration));
  //       });

  //       let moments = data.flat().map((item) => moment(item.x));
  //       let maxDate = moment.max(moments);
  //       let minDate = moment.min(moments);
  //       setZoomDomain({ x: [minDate, maxDate] });
  //       setChartData(data);
  //       if (findOccurance(data.flat(), 'toolkit').filter((i) => i.occurrence > 1).length > 0) setisEmpty(false);
  //       else setisEmpty(true);
  //     } else {
  //       setisAll(false);
  //       var data = [];
  //       selectedTookits.forEach((item, index) => {
  //         data.push(getToolkitDataWithFilter(item.toolkit, selectedTimeHorizon.duration));
  //       });
  //       let moments = data.flat().map((item) => moment(item.x));
  //       let maxDate = moment.max(moments);
  //       let minDate = moment.min(moments);
  //       setZoomDomain({ x: [minDate, maxDate] });
  //       setChartData(data);
  //       if (findOccurance(data.flat(), 'toolkit').filter((i) => i.occurrence > 1).length > 0) setisEmpty(false);
  //       else setisEmpty(true);
  //     }
  //     setJourneyArrayData(journeyArrayData);
  //     setTimeHorizons(timeHorizons);
  //     setIsOpen(false);
  //     setIsGotItClicked(true);
  //   }
  // };

  if (!journeyArrayData) {
    return null;
  }

  return (
    <div>
      <div className="flex flex-row items-center justify-end">
        {gotItClicked &&
          [...new Set([...journeyArrayData.filter((item) => item.isSelected), timeHorizonSelected])].map((item, i) =>
            item.x ? (
              <p key={item.x} className="border border-black rounded-full px-3 py-1 text-sm mx-2">
                {item.toolkit ? item.toolkit + ' ' + moment(item?.x, 'MM/DD/YYYY HH:mm:ss').format('MMM YY') : item.filter}
              </p>
            ) : (
              <p key={i} className="border border-black rounded-full px-3 py-1 text-sm mx-2">{item.toolkit ? item.toolkit : item.filter}</p>
            )
          )}
        {!isAnalyzeDrawer && (
          <div
            className="w-8 h-8 rounded-full flex items-center justify-center bg-black cursor-pointer ml-5"
            onClick={() => {
              setIsOpen(true);
              setIsGotItClicked(false);
            }}
          >
            <FiSliders className="h-4 w-4 text-white" />
          </div>
        )}
      </div>
      <LineChart
        isEmpty={isEmpty}
        zoomDomain={zoomDomain}
        isAllChart={isAll}
        chartData={chartData}
        selectedTimeHorizon={timeHorizonSelected}
        selectedTookitList={selectedTookitList}
        onGotItClicked={gotItClicked}
        setReflectionClicked={clickReflection}
        journeyArrayData={journeyArrayData.filter((item) => item.isSelected)}
        combinedData={combinedData}
      />
      {/* {reflectionClicked && (
        <div className="bg-white rounded-md w-[90%] px-3 py-1 mx-auto mt-7 text-justify">
          <div className="flex flex-row justify-between items-center">
            <p className="text-[#7162D9] font-bold text-left">Score: {reflectionClicked ? reflectionClicked.y : 4.8}</p>
            <ChevronRightIcon
              className="h-4 w-4 text-[#807F7E] cursor-pointer"
              onClick={() => {
                setSingleReflectionData(reflectionClicked);
                setIsReflectionDrawerOpen(true);
              }}
            />
          </div>
          <p className="text-sm">
            {moment(reflectionClicked.x).format('MM/DD/YY')}: Loved the sculpting clay way more than I expected. Maybe that's my cognitive flexibility! Also found myself dancing a
            lot this week, which I haven't done in 5+ years. It was an amazing experience to tap back into a old but familiar habit
          </p>
        </div>
      )} */}
      <RightSlideOver
        isOpen={isOpen}
        darkBg
        close={() => {
          setIsGotItClicked(true);
          setIsOpen(false);
        }}
        title="Filters"
        isNew
      >
        <p className="font-[500] mb-10 text-[20px]">Mood over time</p>
        <div>
          <p className="mb-5 text-[#807F7E]">Select 1 or more journeys</p>
          <div className="flex flex-row justify-center items-center flex-wrap">
            {journeyArrayData.map((item, idx) => (
              <div
                key={idx}
                className="rounded-full px-4 py-1 border border-black mr-5 mb-5 cursor-pointer h-[45px] w-[100px] flex flex-col items-center justify-center text-center"
                style={{ backgroundColor: item.isSelected ? '#A38DF2' : '#ffffff' }}
                onClick={() => onToolkitSelect(item, idx)}
              >
                <p className="text-[16px] text-center">{item?.toolkit}</p>
                {item?.x ? <p className="text-[#807F7E] text-[10px] text-center mx-auto">{moment(item?.x, 'MM/DD/YYYY HH:mm:ss').format('MMM YY')}</p> : null}
              </div>
            ))}
          </div>
          {isJourneyItemSelectedZero ? <p className="text-[#bf41aa] text-[12px] text-center mb-3 font-bold">choose at least 1 journey to display</p> : null}
        </div>
        <hr />
        <div className="mt-8">
          <p className="mb-5 text-[#807F7E]">Select time horizon going back from today.</p>
          <div className="flex flex-row justify-center flex-wrap">
            {timeHorizons.map((item) => (
              <p
                className="rounded-full px-3 py-1 border border-black mr-5 mb-5 cursor-pointer"
                style={{ backgroundColor: timeHorizonSelected?.id === item.id ? '#A38DF2' : '#ffffff' }}
                onClick={() => onToolkitDateSelect(item)}
                key={item.id}
              >
                {item?.filter}
              </p>
            ))}
          </div>
        </div>
        <div className="flex justify-center items-center mt-8">
          <button className="bg-[#342F3B] px-4 py-2 rounded-md text-white w-[40%]" onClick={onGotItClick}>
            got it
          </button>
        </div>
      </RightSlideOver>
    </div>
  );
};

export default MoodOverTimePorted;
