import React, { useEffect, useState } from 'react';
import Bugsnag from '@bugsnag/browser';
import { useParams, useNavigate } from 'react-router-dom';
import { useBreadcrumbs } from '../../contexts/breadcrumbs-provider';
import Heading from '../../components/layout/admin/heading';
import { useNotifications } from '../../contexts/notifications-provider';
import AutoForm from '../../components/form/auto-form';
import Frame from '../../components/layout/frame';
import { getApiRequest } from '../../utils/request';
import Loadable from '../../components/data/loadable';
import Panel from '../../components/common/panel';
import DataRow from '../../components/data/data-list-row';

const AdminAssignNewGuide = () => {
  const [loading, setLoading] = useState(true);
  const [profile, setProfile] = useState(null);
  const navigate = useNavigate();
  const { addNotification } = useNotifications();
  const { setBreadcrumbs } = useBreadcrumbs();
  const { id } = useParams();

  useEffect(() => {
    setBreadcrumbs([
      {
        href: `/admin/guide-assign/${id}`,
        label: `Assign New Guide`,
      },
    ]);

    const init = async () => {
      try {
        const result = await getApiRequest(`/profile/${id}`);
        setProfile(result.profile);
      } catch (err) {
        Bugsnag.notify(err);
        addNotification({
          type: 'error',
          body: 'There was an error loading the requested profile.',
        });
        navigate('/admin/profiles');
      }
      setLoading(false);
    };
    init();
  }, [id]);


  const coachAssignForm = {
    type: 'post',
    postRoute: `/guide-assign/${id}/new-guide`,
    successRedirect: '/admin/profiles',
    successNotification: 'The new guide was assigned!',
    fields: [
      {
        name: 'coachUid',
        label: 'Choose A New Guide',
        type: 'select',
        required: true,
        optionsUrl: '/options/coaches',
        helpText: 'This is the new guide that will be assigned to this member.',
      },
    ],
    submitLabel: `Assign New Guide`,
  };

  const { email, name } = profile || {};
  const formattedData = { name, email };

  const rows = [
    {
      field: 'name',
      label: 'Member Name',
    },
    {
      field: 'email',
      label: 'Member Email',
    },
  ];

  return (
    <Frame>
      <Heading title={`Assign New Guide`} />
      <Loadable loading={loading}>
        <div className="max-w-2xl mx-auto py-12">
          <div className="p-8">
            <Panel>
              <div className="px-4 py-5 sm:p-0">
                <dl>
                  {rows.map((item, i) => {
                    const value = formattedData[item.field];
                    return <DataRow key={item.field} label={item.label} value={value} first={i === 0} />
                  })}
                </dl>
              </div>
            </Panel>
          </div>
          <AutoForm {...coachAssignForm} />
        </div>
      </Loadable>
    </Frame>
  );
}

export default AdminAssignNewGuide;
