import Badge from '../badges/badge';
import SearchSelect from './search-select';

export default function SaveBar({ submit, changed, published, updatePublished, hideStatus = false, label = 'Save Journey Map' }) {
  const disabled = !changed;
  const noContainer = false;
  const centerButton = false;

  const btnClasses = disabled ? 'bg-blue-400 focus:ring-blue-400 hover:bg-blue-500 cursor-not-allowed' : 'bg-blue-600 focus:ring-blue-600 hover:bg-blue-700';

  const publishedOptions = [
    {
      value: 'unpublished',
      label: 'Unpublished',
    },
    {
      value: 'published',
      label: 'Published',
    },
  ];

  const handleChange = (e) => {
    const newVal = e.target.value;
    if (newVal !== published) {
      updatePublished(newVal);
    }
  };
  return (
    <div className={`py-3 ${noContainer ? '' : 'px-4 bg-gray-100 sm:px-6 shadow-lg rounded-lg'} flex flex-row w-full justify-between`}>
      <div className={`${centerButton ? 'text-center' : 'text-left'} space-x-2 flex flex-1 justify-between`}>
        {!hideStatus && (
          <div className="max-w-sm">
            <SearchSelect options={publishedOptions} value={published} onChange={handleChange} name="published" />
          </div>
        )}
        {!!hideStatus && <div />}
        <div className="w-md flex space-x-3">
          {!!changed && (
            <div className="text-right pt-2">
              <Badge color="yellow" large text="There are unsaved changes" />
            </div>
          )}
          <button
            type="submit"
            onClick={submit}
            className={`${btnClasses} border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2`}
          >
            {label}
          </button>
        </div>
      </div>
    </div>
  );
}
