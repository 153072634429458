import React from 'react';
import { Switch as HeadlessSwitch } from '@headlessui/react';
import { classNames } from '../../../utils/helpers';

function Switch({ formik, name, sideBySide = false }) {

  const value = formik && formik.values ? formik.values[name] : false;

  const onClick = () => {
    formik.setFieldValue(name, !value);
  }

  const enabled = !!value;

  return (
    <div className="flex flex-row items-center">
      {!!sideBySide && <p className="mr-2">No</p>}
      <HeadlessSwitch
        checked={value}
        onChange={onClick}
        className={classNames(
          enabled ? 'bg-[#a38df2]' : 'bg-[#e1d9ff]',
          'relative inline-flex flex-shrink-0 h-[38px] w-[74px] border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-[#A38DF2] focus:border-[#A38DF2] focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75'
        )}
      >
        <span className="sr-only">Toggle</span>
        <span
          aria-hidden="true"
          className={classNames(
            enabled ? 'translate-x-9' : 'translate-x-0',
            'pointer-events-none inline-block h-[34px] w-[34px] rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
          )}
        />
      </HeadlessSwitch>
      {!!sideBySide && <p className="ml-2">Yes</p>}

    </div>
  );
}

export default Switch;
