import React from 'react';

function HelpText({ id, helpText, hasError, errorText, sideBySide = false }) {
  if (!helpText && !errorText) {
    return null;
  }
  if (hasError && errorText) {
    const className = sideBySide ? 'text-[16px] text-red-600' : 'mt-2 text-sm text-red-600';
    return (
      <p id={`${id}-description`} className={className}>
        {errorText}
      </p>
    );
  }

  const className = sideBySide ? 'text-[16px] text-[#807f7e]' : 'mt-2 text-sm text-gray-500';
  return (
    <p id={`${id}-description`} className={className}>
      {helpText}
    </p>
  );
}

export default HelpText;
