import { PencilIcon } from '@heroicons/react/solid';

export default function EditButton({ onClick }) {
  return (
    <button
      type="button"
      title="Edit Homework"
      onClick={onClick}
      className="inline-flex items-center p-1 ml-2 border border-transparent rounded-full shadow-sm text-secondary-500 bg-secondary-100 hover:bg-secondary-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary-100"
    >
      <PencilIcon className="h-5 w-5" aria-hidden="true" />
    </button>
  );
}
