import React from 'react';

const ReflectionsCard = ({ text, date, score }) => {
  return (
    <div className="border border-[#C5C4C2] rounded-md px-4 py-2 mb-5 text-[16px]">
      <p>{text}</p>
      <div className="flex flex-row justify-between items-center mt-5">
        <p className="text-[#807F7E]">{date}</p>
        <p className="text-[#A38DF2]">Score: {score}</p>
      </div>
    </div>
  );
};

export default ReflectionsCard;
