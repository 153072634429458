import React, { useEffect, useState } from 'react';
import { VictoryArea, VictoryAxis, VictoryChart, VictoryLabel, VictoryScatter, VictoryZoomContainer } from 'victory';
import { getReflectionWithFilter, getTookitList, getToolkitColor } from '../../utils/helpers';

export default function LineChart({ selectedTookitList, selectedTimeHorizon, isAllChart, chartData, isEmpty, zoomDomain, journeyArrayData, onGotItClicked, setReflectionClicked, combinedData }) {
  const selectedCurrentTimeHorizon = selectedTimeHorizon;
  const selectedFilter = selectedTookitList[0];
  //   const windowWidth = Dimensions.get('window').width;
  const fontFamily = 'Helvetica Neue';
  const fontWeight = 500;
  const fontSize = 12;

  const [windowWidth, setWindowWidth] = useState();

  useEffect(() => {
    setWindowWidth(window.innerWidth);
  }, [windowWidth]);

  const chartTheme = {
    axis: {
      style: {
        tickLabels: {
          fill: 'black',
        },
        axis: {
          stroke: 'black',
        },
        grid: {
          stroke: '#C5C4C2',
          strokeDasharray: '7',
        },
        dependentAxis: {
          stroke: 'black',
        },
      },
    },
  };

  const formatTick = (tick) => {
    if (selectedCurrentTimeHorizon.filter === '6 Months' || selectedCurrentTimeHorizon.filter === 'All') {
      return (new Date(tick).getMonth() + 1) + '/' + new Date(tick).getDate() + '/' + new Date(tick).getFullYear().toString().slice(-2);
    } else {
      return new Date(tick).getMonth() + 1 + '/' + new Date(tick).getDate();
    }
  };

  return (
    <div className="flex flex-col items-center justify-center ml-8 relative">
      {!isEmpty && (
        <VictoryChart
          width={windowWidth < 600 ? 350 : windowWidth < 1200 ? 400 : 600}
          theme={chartTheme}
          scale={{ x: 'time', y: 'linear' }}
          containerComponent={<VictoryZoomContainer responsive={false} zoomDimension="x" zoomDomain={zoomDomain}></VictoryZoomContainer>}
        >
          <VictoryAxis
            invertAxis={isEmpty ? true : false}
            axisLabelComponent={<VictoryLabel dy={4} />}
            style={{ tickLabels: { padding: 4, fontFamily: fontFamily, fontWeight: fontWeight, fontSize: fontSize } }}
            tickFormat={(x) => formatTick(x)}
            fixLabelOverlap
          />
          <VictoryAxis
            domain={{ x: [0.5, 5.5], y: [0, 10] }}
            dependentAxis
            label="score"
            axisLabelComponent={<VictoryLabel dy={8} />}
            style={{ tickLabels: { padding: 10, fontFamily: fontFamily, fontWeight: fontWeight }, axisLabel: { fill: 'black', fontFamily: fontFamily, fontWeight: '400' } }}
          />
          {/* <ChillSVGGradient /> */}
          {selectedFilter === 'Latest' ? (
            chartData?.map((item, index) => {
              return (
                <VictoryArea
                  key={index}
                  interpolation="basis"
                  data={item}
                  style={{ data: { fill: getToolkitColor(item[0]?.toolkit), stroke: 'white', strokeWidth: 1, opacity: 0.7 } }}
                />
              );
            })
          ) : isAllChart ? (
            <VictoryArea interpolation="basis" data={chartData} style={{ data: { fill: getToolkitColor('All'), stroke: 'white', strokeWidth: 1, opacity: 0.7 } }} />
          ) : (
            chartData.map((item, index) => {
              return (
                <VictoryArea
                  key={index}
                  interpolation="basis"
                  data={item}
                  style={{ data: { fill: item.length > 0 ? getToolkitColor(item[0]?.toolkit) : null, stroke: 'white', strokeWidth: 1, opacity: 0.7 } }}
                  // style={{data: {fill: 'l'}}}
                />
              );
            })
          )}

          <VictoryScatter
            style={{
              data: {
                fill: ({ datum }) => getToolkitColor(datum.toolkit),
                stroke: ({ datum }) => 'white',
                strokeWidth: 1,
              },
              labels: {
                fontSize: 15,
                fill: ({ datum }) => '#c43a31',
              },
            }}
            size={isEmpty ? 0 : 5}
            data={getReflectionWithFilter(selectedTookitList, selectedCurrentTimeHorizon?.duration, combinedData)}
            events={[
              {
                target: 'data',
                eventHandlers: {
                  onClick: () => {
                    return [
                      {
                        target: 'data',
                        mutation: (props) => {
                          setReflectionClicked(props.datum);
                          // alert('Clicked index ' + props.datum.index, 'Data : ' + JSON.stringify(props.datum));
                        },
                      },
                    ];
                  },
                },
              },
            ]}
          />
        </VictoryChart>
      )}
      {!isEmpty && (
        <div className="flex flex-row justify-center">
          {selectedFilter === 'Latest'
            ? getTookitList().map((item, index) => {
                return (
                  <div key={index} className="flex flex-row justify-center items-center">
                    <div className="mr-2 w-2 h-2 rounded-full" style={{ backgroundColor: getToolkitColor(item) }} />
                    <p className={`text-black text-sm mr-4 font-bold`} color="white" fontFamily="heading" fontWeight={fontWeight} fontSize="sm">
                      {item}
                    </p>
                  </div>
                );
              })
            : onGotItClicked &&
              journeyArrayData.map((item, index) => {
                return (
                  <div key={index} className="flex flex-row justify-center items-center">
                    <div className="mr-2 w-2 h-2 rounded-full" style={{ backgroundColor: getToolkitColor(item.toolkit) }} />
                    <p className={`text-black text-sm mr-4 font-bold`} color="white" fontFamily="heading" fontWeight={fontWeight} fontSize="sm">
                      {item.toolkit}
                    </p>
                  </div>
                );
              })}
        </div>
      )}
      {isEmpty ? (
        <div className="bg-[#3D3380] rounded-md mt-[25%] p-2 absolute top-1/2 w-[50%]">
          <p className="text-sm text-center font-bold text-white">Come back after completing a creative exercise and associated survey!</p>
        </div>
      ) : null}
    </div>
  );
}
