import { useState, useEffect } from 'react';
import Bugsnag from '@bugsnag/browser';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { getApiRequest } from '../../../utils/request';
import { weekOptions, formatUtcUnixDate, getCurrentWeekForJourney, findIndexOfItemByValue } from '../../../utils/helpers';
import Loadable from '../../../components/data/loadable';
import Frame from '../../../components/layout/frame';
import MemberHeader from '../../../components/layout/coach/member-header';
import MemberSection from '../../../components/layout/coach/member-section';
import MemberSetForm from '../../../components/layout/coach/member-set-form';
import Nl2br from '../../../components/common/nl2br';
import EditButton from '../../../components/layout/coach/edit-button';

async function getExerciseOptionsFromJourney(journey) {
  const ret = [{
    value: '',
    label: 'Select...',
  }];
  if (!journey) {
    return ret;
  }

  try {
    const optionsResult = await getApiRequest(`/coach/journey/${journey._id}/exercise-options`);
    const { options } = optionsResult;
    options.forEach(opt => {
      ret.push(opt);
    });
  } catch (err) {
    console.log(err, 'error getting exercise options');
  }
  
  return ret;
}


function PracticeSection({ recommendations, reload, journeyId, currentWeek, exerciseOptions }) {
  const [createForm, setCreateForm] = useState(null);
  const [updateForm, setUpdateForm] = useState(null);
  const [practices, setPractices] = useState(null);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [editing, setEditing] = useState(null);

  const practiceSetForm = {
    noContainer: true,
    bg: 'bg-cream',
    type: 'post',
    postRoute: `/coach/journey/${journeyId}/member-recommendation`,
    successNotification: 'The personalized practice was saved!',
    beforePost: (values) => {
      return {
        ...values,
        type: 'recommendation',
      };
    },
    fields: [
      {
        name: 'title',
        label: 'Title',
        type: 'text',
        placeholder: 'Enter a title',
        required: true,
      },
      {
        name: 'description',
        label: 'Instructions / Message',
        type: 'textarea',
        placeholder: 'Compose your instructions',
        required: true,
      },
      // {
      //   name: 'week',
      //   label: 'Week',
      //   type: 'select',
      //   options: weekOptions(),
      //   // helpText: 'Choose a goal for this member for this quarter.',
      //   required: true,
      //   initialValue: (entity) => {
      //     return entity ? `${entity.week}` : `${currentWeek}`;
      //   },
      // },
      {
        name: 'date',
        label: 'Date',
        type: 'date',
        // helpText: 'Set the day that marks the beginning of the first week of this journey.  It should ideally coincide with your check in time.',
        initialValue: (entity) => {
          return entity ? formatUtcUnixDate(entity.date._seconds, 'MM/DD/Y') : moment().format('MM/DD/Y');
        },
        required: true,
      },
      {
        name: 'recommendation',
        label: 'Recommended Exercise',
        type: 'select',
        helpText: 'You can optionally recommend an exercise from any current or past member mood.',
        options: exerciseOptions,
      },
    ],
    submitLabel: 'Save and Publish',
  };

  useEffect(() => {
    // Set the create form, it's constant
    setCreateForm(practiceSetForm);
  }, []);

  useEffect(() => {
    // Set the practices
    setPractices(recommendations && recommendations.length ? recommendations.filter(rec => rec.type === 'recommendation') : []);
  }, [recommendations]);

  useEffect(() => {
    // Whenever we change the item we are editing, update the edit form
    if (editing) {
      const newUpdateForm = {
        ...practiceSetForm,
      };
      newUpdateForm.fetchRoute = `/coach/member-recommendation/v2/${editing}`;
      newUpdateForm.postRoute = `/coach/member-recommendation/v2/${editing}`;
      setUpdateForm(newUpdateForm);
      setShowUpdateForm(true);
    }
  }, [editing]);

  const hideUpdateForm = () => {
    setShowUpdateForm(false);
    setEditing(null);
  };

  const hasPractices = !!practices && !!practices.length;

  const reversedPractices = hasPractices ? [...practices].reverse() : [];


  return (
    <MemberSection>
      <div className="grid grid-cols-5">
        <div className="col-span-4">
          <h3 className="text-lg font-semibold text-primary-500">Personalized Homework <span className="text-sm ml-4 text-gray-500 font-normal">These are displayed to the member in the app.</span></h3>
        </div>
        <div className="col-span-1 items-end text-right">
          <MemberSetForm
            form={createForm}
            reload={reload}
            title="Create a Personalized Practice"
            body="Once saved and published, this is visible to the member in the app. Press ESCAPE or click away to cancel this action."
            buttonText="Create New"
          />
        </div>
      </div>
      <div className="space-y-3 divide-y py-4 divide-gray-300">
        {!hasPractices && <p className="text-sm text-gray-500">There are no personalized homework items yet.</p>}
        {hasPractices && reversedPractices.map(practice => {
          const { title, description, week, date, _id, complete, completedAt } = practice;
          // console.log(date, 'this is the practice date');
          return (
            <div key={_id} className="pb-2 pt-4">
              <h4 className="font-semibold text-gray-700 text-lg mb">{title} <EditButton onClick={() => setEditing(_id)} /></h4>
              {/* <h4 className="font-semibold text-primary-500 mb-3">{`Week ${week}`}</h4> */}
              {complete && <p className="text-secondary-500 text-sm -mt-2 mb-3">Completed at {formatUtcUnixDate(completedAt._seconds, 'lll')}</p>}
              <p className="text-gray-500 text-sm"><Nl2br str={description} /></p>
              <p className="text-gray-400 text-sm mt-1 text-right">{formatUtcUnixDate(date._seconds)}</p>
            </div>
          )
        })}
        <MemberSetForm
          form={updateForm}
          reload={reload}
          title="Update Personalized Practice"
          body="Once saved and published, this is visible to the member in the app. Press ESCAPE or click away to cancel this action."
          hideButton
          isOpen={showUpdateForm}
          externalClose={hideUpdateForm}
        />
      </div>
    </MemberSection>
  );
}


function NotesSection({ recommendations, reload, journeyId, currentWeek }) {
  const [createForm, setCreateForm] = useState(null);
  const [updateForm, setUpdateForm] = useState(null);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [notes, setNotes] = useState(null);
  const [editing, setEditing] = useState(null);

  const notesSetForm = {
    noContainer: true,
    bg: 'bg-cream',
    type: 'post',
    postRoute: `/coach/journey/${journeyId}/member-recommendation`,
    successNotification: 'The summary note was saved!',
    beforePost: (values) => {
      return {
        ...values,
        type: 'note',
      };
    },
    fields: [
      {
        name: 'title',
        label: 'Title',
        type: 'text',
        placeholder: 'Enter a title',
        required: true,
      },
      {
        name: 'description',
        label: 'Message',
        type: 'textarea',
        placeholder: 'Compose your Summary Note here',
        required: true,
      },
      // {
      //   name: 'week',
      //   label: 'Week',
      //   type: 'select',
      //   options: weekOptions(),
      //   // helpText: 'Choose a goal for this member for this quarter.',
      //   required: true,
      //   initialValue: (entity) => {
      //     return entity ? `${entity.week}` : `${currentWeek}`;
      //   },
      // },
      {
        name: 'date',
        label: 'Date',
        type: 'date',
        // helpText: 'Set the day that marks the beginning of the first week of this journey.  It should ideally coincide with your check in time.',
        initialValue: (entity) => {
          return entity ? formatUtcUnixDate(entity.date._seconds, 'MM/DD/Y') : moment().format('MM/DD/Y');
        },
        required: true,
      },
    ],
    submitLabel: 'Save and Publish',
  };

  useEffect(() => {
    // Set the create form, it's constant
    setCreateForm(notesSetForm);
  }, []);

  useEffect(() => {
    // Set the practices
    setNotes(recommendations && recommendations.length ? recommendations.filter(rec => rec.type === 'note') : []);
  }, [recommendations]);

  useEffect(() => {
    // Whenever we change the item we are editing, update the edit form
    if (editing) {
      const newUpdateForm = {
        ...notesSetForm,
      };
      newUpdateForm.fetchRoute = `/coach/member-recommendation/v2/${editing}`;
      newUpdateForm.postRoute = `/coach/member-recommendation/v2/${editing}`;
      setUpdateForm(newUpdateForm);
      setShowUpdateForm(true);
    }
  }, [editing]);

  const hideUpdateForm = () => {
    setShowUpdateForm(false);
    setEditing(null);
  };

  const hasNotes = !!notes && !!notes.length;
  const reversedNotes = hasNotes ? [...notes].reverse() : [];

  return (
    <MemberSection>
      <div className="grid grid-cols-5 mt-8">
        <div className="col-span-4">
          <h3 className="text-lg font-semibold text-primary-500">Member Session Summary Notes <span className="text-sm ml-4 text-gray-500 font-normal">These are displayed to the member in the app.</span></h3>
        </div>
        <div className="col-span-1 items-end justify-end text-right">
          <MemberSetForm
            form={createForm}
            reload={reload}
            title="Compose a Summary Note"
            body="Once saved and published, this note is visible to the member in the app. Press ESCAPE or click away to cancel this action."
            buttonText="Compose"
          />
        </div>
      </div>
      <div className="space-y-3 divide-y divide-gray-300">
        {!hasNotes && <p className="text-md text-gray-500 my-4 text-sm">There are no member session summary notes yet.</p>}
        {hasNotes && reversedNotes.map(note => {
          const { title, description, week, date, _id } = note;
          return (
            <div key={_id} className="pb-2 pt-4">
              <h4 className="font-semibold text-gray-700 text-lg mb">{title} <EditButton onClick={() => setEditing(_id)} /></h4>
              {/* <h4 className="font-semibold text-primary-500 mb-3">{`Week ${week}`}</h4> */}
              <p className="text-gray-500 text-sm"><Nl2br str={description} /></p>
              <p className="text-gray-400 text-sm mt-1 text-right">{formatUtcUnixDate(date._seconds)}</p>
            </div>
          )
        })}

      <MemberSetForm
          form={updateForm}
          reload={reload}
          title="Update Sumary Note"
          body="Once saved and published, this note is visible to the member in the app. Press ESCAPE or click away to cancel this action."
          hideButton
          isOpen={showUpdateForm}
          externalClose={hideUpdateForm}
        />
      </div>
    </MemberSection>
  );
}


function ExercisesSection({ recommendations, reload, journeyId, exerciseOptions }) {
  const [createForm, setCreateForm] = useState(null);
  const [updateForm, setUpdateForm] = useState(null);
  const [practices, setPractices] = useState(null);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [editing, setEditing] = useState(null);

  const exerciseSetForm = {
    noContainer: true,
    bg: 'bg-cream',
    type: 'post',
    postRoute: `/coach/journey/${journeyId}/member-recommendation`,
    successNotification: 'The exercise was recommended!',
    beforePost: (values) => {
      return {
        ...values,
        date: moment().format('MM/DD/Y'),
        title: 'Exercise recommendation',
        description: 'Exercise recommendation',
        type: 'exercise',
      };
    },
    fields: [
      {
        name: 'recommendation',
        label: 'Recommended Exercise',
        type: 'select',
        helpText: 'This exercise will be recommended to the user directly, with no card generated in the Connect portion of the app.',
        options: exerciseOptions,
        required: true,
      },
    ],
    submitLabel: 'Save and Publish',
  };

  useEffect(() => {
    // Set the create form, it's constant
    setCreateForm(exerciseSetForm);
  }, []);

  useEffect(() => {
    // Set the practices
    setPractices(recommendations && recommendations.length ? recommendations.filter(rec => rec.type === 'exercise') : []);
  }, [recommendations]);

  useEffect(() => {
    // Whenever we change the item we are editing, update the edit form
    if (editing) {
      const newUpdateForm = {
        ...exerciseSetForm,
      };
      newUpdateForm.fetchRoute = `/coach/member-recommendation/v2/${editing}`;
      newUpdateForm.postRoute = `/coach/member-recommendation/v2/${editing}`;
      setUpdateForm(newUpdateForm);
      setShowUpdateForm(true);
    }
  }, [editing]);

  const hideUpdateForm = () => {
    setShowUpdateForm(false);
    setEditing(null);
  };

  const hasPractices = !!practices && !!practices.length;

  const reversedPractices = hasPractices ? [...practices].reverse() : [];


  return (
    <MemberSection>
      <div className="grid grid-cols-5">
        <div className="col-span-4">
          <h3 className="text-lg font-semibold text-primary-500">Recommend Exercise <span className="text-sm ml-4 text-gray-500 font-normal">These exercises are recommended to the member in the Flow portion of their app.</span></h3>
        </div>
        <div className="col-span-1 items-end text-right">
          <MemberSetForm
            form={createForm}
            reload={reload}
            title="Recommend an Exercise"
            body="Once saved and published, this is visible to the member in the app. Press ESCAPE or click away to cancel this action."
            buttonText="Create New"
          />
        </div>
      </div>
      <div className="space-y-3 divide-y py-4 divide-gray-300">
        {!hasPractices && <p className="text-sm text-gray-500">There are no recommended exercises yet.</p>}
        {hasPractices && reversedPractices.map(practice => {
          const { _id, recommendation, date } = practice;
          const exerciseIndex = findIndexOfItemByValue(exerciseOptions, recommendation, 'value');
          const exerciseTitle = exerciseIndex !== -1 ? exerciseOptions[exerciseIndex].label : 'Not Found';
          // console.log(date, 'this is the practice date');
          return (
            <div key={_id} className="pb-2 pt-4">
              {/* <h4 className="font-semibold text-gray-700 text-lg mb">{title} <EditButton onClick={() => setEditing(_id)} /></h4> */}
              {/* <h4 className="font-semibold text-primary-500 mb-3">{`Week ${week}`}</h4> */}
              {/* {complete && <p className="text-secondary-500 text-sm -mt-2 mb-3">Completed at {formatUtcUnixDate(completedAt._seconds, 'lll')}</p>} */}
              <p className="text-gray-500 text-sm">{exerciseTitle}</p>
              <p className="text-gray-400 text-sm mt-1 text-right">{formatUtcUnixDate(date._seconds)}</p>
            </div>
          )
        })}
        {/* <MemberSetForm
          form={updateForm}
          reload={reload}
          title="Update Personalized Practice"
          body="Once saved and published, this is visible to the member in the app. Press ESCAPE or click away to cancel this action."
          hideButton
          isOpen={showUpdateForm}
          externalClose={hideUpdateForm}
        /> */}
      </div>
    </MemberSection>
  );
}

export default function CoachMemberConnect() {
  const { id } = useParams();
  const [summary, setSummary] = useState(null);
  const [currentWeek, setCurrentWeek] = useState(0);
  const [exerciseOptions, setExerciseOptions] = useState([]);
  const [loading, setLoading] = useState(true);
  // const { setBreadcrumbs } = useBreadcrumbs();
  // useEffect(() => {
  //   setBreadcrumbs([
  //     {
  //       href: '/coach/members',
  //       label: 'Your Members',
  //     },
  //   ]);
  // }, []);

  const load = async () => {
    if (!loading) {
      setLoading(true);
    }
    try {
      const result = await getApiRequest(`/coach/journey/${id}/connect/v2`);
      setSummary(result);
      const journey = result.journey;
      const newCurrWeek = getCurrentWeekForJourney(journey ? journey : null).weeks;
      setCurrentWeek(newCurrWeek);
      const newExerciseOptions = await getExerciseOptionsFromJourney(journey);
      setExerciseOptions(newExerciseOptions);
    } catch (err) {
      Bugsnag.notify(err);
      console.log(err, 'there was an error loading the summary');
    }
    setLoading(false);
  };


  useEffect(() => {
    load();
  }, [id]);

  return (
      <Frame fullscreen classes="p-4">  
        <Loadable loading={loading}>
        {!!summary && (
          <>
            <MemberHeader memberUid={summary.journey.uid} journeyId={id} which="connect" name={summary.profile.name} toolkitName={summary.journey.toolkitName} />
            <div className="max-w-7xl my-8 px-4 mx-auto">
              <PracticeSection currentWeek={currentWeek} reload={load} journeyId={id} recommendations={summary.recommendations} exerciseOptions={exerciseOptions} />
              <NotesSection currentWeek={currentWeek} reload={load} journeyId={id} recommendations={summary.recommendations} />
              <ExercisesSection currentWeek={currentWeek} reload={load} journeyId={id} recommendations={summary.recommendations} exerciseOptions={exerciseOptions} />
            </div>
          </>
        )}
          
        </Loadable>
      </Frame>
  );
}
