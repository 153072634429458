import React, { useState, useEffect } from 'react';
import JourneyControlCard from './journey-control-card';
import AutoForm from '../../../form/auto-form';
import { transformFormToView } from '../../../../utils/helpers';
import DataSideBySideDisplay from '../../../data/data-side-by-side-display';
import Loadable from '../../../data/loadable';
import { getApiRequest } from '../../../../utils/request';

const JourneyControlModalMember = ({ memberUid }) => {
  const [mode, setMode] = useState('view');
  const [loading, setLoading] = useState(true);
  const [memberType, setMemberType] = useState(null);

  const load = async () => {
    setLoading(true);
    try {
      const result = await getApiRequest(`/member-type/${memberUid}`);
      setMemberType(result.settings);
    } catch (err) {
      console.log(err, 'there was an error loading the summary');
    }
    setLoading(false);
  };

  useEffect(() => {
    load();
  }, []);

  if (loading) {
    return <Loadable loading />;
  }

  const memberForm = {
    type: 'post',
    postRoute: `/member-type/${memberUid}`,
    successNotification: 'The member archetype was saved.',
    successHandler: () => {
      load();
      setMode('view');
    },
    sideBySide: true,
    fields: [
      {
        name: 'type',
        label: 'Step 1: Member Archetype',
        type: 'select',
        helpText: <>Based on your initial assessment what is the Member archetype / profile? Provide a brief explanation and update if adjusted in the future. More information <a href="https://www.notion.so/daydreamers/Member-Archetypes-1adf85b85cea46618869edd17ea5ca7b?pvs=4" target="_blank" rel="noreferrer">here</a>.</>,
        options: [
          {
            value: '',
            label: 'Select...',
          },
          {
            value: 'Skeptical Philosopher',
            label: 'Skeptical Philosopher',
          },
          {
            value: 'Chill High Achiever',
            label: 'Chill High Achiever',
          },
          {
            value: 'Self-Aware Wanderers',
            label: 'Self-Aware Wanderers',
          },
          {
            value: 'Aspirational Doers',
            label: 'Aspirational Doers',
          },
          {
            value: 'Rule Followers',
            label: 'Rule Followers',
          },
          {
            value: 'Nervous Explorers',
            label: 'Nervous Explorers',
          },
        ],
        initialValue: () => memberType?.type || '',
      },
      {
        name: 'notes',
        label: '',
        hideLabel: true,
        fullWidth: true,
        type: 'textarea',
        placeholder: 'Short explanation',
        initialValue: () => memberType?.notes || '',
      },
    ],
    submitLabel: 'Save',
  };

  const items = transformFormToView(memberForm, memberType);
  const menu = mode === 'view' ? 
    [
      {
        label: 'Edit',
        onClick: () => setMode('edit'),
      },
    ]
    : [
      {
        label: 'View',
        onClick: () => setMode('view'),
      },
    ];

  return (
    <div>
      {mode === 'edit' && (
        <JourneyControlCard title="Member" menu={menu}>
          <AutoForm {...memberForm} />
        </JourneyControlCard>
      )}
      {mode === 'view' && (
        <JourneyControlCard title="Member" menu={menu}>
          <DataSideBySideDisplay items={items} />
        </JourneyControlCard>
      )}
    </div>
  );
    // <div className="w-full lg:w-[98%] rounded-md bg-[#F2F2F2] ml-0 lg:ml-4 p-4 mb-8">
    //   <p className="text-[32px] font-[700] text-left">Member</p>
    //   <div className="mt-5 flex flex-col lg:flex-row justify-between text-left">
    //     <div className="w-[75%]">
    //       <p className="text-[18px]">Step 1: Member Archetype</p>
    //       <p className="text-[16px] text-[#807F7E]">
    //         Based on your initial assessment what is the Member archetype / profile? Provide a brief explanation and update if adjust in the future.{' '}
    //       </p>
    //     </div>
    //     <div className="w-full mt-2 lg:mt-0 lg:w-[20%]">
    //       <select className="w-full bg-[#D9D9D9] border border-[#D9D9D9] rounded-md">
    //         <option>Skeptical</option>
    //       </select>
    //     </div>
    //   </div>
    //   <textarea className="w-full rounded-md mt-10" placeholder="Short explanation" rows="5" />
    //   <button className="bg-[#A38DF2] text-[20px] p-3 rounded-lg text-white w-[30%] my-10 shadow-lg hover:opacity-70">Save</button>
    // </div>
  // );
};

export default JourneyControlModalMember;
