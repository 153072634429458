import { useEffect, useState } from 'react';
import Bugsnag from '@bugsnag/browser';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { getApiRequest } from '../../../utils/request';
import Frame from '../../../components/layout/frame';
import MemberHeader from '../../../components/layout/coach/member-header';
import Loadable from '../../../components/data/loadable';
import {truncateString } from '../../../utils/helpers';

function ActivityCard({ activity, journeyId }) {
  const { title, week, description, images, videoData } = activity || {};
  const { image } = videoData || {};
  const firstImage = images?.length ? images[0] : null;
  const cardImage = image ?? firstImage;
  const shortDescription = truncateString(description, 80);

  if (!title) {
    return null;
  }

  return (
    <Link to={`/coach/member/journey/${journeyId}/flow/activities/${week}`} className="bg-lightsky rounded-md">
      <div className="flex flex-col p-5 text-white">
        {cardImage ? <img className="rounded-md w-full" alt={title} src={cardImage} />: null}
        <p className="text-2xl font-semibold mb-4 mt-3 truncate">{title}</p>
        <p className="mb-4">
          {shortDescription}
        </p>
        <p>
          Week <span className="font-semibold">#{week}</span>
        </p>
      </div>
    </Link>
  );
}

export default function CoachMemberFlow() {
  const { id } = useParams();
  const [summary, setSummary] = useState(null);
  const [journey, setJourney] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const load = async () => {
      if (!id) {
        navigate('/coach/members', { replace: true });
        return;
      }
  
      setLoading(true);
      try {
        const result = await getApiRequest(`/coach/journey/${id}`);
        setSummary(result);
        setJourney(result.journey);
      } catch (err) {
        Bugsnag.notify(err);
        console.log(err, 'there was an error loading the summary');
      }
      setLoading(false);
    };

    load();
  }, [id]);

  return (
    <Frame fullscreen classes="p-4">
      <Loadable loading={loading}>
        {!!summary && (
          <>
            <MemberHeader journeyId={id} which="flow" name={summary.profile.name} toolkitName={summary.journey.toolkitName} />
            <div className="max-w-7xl my-8 px-4 mx-auto">
              <div className="grid grid-cols-3 gap-5">{journey?.weeks?.length ? journey.weeks.map((week) => <ActivityCard key={week.week} activity={week?.activity} journeyId={id} />) : null}</div>
            </div>
          </>
        )}
      </Loadable>
    </Frame>
  );
}
