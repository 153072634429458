import React from 'react';

function getColor(color) {
  switch (color) {
    case 'red':
      return 'bg-red-100 text-red-800';

    case 'yellow':
      return 'bg-yellow-100 text-yellow-800';

    case 'green':
      return 'bg-green-100 text-green-800';
    
    case 'blue':
      return 'bg-blue-100 text-blue-800';
    
    case 'indigo':
      return 'bg-indigo-100 text-indigo-800';
  
    case 'purple':
      return 'bg-purple-100 text-purple-800';

    case 'pink':
      return 'bg-pink-100 text-pink-800';

    default:
    case 'gray':
      return 'bg-gray-100 text-gray-800';
  }
}

function getSize(large, rounded) {
  if (rounded) {
    return large ? 'px-2.5 py-0.5 rounded-md text-sm' : 'px-2 py-0.5 rounded text-xs';
  }
  return large ? 'px-3 py-0.5 rounded-full text-sm' : 'px-2.5 py-0.5 rounded-full text-xs';
}

export default function Badge({ color, rounded, large, text }) {
  const classes = `inline-flex items-center font-semibold ${getColor(color)} ${getSize(large, rounded)}`;
  return <span className={classes}>{text}</span>;  
}
