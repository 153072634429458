import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import Frame from '../../components/layout/frame';
import Loadable from '../../components/data/loadable';
import Heading from '../../components/layout/admin/heading';
import AdminTable from '../../components/common/admin-table';
import ActionLinks from '../../components/data/action-links';
// import { getApiRequest } from '../../utils/request';
import { useServerNotifications } from '../../contexts/server-notifications-provider';
import { useBreadcrumbs } from '../../contexts/breadcrumbs-provider';
import { useAuth } from '../../contexts/auth-provider';


function constructViewUrl(type, data) {
  const { toolkitJourneyId, memberUid } = data;
  // Any message with a conversation should just go straight to it
  // console.log(data, 'data', type);
  if (type === 'admin_member_intro_survey' && toolkitJourneyId) {
    return `/admin/member/journey/${toolkitJourneyId}/survey-responses`;
  }
  if (type === 'admin_journey_ending' && memberUid) {
    return `/admin/profile-view/${memberUid}`;
  }

  return '/admin/members';
}

export default function AdminDashboard() {
  const { notDismissed, loading, unreadNotifications, numUnread, dismiss, dismissAll, markAsRead } = useServerNotifications();
  const { setBreadcrumbs } = useBreadcrumbs();
  const { hasRole } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (hasRole(['coach'])) {
      navigate('/coach', { replace: true });
      return;
    }
    setBreadcrumbs([
      {
        href: '/admin',
        label: 'Dashboard',
      },
    ]);
  }, []);


  useEffect(() => {
    if (notDismissed && notDismissed.length && numUnread) {
      for (let i = 0; i < unreadNotifications.length; i++) {
        markAsRead(unreadNotifications[i].id);
      }
    }
  }, [notDismissed]);


  const formatRow = (item) => {
    const { message, id, type, data, created } = item;
    const date = moment.unix(created.seconds).format('M/DD/YY h:mm a');
    
    return {
      message: <p className="max-w-lg break-normal whitespace-pre-wrap text-sm">{message}</p>,
      id,
      date,
      actionLinks: [
        {
          label: 'Dismiss',
          onClick: () => dismiss(id),
          unstyled: true,
        },
        {
          label: 'View',
          href: constructViewUrl(type, data),
        },
      ],
    };
  };

  const columns = [
    {
      key: 'message',
      label: 'Message',
    },
    {
      key: 'date',
      label: 'Date',
    },
    {
      key: 'actions',
      label: '',
      isRight: true,
      render: (row) => { return !!row.actionLinks ? <ActionLinks links={row.actionLinks} /> : null; },
    },
  ];


  const actionLinks = !!notDismissed && !!notDismissed.length
    ? [
      {
        label: 'Dismiss All',
        onClick: dismissAll,
        secondary: true,
      },
    ]
    : null;


  const baseUrl = '/admin';
  
  return (
    <Frame fullscreen classes="p-4">
      <Heading title="What's Happening" links={actionLinks} />
      <Loadable loading={loading}>
        <AdminTable alternate results={{ data: notDismissed }} columns={columns} baseUrl={baseUrl} rowFormatter={formatRow} emptyMessage="You're all caught up!" />
      </Loadable>
    </Frame>
  );
}