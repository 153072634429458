import React from 'react';
import { Link } from 'react-router-dom';

export default function ActionLinks({ links }) {
  if (!links || !links.length) {
    return null;
  }

  return (
    <div className="flex-shrink-0 flex space-x-2">
      {links.map(item => {
        const { secondary, href, label, onClick, unstyled } = item;
        const commonClasses = 'inline-flex items-center px-4 py-3 border border-transparent rounded-md text-sm font-medium  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-indigo-500'
        const secondaryClasses = 'shadow-sm bg-secondary-500 hover:bg-secondary-600 text-white';
        const primaryClasses = 'shadow-sm bg-primary-500 hover:bg-primary-600 text-white';
        const unstyledClasses = 'bg-transparent hover:bg-gray-100 text-gray-500 text-underline underline';
        let classes = '';
        if (!secondary && !unstyled) {
          classes = `${commonClasses} ${primaryClasses}`;
        } else if (secondary) {
          classes = `${commonClasses} ${secondaryClasses}`;
        } else {
          classes = `${commonClasses} ${unstyledClasses}`;
        }
        

        if (onClick) {
          return (
            <button
              key={label}
              onClick={onClick}
              className={classes}
            >
              {label}
            </button>
          );
        }
        return (
          <Link
            key={label}
            to={href}
            className={classes}
          >
            {label}
          </Link>
        );
      })}
    </div>
  );
}
