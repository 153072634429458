import { useState, useEffect } from 'react';
import Bugsnag from '@bugsnag/browser';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import Avatar from '../../components/common/avatar';
import { getApiRequest } from '../../utils/request';
import { daysOfWeek, timezonePrettyName } from '../../utils/helpers';
import Loadable from '../../components/data/loadable';
import Frame from '../../components/layout/frame';
import Heading from '../../components/layout/coach/heading';
import MemberSection from '../../components/layout/coach/member-section';
import Nl2br from '../../components/common/nl2br';

function InfoSection({ left, right }) {
  return (
    <div className="grid grid-cols-4 my-10">
      <div className="col-span-2">
        <h4 className="text-sm text-gray-400 font-medium mb-1">{left.label}</h4>
        <p className="text-md text-gray-700">{left.value || '--'}</p>
      </div>

      {!!right && (
        <div>
          <h4 className="text-sm text-gray-400 font-medium mb-1">{right.label}</h4>
          <p className="text-md text-gray-700">{right.value || '--'}</p>
        </div>
      )}
      {!right && <div />}
    </div>
  );
}

function ProfileSection({ profile }) {

  const { photo, name, pronouns, timezone, birthday, shippingAddress, shippingAddress2, shippingCity, shippingState, shippingZip, phone, email, calendly } = profile;

  const birthdayMoment = birthday ? moment(birthday) : null;
  const age = birthdayMoment ? Math.floor(moment().diff(birthdayMoment, 'year', true)) : null;

  const shippingAddressFormatted = !!shippingAddress ? (
    <>
      {shippingAddress}{`${shippingAddress2 ? `, ${shippingAddress2}` : ''}`}<br />
      {shippingCity}, {`${shippingState} ${shippingZip}`}
    </>
  ) : '';
  

  return (
    <MemberSection>
      <h3 className="text-primary-400 font-bold text-2xl mb-6">General</h3>
      <div className="grid grid-cols-4">
        <div className="col-span-3">
          <InfoSection left={{ label: 'Full Name', value: name }} right={{ label: 'Pronouns', value: pronouns }} />
          <InfoSection left={{ label: 'Email Address', value: email }} right={{ label: 'Phone Number', value: phone }} />
          <InfoSection left={{ label: 'Calendly Link', value: calendly || '--' }} right={null} />
          <InfoSection left={{ label: 'Shipping Address', value: shippingAddressFormatted }} right={{ label: 'Time Zone', value: timezonePrettyName(timezone) }} />
          <InfoSection left={{ label: 'Birthday', value: birthday || '--' }} right={{ label: 'Age', value: age || '--' }} />

        </div>
        <div className="col-span-1 text-center">
          <div className="mb-4">
            <Avatar xl photo={photo} name={name} bg="bg-secondary-500" />
          </div>
        </div>
      </div>
    </MemberSection>
  );
}

function LogisticsSection({ profile }) {

  const days = daysOfWeek();
  const daysWithAvailability = [];
  days.forEach(day => {
    const start = `${day.toLowerCase()}_start`;
    const end = `${day.toLowerCase()}_end`;
    if (!!profile && profile[start] && profile[end]) {
      daysWithAvailability.push({
        day,
        start: profile[start],
        end: profile[end],
      });
    }
  });

  const hasAvailability = !!daysWithAvailability.length;

  return (
    <MemberSection>
      <h3 className="text-primary-400 font-bold text-2xl mb-6">Availability</h3>
      {!hasAvailability && <p className="text-gray-700 text-lg mb-6">You have not entered any availability information.</p>}
      {hasAvailability && daysWithAvailability.map(day => (
        <p key={day.day} className="mb-2 text-md text-gray-600"><span className="font-semibold">{day.day}</span>: {`${day.start} - ${day.end}`}</p>
      ))}
    </MemberSection>
  );
}

function AboutSection({ profile }) {

  const { description, interests, experience } = profile;
 

  return (
    <MemberSection>
      <h3 className="text-primary-400 font-bold text-2xl mb-4">About Me</h3>
      <p className="text-gray-500 text-lg mb-6">Each coach has a profile in the app for members to look at.  All of the info below will be viewable by members.</p>
      <div className="mb-8">
        <h4 className="text-sm text-gray-400 font-medium mb-1">Overview / Description</h4>
        <p className="text-md text-gray-700">{<Nl2br str={description} /> || 'You have not provided a description yet.'}</p>
      </div>

      <div className="mb-8">
        <h4 className="text-sm text-gray-400 font-medium mb-1">Interests</h4>
        <p className="text-md text-gray-700">{<Nl2br str={interests} /> || 'You have not provided any interests yet.'}</p>
      </div>


      <div className="mb-8">
        <h4 className="text-sm text-gray-400 font-medium mb-1">Experience</h4>
        <p className="text-md text-gray-700">{<Nl2br str={experience} /> || 'You have not provided any experience information yet.'}</p>
      </div>

    </MemberSection>
  );
}

export default function CoachProfile() {
  const { id } = useParams();
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const load = async () => {
    if (!loading) {
      setLoading(true);
    }
    try {
      const result = await getApiRequest(`/my-profile`);
      setProfile(result.profile);
    } catch (err) {
      Bugsnag.notify(err);
      console.log(err, 'there was an error loading the summary');
    }
    setLoading(false);
  };


  useEffect(() => {
    load();
  }, [id]);


  return (
      <Frame fullscreen classes="p-4">
        <Loadable loading={loading}>
        {!!profile && (
          <>
            <Heading title="Your Profile" links={[{ href: '/coach/profile-edit', label: 'Update Profile' }]} />
            <div className="max-w-7xl my-8 pl-8 pr-4 mx-auto">
              <ProfileSection profile={profile} />
              <LogisticsSection profile={profile} />
              <AboutSection profile={profile} />
            </div>
          </>
        )}
        </Loadable>
      </Frame>
  );
}
