import React, { useState } from 'react';
import { useNotifications } from '../../../../contexts/notifications-provider';
import { getMomentFromUnix, getSurveyName, getWeekFromJourney } from '../../../../utils/helpers';
import { postApiRequest } from '../../../../utils/request';
import RightSlideOver from '../../../overlays/right-slide-over';

function getGoalName(id, goals) {
  if (!goals) {
    return '';
  }
  for (let i = 0; i < goals.length; i++) {
    if (goals[i]._id === id) {
      return goals[i].name;
    }
  }
  return '';
}

const PerspectiveCheckin = ({ responses, journey }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [drawerTitle, setDrawerTitle] = useState('');
  const [completed, setCompleted] = useState('');
  const [questionData, setQuestionData] = useState([]);
  const { addNotification } = useNotifications();

  const requestMonthlySurvey = async () => {
    try {
      await postApiRequest(`/coach/request-monthly-survey/${journey._id}`);
      addNotification({
        type: 'success',
        body: "The member now has a Perspective Check-in. They were notified if it was new.",
      });
    } catch (err) {
      addNotification({
        type: 'error',
        body: "There was an error creating the Perspective Check-in for the member.",
      });
      console.log(err, 'perspective create error');
    }
  };

  const getQuestions = (idx) => {
    const response = responses[idx];
    const keys = Object.keys(response.data);

    // V2 responses will have an exercise ID, even if it's null
    const isV2 = typeof response.exerciseId !== 'undefined';

    // Old logic, handle the v1 responses
    if (!isV2) {
      const allResponses = [];
      for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        allResponses.push(responses[idx].data[key]);
      }
  
      const newSections = [
        {
          key: 'Engagement',
          questions: allResponses.filter((item) => item.question?.type === 'Engagement'),
        },
        {
          key: 'Activity',
          questions: allResponses.filter((item) => item.question?.type === 'Activity'),
        },
        {
          key: 'Mood',
          questions: allResponses.filter((item) => item.question?.type === 'Mood'),
        },
        {
          key: 'General Well Being',
          questions: allResponses.filter((item) => item.question?.type === 'General Well Being'),
        },
        {
          key: 'Perspective',
          questions: allResponses.filter((item) => item.question?.type === 'Perspective'),
        },
        {
          key: 'Goal Choice',
          questions: allResponses.filter((item) => item.question?.goalChoice === true),
        },
      ];
      return newSections;
    }

    // Handle v2 responses
    const newSections = [];
    const { instance } = response;

    const { sections: instanceSections } = instance;


    for (let i = 0; i < instanceSections.length; i++) {
      const section = instanceSections[i];
      const { questions, title } = section;

      const newQuestions = [];
      questions.forEach(question => {
        const questionData = response.data[question._id];
        if (typeof questionData !== 'undefined') {
          newQuestions.push(questionData);
        }
      })
      
      newSections.push({
        type: title,
        key: title,
        questions: newQuestions,
      });
    }
    return newSections;

  };

  const hasResponses = !!responses && !!responses.length;

  return (
    <>
      <h3 id="checkins" className="text-[32px] font-bold mb-4">Perspective Check-ins</h3>
      <button onClick={requestMonthlySurvey} className="mb-4 px-6 py-4 rounded-xl bg-[#A38DF2] hover:opacity-70 text-white text-sm font-semibold">Send Perspective Check-in</button>

      {!hasResponses && <p className="text-md text-gray-500 my-4 text-sm">There are no completed surveys.</p>}
      {hasResponses && (
        <>
          <div className="border border-[#807F7E] rounded-lg mb-10 overflow-x-scroll xl:overflow-hidden w-full">
            <table className="bg-white h-full w-full border-collapse">
              <thead>
                <tr className="bg-[#342F3B] w-full text-white">
                  <th className="text-[20px] font-[500] py-4 text-left pl-3 pt-3 pb-3 min-w-[200px]">Survey Type</th>
                  <th className="text-[20px] font-[500] py-4 text-left min-w-[200px]">Mood/Exercise</th>
                  <th className="text-[20px] font-[500] py-4 text-left min-w-[200px]">Completed</th>
                  <th className="text-[20px] font-[500] py-4 text-left min-w-[200px]">Timeline</th>
                  <th className="text-[20px] font-[500] py-4 text-left min-w-[200px]">Full Response</th>
                </tr>
              </thead>
              <tbody>
                {responses.map((item, idx) => {
                  // console.log(item, 'item');
                  const exerciseInfo = item.exerciseTitle ? ` - ${item.exerciseTitle}` : '';
                  
                  const updatedMoment = getMomentFromUnix(item.updatedAt._seconds);
                  const weeksData = getWeekFromJourney(journey, updatedMoment);
                  return (
                    <tr key={`${item._id}_${idx}`} className={`${idx !== responses.length - 1 && 'border-b border-[#807F7E]'} overflow-hidden`}>
                      <td className="text-left pb-3 pt-3 pl-3">{getSurveyName(item.instance.type)}</td>
                      <td className="text-left pb-3 pt-3">{item.exerciseToolkitName || item.toolkitName}{exerciseInfo}</td>
                      <td className="text-left pb-3 pt-3">{item?.updatedAt ? updatedMoment.format('MMM DD, YYYY') : '--'}</td>
                      <td className="text-left pb-3 pt-3">{!!weeksData ? `Week ${weeksData.weeks}` : '--'}</td>
                      <td className="text-left pb-3 pt-3">
                        <button
                          className="bg-[#A38DF2] px-4 py-2 w-[80%] rounded-lg text-white hover:opacity-70"
                          onClick={() => {
                            setQuestionData(getQuestions(idx));
                            setDrawerTitle(getSurveyName(item.instance.type));
                            setCompleted(updatedMoment.format('MMM DD, YYYY'));
                            setIsDrawerOpen(true);
                          }}
                        >
                          View
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <RightSlideOver isOpen={isDrawerOpen} close={() => setIsDrawerOpen(false)} darkBg title={drawerTitle} isNew large>
            <p className="text-[14px] -mt-5">Completed: {completed}</p>
            {questionData.length > 0 &&
              questionData.map((item, i) => (
                <div key={`${item.key}_${i}`} className="mt-5">
                  <p className="text-[20px] w-max font-semibold border-b border-b-black">{item.key}</p>
                  {item.questions.map((question) => {
                    const { question: questionData, value: response } = question;
                    const { goals } = questionData;
                    let responseValue = response !== null ? response : '';
                    if (!!goals) {
                      // The response is a goal id, grab it from our list of goal options
                      responseValue = getGoalName(response, goals);
                    }
                    return (
                      <div key={questionData._id} className="mt-3">
                        <p className="text-[16px]">{question.question.question}</p>
                        <p className="text-[#7162D9]">{responseValue}</p>
                      </div>
                    );
                  })}
                </div>
              ))}
          </RightSlideOver>
        </>
      )}
      
    </>
  );
};

export default PerspectiveCheckin;
