import { classNames } from "../../utils/helpers";

export default function SimpleFeed({ timeline }) {

  return (
    <div className="flow-root">
      <ul role="list" className="-mb-8">
        {timeline.map((event, i) => {
          const isLast = i === timeline.length - 1;
          const { date, id, content, iconBackground, icon: Icon } = event;
          const iconBg = iconBackground || 'bg-gray-400';

          return (
            <li key={id}>
              <div className="relative pb-8">
                {!isLast && <span className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />}
                <div className="relative flex space-x-3">
                  <div>
                    <span
                      className={classNames(
                        iconBg,
                        'h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white'
                      )}
                    >
                      <Icon className="h-5 w-5 text-white" aria-hidden="true" />
                    </span>
                  </div>
                  <div className="min-w-0 flex-1 pt-2 flex justify-between space-x-3">
                    <div>
                      <p className="text-xs text-gray-500">
                        {content}
                      </p>
                    </div>
                    <div className="text-right text-xs whitespace-nowrap text-gray-400">
                      {date}
                    </div>
                  </div>
                </div>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  )
}