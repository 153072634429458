import { useState, useEffect } from 'react';
import { findIndexById } from '../../utils/helpers';
import { fetchContentItems } from '../../utils/search';
import AddButton from '../buttons/add-button';
import { DndContext, closestCenter, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { useSortable } from '@dnd-kit/sortable';
import Panel from '../common/panel';
import { SelectorIcon } from '@heroicons/react/outline';
import IconButton from '../buttons/icon-button';
import { XIcon } from '@heroicons/react/solid';
import Confirm from '../overlays/confirm';
import SearchSelect from '../pathways/search-select';
import ItemDisplay from './item-display';


function StepItem({ item, removeItem, id, step, updateStepItems }) {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });
  const [fullItem, setFullItem] = useState(null);
  const [showConfirm, setShowConfirm] = useState(false);

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  useEffect(() => {
    if (!item?.id) {
      return;
    }
    const loadFullItem = async () => {
      const result = await fetchContentItems([item.id]);
      setFullItem(result[0]);
    };
    loadFullItem();
  }, [item]);
  // console.log(item, 'this is the item');

  const cancelConfirm = () => {
    setShowConfirm(false);
  };

  const actuallyRemoveItem = () => {
    removeItem(step, item);
  };

  const handleDosesUpdate = (e) => {
    const newDoses = e.target.value;
    if (item.doses === newDoses) {
      return;
    }
    const newItem = {
      ...item,
      doses: 1 * newDoses,
    };
    const itemIndex = findIndexById(step.items, item.id);
    const newItems = [...step.items];
    newItems[itemIndex] = newItem;
    updateStepItems(step, newItems);
  }

  if (!fullItem) {
    return null;
  }
  const doseOptions = [];
  for (let i = 1; i <= 20; i++) {
    doseOptions.push({
      value: i,
      label: `${i}`,
    });
  }

  return (
    <>
      <div className="flex w-full py-4" ref={setNodeRef} style={style}>
        <div className="flex-shrink-0 pt-8 px-1" {...attributes} {...listeners}>
          <SelectorIcon className="cursor-move h-6 w-6" aria-hidden="true" />
        </div>
        {item.type === 'exercise' && (
          <div className="flex-shrink-0 pt-3 px-2" {...attributes} {...listeners}>
            <label htmlFor="doses" className="block text-sm font-medium text-gray-700 mb-1">
              Doses:
            </label>
            <SearchSelect options={doseOptions} value={`${item.doses}`} onChange={handleDosesUpdate} name="doses" />
          </div>
        )}
        <ItemDisplay item={fullItem} />
        <div className="ml-4 flex-1 justify-end flex self-center p-1">
          <IconButton Icon={XIcon} color="red" size="sm" onClick={() => setShowConfirm(true)} />
        </div>
      </div>
      <Confirm
        open={showConfirm}
        cancel={cancelConfirm}
        action={actuallyRemoveItem}
        title="Are you sure?" body={`Are you sure you want to remove '${fullItem.title || 'this item'}'?`}
        actionText="Remove Item"
      />
    </>
  );
}

export default function Step({ step, addItems, updateStepItems, removeItem, removeStep }) {
  const sensors = useSensors(useSensor(PointerSensor));
  function handleDragEnd(event) {
    const { active, over } = event;

    if (active.id !== over.id) {
      const oldIndex = findIndexById(step.items, active.id);
      const newIndex = findIndexById(step.items, over.id);
      const newItems = arrayMove(step.items, oldIndex, newIndex);
      updateStepItems(step, newItems);
    }
  }

  const { items, index } = step;
  const hasItems = !!items?.length;
  return (
    <Panel>
      <div className="p-4">
        <div className="flex justify-between border-b border-gray-100 mb-4">
          <h2 className="text-xl">Step {index}</h2>
          {!hasItems && (
            <div className="ml-4 flex-1 justify-end flex self-center p-1">
              <IconButton color="red" size="sm" Icon={XIcon} onClick={() => removeStep(step)} />
            </div>
          )}
        </div>
        {!hasItems && (
          <div className="py-4 text-gray-700">
            <p>
              You must add items to create a valid step. Only empty steps can be removed.
            </p>
          </div>
        )}
        {hasItems && (
          <div className="py-4 divide-y">
            <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
              <SortableContext items={items} strategy={verticalListSortingStrategy}>
                {items.map(item => <StepItem step={step} item={item} id={item.id} key={item.id} removeItem={removeItem} updateStepItems={updateStepItems} />)}
              </SortableContext>
            </DndContext>
          </div>
        )}
        <AddButton buttonText="Add Items" onClick={() => addItems(step)} />
      </div>
    </Panel>
  );
}
