import React, { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react'
import { FaEllipsisH } from 'react-icons/fa';
import { classNames } from '../../../../utils/helpers';


function BubbleMenu({ items }) {
  if (!items?.length) {
    return null;
  }
  
  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className="-m-2 flex items-center rounded-md px-2 text-gray-700 hover:text-black border border-[#b8b7b5] bg-[#d0d0d0] hover:bg-[#b8b7b5]">
          <span className="sr-only">Open options</span>
          <FaEllipsisH className="w-5 h-5" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-[#b8b7b5] shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="my-3 mx-3 divide-y divide-slate-300 border-t border-b border-slate-300">
            {items.map((item) => {
              return (
                <Menu.Item key={item.label}>
                  {({ active }) => (
                    <button
                      onClick={item.onClick}
                      className={classNames(
                        active ? 'bg-[#d0d0d0] text-gray-900' : 'text-gray-800',
                        'px-4 py-2 text-sm w-full text-right',
                        item.classes ? item.classes : '',
                      )}
                    >
                      {/* <StarIcon className="mr-3 h-5 w-5 text-gray-400" aria-hidden="true" /> */}
                      {item.label}
                    </button>
                  )}
                </Menu.Item>
              );
            })}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}


const JourneyControlCard = ({ title, children, menu }) => {

  return (
    <div className="w-full lg:w-[98%] rounded-md bg-[#efefef] ml-0 lg:ml-4 p-4 mb-8">
      <div className="flex space-x-3">
        <div className="flex-1">
          <p className="text-[32px] font-[700] text-left">{title}</p>
        </div>
        <div className="flex flex-shrink-0 self-center">
          <BubbleMenu items={menu} />
        </div>
      </div>
      {children}
    </div>
  );
};

export default JourneyControlCard;
