import React, { useState, useEffect } from 'react';

function TimeInput({ formik, name, id, hasError, timezone = null }) {
  const [hour, setHour] = useState('');
  const [minute, setMinute] = useState('');
  const [ampm, setAmpm] = useState('');
  const [changed, setChanged] = useState(false);
  const classes = hasError ? 'border-red-300 text-red-900' : 'border-gray-300'
  const defaultValue = formik.initialValues[name];

  useEffect(() => {
    // Separate into the parts
    if (defaultValue) {
      const timeParts = defaultValue.split(' ');
      const minuteHourParts = timeParts[0].split(':');
      const newHour = minuteHourParts[0];
      const newMinute = minuteHourParts[1];
      const newAmpm = timeParts[1];
      setHour(newHour);
      setMinute(newMinute);
      setAmpm(newAmpm);
    }
  }, [defaultValue]);

  // Set the value IF we have all three elements selected
  useEffect(() => {
    if (changed) {
      if (hour !== '' && minute !== '' && ampm !== '') {
        formik.setFieldValue(name, `${hour}:${minute} ${ampm}`);
      } else {
        formik.setFieldValue(name, '');
      }
    }
  }, [hour, minute, ampm, changed]);

  const changeHour = (e) => {
    setHour(e.target.value);
    if (!changed) {
      setChanged(true);
    }
  };
  const changeMinute = (e) => {
    setMinute(e.target.value);
    if (!changed) {
      setChanged(true);
    }
  };
  const changeAmpm = (e) => {
    setAmpm(e.target.value);
    if (!changed) {
      setChanged(true);
    }
  };

  const hourOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  const minuteOptions = ['00', '15', '30', '45'];
  const ampmOptions = ['AM', 'PM'];

  return (
    <div className="flex justify-start">
      <div>
        <div className="flex">
          <select
            name={`${name}_hours`}
            className={`bg-white text-sm appearance-none outline-none border ${classes} rounded`}
            onChange={changeHour}
          >
            <option value="">--</option>
            {hourOptions.map(opt => {
              const val = `${opt}`;
              const selectedProp = hour === val ? { selected: true } : {};
              return <option key={val} value={val} {...selectedProp}>{val}</option>;
            })}
          </select>
          <span className="text-xl mx-1 mt-1">:</span>
          <select
            name={`${name}_minutes`}
            className={`bg-white text-sm appearance-none outline-none border ${classes} rounded mr-1`}
            onChange={changeMinute}
          >
            <option value="">--</option>
            {minuteOptions.map(val => {
              const selectedProp = minute === val ? { selected: true } : {};
              return <option key={val} value={val} {...selectedProp}>{val}</option>;
            })}
          </select>
          <select
            name={`${name}_ampm`}
            className={`bg-white text-sm appearance-none outline-none border ${classes} rounded`}
            onChange={changeAmpm}
          >
            <option value="">--</option>
            {ampmOptions.map(val => {
              const selectedProp = ampm === val ? { selected: true } : {};
              return <option key={val} value={val} {...selectedProp}>{val}</option>;
            })}
          </select>
          {!!timezone && (
            <p className={`ml-1 text-sm pt-2`}>{timezone}</p>
          )}
        </div>
      </div>
    </div>
  );
}

export default TimeInput;
