import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import Frame from '../../components/layout/frame';
import Loadable from '../../components/data/loadable';
import Heading from '../../components/layout/coach/heading';
import AdminTable from '../../components/common/admin-table';
import ActionLinks from '../../components/data/action-links';
// import { getApiRequest } from '../../utils/request';
import { useServerNotifications } from '../../contexts/server-notifications-provider';
import { useBreadcrumbs } from '../../contexts/breadcrumbs-provider';
import { useAuth } from '../../contexts/auth-provider';
import Panel from '../../components/common/panel';

const upcomingChatNotificationTypes = [
  'coach_upcoming_chat',
  'coach_upcoming_chat_5',
  'coach_upcoming_chat_30',
];

function constructViewUrl(type, data) {
  const { memberUid, conversationId, toolkitJourneyId } = data;
  // Any message with a conversation should just go straight to it
  
  if (memberUid && upcomingChatNotificationTypes.indexOf(type) !== -1) {
    return `/coach/member/${memberUid}/chat`;
  }
  if (conversationId) {
    return `/coach/chat/${conversationId}`;
  }
  if (toolkitJourneyId) {
    return `/coach/member/journey/${toolkitJourneyId}`;
  }

  return '/coach/members';
}

export default function CoachDashboard() {
  const { notDismissed, loading, unreadNotifications, numUnread, dismiss, dismissAll, markAsRead } = useServerNotifications();
  const { setBreadcrumbs } = useBreadcrumbs();
  const { hasRole } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (hasRole(['admin'])) {
      navigate('/admin', { replace: true });
      return;
    }
    setBreadcrumbs([
      {
        href: '/coach',
        label: 'Dashboard',
      },
    ]);
  }, []);

  // useEffect(() => {
  //   const init = async () => {
  //     try {
  //       console.log('CREATING MAPS FOR USERS... RD2');
  //       const res = await getApiRequest('/create-maps-for-users');
  //       console.log(res, 'these are results');
  //     } catch (err) {
  //       console.log(err, 'error loading test route');
  //     }
  //   };
  //   init();
  // }, []);

  useEffect(() => {
    if (notDismissed && notDismissed.length && numUnread) {
      for (let i = 0; i < unreadNotifications.length; i++) {
        markAsRead(unreadNotifications[i].id);
      }
    }
  }, [notDismissed]);


  const formatRow = (item) => {
    const { message, id, type, data, created } = item;
    const date = moment.unix(created.seconds).format('M/DD/YY h:mm a');
    
    return {
      message: <p className="max-w-lg break-normal whitespace-pre-wrap text-sm">{message}</p>,
      id,
      date,
      actionLinks: [
        {
          label: 'Dismiss',
          onClick: () => dismiss(id),
          unstyled: true,
        },
        {
          label: 'View',
          href: constructViewUrl(type, data),
        },
      ],
    };
  };

  const columns = [
    {
      key: 'message',
      label: 'Message',
    },
    {
      key: 'date',
      label: 'Date',
    },
    {
      key: 'actions',
      label: '',
      isRight: true,
      render: (row) => { return !!row.actionLinks ? <ActionLinks links={row.actionLinks} /> : null; },
    },
  ];


  const actionLinks = !!notDismissed && !!notDismissed.length
    ? [
      {
        label: 'Dismiss All',
        onClick: dismissAll,
        secondary: true,
      },
    ]
    : null;


  const baseUrl = '/coach';

  // Create two lists, one for upcoming chat type notifications, the other for everything else
  const upcomingChatNotifications = [];
  const otherNotifications = [];
  if (!!notDismissed && !!notDismissed.length) {
    for (let i = 0; i < notDismissed.length; i++) {
      const notification = notDismissed[i];
      const { type } = notification;
      if (upcomingChatNotificationTypes.indexOf(type) !== -1) {
        upcomingChatNotifications.push(notification);
      } else {
        otherNotifications.push(notification);
      }
    }
  }

  return (
      <Frame fullscreen classes="p-4">
        <Heading title="What's Happening" links={actionLinks} />
        <Loadable loading={loading}>
          <div className="px-8 py-10">
          <Panel>
            <h3 className="pt-6 px-8 text-xl font-bold leading-7 text-primary-500 sm:text-2xl sm:truncate">Upcoming Chat Notifications</h3>
            <AdminTable alternate results={{ data: upcomingChatNotifications }} columns={columns} baseUrl={baseUrl} rowFormatter={formatRow} emptyMessage="You're all caught up!" />
          </Panel>
          </div>

          <div className="px-8 py-10">
          <Panel>
            <h3 className="pt-6 px-8 text-xl font-bold leading-7 text-primary-500 sm:text-2xl sm:truncate">All Other Notifications</h3>
            <AdminTable alternate results={{ data: otherNotifications }} columns={columns} baseUrl={baseUrl} rowFormatter={formatRow} emptyMessage="You're all caught up!" />
          </Panel>
          </div>
        </Loadable>
      </Frame>
  );
}