import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import Avatar from '../../components/common/avatar';


export default function ConversationList({ conversations, activeId }) {
  const navigate = useNavigate();

  return (
    <div className="divide-y divide-gray-200">
      {conversations.map(convo => {
        const { profile, updatedAt, lastMessageTime, coachUnread, preview } = convo;
        const { name, photo } = profile;
        const isActive = activeId === convo.id;
        const messageSent = lastMessageTime ? moment.unix(lastMessageTime.seconds).format('M/DD/YY h:mm a') : moment.unix(updatedAt.seconds).format('M/DD/YY h:mm a');
        return (
          <div className={`${isActive ? 'bg-gray-100' : 'bg-white'} grid grid-cols-6 py-4 cursor-pointer hover:bg-gray-100`} onClick={() => !isActive && navigate(`/coach/chat/${convo.id}`)} key={convo.id}>
            {/* <div className="col-span-1 pt-2.5 pl-5">
              {!!coachUnread && <span className={`inline-block w-3.5 h-3.5 rounded-full ring-2 ring-white bg-red-500`} />}
            </div> */}
            <div className="col-span-1 justify-items-center">
              <Avatar photo={photo} name={name} bg="bg-secondary-500" withBubble={!!coachUnread} />
            </div>
            <div className="col-span-5">
              <p className="text-lg text-primary-500">{name} <span className="text-xs text-gray-400">{messageSent}</span></p>
              {!!preview && <p className="text-sm pr-1 pt-1 text-gray-400">{preview}</p>}
              
            </div>
          </div>
        )
      })}
    </div>
  );
}
