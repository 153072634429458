import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { collection, query, where, orderBy, limit, serverTimestamp, addDoc, getDocs, onSnapshot, startAfter, setDoc, doc } from 'firebase/firestore';
import { firestore } from '../../config/firebase';
import { useAuth } from '../../contexts/auth-provider';
import Message from './message';
import MessageForm from './message-form';
import ImageModal from './image-modal';

export default function MessagePane({ id, conversation }) {
  const [messages, setMessages] = useState(null);
  const [canPaginate, setCanPaginate] = useState(true);
  const [imageModal, setImageModal] = useState({ image: '', open: false });
  const messagesRef = collection(firestore, 'messages');
  const { user } = useAuth();
  const endRef = useRef();
  const startNextBatchRef = useRef();
  const paginateTotal = 30;

  const scrollToBottom = (animate = true) => {
    if (animate) {
      endRef.current.scrollIntoView({ behavior: 'smooth' });
      return;
    }
    endRef.current.scrollIntoView();
  };

  const keepPosition = () => {
    startNextBatchRef.current.scrollIntoView({ block: 'center' });
  };

  const paginateMessage = (lastMessage = '') => query(messagesRef, where('conversationId', '==', id), orderBy('createdAt', 'desc'), startAfter(lastMessage), limit(paginateTotal));


  const updateConversation = async (conversationId) => {
    // Mark this conversation as having been read by the coach
    const docRef = doc(firestore, 'conversations', conversationId);
    await setDoc(docRef, {
      coachUnread: false,
    }, { merge: true });
  };

  useEffect(() => {
    if (id) {
      let firstForId = true;
      const q = paginateMessage();
      const unsub = onSnapshot(q, (snapshot) => {
        const newMessages = [];
        snapshot.forEach((doc) => {
          newMessages.unshift({
            ...doc.data(),
            id: doc.id,
            doc: doc,
          });
        });
        setMessages(newMessages);
        scrollToBottom(!firstForId);
        firstForId = false;
      });
      updateConversation(id);

      return () => unsub();
    }
  }, [id]);


  const loadMoreMessages = async () => {
    const q = paginateMessage(messages[0].doc);
    const querySnapshot = await getDocs(q);
    const previousMessages = messages.map((msg) => {
      return { ...msg, old: false };
    });

    if (querySnapshot.empty || querySnapshot.docs.length < paginateTotal) {
      setCanPaginate(false);
      return;
    }

    querySnapshot.forEach((snap) => {
      previousMessages.unshift({
        ...snap.data(),
        id: snap.id,
        old: true,
        doc: snap,
      });
    });
    setMessages(previousMessages);
    keepPosition();
  };

  const sendMessage = async (values, type = 'text') => {
    const content = { type };
    switch (type) {
      case 'text':
        if (!values.text) {
          return;
        }
        content.text = values.text;
        break;

      case 'image':
        if (!values.image) {
          return;
        }
        content.image = values.image;
        break;
        default:
    }
    await addDoc(messagesRef, {
      conversationId: id,
      uid: user.uid,
      createdAt: serverTimestamp(),
      content: content,
      readAt: null,
      read: false,
    });
    scrollToBottom();
    setCanPaginate(true);
  };

  const markAsRead = async (id) => {
    const docRef = doc(firestore, 'messages', id);
    await setDoc(docRef, {
      read: true,
      readAt: serverTimestamp(),
    }, { merge: true });
  };

  const closeModal = () => {
    setImageModal({ ...imageModal, open: false });
  };

  const openModal = (image) => {
    setImageModal({ image, open: true });
  };

  if (!id || !conversation) {
    return <p className="p-8 text-center text-gray-600">Select a conversation to chat.</p>;
  }

  const { profile, memberUid } = conversation;
  const { name, pronouns } = profile;
  const hasMessages = !!messages && !!messages.length;
  const mostRecentMessages = hasMessages ? messages.filter((msg) => !msg.old) : [];
  const oldMessages = hasMessages ? messages.filter((msg) => msg.old) : [];
  const showPaginateButton = hasMessages && messages.length >= paginateTotal && canPaginate;
  
  return (
    <div className="">
      <div className="fixed h-16 w-full bg-white border-b border-gray-300">
        <div className="px-6 xl:px-8 pt-4">
          <h3 className="text-xl font-semibold text-primary-500">
            <Link to={`/coach/member/${memberUid}/recent-journey`}>{name}</Link> {!!pronouns && <span className="text-base font-medium text-gray-400 ml-8">{pronouns}</span>}
          </h3>
        </div>
      </div>
      <div className="pt-48 pb-24">
        <div className="px-6 xl:px-8 space-y-5">
          <ImageModal imageModal={imageModal} open={openModal} closeModal={closeModal} />

          {showPaginateButton && (
            <div className="flex justify-center">
              <button
                type="button"
                title="Edit Homework"
                onClick={loadMoreMessages}
                className="inline-flex items-center p-1 ml-2 border border-transparent rounded-full shadow-sm text-secondary-500 bg-secondary-100 hover:bg-secondary-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary-100 px-2"
              >
                Load More Messages
              </button>
            </div>
          )}
          {!showPaginateButton && <p className="text-center text-gray-400">All Messages Loaded</p>}

          {!hasMessages && <p className="text-gray-600 p-8 text-center">Get the conversation started.</p>}
          {oldMessages.map((message) => (
            <Message key={message.id} fromMe={message.uid === user.uid} message={message} openModal={openModal} markAsRead={markAsRead} />
          ))}
          <div ref={startNextBatchRef} className="w-full h-1" />
          {mostRecentMessages.map((message) => (
            <Message key={message.id} fromMe={message.uid === user.uid} message={message} openModal={openModal} markAsRead={markAsRead} />
          ))}
          <div ref={endRef} className="w-full h-1" />
        </div>
      </div>
      <div className="fixed bottom-0 w-full h-24 bg-white border-t border-gray-300">
        <MessageForm sendMessage={sendMessage} />
      </div>
    </div>
  );
}
