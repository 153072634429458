import { useState, createContext, useContext, useEffect } from 'react';
import { collection, query, where, orderBy, limit, onSnapshot, getDoc, doc } from 'firebase/firestore';
import { firestore } from '../config/firebase';
import { useAuth } from './auth-provider';

const ConversationsContext = createContext({
  conversations: [],
  loadingConversations: false,
  getConversation: (id, conversations) => null,
  hasUnread: false,
});


export function ProvideConversations({ children }) {
  const [loading, setLoading] = useState(true);
  const [conversations, setConversations] = useState(null);
  const [memberProfiles, setMemberProfiles] = useState({});
  const [hasUnread, setHasUnread] = useState(false);
  const { user } = useAuth();
  const conversationsRef = collection(firestore, 'conversations');

  const uid = user ? user.uid : null;

  const getConversation = (id, conversations) => {
    if (!conversations || !conversations.length || !id) {
      return null;
    }
    for (let i = 0; i < conversations.length; i++) {
      if (conversations[i].id === id) {
        return conversations[i];
      }
    }
    return null;
  };

  const getMemberProfile = async (memberUid) => {
    if (!memberUid) {
      return null;
    }
    // First check our local cache
    if (memberProfiles[memberUid]) {
      return memberProfiles[memberUid];
    }
    // Let's load the profile from firestore
    const profileSnap = await getDoc(doc(firestore, 'profiles', memberUid));
    
    if (profileSnap.exists()) {
      // Fow now this is enough info, but we can always add more
      const { name, photo, pronouns } = profileSnap.data();
      const member = {
        id: profileSnap.id,
        name,
        photo,
        pronouns,
      };
      const newMemberProfiles = {...memberProfiles};
      newMemberProfiles[memberUid] = member;
      setMemberProfiles(newMemberProfiles);
      return member;
    }
    return {};
  };


  useEffect(() => {
    if (!uid) {
      setConversations(null);
      setHasUnread(false);
      return;
    }
    const q = query(conversationsRef, where('coachUid', '==', uid), orderBy('lastMessageTime', 'desc'), limit(1000));
    const unsub = onSnapshot(q, async (snapshot) => {
      let newHasUnread = false;
      const newConversations = [];
      const items = [];
      snapshot.forEach(doc => {
        items.push({
          ...doc.data(),
          id: doc.id,
        });
      });
      if (!!items && items.length) {
        for (let i = 0; i < items.length; i++) {
          if (items[i].coachUnread) {
            newHasUnread = true;
          }
          const profile = await getMemberProfile(items[i].memberUid);
          newConversations.push({
            ...items[i],
            profile,
          });
        }
      }
      setLoading(false);
      setConversations(newConversations);
      setHasUnread(newHasUnread);
    });

    return () => unsub();
  }, [uid]);


  const state = {
    hasUnread,
    loadingConversations: loading,
    conversations,
    getConversation,
  };

  return <ConversationsContext.Provider value={state}>{children}</ConversationsContext.Provider>;
}

export const useConversations = () => {
  return useContext(ConversationsContext);
};
