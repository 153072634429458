export default function IconButton({ Icon, onClick, color = 'blue', size = 'md' }) {

  let colorClasses = '';
  switch (color) {
    case 'blue':
      colorClasses = 'bg-blue-600 text-white hover:bg-blue-500 focus-visible:outline-blue-600';
    break;
    case 'red':
      colorClasses = 'bg-red-600 text-white hover:bg-red-500 focus-visible:outline-red-600';
    break;
    case 'green':
      colorClasses = 'bg-green-600 text-white hover:bg-green-500 focus-visible:outline-green-600';
    break;
    case 'orange':
      colorClasses = 'bg-orange-600 text-white hover:bg-orange-500 focus-visible:outline-orange-600';
    break;
    default:
      colorClasses = 'bg-indigo-600 text-white hover:bg-indigo-500 focus-visible:outline-indigo-600';
    break;
  }

  let sizeClasses = '';
  let iconSize = '';
  switch (size) {
    case 'sm':
      sizeClasses = 'p-1';
      iconSize = 'h-4 w-4';
    break;
    case 'lg':
      sizeClasses = 'p-2';
      iconSize = 'h-5 w-5';
    break;
    default:
      sizeClasses = 'p-1.5';
      iconSize = 'h-5 w-5';
    break;
  }

  return (
    <button
      type="button"
      onClick={onClick}
      className={`${colorClasses} ${sizeClasses} rounded-full shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2`}
    >
      <Icon className={iconSize} />
    </button>
  );

  // return (
  //   <>
  //     <button
  //       type="button"
  //       className="rounded-full bg-indigo-600 p-1 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
  //     >
  //       <PlusIcon className="h-5 w-5" aria-hidden="true" />
  //     </button>
  //     <button
  //       type="button"
  //       className="rounded-full bg-indigo-600 p-1.5 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
  //     >
  //       <PlusIcon className="h-5 w-5" aria-hidden="true" />
  //     </button>
  //     <button
  //       type="button"
  //       className="rounded-full bg-indigo-600 p-2 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
  //     >
  //       <PlusIcon className="h-5 w-5" aria-hidden="true" />
  //     </button>
  //   </>
  // )
}
