export default function DataRow({ label, value, first }) {
  const rowClasses = first ? 'sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5' : 'mt-8 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5';

  return (
    <div className={rowClasses}>
      <dt className="text-sm leading-5 font-medium text-gray-500">
        {label}
      </dt>
      <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
        {value}
      </dd>
    </div>
  );
}
