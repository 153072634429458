import { AdjustmentsIcon } from '@heroicons/react/outline';
import { FiSliders } from 'react-icons/fi';
import { useEffect, useState } from 'react';
import { getRadarData, makeSureHttps, getRadarDateList } from '../../../../utils/helpers';
import PerspectiveMeter from '../../../charts/perspective-meter';
import RightSlideOver from '../../../overlays/right-slide-over';
import moment from 'moment';

const ThemeProgressCreative = ({ journeyData, goalId }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isComparisonClicked, setIsComparisonClicked] = useState(false);
  const [selectedPoint, setSelectedPoint] = useState('');
  const [selectedScore, setSelectedScore] = useState(0);
  const [average, setAverage] = useState('0');

  const [tempDate1, setTempDate1] = useState('');
  const [tempDate2, setTempDate2] = useState('');

  const [filterDate1, setFilterDate1] = useState('');
  const [filterDate2, setFilterDate2] = useState('');
  const [isFilterClicked, setIsFilterClicked] = useState(false);

  // New ported stuff
  const [allData, setAllData] = useState(null);
  const [combinedData, setCombinedData] = useState(null);
  const [goalIcons, setGoalIcons] = useState(null);
  const [goalMeta, setGoalMeta] = useState(null);
  const [filterDateList, setFilterDateList] = useState([]);
  const [init, setInit] = useState(false);

  useEffect(() => {
    if (!journeyData || !journeyData.length) {
      return;
    }


    // Assemble all of our mood data
    const newAllData = [];
    // Loop over journeys with data
    for (let i = 0; i < journeyData.length; i++) {
      const d = journeyData[i];
      // Goal
      if (d && d.goalSeries && d.goalSeries.length) {
        newAllData.push({
          _id: d._id,
          toolkitName: d.toolkitName,
          toolkitId: d.toolkitId,
          startTimestamp: d.createdAt._seconds,
          endTimestamp: d.toolkitComplete ? d.updatedAt._seconds : null,
          data: d.goalSeries,
        });
      }
    }

    setAllData(newAllData);
    // console.log(journeyData, 'this is journey data');
  }, [journeyData]);

  useEffect(() => {
    if (!allData || !allData.length || !journeyData || !journeyData.length) {
      setCombinedData(null);
      return;
    }
    const newCombinedData = [];
    const newGoalIcons = {};
    const newGoalMeta = {};
    for (let i = 0; i < allData.length; i++) {
      const d = allData[i];
      for (let j = 0; j < d.data.length; j++) {
        // Transform our data to match the format that the functions expect...
        const toTransform = d.data[j];
        const { goals, isoTime } = toTransform;
        const date = moment(isoTime).format('MM/DD/YYYY HH:mm:ss');
        // Just going to use their data shape
        const formattedGoals = {};
        if (goals && goals.length) {
          goals.forEach(g => {
            const { icon, _id, name, score, shortTitle } = g;
            formattedGoals[_id] = score;
            // Build our icons map
            if (!newGoalIcons[_id] && !!icon && !!icon.url) {
              newGoalIcons[_id] = makeSureHttps(icon.url);
            }
            // Build our meta map
            if (!newGoalMeta[_id]) {
              newGoalMeta[_id] = {
                name,
                shortTitle,
              };
            }
          });
        }
        newCombinedData.push({
          date,
          goals: [formattedGoals],
        });
      }
    }
    setGoalMeta(newGoalMeta);
    setGoalIcons(newGoalIcons);
    setCombinedData(newCombinedData);
    setFilterDateList(getRadarDateList(newCombinedData));

    setInit(true);
  }, [allData, journeyData]);

  useEffect(() => {
    if (!filterDateList || !filterDateList.length) {
      return;
    }
    setFilterDate1(filterDateList[0]);
    setFilterDate2(filterDateList[0]);
    setTempDate1(filterDateList[0]);
    setTempDate2(filterDateList[0]);
  }, [filterDateList]);


  const dateLabel = (label, color) => {
    return (
      <div className="flex flex-row items-center justify-center md:my-0 my-2">
        <p className="text-white rounded-full px-4 py-1 font-normal text-[13px] md:mx-2 mx-0" style={{ backgroundColor: color }}>
          {label}
        </p>
      </div>
    );
  };

  if (!init) {
    return null;
  }

  return (
    <div>
      <div className="flex flex-row justify-between items-start">
        <div className="w-max relative pl-4">
          <div className="rounded-full md:w-17 md:h-17 w-16 h-16 border-[1px] border-black p-3 relative">
            <div
              className="rounded-full md:w-[3.5rem] md:h-[3.5rem] w-[3.5rem] h-[3.5rem] absolute md:top-1/2 md:left-1/2 top-[1.95rem] left-[1.95rem] -translate-x-1/2 -translate-y-1/2 flex items-center justify-center backdrop-blur-md"
              style={{ background: 'radial-gradient(circle farthest-corner at center center, #3D3380 40%, #807F7E 100%)', filter: 'blur(1px)' }}
            ></div>
          </div>
          <p className="absolute md:top-4.5 top-4 left-8 md:text-[20px] text-[23px] font-bold text-white">{average}</p>
          <p className="text-center">avg score</p>
        </div>
        <div className="flex flex-row items-center pt-2">
          <div className="flex md:flex-row flex-col items-center justify-center order-1 mr-1 md:mr-0">
            {filterDate2 !== '' && filterDate1 !== filterDate2 ? dateLabel(filterDate2, '#3D3380') : <div />}
            {filterDate1 !== '' ? dateLabel(filterDate1, '#A38DF2') : <div />}
          </div>
          <div className="bg-black p-2 w-max ml-auto rounded-full cursor-pointer order-1 md:order-2" onClick={() => setIsDrawerOpen(true)}>
            <FiSliders className="h-4 w-4 text-white" />
          </div>
        </div>
      </div>
      <div className="flex flex-col items-center justify-between -mt-12">
        {!!filterDate1 && !!filterDate2 && (
          <>
            <PerspectiveMeter
              filterDate1={filterDate1}
              filterDate2={filterDate2}
              closeDrawer={() => {
                setIsDrawerOpen(false);
                setIsFilterClicked(false);
              }}
              average={average}
              setAverage={setAverage}
              isFilterClicked={isFilterClicked}
              selectedPoint={selectedPoint}
              setSelectedPoint={setSelectedPoint}
              setSelectedScore={setSelectedScore}
              combinedData={combinedData}
              goalId={goalId}
              goalMeta={goalMeta}
            />
            <div className="bg-white p-2 ml-2 lg:ml-8 -mb-0 lg:-mb-8 rounded-lg w-[75%] items-center justify-center">
              <p className="text-black mr-1 text-center">
                {goalMeta && goalMeta[selectedPoint] ? `${goalMeta[selectedPoint].name}` : ''}
              </p>
              <p className="text-[#A38DF2] text-center">{selectedScore ? `Score ${selectedScore.toFixed(1)}` : ``}</p>
              
            </div>
          </>
        )}
      </div>
      <RightSlideOver isOpen={isDrawerOpen} close={() => setIsDrawerOpen(false)} title="Filters" darkBg isNew>
        <p className="font-[500] mb-10 text-[20px]">Creative Perspective</p>
        <p className="text-[#807F7E] mt-10">Select a time period</p>
        <select
          className="bg-[#C5C4C2] w-full rounded-lg border-0 mt-2"
          onChange={(e) => {
            if (!isComparisonClicked) {
              setTempDate1(e.target.value);
              setTempDate2(e.target.value);
            } else {
              setTempDate1(e.target.value);
            }
          }}
          value={tempDate1}
        >
          {filterDateList && filterDateList.map((opt, i) => {
            const displayAs = moment(opt, 'MM/DD/YY').format('MM/DD/YYYY');
            return (
              <option key={`${opt}_${i}`} value={opt}>{displayAs}</option>
            );
          })}
        </select>
        <p
          className={`${isComparisonClicked ? 'text-[#807F7E]' : 'text-[#A38DF2]'} ${isComparisonClicked ? 'no-underline' : 'underline'} text-center my-4 cursor-pointer`}
          onClick={() => setIsComparisonClicked(true)}
        >
          {isComparisonClicked ? 'compare to:' : '+comparison'}
        </p>
        {isComparisonClicked && (
          <div>
            <select className="bg-[#C5C4C2] w-full rounded-lg border-0" onChange={(e) => setTempDate2(e.target.value)} value={tempDate2}>
              {filterDateList && filterDateList.map((opt, i) => {
                const displayAs = moment(opt, 'MM/DD/YY').format('MM/DD/YYYY');
                return (
                  <option key={`${opt}_${i}`} value={opt}>{displayAs}</option>
                );
              })}
            </select>
            <p
              className="text-[#BF41AA] underline my-4 text-center cursor-pointer"
              onClick={() => {
                setTempDate2(filterDate1);
                setIsComparisonClicked(false);
              }}
            >
              remove
            </p>
          </div>
        )}
        <div className="flex justify-center items-center mt-8">
          <button
            className="bg-[#342F3B] px-4 py-2 rounded-md text-white w-[40%]"
            onClick={() => {
              setFilterDate1(tempDate1);
              setFilterDate2(tempDate2);
              setIsFilterClicked(true);
            }}
          >
            got it
          </button>
        </div>
      </RightSlideOver>
    </div>
  );
};

export default ThemeProgressCreative;
