
export default function ItemDisplay({ item }) {

  const { toolkitName, title, type, cardImage, phase, week, thumbnailImage, videoData, images, subtitle, shortDescription, internal_content_type: internalType } = item;
  const imageSrc = !!cardImage?.url ? cardImage.url : !!thumbnailImage?.url ? thumbnailImage.url : !!images?.length ? images[0].url : !!videoData?.image ? videoData.image : null;

  const subtext = subtitle || shortDescription;

  let niceType = 'Explore';
  if (internalType === 'activities2') {
    niceType = 'Thought';
  } else if (internalType === 'exercises') {
    niceType = 'Exercise';
  }


  return (
    <>
      <div className="mr-4 flex-shrink-0 self-center">
        <img alt={title} className="h-24 w-24 object-cover rounded-md border border-gray-300 bg-white text-gray-300" src={`${imageSrc}?w=160`} />
      </div>
      <div>
        <h4 className="text-lg font-bold">{title}</h4>
        <h5 className="text-sm text-gray-600">{niceType}
          {!!toolkitName && <> &bull; {toolkitName}</>}
          {!!type && <> &bull; {type}</>}
          {!!phase && <> &bull; Phase {phase}</>}
          {!!week && <> &bull; Part {week}</>}</h5>
        <p className="mt-1 text-gray-700 text-">
          {subtext}
        </p>
      </div>
    </>
  );
}
