import DataWidget from './data-widget';


export default function GoalScore({ goalName, goalMetric, goalRecentAvg, goalScore }) {


  const goalScoreToDisplay = goalScore ? goalScore.toFixed(1).replace('.0', '') : '--';
  const goalRecentAvgToDisplay = goalRecentAvg ? goalRecentAvg.toFixed(1).replace('.0', '') : '--';


  return (
    <DataWidget title="Goal Score" description={goalMetric?.short}>
      <div className="flex mb-2 mt-4">
        <div className="text-center relative">
          <div className="rounded-full w-14 h-14 bg-gradient-to-b from-secondary-300 to-primary-300 outline-2 outline-darkblue outline border-white border-2">
            <p className="text-center pt-2 font-bold text-darkblue" style={{ fontSize: '24px' }}>{goalScoreToDisplay}</p>
          </div>
        </div>
        <div className="text-left pl-4 pt-1">
            <p className="text-primary-500 font-bold text-sm leading-4 mb-1">{goalName}</p>
            <p className="text-darkblue font-semibold text-xs">Recent average: {goalRecentAvgToDisplay}</p>
        </div>
      </div>
    </DataWidget>
        
  );
}

