import React from 'react';
import { useFormik } from 'formik';
import Field from './field';

/**
 * 
 * Example form config:
 * 
 * const testForm = {
  initialValues: {
    // email: 's@so.com',
    // fname: 'Steve Test',
    // role: '1',
    // lname: 'Test Last',
    // phone: '(301) 404-4875',
    // password: 'this is a password',
    // comments: 'testing default value',
    // terms: true,
    // birthday: null,
    // preferences: ['catering'],
    // img: 'http://funbowlingleagues.test/storage/uploads/images/elZHA3FiFRnDM1vBGIACcLSwDRB3mLDpA8SGyj0z.png',
  },
  validationSchema: Yup.object({
    // email: Yup.string().email('Invalid email address.').required('Email is a required field.'),
    // password: Yup.string().min(6, 'Must be at least 6 characters.').required('Password is a required field.'),
    // fname: Yup.string().required('First name is a required field.'),
    // role: Yup.mixed().required('Role is a required field.'),
    // preferences: Yup.array().min(1, 'You must select at least one option.'),
    // comments: Yup.string().required('You must provide some comments.'),
    // birthday: Yup.date().typeError('Must be a valid date.'), // is required by default, unless adding .nullable()
    // terms: Yup.boolean().oneOf([true], 'You must agree to the terms to continue.'),
    // img: Yup.string().required('You must upload an image.'),
    // phone: Yup.string()
    //   .test(
    //     'valid-phone',
    //     'Must be a valid phone number.',
    //     (val) => val === '' || typeof val === 'undefined' || validatePhone(val)
    //   )
    //   .required('Phone number is a required field.'),
    // lname: Yup.string().required('Last name is a required field.'),
  }),
  onSubmit: async (values, actions) => {},
  // fields: [
  //   {
  //     id: 'fname',
  //     name: 'fname',
  //     label: 'First name',
  //     type: 'text',
  //   },
  //   {
  //     name: 'lname',
  //     label: 'Last name',
  //     type: 'text',
  //   },
  //   {
  //     name: 'comments',
  //     label: 'Comments',
  //     type: 'textarea',
  //     placeholder: 'Provide some comments please...',
  //   },
  //   {
  //     name: 'role',
  //     label: 'Role',
  //     type: 'select',
  //     optionsUrl: '/options/roles',
  //   },
  //   {
  //     name: 'preferences',
  //     label: 'Preferences',
  //     type: 'checkboxes',
  //     options: [
  //       {
  //         value: 'warp',
  //         label: 'Warp Speed',
  //       },
  //       {
  //         value: 'catering',
  //         label: 'Catering',
  //         subtitle: 'Vegetarian options available.',
  //       },
  //       {
  //         value: 'solitude',
  //         label: 'Solitude',
  //       },
  //     ],
  //   },
  //   {
  //     id: 'phone',
  //     name: 'phone',
  //     label: 'Phone',
  //     type: 'phone',
  //     helpText: 'Must be a mobile phone.',
  //   },
  //   {
  //     id: 'email',
  //     name: 'email',
  //     label: 'Email',
  //     type: 'email',
  //     helpText: 'You will use this to login.',
  //   },
  //   {
  //     name: 'birthday',
  //     label: 'Birthday',
  //     type: 'date',
  //     helpText: 'Add your birthday so we can verify your age.',
  //   },
  //   {
  //     name: 'password',
  //     label: 'Password',
  //     placeholder: 'Enter a strong password.',
  //     type: 'password',
  //     helpText: 'Must be at least 6 characters.',
  //   },
  //   {
  //     name: 'terms',
  //     // label: 'HIDE ME',
  //     type: 'checkbox',
  //     title: 'I agree to the terms',
  //     subtitle: <a href="/">View complete terms &raquo;</a>,
  //     // helpText: 'You must accept the terms to continue',
  //   },
  //   {
  //     name: 'img',
  //     type: 'file',
  //     label: 'Cover photo',
  //     maxFiles: 1,
  //     isImage: true,
  //     helpText: 'Cover photo for the league.',
  //   }
  //   {
  //     name: 'img',
  //     type: 'cloudinary-upload',
  //     label: 'Cover photo',
  //     maxFiles: 1,
  //     isImage: true,
  //     helpText: 'Cover photo for the league.',
  //   }
  //   {
  //     name: 'images',
  //     type: 'cloudinary-multiple-upload',
  //     label: 'Cover photo',
  //     maxFiles: 5,
  //     isImage: true,
  //     helpText: 'Cover photo for the league.',
  //   }
  // ],
  submit: {
    label: 'Create User',
  },
};
 */

function Form({ initialValues, validationSchema, onSubmit, fields, submit, noContainer, bg = 'bg-white', sideBySide = false }) {
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  const { label: btnText } = submit;

  const btnClasses = formik.isSubmitting ? 'opacity-60 cursor-not-allowed' : '';


  if (sideBySide) {
    return (
      <form className="" onSubmit={formik.handleSubmit}>
        {fields.map((field) => {
          return <Field field={field} formik={formik} key={field.name} sideBySide />;
        })}
        <button
          type="submit"
          disabled={formik.isSubmitting}
          className={`${btnClasses} bg-[#A38DF2] text-[20px] p-3 rounded-lg text-white w-[30%] my-10 shadow-lg hover:opacity-70`}
        >
          {btnText}
        </button>
      </form>
    );
  }


  return (
    <form className="" onSubmit={formik.handleSubmit}>
      <div className={`${noContainer ? '' : 'shadow sm:rounded-md sm:overflow-hidden'}`}>
        <div className={`${bg} py-6 px-4 space-y-6 ${noContainer ? '' : 'sm:p-6 border border-gray-100'}`}>
          {fields.map((field) => {
            return <Field field={field} formik={formik} key={field.name} />;
          })}
        </div>
        <div className={`text-center py-3 ${noContainer ? '' : 'px-4 bg-gray-50 sm:px-6'}`}>
          <button
            type="submit"
            disabled={formik.isSubmitting}
            className={`${btnClasses} border border-transparent bg-gray-800 rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2`}
          >
            {btnText}
          </button>
        </div>
      </div>
    </form>
  );
}

export default Form;
