import React, { useEffect, useState } from 'react';
import Bugsnag from '@bugsnag/browser';
import { useParams, useNavigate } from 'react-router-dom';
import { useBreadcrumbs } from '../../contexts/breadcrumbs-provider';
import { useNotifications } from '../../contexts/notifications-provider';
import Heading from '../../components/layout/admin/heading';
import AutoForm from '../../components/form/auto-form';
import Frame from '../../components/layout/frame';
import { getApiRequest } from '../../utils/request';
import Loadable from '../../components/data/loadable';
import Panel from '../../components/common/panel';
import DataRow from '../../components/data/data-list-row';

const AdminToolkitAssignCoach = () => {
  const [loading, setLoading] = useState(true);
  const [toolkitAssignment, setToolkitAssignment] = useState(null);
  const navigate = useNavigate();
  const { addNotification } = useNotifications();
  const { setBreadcrumbs } = useBreadcrumbs();
  const { id } = useParams();

  useEffect(() => {
    setBreadcrumbs([
      {
        href: '/admin/toolkit-assignments',
        label: 'Toolkit Assignments',
      },
      {
        href: `/admin/toolkit-assignment/${id}/assign-coach`,
        label: `Assign Coach`,
      },
    ]);
    const init = async () => {
      try {
        const result = await getApiRequest(`/toolkit-assignment/${id}`);
        setToolkitAssignment(result.doc);
      } catch (err) {
        Bugsnag.notify(err);
        addNotification({
          type: 'error',
          body: 'There was an error loading the requested toolkit assignment.',
        });
        navigate('/admin/toolkit-assignments');
      }
      setLoading(false);
    };
    init();
  }, [id]);


  const coachAssignForm = {
    type: 'post',
    postRoute: `/toolkit-assignment/${id}/assign-coach`,
    successRedirect: '/admin/toolkit-assignments',
    successNotification: 'The coach was assigned!',
    fields: [
      {
        name: 'coachUid',
        label: 'Choose A Coach',
        type: 'select',
        required: true,
        optionsUrl: '/options/coaches',
        helpText: 'This is the coach that will be assigned to the member associated with this toolkit.',
      },
    ],
    submitLabel: `Assign Coach`,
  };

  const { toolkitName, email, userName } = toolkitAssignment || {};
  const formattedData = { toolkitName, email, userName };

  const rows = [
    {
      field: 'toolkitName',
      label: 'Toolkit',
    },
    {
      field: 'userName',
      label: 'Member Name',
    },
    {
      field: 'email',
      label: 'Member Email',
    },
  ];

  return (
    <Frame>
      <Heading title={`Assign Coach`} />
      <Loadable loading={loading}>
        <div className="max-w-2xl mx-auto py-12">
          <div className="p-8">
            <Panel>
              <div className="px-4 py-5 sm:p-0">
                <dl>
                  {rows.map((item, i) => {
                    const value = formattedData[item.field];
                    return <DataRow key={item.field} label={item.label} value={value} first={i === 0} />
                  })}
                </dl>
              </div>
            </Panel>
          </div>
          <AutoForm {...coachAssignForm} />
        </div>
      </Loadable>
    </Frame>
  );
}

export default AdminToolkitAssignCoach;
