import { useState, useEffect } from 'react';
import Bugsnag from '@bugsnag/browser';
import { Switch } from '@headlessui/react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { DocumentTextIcon, CheckIcon } from '@heroicons/react/solid';
import moment from 'moment';
import Avatar from '../../../components/common/avatar';
import { getApiRequest, postApiRequest } from '../../../utils/request';
import { getCurrentWeekForJourney, timezonePrettyName, daysOfWeekOptions, weekOptions, formatUtcUnixDate, classNames } from '../../../utils/helpers';
import Loadable from '../../../components/data/loadable';
import Frame from '../../../components/layout/frame';
import MemberHeader from '../../../components/layout/coach/member-header';
import MemberSection from '../../../components/layout/coach/member-section';
import MemberSetForm from '../../../components/layout/coach/member-set-form';
import Nl2br from '../../../components/common/nl2br';
import SimpleFeed from '../../../components/common/simple-feed';
import { useBreadcrumbs } from '../../../contexts/breadcrumbs-provider';
import EditButton from '../../../components/layout/coach/edit-button';
import JourneyControlsModal from '../../../components/coach/member/dashboard/journeyControlsModal';
import StatusControls from '../../../components/coach/member/dashboard/statusControls';


function Toggle ({ onClick, enabled }) {
  return (
    <Switch
      checked={enabled}
      onChange={onClick}
      className={classNames(
        enabled ? 'bg-primary-500' : 'bg-gray-200',
        'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-100'
      )}
    >
      <span className="sr-only">Toggle</span>
      <span
        aria-hidden="true"
        className={classNames(
          enabled ? 'translate-x-5' : 'translate-x-0',
          'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
        )}
      />
    </Switch>
  );
}

function ActivitySection({ data, journey }) {
  const [activities, setActivities] = useState(null);


  const hasActivities = !!activities && !!activities.length;
  const reversedActivities = hasActivities ? [...activities].reverse() : [];
  const series = !!data ? data.series : null;
  const { weeks } = journey;


  useEffect(() => {
    if (!series) {
      return;
    }
    // Build our timeline data from series
    const newActivities = [];
    series.forEach(d => {
      const { week, _id, updatedAt } = d;
      newActivities.push({
        id: _id,
        content: `Completed weekly survey for week ${week}`,
        date: formatUtcUnixDate(updatedAt._seconds),
        icon: DocumentTextIcon,
        iconBackground: 'bg-secondary-500',
        time: updatedAt._seconds,
      });
    });
    // Ok, check for any homework assignments done now
    weeks.forEach((d) => {
      const { practices } = d;
      if (practices && practices.length) {
        for (let i = 0; i < practices.length; i++) {
          const practice = practices[i];
          if (practice.complete && practice.completedAt) {
            const { _id, week, completedAt } = practice;
            newActivities.push({
              id: _id,
              content: `Completed personalized homework for week ${week}`,
              date: formatUtcUnixDate(completedAt._seconds),
              icon: CheckIcon,
              iconBackground: 'bg-green-600',
              time: completedAt._seconds,
            });
          }
        }
      }
    });

    setActivities(newActivities.sort((a, b) => a.time - b.time));
  }, [series]);
  


  return (
    <MemberSection hideBorder>
      <div className="mt-8">
        <h3 className="text-lg font-semibold text-primary-500">Activity History</h3>
      </div>
      <div className="py-8">
        {!hasActivities && <p className="text-md text-gray-500 mb-4 text-sm">There is no activity history yet.</p>}
        {hasActivities && <SimpleFeed timeline={reversedActivities} />}
      </div>
    </MemberSection>
  );
}



function PrivateNotesSection({ journeyId, currentWeek }) {
  const [notes, setNotes] = useState(null);
  const [loading, setLoading] = useState(true);
  const [createForm, setCreateForm] = useState(null);
  const [updateForm, setUpdateForm] = useState(null);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [editing, setEditing] = useState(null);

  const loadNotes = async () => {
    if (!loading) {
      setLoading(true);
    }
    try {
      const result = await getApiRequest(`/coach/journey/${journeyId}/private-notes`);
      setNotes(result.notes);
    } catch (err) {
      Bugsnag.notify(err);
      console.log(err, 'there was an error loading the summary');
    }
    setLoading(false);
  };

  useEffect(() => {
    if (journeyId) {
      loadNotes();
    }
  }, [journeyId]);

  const notesSetForm = {
    noContainer: true,
    bg: 'bg-cream',
    type: 'post',
    postRoute: `/coach/journey/${journeyId}/private-note`,
    successNotification: 'The private coach note was saved!',
    fields: [
      {
        name: 'description',
        label: 'Private Note',
        type: 'textarea',
        placeholder: 'Compose your private coach note here',
        required: true,
      },
      {
        name: 'week',
        label: 'Week',
        type: 'select',
        options: weekOptions(),
        initialValue: (entity) => {
          return entity ? `${entity.week}` : `${currentWeek}`;
        },
        required: true,
      },
      {
        name: 'date',
        label: 'Date',
        type: 'date',
        initialValue: (entity) => {
          return entity ? formatUtcUnixDate(entity.date._seconds, 'MM/DD/Y') : moment().format('MM/DD/Y');
        },
        required: true,
      },
    ],
    submitLabel: 'Save',
  };

  useEffect(() => {
    // Set the create form, it's constant
    setCreateForm(notesSetForm);
  }, []);

  useEffect(() => {
    // Whenever we change the item we are editing, update the edit form
    if (editing) {
      const newUpdateForm = {
        ...notesSetForm,
      };
      newUpdateForm.fetchRoute = `/coach/private-note/${editing}`;
      newUpdateForm.postRoute = `/coach/private-note/${editing}`;
      setUpdateForm(newUpdateForm);
      setShowUpdateForm(true);
    }
  }, [editing]);


  const hideUpdateForm = () => {
    setShowUpdateForm(false);
    setEditing(null);
  };

  const hasNotes = !!notes && !!notes.length;

  return (
    <MemberSection hideBorder>
      <Loadable loading={loading}>
        <div className="grid grid-cols-5 mt-8">
          <div className="col-span-4">
            <h3 className="text-lg font-semibold text-primary-500">Private Coach Notes <span className="text-sm ml-4 text-gray-500 font-normal">These notes are not public to the member.</span></h3>
          </div>
          <div className="col-span-1 items-end justify-end text-right">
            <MemberSetForm
              form={createForm}
              reload={loadNotes}
              title="Compose a Private Coach Note"
              body="Saving this note will save in Private Coach Notes, and will not be visible to the member. Press ESCAPE or click away to cancel this action."
              buttonText="Compose"
            />
          </div>
        </div>
        <div className="space-y-3 divide-y divide-gray-300">
          {!hasNotes && <p className="text-md text-gray-500 my-4 text-sm">There are no private coach notes yet.</p>}
          {hasNotes && notes.map(note => {
            const { description, week, date, _id } = note;
            return (
              <div key={_id} className="pb-2 pt-4">
                <h4 className="font-semibold text-primary-500 mb-3">{`Week ${week}`} <EditButton onClick={() => setEditing(_id)} /></h4>
                <p className="text-gray-500 text-sm"><Nl2br str={description} /></p>
                <p className="text-gray-400 text-sm mt-1 text-right">{formatUtcUnixDate(date._seconds)}</p>
              </div>
            )
          })}

        <MemberSetForm
          form={updateForm}
          reload={loadNotes}
          title="Update Private Note"
          body="Saving this note will save in Private Coach Notes, and will not be visible to the member. Press ESCAPE or click away to cancel this action."
          hideButton
          isOpen={showUpdateForm}
          externalClose={hideUpdateForm}
        />
        </div>
      </Loadable>
    </MemberSection>
  );
}



function EngagmentSection({ profile, data, journey, reload, journeyId }) {

  const { photo, name, pronouns, timezone } = profile;
  const { goal, checkinDay, checkinTime, startDate, lastMessageTime, toolkitName, complete, uid, completedAt, skipUpcomingChatNotifications } = journey;
  const { goalAverage } = data || {};
  const { weeks: currentWeek, days: currentDay } = getCurrentWeekForJourney(journey);

  const hasCheckinSet = !!checkinTime && !!checkinDay;
  const hasGoalSet = !!goal;
  const hasStartDate = !!startDate;

  const completedAtTime = completedAt ?  moment.unix(completedAt._seconds).format('lll') : null;

  const lastMessageSent = lastMessageTime ? moment.unix(lastMessageTime._seconds).format('lll') : 'Never';
  const checkinSetForm = {
    noContainer: true,
    bg: 'bg-cream',
    type: 'post',
    postRoute: `/coach/journey/${journeyId}/set-checkin`,
    successNotification: 'The check in time was saved!',
    fields: [
      {
        name: 'day',
        label: 'Day Of Week',
        type: 'select',
        options: daysOfWeekOptions(),
        helpText: 'Choose the day that you will meet weekly with this member.',
        required: true,
        initialValue: () => {
          return checkinDay ? checkinDay : '';
        },
      },
      {
        name: 'time',
        label: 'Time',
        type: 'time',
        helpText: `Enter an approximate time that you will meet with this member.  Keep in mind, this time should be in the member's timezone, which is ${timezonePrettyName(timezone)}.`,
        required: true,
        initialValue: () => {
          return checkinTime ? checkinTime : '';
        },
      },
    ],
    submitLabel: 'Set Check In Time',
  };

  const goalSetForm = {
    noContainer: true,
    bg: 'bg-cream',
    type: 'post',
    postRoute: `/coach/journey/${journeyId}/set-goal2`,
    successNotification: 'The goal was saved!',
    fields: [
      {
        name: 'goalId',
        label: 'Choose Goal',
        type: 'select',
        optionsUrl: `/options/goals2`,
        helpText: 'Choose a goal for this member for this quarter.',
        required: true,
        initialValue: () => {
          return goal ? goal._id : '';
        },
      },
    ],
    submitLabel: 'Choose Goal',
  };


  const startDateSetForm = {
    noContainer: true,
    bg: 'bg-cream',
    type: 'post',
    postRoute: `/coach/journey/${journeyId}/set-start-date`,
    successNotification: 'The start date was saved!',
    fields: [
      {
        name: 'startDate',
        label: 'Start Date',
        type: 'date',
        helpText: 'Set the day that marks the beginning of the first week of this journey.  It should ideally coincide with your check in time.',
        required: true,
        initialValue: () => {
          return startDate ? startDate : moment().format('MM/DD/Y');
        },
      },
    ],
    submitLabel: 'Choose Start Date',
  };

  const toggleSkipChatNotifications = async () => {
    try {
      await postApiRequest(`/coach/journey/${journeyId}/toggle-chat-notifications/${skipUpcomingChatNotifications ? 'send' : 'skip'}`, {});
      reload();
    } catch (err) {
      console.log(err, 'error toggling chat notifications');
    }
  }

  const toggleComplete = async () => {
    try {
      await postApiRequest(`/coach/journey/${journeyId}/toggle-complete/${complete ? 'active' : 'complete'}`, {});
      reload();
    } catch (err) {
      console.log(err, 'error toggling complete');
    }
  }

  console.log(completedAt, 'completed at date');

  return (
    <MemberSection>
      <div className="grid grid-cols-4">
        <div className="col-span-3">
          <div className="flex justify-between">
            <h3 className="text-lg font-semibold text-primary-500">Engagement Details</h3>
            <Link
              className="px-2 py-2 text-sm text-white bg-primary-500 hover:bg-primary-600 rounded-lg"
              to={`/coach/member/${uid}/chat`}
            >
              Chat With Member
            </Link>
          </div>
          <div className="grid grid-cols-4 my-10">
            <div className="col-span-2">
              <h4 className="text-sm text-gray-400 font-medium mb-1">Weekly Check In Time</h4>
              {hasCheckinSet && <p className="text-md text-gray-700">{checkinDay} at {checkinTime} {timezonePrettyName(timezone, 'short')}
                <MemberSetForm
                  form={checkinSetForm}
                  reload={reload}
                  title="Update Check In Time"
                  body="Update your weekly check in time for the quarterr. Press ESCAPE or click away to cancel this action."
                  buttonText="Set Time"
                  hideButton
                  useEditButton
                />
              </p>}
              {!hasCheckinSet && <p className="text-md text-gray-700"><span className="mr-2">A time has not yet been chosen.</span>
                <MemberSetForm
                  form={checkinSetForm}
                  reload={reload}
                  title="Set Check In Time"
                  body="After you meet with this member for the first time, set your weekly check in time for the quarter. Press ESCAPE or click away to cancel this action."
                  buttonText="Set Time"
                /></p>}
            </div>
            <div>
              <h4 className="text-sm text-gray-400 font-medium mb-1">Toolkit</h4>
              <p className="text-md text-gray-700">{toolkitName}</p>
            </div>

            <div className="flex justify-between">
              <div>
                <h4 className="text-sm text-gray-400 font-medium mb-1">Flow Journey</h4>
                <p className="text-md">
                  <Link
                    className="text-md text-primary-500 hover:text-primary-600"
                    to={`/coach/member/journey/${journeyId}/flow/activities/${currentWeek}`}
                  >Week #{currentWeek}
                  </Link>
                </p>
              </div>
              <div>
                
              </div>
            </div>
          </div>
          <div className="grid grid-cols-4 my-10">
            <div className="col-span-3">
              <h4 className="text-sm text-gray-400 font-medium mb-1">Quarterly Goal</h4>
              {hasGoalSet && <p className="text-md text-gray-700"><span>{goal.name}</span>
                <MemberSetForm
                  form={goalSetForm}
                  reload={reload}
                  title="Update Goal For Member"
                  body="After you meet with this member for the first time, set their official goal for the quarter. Press ESCAPE or click away to cancel this action."
                  buttonText="Set Goal"
                  hideButton
                  useEditButton
                />
              </p>}
              {!hasGoalSet && <p className="text-md text-gray-700">
                <span className="mr-2">Goal has not yet been set.</span>
                <MemberSetForm
                  form={goalSetForm}
                  reload={reload}
                  title="Set Goal For Member"
                  body="After you meet with this member for the first time, set their official goal for the quarter. Press ESCAPE or click away to cancel this action."
                  buttonText="Set Goal"
                />
              </p>}
            </div>
            <div>
              <h4 className="text-sm text-gray-400 font-medium mb-1">Goal Score</h4>
              <p className="text-md text-gray-700">{goalAverage || '--'}</p>
            </div>
          </div>
          <div className="grid grid-cols-4 my-10">
            <div className="col-span-2">
              <h4 className="text-sm text-gray-400 font-medium mb-1">Start Date</h4>
              {hasStartDate && <p className="text-md text-gray-700">{startDate}
                <MemberSetForm
                  form={startDateSetForm}
                  reload={reload}
                  title="Update Start Date For Member"
                  body="Update the official start date for the quarter. Press ESCAPE or click away to cancel this action."
                  buttonText="Set Date"
                  hideButton
                  useEditButton
                />
              </p>}
              {!hasStartDate && <p className="text-md text-gray-700"><span className="mr-2">Start Date has not yet been set.</span>
                <MemberSetForm
                  form={startDateSetForm}
                  reload={reload}
                  title="Set Start Date For Member"
                  body="After you meet with this member for the first time, set their official start date for the quarter. Press ESCAPE or click away to cancel this action."
                  buttonText="Set Date"
                /></p>}
            </div>
            <div className="col-span-2">
              <h4 className="text-sm text-gray-400 font-medium mb-1">Last Message Sent</h4>
              <p className="text-md text-gray-700">{lastMessageSent}</p>
            </div>
          </div>
          <div>
            {!!completedAtTime && (
              <>
                <h4 className="text-sm text-gray-400 font-medium mb-1">Completed At</h4>
                <p className="text-md text-gray-700 mb-2">{completedAtTime}</p>
              </>
            )}
            <button
              className="px-2 py-2 text-sm text-white bg-primary-500 hover:bg-primary-600 rounded-lg"
              onClick={toggleComplete}
            >
              {complete ? 'Activate Journey' : 'Mark Journey Complete'}
            </button>
          </div>
        </div>
        <div className="col-span-1 text-center">
          <div className="mb-4">
            <Avatar xl photo={photo} name={name} bg="bg-secondary-500" />
          </div>
          <p className="text-lg mb-1 text-primary-500 font-semibold">{name}</p>
          {!!pronouns && <p className="text-md text-gray-400">{pronouns}</p>}
          <div className="mt-2 pl-8 flex align-middle justify-start">
            <Toggle enabled={!skipUpcomingChatNotifications} onClick={toggleSkipChatNotifications} />
            <h4 className="text-sm text-gray-400 mt-1 ml-2 font-medium mb-1">Chat Notifications: <span className="text-gray-500">{!!skipUpcomingChatNotifications ? 'Off' : 'On'}</span></h4>
          </div>
        </div>
      </div>
    </MemberSection>
  );
}

export default function CoachMemberJourney() {
  const { id, memberId } = useParams();
  const [summary, setSummary] = useState(null);
  const [currentWeek, setCurrentWeek] = useState(0);
  const [loading, setLoading] = useState(true);
  const [isJourneyControlModalOpen, setIsJourneyControlModalOpen] = useState(false);
  const [isCheckInModalOpen, setIsCheckInModalOpen] = useState(false);
  const navigate = useNavigate();
  const { setBreadcrumbs } = useBreadcrumbs();
  useEffect(() => {
    setBreadcrumbs([
      {
        href: '/coach/members',
        label: 'Your Members',
      },
    ]);
  }, []);

  const load = async () => {
    if (!loading) {
      setLoading(true);
    }
    try {
      const result = await getApiRequest(`/coach/journey/${id}`);
      setSummary(result);
      const journey = result.journey;
      setCurrentWeek(getCurrentWeekForJourney(journey ? journey : null).weeks);
    } catch (err) {
      Bugsnag.notify(err);
      console.log(err, 'there was an error loading the summary');
    }
    setLoading(false);
  };

  const fetchJourney = async () => {
    try {
      const result = await getApiRequest(`/coach/members?uid=${memberId}`);
      // setProfiles(result.data);
      if (!!result && !!result.results && !!result.results.length) {
        // Just go to the first journey
        navigate(`/coach/member/journey/${result.results[0]._id}`, { replace: true });
        return;
      }
      navigate('/coach/members', { replace: true });
    } catch (err) {
      navigate('/coach/members', { replace: true });
    }
  };


  useEffect(() => {
    if (id) {
      load();
    } else if (memberId) {
      // We only have a member id, let's try to grab a journey!
      fetchJourney();
    } else {
      navigate('/coach/members', { replace: true });
    }
  }, [id, memberId]);

  const { active } = summary?.profile || {};
  console.log(summary, 'summary');

  return (
      <Frame fullscreen classes="p-4">
        <Loadable loading={loading}>
        {!!summary && (
          <>
            <MemberHeader memberUid={summary.journey.uid} journeyId={id} which="dashboard" name={summary.profile.name} toolkitName={summary.journey.toolkitName} />
            <div className="my-8 px-4 mx-auto">
              {/* <EngagmentSection reload={load} journeyId={id} profile={summary.profile} data={summary.data} journey={summary.journey} /> */}

              <JourneyControlsModal summary={summary} memberUid={summary.journey.uid} isOpen={isJourneyControlModalOpen} setIsOpen={setIsJourneyControlModalOpen} />
              <div className="px-8 py-10">
                {/* <PrivateNotesModal isOpen={isNotesModalOpen} setIsOpen={setIsNotesModalOpen} />
                <FlowExercisesModal isOpen={isExerciseModalOpen} setIsOpen={setIsExerciseModalOpen} />
                <SendMemberCheckInModal isOpen={isCheckInModalOpen} setIsOpen={setIsCheckInModalOpen} /> */}
                <div className="flex lg:flex-row flex-col items-start">
                  <div className="mr-7">
                    <p className="text-[32px] font-[700]">Journey Management</p>
                    {!!summary && <p className="text-[20px] text-[#BF41AA]">Membership: {active ? 'Active' : 'Inactive'}</p>}
                  </div>
                  {/* <div className="bg-[#BF41AA] px-6 py-2 rounded-xl lg:w-[25%] w-full mt-2 lg:mt-0 text-center">
                    <p className="text-[18px] text-white">New Journey Assigned</p>
                  </div> */}
                </div>
                <div className="mt-10 flex lg:flex-row flex-col items-stretch justify-between">
                  <StatusControls summary={summary} setIsJourneyControlModalOpen={setIsJourneyControlModalOpen} setIsCheckInModalOpen={setIsCheckInModalOpen} />
                </div>
                {/* <div className="flex lg:flex-row flex-col items-stretch justify-between mt-20">
                  <ActivitySchedule />
                </div>
                <div className="mt-20 w-full">
                  <FlowExercises setIsExerciseModalOpen={setIsExerciseModalOpen} />
                </div>
                <div className="mt-20 w-full">
                  <DashboardNotes setIsNotesModalOpen={setIsNotesModalOpen} />
                </div> */}
              </div>
              <MemberSection>
                <div className="grid grid-cols-5 divide-x space-x-2">
                  <div className="col-span-2 pr-3">
                    <ActivitySection data={summary.data} journey={summary.journey} />
                  </div>
                  <div className="col-span-3 pl-3">
                    <PrivateNotesSection currentWeek={currentWeek} journeyId={id} />
                  </div>
                </div>
              </MemberSection>
            </div>
          </>
        )}
          
        </Loadable>
      </Frame>
  );
}
