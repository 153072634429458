import { useState } from 'react';
import { PencilIcon } from '@heroicons/react/solid';
import FormModal from '../../../components/overlays/form-modal';


function EditButton({ onClick }) {
  return (
    <button
      type="button"
      title="Edit Homework"
      onClick={onClick}
      className="inline-flex items-center p-1 ml-2 border border-transparent rounded-full shadow-sm text-secondary-500 bg-secondary-100 hover:bg-secondary-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary-100"
    >
      <PencilIcon className="h-5 w-5" aria-hidden="true" />
    </button>
  );
}


export default function MemberSetForm({ buttonText, form, reload, title, body, hideButton = false, isOpen = false, externalClose = null, useEditButton = false }) {
  const [showModal, setShowModal] = useState(false);
  if (!form) {
    return null;
  }

  // Hide the modal, using the external close function, or the internal one
  const hideModal = () => {
    if (externalClose) {
      externalClose();
    } else {
      setShowModal(false);
    }
  }

  form.successHandler = (values, actions) => {
    actions.resetForm();
    hideModal();
    // Reload
    if (reload) {
      setTimeout(() => { reload(); }, 200);
    }
  };

  return (
    <>
      {!hideButton && (
        <button
          className="px-2 py-2 text-sm text-white bg-primary-500 hover:bg-primary-600 rounded-lg"
          onClick={() => setShowModal(true)}
        >
          {buttonText}
        </button>
      )}
      {useEditButton && <EditButton onClick={() => setShowModal(true)} />}
      <FormModal
        form={form}
        open={showModal || isOpen}
        cancel={hideModal}
        title={title}
        body={body}
        bg="bg-cream"
      />
    </>
  )
}
