import React, { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { FaUser } from 'react-icons/fa';
import { FiX } from 'react-icons/fi';
import { ReactComponent as ChatIcon } from '../../../../assets/chat.svg';
import { ReactComponent as MoodIcon } from '../../../../assets/mood.svg';
import JourneyControlsModalChat from './journeyControlsModalChat';
import JourneyControlsModalMood from './journeyControlsModalMood';
import JourneyControlModalMember from './journeyControlModalMember';

const JourneyControlsModal = ({ isOpen, setIsOpen, memberUid, summary = null }) => {
  const [currentTab, setCurrentTab] = useState('chat');

  if (!summary) {
    return null;
  }

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog onClose={() => setIsOpen(false)} className="relative z-10">
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-25 transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center w-full">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              {/* <Dialog.Panel> */}
                <div className="bg-[#342F3B] w-[350px] lg:w-[1200px] rounded-md p-4">
                  <div className="flex flex-row items-center justify-between mb-10">
                    <p className="text-[#E1D9FF] text-[32px] font-[700] text-left">Member Journey Controls</p>
                    <div className="w-[40px] h-[40px] rounded-full flex items-center justify-center bg-[#C5C4C2] cursor-pointer" onClick={() => setIsOpen(false)}>
                      <FiX className="w-[24px] h-[24px] text-[#A38DF2]" />
                    </div>
                  </div>
                  <div className="flex flex-col lg:flex-row">
                    <div className="mb-2 lg:mb-0 w-full lg:w-[20%]">
                      <div
                        className="flex flex-row items-center p-2 w-[full] rounded-md mb-5 cursor-pointer"
                        style={{ backgroundColor: currentTab === 'chat' ? 'rgba(255, 255, 255, 0.2)' : 'transparent' }}
                        onClick={() => setCurrentTab('chat')}
                      >
                        <ChatIcon width={20} height={20} className="text-white mr-3" />
                        <p className="text-white text-[20px]">Chat</p>
                      </div>
                      <div
                        className="flex flex-row items-center p-2 w-[full] rounded-md mb-5 cursor-pointer"
                        style={{ backgroundColor: currentTab === 'mood' ? 'rgba(255, 255, 255, 0.2)' : 'transparent' }}
                        onClick={() => setCurrentTab('mood')}
                      >
                        <MoodIcon width={20} height={20} className="text-white mr-3" />
                        <p className="text-white text-[20px]">Mood</p>
                      </div>
                      <div
                        className="flex flex-row items-center p-2 w-[full] rounded-md cursor-pointer"
                        style={{ backgroundColor: currentTab === 'member' ? 'rgba(255, 255, 255, 0.2)' : 'transparent' }}
                        onClick={() => setCurrentTab('member')}
                      >
                        <FaUser width={20} height={20} className="text-white mr-3" />
                        <p className="text-white text-[20px]">Member</p>
                      </div>
                    </div>
                    <div className="w-full">
                      {currentTab === 'chat' ? <JourneyControlsModalChat summary={summary} memberUid={memberUid} /> : currentTab === 'mood' ? <JourneyControlsModalMood memberUid={memberUid} /> : <JourneyControlModalMember memberUid={memberUid} />}
                    </div>
                  </div>
                </div>
              {/* </Dialog.Panel> */}
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default JourneyControlsModal;
