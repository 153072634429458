import React from 'react';
import Label from './label';
import HelpText from './help-text';
import Input from './types/input';
import Select from './types/select';
import Phone from './types/phone';
import Checkbox from './types/checkbox';
import Checkboxes from './types/checkboxes';
import File from './types/file';
import Textarea from './types/textarea';
import DateInput from './types/date';
import TimeInput from './types/time';
import SelectLookup from './types/select-lookup';
import Markdown from './types/markdown';
import CloudinaryUpload from './types/cloudinary-upload';
import CloudinaryDirectUpload from './types/cloudinary-direct-upload';
import CloudinaryMultipleUpload from './types/cloudinary-multiple-upload';
import Switch from './types/switch';
import BrowserTime from './types/browser-time';


function FieldSwitch({ formik, field, hasError, id, sideBySide = false }) {
  const { type } = field;
  switch (type) {
    case 'phone':
      return <Phone formik={formik} {...field} hasError={hasError} id={id} sideBySide={sideBySide} />;
    case 'select':
      return <Select formik={formik} {...field} hasError={hasError} id={id} sideBySide={sideBySide} />;
    case 'select-lookup':
      return <SelectLookup formik={formik} {...field} hasError={hasError} id={id} sideBySide={sideBySide} />;
    case 'checkbox':
      return <Checkbox formik={formik} {...field} hasError={hasError} id={id} sideBySide={sideBySide} />;
    case 'checkboxes':
      return <Checkboxes formik={formik} {...field} hasError={hasError} id={id} sideBySide={sideBySide} />;
    case 'file':
      return <File formik={formik} {...field} hasError={hasError} id={id} sideBySide={sideBySide} />;
    case 'cloudinary-upload':
      return <CloudinaryUpload formik={formik} {...field} hasError={hasError} id={id} sideBySide={sideBySide} />;
    case 'cloudinary-direct-upload':
      return <CloudinaryDirectUpload formik={formik} {...field} hasError={hasError} id={id} sideBySide={sideBySide} />;
    case 'cloudinary-multiple-upload':
      return <CloudinaryMultipleUpload formik={formik} {...field} hasError={hasError} id={id} sideBySide={sideBySide} />;
    case 'textarea':
      return <Textarea formik={formik} {...field} hasError={hasError} id={id} sideBySide={sideBySide} />;
    case 'markdown':
      return <Markdown formik={formik} {...field} hasError={hasError} id={id} sideBySide={sideBySide} />;
    case 'date':
      return <DateInput formik={formik} {...field} hasError={hasError} id={id} sideBySide={sideBySide} />;
    case 'time':
      return <TimeInput formik={formik} {...field} hasError={hasError} id={id} sideBySide={sideBySide} />;
    case 'browser-time':
      return <BrowserTime formik={formik} {...field} hasError={hasError} id={id} sideBySide={sideBySide} />;
    case 'switch':
      return <Switch formik={formik} {...field} hasError={hasError} id={id} sideBySide={sideBySide} />;
    case 'text':
    case 'email':
    case 'number':
    case 'password':
    default:
      return <Input formik={formik} {...field} hasError={hasError} id={id} />;
  }
}

function Field({ formik, field, sideBySide = false }) {
  const { id, name, label, hideLabel, helpText, showIf, fullWidth } = field;
  const realId = id || name;
  const hasError = formik.touched[name] && formik.errors[name];
  const errorText = hasError ? formik.errors[name] : null;

  // Skip fields that are not set to show, if we have a showIf function
  if (showIf && !showIf(formik)) {
    return null;
  }

  if (sideBySide) {
    return (
      <div className={`mt-5 ${!fullWidth ? 'flex flex-row justify-between' : ''} text-left mb-5`}>
        {!hideLabel && (
          <div className="w-[75%]">
            <Label id={realId} label={label} hidden={hideLabel} sideBySide />
            <HelpText id={realId} helpText={helpText} hasError={hasError} sideBySide errorText={errorText} />
          </div>
        )}
        <div className={`${!fullWidth ? 'flex flex-row items-center' : 'w-full'} ${hideLabel ? 'w-full' : ''}`}>
          <FieldSwitch formik={formik} field={field} hasError={hasError} id={realId} sideBySide />
        </div>
        {!!hideLabel && !!errorText && !!hasError && <HelpText id={realId} helpText={helpText} hasError={hasError} sideBySide errorText={errorText} />}
      </div>
    );
  }

  return (
    <div>
      <Label id={realId} label={label} hidden={hideLabel} />
      <FieldSwitch formik={formik} field={field} hasError={hasError} id={realId} />
      <HelpText id={realId} helpText={helpText} hasError={hasError} errorText={errorText} />
    </div>
  );
}

export default Field;
