import AppRoutes from './app-routes';
import { ProvideNotifications } from './contexts/notifications-provider';
import { ProvideBreadcrumbs } from './contexts/breadcrumbs-provider';
import { ProvideAuth } from './contexts/auth-provider';
import { ProvideServerNotifications } from './contexts/server-notifications-provider';
import { ProvideConversations } from './contexts/conversations-provider';


function App() {
  return (
    <ProvideAuth>
      <ProvideServerNotifications>
        <ProvideConversations>
          <ProvideNotifications>
            <ProvideBreadcrumbs>
              <AppRoutes />
            </ProvideBreadcrumbs>
          </ProvideNotifications>
        </ProvideConversations>
      </ProvideServerNotifications>
    </ProvideAuth>
  );
}

export default App;
