import DataRow from '../data/data-list-row';
import Panel from '../common/panel';
import Avatar from '../common/avatar';
import { formatUnixDate, timezonePrettyName, capFirstLetter } from '../../utils/helpers';


export default function ProfileDataView({ profile }) {
  if (!profile) {
    return null;
  }
  
  const { updatedAt: updated, createdAt: created, age, active, email, phone, name, pronouns, shippingAddress, shippingAddress2, shippingCity, shippingZip, shippingState, role, photo, timezone } = profile;

  const formattedData = {
    name,
    email,
    phone,
    pronouns,
    active: active ? 'Yes' : 'No (ignore for Guides)',
    age,
    role: capFirstLetter(role),
    timezone: timezonePrettyName(timezone),
    address: shippingAddress ? <>{shippingAddress}{`${shippingAddress2 ? `, ${shippingAddress2}` : ''}`}<br />{`${shippingCity}, ${shippingState} ${shippingZip}`}</> : '--',
    updated: formatUnixDate(updated._seconds),
    created: formatUnixDate(created._seconds),
    photo: <Avatar photo={photo} lg name={name} />,
  };

  const rows = [
    {
      field: 'photo',
      label: 'Photo',
    },
    {
      field: 'name',
      label: 'Name',
    },
    {
      field: 'active',
      label: 'Membership Active',
    },
    {
      field: 'role',
      label: 'Role',
    },
    {
      field: 'email',
      label: 'Email',
    },
    {
      field: 'phone',
      label: 'Phone',
    },
    {
      field: 'pronouns',
      label: 'Pronouns',
    },
    {
      field: 'age',
      label: 'Age',
    },
    {
      field: 'address',
      label: 'Shipping Address',
    },
    {
      field: 'timezone',
      label: 'Timezone',
    },
    {
      field: 'updated',
      label: 'Last Updated',
    },
    {
      field: 'created',
      label: 'Created Date',
    },
  ];

  return (
    <div className="mb-20">
      <Panel>
        <div className="px-4 py-5 sm:p-0">
          <dl>
            {rows.map((item, i) => {
              const value = formattedData[item.field];
              return <DataRow key={item.field} label={item.label} value={value} first={i === 0} />
            })}
          </dl>
        </div>
      </Panel>
    </div>
  )
}