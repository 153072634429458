import moment from 'moment';
import { isValidPhoneNumber, AsYouType } from 'libphonenumber-js';
import { radarChartData } from '../constants/radar-chart-data';
import { chartData } from '../constants/line-chart-data';
import { habitChartData } from '../constants/habit-chart-data';

export function validatePhone(value) {
  return isValidPhoneNumber(value || '', 'US');
}

export function formatPhoneFromServer(value) {
  if (!value) {
    return null;
  }

  const asYouType = new AsYouType('US');
  return asYouType.input(value.replace('+1', ''));
}


export function getMomentFromUnix(seconds) {
  return moment.unix(seconds);
}

export function formatUnixDate(timestamp, format = 'M/D/Y') {
  return getMomentFromUnix(timestamp).format(format);
}

export function formatUtcUnixDate(timestamp, format = 'll') {
  return getMomentFromUnix(timestamp).utc().format(format);
}


export function dateLeadingZero(num) {
  const absNum = Math.abs(num);
  if (absNum < 10) {
    return `0${absNum}`;
  }
  return absNum;
}

// See: https://github.com/iamcal/lib_timezones/blob/master/lib/lib_timezones.js
export function timezoneOptions() {
  return [
    {
      value: '',
      label: 'Select...',
    },
    {
      value: 'America/New_York',
      label: 'Eastern Time (US)',
      short: 'EST',
    },
    {
      value: 'America/Chicago',
      label: 'Central Time (US)',
      short: 'CST',
    },
    {
      value: 'America/Denver',
      label: 'Mountain Time (US)',
      short: 'MST',
    },
    {
      value: 'America/Los_Angeles',
      label: 'Pacific Time (US)',
      short: 'PST',
    },
    {
      value: 'America/Phoenix',
      label: 'Arizona',
      short: 'MST',
    },
    {
      value: 'America/Anchorage',
      label: 'Alaska',
      short: 'AKST',
    },
    {
      value: 'Pacific/Honolulu',
      label: 'Hawaii',
      short: 'HST',
    },
  ];
}

export function timezonePrettyName(tz, which = 'label') {
  const timezones = timezoneOptions();
  for (let i = 0; i < timezones.length; i++) {
    if (tz === timezones[i].value) {
      return timezones[i][which];
    }
  }
  return tz;
}


const states = {
  'AL': 'Alabama',
  'AK': 'Alaska',
  'AZ': 'Arizona',
  'AR': 'Arkansas',
  'CA': 'California',
  'CO': 'Colorado',
  'CT': 'Connecticut',
  'DE': 'Delaware',
  'DC': 'District Of Columbia',
  'FL': 'Florida',
  'GA': 'Georgia',
  'HI': 'Hawaii',
  'ID': 'Idaho',
  'IL': 'Illinois',
  'IN': 'Indiana',
  'IA': 'Iowa',
  'KS': 'Kansas',
  'KY': 'Kentucky',
  'LA': 'Louisiana',
  'ME': 'Maine',
  'MD': 'Maryland',
  'MA': 'Massachusetts',
  'MI': 'Michigan',
  'MN': 'Minnesota',
  'MS': 'Mississippi',
  'MO': 'Missouri',
  'MT': 'Montana',
  'NE': 'Nebraska',
  'NV': 'Nevada',
  'NH': 'New Hampshire',
  'NJ': 'New Jersey',
  'NM': 'New Mexico',
  'NY': 'New York',
  'NC': 'North Carolina',
  'ND': 'North Dakota',
  'OH': 'Ohio',
  'OK': 'Oklahoma',
  'OR': 'Oregon',
  'PA': 'Pennsylvania',
  'PR': 'Puerto Rico',
  'RI': 'Rhode Island',
  'SC': 'South Carolina',
  'SD': 'South Dakota',
  'TN': 'Tennessee',
  'TX': 'Texas',
  'UT': 'Utah',
  'VT': 'Vermont',
  'VA': 'Virginia',
  'WA': 'Washington',
  'WV': 'West Virginia',
  'WI': 'Wisconsin',
  'WY': 'Wyoming',
};

export function statesOptions(hideSelect) {
  const keys = Object.keys(states);
  const ret = hideSelect ? [] : [{
    value: '',
    label: 'Select...',
  }];
  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    ret.push({
      value: key,
      label: states[key],
    });
  }
  return ret;
}

export function statePrettyName(abbr) {
  return states[abbr];
}


export function capFirstLetter(str) {
  if (!str) {
    return str;
  }
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function truncateString(str, length) {
  if (!str) {
    return str;
  }
  if (str.length <= length) {
    return str;
  }
  return `${str.slice(0, length)}...`;
}

export function roundLikePhp(num, dec) {
  const num_sign = num >= 0 ? 1 : -1;
  return parseFloat((Math.round((num * Math.pow(10, dec)) + (num_sign * 0.0001)) / Math.pow(10, dec)).toFixed(dec));
}

// Function to take an input ref and copy to clipboard it's contents
export function copyToClipboard(ref) {
  if (ref && ref.current) {
    ref.current.select();
    document.execCommand('copy');
    ref.current.setSelectionRange(0, 0);
    ref.current.blur();
    return true;
  }
  return false;
}


export function pluralize(str, num, plural) {
  const absNum = Math.abs(num);
  if (absNum === 1) {
    return str;
  }
  return plural || `${str}s`;
}


export function initialsFromName(name) {
  if (!name) {
    return '';
  }
  const parts = name.split(' ');
  let initials = '';
  for (let i = 0; i < parts.length; i++) {
    initials = `${initials}${parts[i].charAt(0).toUpperCase()}`;
  }
  return initials;
}

export function getCurrentWeekBasedOnStartDate(startDate) {
  if (!startDate) {
    return { weeks: 0, days: 0 };
  }
  // calculate the week from the start date
  const startDateMoment = moment(startDate, 'MM/DD/YYYY');
  const now = moment();
  const weekDiff = now.diff(startDateMoment, 'weeks');
  const dayDiff = now.diff(startDateMoment, 'days');
  let weekValue = weekDiff >= 0 ? weekDiff + 1 : 0;
  weekValue = weekValue > 12 ? 12 : weekValue;
  return { weeks: weekValue, days: (dayDiff % 7) };
}

function getPriorSunday(seconds) {
  // Sunday = 0
  const day = 0;
  const currentDay = getMomentFromUnix(seconds).isoWeekday();

  const format = 'MM/DD/YYYY';
  const hoursBuffer = 60*60*6;

  // If it's currently sunday, just return today
  if (currentDay === day) { 
    // Just return seconds, the timestamp we started with
    return moment(getMomentFromUnix(seconds).format(format), format).unix() + hoursBuffer;
  } else {
    // Go back one week, get Sunday, and return the timestamp
    return moment(getMomentFromUnix(seconds).subtract(currentDay, 'days').format(format), format).unix() + hoursBuffer;
  }
};

export function getCurrentWeekForJourney(journey) {
  // Now based off of the intro survey!
  if (!journey || !journey.introSurveyCompletedAt) {
    return { weeks: 0, days: 0 };
  }
  if (journey.complete) {
    return { weeks: 14, days: 0 };
  }
  // Start date is now based off of the intro survey completion!
  const sundayStart = getPriorSunday(journey.introSurveyCompletedAt.seconds || journey.introSurveyCompletedAt._seconds);
  const startDate = getMomentFromUnix(sundayStart);

  const now = moment();
  const weekDiff = now.diff(startDate, 'weeks');
  const dayDiff = now.diff(startDate, 'days');
  return { weeks: weekDiff >= 0 ? weekDiff : 0, days: (dayDiff % 7) };
}

export function daysOfWeek() {
  return ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
}

export function daysOfWeekOptions(hideSelect) {
  const days = daysOfWeek();
  const ret = hideSelect ? [] : [{
    value: '',
    label: 'Select...',
  }];
  for (let i = 0; i < days.length; i++) {
    const day = days[i];
    ret.push({
      value: day,
      label: day,
    });
  }
  return ret;
}

export function weekOptions(hideSelect) {
  const ret = hideSelect ? [] : [{
    value: '',
    label: 'Select...',
  }];
  for (let i = 0; i < 13; i++) {
    ret.push({
      value: i,
      label: `Week ${i}`,
    });
  }
  return ret;
}

export function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

// Helper function to translate a survey type to a friendly name
// export function getSurveyName(type) {
//   return `${type} Survey`;
//   // return type === 'pre' ? 'Intro Survey' : type === 'post' ? 'Quarterly Survey' : 'Weekly Survey';
// }

export function convertMinutesToHoursMinutes(min, hideZeroHours = false) {
  const hours = Math.floor(min / 60);
  const minutes = min % 60;
  if (hours === 0 && hideZeroHours) {
    return `${minutes}min`;
  }
  return `${hours}h ${minutes}min`;
}

export function ucwords(str) {
  return str.toLowerCase().replace(/(^([a-zA-Z\p{M}]))|([ -][a-zA-Z\p{M}])/g,
  	function(s){
  	  return s.toUpperCase();
	});
};

export function makeSureHttps(url) {
  if (!url) {
    return url;
  }
  if (url.indexOf('http') !== 0) {
    return `https:${url}`;
  }
  return url.replace('http:', 'https:');
}

// Return the smallest video from an array of files
export function findSmallestVideo(files, desiredQuality = 'hls') {
  let smallest = null;
  files.forEach(file => {
    const { type, size, quality } = file;
    // if (type === 'video/mp4' && quality === 'hd') {
    if (type === 'video/mp4' && quality === desiredQuality) {
      if (!smallest) {
        smallest = file;
      } else if (smallest.size > size) {
        smallest = file;
      }
    }
  });
  return smallest;
}

export function findIndexOfItemByValue(arr, value, key = 'id') {
  if (!arr || !arr.length) {
    return -1;
  }
  for (let i = 0; i < arr.length; i++) {
    if (arr[i][key] === value) {
      return i;
    }
  }
  return -1;
}





export function getRadarData(date, providedData = null) {
  const dataToUse = providedData ? providedData : radarChartData;
  const array = dataToUse.filter((item) => moment(item.date, 'MM/DD/YYYY HH:mm:ss').format('MM/DD/YY') === date);
  return array.length > 0 ? array[0].goals[0] : null;
}

export function getAllDataWithFilterHabit(filter, providedData = null) {
  const dataToUse = providedData ? providedData : habitChartData;
  const amount = filter?.split(' ')[0];
  const unit = filter?.split(' ')[1];
  let data = dataToUse.filter((i) => moment(i.date, 'MM/DD/YYYY HH:mm:ss') > moment().subtract(amount?.trim(), unit?.trim()));
  return data;
}

export function getTookitListHabit() {
  return [...new Set(habitChartData.map((item) => item.toolkit))];
}

export function getToolkitDataWithFilterHabitChart(toolkit, filter, providedData = null) {
  const dataToUse = providedData ? providedData : habitChartData;
  const amount = filter?.split(' ')[0];
  const unit = filter?.split(' ')[1];

  const data = dataToUse.reduce((res, item) => {
    if (moment(item.x, 'MM/DD/YYYY HH:mm:ss') > moment().subtract(amount?.trim(), unit?.trim()) && item.journeyId === toolkit) {
      res.push(item);
    }

    return res;
  }, []);

  return data;
}

export function getToolkitDataWithFilterHabit(toolkit, filter, providedData = null) {
  const dataToUse = providedData ? providedData : habitChartData;
  const amount = filter?.split(' ')[0];
  const unit = filter?.split(' ')[1];
  let data = dataToUse.filter((item) => item.toolkit === toolkit).filter((i) => moment(i.date, 'MM/DD/YYYY HH:mm:ss') > moment().subtract(amount?.trim(), unit?.trim()));
  return data;
}

export function getAvg(key, data) {
  if (data.length === 0) {
    return 'n/a';
  } else {
    const result = data.reduce((total, currentValue) => (total = total + currentValue[key]), 0);
    return (result / data.length).toFixed(0);
  }
}

export function getFavDay(data) {
  if (data.length === 0) {
    return 'n/a';
  } else {
    const array = data.map((item) => item.dayOfWeek);
    return array.sort((a, b) => array.filter((v) => v === a).length - array.filter((v) => v === b).length).pop();
  }
}

export function getStreak(data) {
  if (data.length === 0) {
    return 'n/a';
  } else {
    const arr = data.map((item) => item.day);
    var i,
      temp,
      streak,
      length = arr.length,
      highestStreak = 0,
      highestStreakValue = '';

    for (i = 0; i < length; i++) {
      if (temp !== '' && temp === arr[i]) {
        streak++;
      } else {
        streak = 1;
      }
      temp = arr[i];
      if (streak > highestStreak) {
        highestStreakValue = temp;
        highestStreak = streak;
      }
    }
    // console.log(highestStreakValue);
    return highestStreak;
  }
}

export function getToolkitListForFilterHabit() {
  var filters = [];
  filters.push({
    index: 'Latest',
    toolkit: 'Latest',
    isSelected: true,
  });
  getTookitListHabit().map((toolKit) => filters.push(habitChartData.find((item) => item.toolkit === toolKit)));
  filters.push({
    index: 'All',
    toolkit: 'All',
  });
  return filters;
}

export function mapData(data) {
  // return data.map(({ index, x, toolkit, y, isReflection }) => {
  //   var date = moment(x, 'MM/DD/YYYY HH:mm:ss');
  //   return {
  //     index,
  //     x: date,
  //     toolkit: toolkit,
  //     y: y,
  //     isReflection: isReflection,
  //   };
  // });
  return data.map(({ index, x, toolkit, y, isReflection, hasDifferentToolkit, otherToolkit, reflections, dateShort, exerciseType, exerciseTitle, journeyId }) => {
    var date = moment(x, 'MM/DD/YYYY HH:mm:ss');
    return {
      index,
      x: date,
      toolkit: toolkit,
      y: y,
      isReflection,
      hasDifferentToolkit,
      otherToolkit,
      reflections,
      dateShort,
      exerciseType,
      exerciseTitle,
      journeyId,
    };
  });
}

export function getTookitList() {
  return [...new Set(chartData.map((item) => item.toolkit))];
}

export function getToolkitColor(toolkit) {
  switch (toolkit) {
    case 'Chill':
      return '#C3B2FF';
    case 'Savor':
      return '#1D3B62';
    case 'Focus':
      return '#B0C8E8';
    case 'Energize':
      return '#3D3380';
    case 'All':
      return '#342F3B';
    case 'Latest':
      return '#342F3B';
    default:
      return '';
  }
}

export function getAllDataWithFilter(filter, providedData) {
  const dataToUse = providedData ? providedData : chartData;
  const amount = filter.split(' ')[0];
  const unit = filter.split(' ')[1];
  let data = dataToUse.filter((i) => moment(i.x, 'MM/DD/YYYY HH:mm:ss') > moment().subtract(amount?.trim(), unit?.trim()));
  return mapData(data);
}

export function findOccurance(arr, key) {
  let arr2 = [];
  arr.forEach((x) => {
    if (
      arr2.some((val) => {
        return val[key] === x[key];
      })
    ) {
      arr2.forEach((k) => {
        if (k[key] === x[key]) {
          k['occurrence']++;
        }
      });
    } else {
      let a = {};
      a[key] = x[key];
      a['occurrence'] = 1;
      arr2.push(a);
    }
  });
  return arr2;
}

export function getToolkitDataWithFilter(toolkit, filter, providedData = null) {
  const dataToUse = providedData ? providedData : chartData;
  const amount = filter?.split(' ')[0];
  const unit = filter?.split(' ')[1];

  const data = dataToUse.reduce((res, item) => {
    if (moment(item.x, 'MM/DD/YYYY HH:mm:ss') > moment().subtract(amount?.trim(), unit?.trim()) && item.journeyId === toolkit) {
      res.push(item);
    }

    return res;
  }, []);

  return mapData(data);
}

export function findMostRecentSeries(data, week) {
  let ret = null;
  let priorWeek = -1;
  for (let i = 0; i < data.series.length; i++) {
    const seriesData = data.series[i];
    if (seriesData.week > priorWeek && seriesData.week <= week) {
      ret = seriesData;
    }
  }
  return ret;
}

export function findSpecificWeekSeries(data, week) {
  if (!data || !data.series || week < 0) {
    return null;
  }
  for (let i = 0; i < data.series.length; i++) {
    const seriesData = data.series[i];
    if (seriesData.week === week) {
      return seriesData;
    }
  }
  return null;
}

export function getToolkitListForFilter() {
  var filters = [];

  getTookitList().forEach((toolKit) => {
    filters.push(chartData.find((item) => item.toolkit === toolKit));
  });
  const ordered = filters
    .sort(function (left, right) {
      return moment(left.x, 'MM/DD/YYYY HH:mm:ss').diff(moment(right.x, 'MM/DD/YYYY HH:mm:ss'));
    })
    .reverse();
  filters.unshift({
    index: 'Latest',
    toolkit: 'Latest',
    isSelected: true,
  });
  filters.push({
    index: 'All',
    toolkit: 'All',
  });
  return ordered;
}

export function getReflectionWithFilter(selectedFilters, filter, providedData = null) {
  const dataToUse = providedData ? providedData : chartData;
  const amount = filter?.split(' ')[0];
  const unit = filter?.split(' ')[1];

  let data;

  if (selectedFilters?.length > 0 && (selectedFilters[0] === 'Latest' || selectedFilters[0] === 'All')) {
    data = dataToUse.reduce((res, item) => {
      if (moment(item.x, 'MM/DD/YYYY HH:mm:ss') > moment().subtract(amount?.trim(), unit?.trim()) && item.isReflection) {
        res.push(item);
      }

      return res;
    }, []);
  } else {
    data = dataToUse
      .filter(({ journeyId }) => selectedFilters.includes(journeyId))
      .reduce((res, item) => {
        if (moment(item.x, 'MM/DD/YYYY HH:mm:ss') > moment().subtract(amount?.trim(), unit?.trim()) && item.isReflection) {
          res.push(item);
        }

        return res;
      }, []);
  }

  return mapData(data);
}

export function getRadarAverage(data) {
  let total = 0;
  let noOfItem = 0;
  for (let i = 0; i < data.length; i++) {
    total += Object.values(data[i]).reduce((total, item) => total + item, 0);
    noOfItem += Object.keys(data[i]).length;
  }
  return (total / noOfItem).toFixed(1);
}


// Helper function to translate a survey type to a friendly name
export function getSurveyName(type, suffix = ' Survey') {
  if (type === 'Monthly') {
    return `Perspective Check-in`;
  }
  if (['Kick Off', 'Intro', 'Exercise'].indexOf(type) !== -1) {
    return `${type}${suffix}`;
  }
  return type === 'pre' ? 'Intro Survey' : type === 'post' ? 'Quarterly Survey' : 'Weekly Survey';
}


export function formatScoreForDisplay(score, emptyValue = '--') {
  if (!score) {
    return emptyValue;
  }
  const numericScore = parseFloat(score);
  if (numericScore > 9.999) {
    return '10';
  }
  return numericScore.toFixed(1);
}



export const getWeekFromJourney = (journey, existingMoment = null) => {
  // Ok, does it have a start date?  If not, return 0
  // Now based off of the intro survey!
  if (!journey.introSurveyCompletedAt) {
    return { weeks: 0, days: 0 };
  }
  // Finally, calculate the week from the start date
  // const startDate = moment(journey.startDate, 'MM/DD/YYYY');
  // Start date is now based off of the intro survey completion!
  const sundayStart = getPriorSunday(journey.introSurveyCompletedAt.seconds || journey.introSurveyCompletedAt._seconds);
  const startDate = getMomentFromUnix(sundayStart);

  const now = existingMoment ? existingMoment : moment();
  const weekDiff = now.diff(startDate, 'weeks');
  const dayDiff = now.diff(startDate, 'days');
  return { weeks: weekDiff >= 0 ? weekDiff : 0, days: (dayDiff % 7) };
};



// Fix the index of an item by it's id
export function findIndexById(items, id, idKey = 'id') {
  if (!items || !id) {
    return -1;
  }
  for (let i = 0; i < items.length; i++) {
    if (items[i][idKey] === id) {
      return i;
    }
  }
  return -1;
}



export function convertDateIntoMMDDYY(date) {
  return moment(date).format('MM/DD/YY');
}

export function getRadarDateList(providedData = null) {
  const dataToUse = providedData ? providedData : radarChartData;
  var array = [...new Set(dataToUse.map((item) => convertDateIntoMMDDYY(item.date)))];
  return array.sort((a, b) => moment(b, 'MM/DD/YY').diff(moment(a, 'MM/DD/YY')));
}

export function transformFormToView(form, values = null) {
  return form.fields.map(item => {
    const value = values && typeof values[item.name] !== 'undefined' ? values[item.name] : '';
    const extra = {};
    if (item.type === 'time') {
      extra.timezone = item.timezone;
    }
    return {
      title: item.label,
      description: item.helpText,
      value,
      hideLabel: !!item.hideLabel,
      fullWidth: !!item.fullWidth,
      type: item.type,
      extra,
    };
  });
}
