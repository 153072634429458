import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  LineElement,
  Title,
  Tooltip,
  PointElement,
  Legend,
  Filler,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

export default function QuarterlyGoal({ goalOverTime, goalName, hasComments }) {
  const options = {
    responsive: true,
    plugins: {
      filler: {
        propagate: true,
      },
    },
  };


  const labels = goalOverTime.map((m, i) => hasComments.indexOf(i) !== -1 ? `💭 Week ${i}` : `Week ${i}`);
  const data = {
    labels,
    datasets: [
      {
        label: goalName,
        backgroundColor: '#08517b',
        borderColor: '#08517b',
        fill: {
          target: 'origin',
          below: '#08517b',
        },
        data: goalOverTime,
        tension: 0.4,
      },
    ],
  };

  return (
    <Line
      options={options}
      data={data}
    />
  );
}