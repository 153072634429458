import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom"
import useJourneyData from "../../../hooks/use-journey-data";
import { getMomentFromUnix } from "../../../utils/helpers";

function MaybeLink({ active, label, href }) {
  const classes = ' block text-center hover:text-primary-600 cursor-pointer';
  if (active) {
    return (
      <span className={`${classes} font-bold text-primary-600`}>{label}</span>
    );
  }
  return (
    <Link to={href} className={`${classes} text-primary-500`}>{label}</Link>
  );
}

function getHrefFromWhich(which, journeyId) {
  switch (which) {
    case 'flow':
      return `/coach/member/journey/${journeyId}/flow`;
    case 'connect':
      return `/coach/member/journey/${journeyId}/connect`;
    case 'analyze':
      return `/coach/member/journey/${journeyId}/analyze`;
    case 'info':
      return `/coach/member/journey/${journeyId}/info`;
    case 'map':
      return `/coach/member/journey/${journeyId}/map`;
    default:
    case 'dashboard':
      return `/coach/member/journey/${journeyId}`;
  }
}

export default function MemberHeader({ memberUid, name, toolkitName, journeyId, which }) {
  const { getMemberJourneys } = useJourneyData();
  const [journeyOptions, setJourneyOptions] = useState(null);
  const navigate = useNavigate();

  const loadOptions = async () => {
    const journeys = await getMemberJourneys(memberUid);
    if (!journeys) {
      return;
    }
    const newOptions = [];
    for (let i = 0; i < journeys.length; i++) {
      const j = journeys[i];
      newOptions.push({
        value: j._id,
        label: `${j.toolkitName} ${getMomentFromUnix(j.createdAt._seconds).format(`MMM 'YY`)}`,
      });
    }
    setJourneyOptions(newOptions);
  };

  useEffect(() => {
    loadOptions();
  }, [memberUid]);

  const handleJourneyChange = (e) => {
    const newId = e.target.value;
    if (journeyId !== newId) {
      navigate(getHrefFromWhich(which, newId));
    }
  };

  const hasJourneyOptions = !!journeyOptions && !!journeyOptions.length;

  return (
    <div className="grid grid-cols-3 pt-5 pb-8 border-b border-gray-300">
      <div className="col-span-1">
        {!hasJourneyOptions && (
          <div className="flex justify-start items-center">
            <h3 className="text-2xl -mt-1 text-primary-500 font-bold">{name}</h3>
            <p className="pl-8 text-md text-gray-600 font-semibold">{toolkitName}</p>
          </div>
        )}
        {hasJourneyOptions && (
          <div className="flex justify-start items-center">
            <h3 className="text-2xl -mt-1 text-primary-500 font-bold">{name}</h3>
            <select value={journeyId} onChange={handleJourneyChange} className="bg-[#C5C4C2] ml-8 rounded-md">
              {/* <option>Energize Feb' 22</option> */}
              {journeyOptions && journeyOptions.map((opt, i) => {
                return (
                  <option key={opt.value} value={opt.value}>{opt.label}</option>
                );
              })}
            </select>
          </div>
        )}
      </div>
      <div className="col-span-2">
        <div className="grid grid-cols-6">
          <div>
            <MaybeLink active={which === 'dashboard'} label="Dashboard" href={getHrefFromWhich('dashboard', journeyId)} />
          </div>
          <div>
            <MaybeLink active={which === 'map'} label="Map" href={getHrefFromWhich('map', journeyId)} />
          </div>
          <div>
            <MaybeLink active={which === 'flow'} label="Flow" href={getHrefFromWhich('flow', journeyId)} />
          </div>
          <div>
            <MaybeLink active={which === 'connect'} label="Connect" href={getHrefFromWhich('connect', journeyId)} />
          </div>
          <div>
            <MaybeLink active={which === 'analyze'} label="Analyze" href={getHrefFromWhich('analyze', journeyId)} />
          </div>
          <div>
            <MaybeLink active={which === 'info'} label="Info" href={getHrefFromWhich('info', journeyId)} />
          </div>
        </div>
      </div>
    </div>
  )
}

