import { CalendarIcon, ClockIcon } from '@heroicons/react/solid';
import { convertMinutesToHoursMinutes } from '../../utils/helpers';
import DataWidget from './data-widget';


export default function ActivitySummary({ engagementTime, engagementFrequencyTotal }) {

  return (
    <DataWidget title="Activity Summary">
      <div className="grid grid-cols-2 my-2">
        <div className="px-2 text-center">
          <CalendarIcon className="inline-flex h-6 w-6 text-primary-500" />
          <p className="text-darkblue font-bold text-md my-1">{engagementFrequencyTotal || '--'}</p>
          <p className="text-darkblue text-xs">number of<br />creative sessions</p>
        </div>
        <div className="px-2 text-center">
          <ClockIcon className="inline-flex h-6 w-6 text-primary-500" />
          <p className="text-darkblue font-bold text-md my-1">{engagementTime ? convertMinutesToHoursMinutes(engagementTime) : '--'}</p>
          <p className="text-darkblue text-xs">time spent<br />being creative</p>
        </div>
      </div>
    </DataWidget>
        
  );
}

