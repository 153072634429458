import React from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid';
import { Link } from 'react-router-dom';
import { useBreadcrumbs } from '../../../contexts/breadcrumbs-provider';

function Breadcrumbs() {
  const { breadcrumbs } = useBreadcrumbs();
  // We won't show breadcrumbs unless we have at least 2
  if (breadcrumbs.length < 2) {
    return null;
  }

  const previousLink = breadcrumbs[breadcrumbs.length - 2];

  return (
    <div className="mb-2">
      <nav className="sm:hidden" aria-label="Back">
        <Link to={previousLink.href} className="flex items-center text-sm font-medium text-gray-500 hover:text-gray-700">
          <ChevronLeftIcon className="flex-shrink-0 -ml-1 mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
          Back
        </Link>
      </nav>
      <nav className="hidden sm:flex" aria-label="Breadcrumb">
        <ol className="flex items-center space-x-4">
          {breadcrumbs.map((crumb, i) => {
            const isFirst = i === 0;
            const isLast = i === breadcrumbs.length - 1;
            const { label, href } = crumb;
            if (isFirst) {
              return (
                <li key={label}>
                  <div className="flex">
                    <Link to={href} className="text-sm font-medium text-gray-500 hover:text-gray-700">
                      {label}
                    </Link>
                  </div>
                </li>
              );
            }
            const ariaProps = isLast ? { 'aria-current': 'page' } : {};
            return (
              <li key={label}>
                <div className="flex items-center">
                  <ChevronRightIcon className="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
                  <Link to={href} {...ariaProps} className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">
                    {label}
                  </Link>
                </div>
              </li>
            );
          })}
        </ol>
      </nav>
    </div>
  )
}

function Links({ links }) {
  if (!links || !links.length) {
    return null;
  }

  return (
    <div className="mt-4 flex-shrink-0 flex md:mt-0 md:ml-4">
      {links.map(item => {
        const { secondary, href, label, onClick } = item;
        const classes = secondary
          ? 'inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-secondary-500'
          : 'ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-secondary-500 hover:bg-secondary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-secondary-500';

        if (onClick) {
          return (
            <button
              key={label}
              onClick={onClick}
              className={classes}
            >
              {label}
            </button>
          );
        }
        return (
          <Link
            key={label}
            to={href}
            className={classes}
          >
            {label}
          </Link>
        );
      })}
    </div>
  );
}

export default function Heading({ title, links }) {
  return (
    <div className="p-4 md:pt-6 md:pb-1 md:px-8">
      <Breadcrumbs />
      <div className="md:flex md:items-center md:justify-between">
        <div className="flex-1 min-w-0">
          <h2 className="text-2xl font-bold leading-7 text-primary-500 sm:text-4xl sm:truncate">{title}</h2>
        </div>
        <Links links={links} />
      </div>
    </div>
  )
}