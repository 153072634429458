import { useEffect, useState } from 'react';
import Bugsnag from '@bugsnag/browser';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel, AccordionItemState } from 'react-accessible-accordion';
import { getApiRequest } from '../../../utils/request';
import Frame from '../../../components/layout/frame';
import MemberHeader from '../../../components/layout/coach/member-header';
import Nl2br from '../../../components/common/nl2br';
import Loadable from '../../../components/data/loadable';
import { ChevronLeftIcon, ChevronRightIcon, ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/outline';
import Videplayer from '../../../components/video/player';
import RichTextRenderer from '../../../components/common/rich-text-renderer';

const ACTIVITY_CONTENT_ID = 'practice';

function PreviousNextWeeks({ journeyId, weeks, selectedWeekIndex }) {
  const previousWeekIndex = selectedWeekIndex > 0 ? selectedWeekIndex - 1 : -1;
  const nextWeekIndex = selectedWeekIndex < weeks.length - 1 ? selectedWeekIndex + 1 : -1;

  const previousWeek = weeks[previousWeekIndex]?.week;
  const nextWeek = weeks[nextWeekIndex]?.week;

  return (
    <>
      {previousWeek !== undefined ? (
        <Link to={`/coach/member/journey/${journeyId}/flow/activities/${previousWeek}`} className="flex items-center font-bold">
          <ChevronLeftIcon className="mr-1 h-4 w-4" />
          Week {previousWeek}
        </Link>
      ) : (
        <div />
      )}
      {nextWeek !== undefined ? (
        <Link to={`/coach/member/journey/${journeyId}/flow/activities/${nextWeek}`} className="flex items-center font-bold">
          Week {nextWeek} <ChevronRightIcon className="ml-1 h-4 w-4" />
        </Link>
      ) : (
        <div className="flex items-center font-bold hover:cursor-not-allowed text-gray-500">
          Done <ChevronRightIcon className="ml-1 h-4 w-4" />
        </div>
      )}
    </>
  );
}

export default function FlowDetails() {
  const { id, weekNumber } = useParams();
  const [summary, setSummary] = useState(null);
  const [journey, setJourney] = useState(null);
  const [loading, setLoading] = useState(true);
  const [tab, setTab] = useState(ACTIVITY_CONTENT_ID);
  const navigate = useNavigate();

  useEffect(() => {
    const load = async () => {
      if (!id) {
        navigate('/coach/members', { replace: true });
        return;
      }

      setLoading(true);
      try {
        const result = await getApiRequest(`/coach/journey/${id}`);
        setSummary(result);
        setJourney(result.journey);
      } catch (err) {
        Bugsnag.notify(err);
        console.log(err, 'there was an error loading the summary');
      }
      setLoading(false);
    };
    if (!journey) {
      load();
    }
  }, [id]);

  useEffect(() => {
    setTab(ACTIVITY_CONTENT_ID);
  }, [weekNumber]);

  const weeks = journey?.weeks?.length ? journey.weeks : [];
  const weekIndex = weeks.findIndex((week) => week.week === parseInt(weekNumber, 10));
  const selectedWeek = weekIndex !== -1 && weeks[weekIndex] ? weeks[weekIndex] : {};
  const { activity } = selectedWeek;
  const { title, descriptionRichMedia, videoUrl, exercises } = activity || {};

  const hasPractices = !!exercises && !!exercises.length;
  // Ensure our practices are always in duration order
  const formattedPractices = hasPractices
    ? exercises.sort((a, b) => b.listWeight - a.listWeight)
    : [];

  const activeTabVideoUrl = tab === ACTIVITY_CONTENT_ID ? videoUrl : formattedPractices.find((practice) => practice.title === tab)?.videoUrl;
  const activeTabClass = (tabName) => {
    const defaultTabClass = 'mx-2 rounded-lg border py-3 px-4 text-xs';
    const tabStateClass = tabName === tab ? ' bg-primary-500 text-white border-primary-500' : ' hover:cursor-pointer bg-white border-gray-900';
    return `${defaultTabClass}${tabStateClass}`;
  }; 

  return (
    <Frame fullscreen classes="p-4">
      <Loadable loading={loading}>
        {!!summary && (
          <>
            <MemberHeader memberUid={summary.journey.uid} journeyId={id} which="flow" name={summary.profile.name} toolkitName={summary.journey.toolkitName} />
            <div className="max-w-7xl my-8 px-4 mx-auto">
              <div className="flex items-center">
                <p className="mr-auto text-3xl font-bold text-primary-500">{title}</p>
                <div>
                  <div className="grid grid-cols-2 gap-x-14">
                    <PreviousNextWeeks journeyId={id} weeks={weeks} selectedWeekIndex={weekIndex} />
                  </div>
                  <div className="text-right pt-1">
                    <Link to={`/coach/member/journey/${id}/flow`} className="underline text-primary-500">
                      View All
                    </Link>
                  </div>
                </div>
              </div>
              <p className="py-5">Part {weekNumber}</p>
              <div className="grid grid-cols-2 gap-x-6 my-4">
                <div>
                  <div className="mb-6">
                    <ul className="list-none flex items-center">
                      <li onClick={() => setTab(ACTIVITY_CONTENT_ID)} className={activeTabClass(ACTIVITY_CONTENT_ID)}>
                        Thought of the Week
                      </li>
                      {formattedPractices.map((practice) => (
                        <li
                          key={practice._id}
                          onClick={() => setTab(practice.title)}
                          className={activeTabClass(practice.title)}
                        >
                          {practice.title}
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="h-full w-full ">
                    {activeTabVideoUrl ? <Videplayer videoUrl={activeTabVideoUrl}/> : <p className="flex justify-center items-center h-64 bg-gray-200">No videos - refer to the text based prompts</p>}
                  </div>
                </div>
                <div>
                  <Accordion preExpanded={[ACTIVITY_CONTENT_ID]} allowZeroExpanded className="p-5 text-left">
                    <AccordionItem uuid={ACTIVITY_CONTENT_ID} className="mb-5">
                      <AccordionItemHeading>
                        <AccordionItemButton className="border-b-2 border-gray-800  p-2 mb-2">
                          <div className="flex justify-between">
                            <p>Practice of the Week</p>
                            <AccordionItemState>
                              {({ expanded }) => (expanded ? <ChevronUpIcon className="h-6 w-6" /> : <ChevronDownIcon className="h-6 w-6" />)}
                            </AccordionItemState>
                          </div>
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <RichTextRenderer content={descriptionRichMedia?.content} />
                      </AccordionItemPanel>
                    </AccordionItem>
                    {formattedPractices.map((practice) => (
                      <AccordionItem key={practice._id} className="mb-5">
                        <AccordionItemHeading>
                          <AccordionItemButton className="border-b-2 border-gray-800 p-2 mb-2">
                            <div className="flex justify-between">
                              <p>{practice.title}</p>
                              <AccordionItemState>
                                {({ expanded }) => (expanded ? <ChevronUpIcon className="h-6 w-6" /> : <ChevronDownIcon className="h-6 w-6" />)}
                              </AccordionItemState>
                            </div>
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <RichTextRenderer content={practice?.instructionsRichMedia?.content} />
                        </AccordionItemPanel>
                      </AccordionItem>
                    ))}
                  </Accordion>
                </div>
              </div>
            </div>
          </>
        )}
      </Loadable>
    </Frame>
  );
}
