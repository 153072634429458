import { useState, useEffect } from 'react';
import Bugsnag from '@bugsnag/browser';
import { useParams, useNavigate } from 'react-router-dom';
import { useBreadcrumbs } from '../../contexts/breadcrumbs-provider';
import Heading from '../../components/layout/admin/heading';
import ProfileDataView from '../../components/profiles/profile-view';
import Loadable from '../../components/data/loadable';
import { getApiRequest } from '../../utils/request';
import { useNotifications } from '../../contexts/notifications-provider';
import Frame from '../../components/layout/frame';

export default function ProfileView() {
  const { setBreadcrumbs } = useBreadcrumbs();
  const { addNotification } = useNotifications();
  const navigate = useNavigate();
  const { id } = useParams();
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setBreadcrumbs([
      {
        href: '/admin/profiles',
        label: 'Profiles',
      },
      {
        href: `/admin/profile-view/${id}`,
        label: `View Profile`,
      },
    ]);
    // Fetch the profile
    const load = async () => {
      try {
        setLoading(true);
        const result = await getApiRequest(`/profile/${id}`);
        setProfile(result.profile);
        setLoading(false);
      } catch (err) {
        Bugsnag.notify(err);
        addNotification({
          type: 'error',
          body: "There was an error loading the profile.",
        });
        navigate('/admin/profiles');
      }
    };
    load();
  }, [id]);
  
  return (
    
    <Frame>
      <Heading title={`View Profile`} />
      <Loadable loading={loading}>
        <div className="max-w-2xl mx-auto py-12">
          <ProfileDataView profile={profile} />
        </div>
      </Loadable>
    </Frame>
  )
}