import '../config/firebase';
import { useState, useEffect, useContext, createContext } from 'react';
import { getAuth, onAuthStateChanged, signInWithEmailAndPassword, signOut, sendPasswordResetEmail } from 'firebase/auth';


const auth = getAuth();

export const AuthContext = createContext({
  signout: () => null,
  signin: (email, password) => null,
  sendForgotPassword: (email) => null,
  hasRole: (providedRoles) => null,
  user: null,
  claims: null,
  initialized: false,
});

function useProvideAuth() {
  const [user, setUser] = useState(null);
  const [claims, setClaims] = useState(null);
  const [initialized, setInitialized] = useState(false);

  // Just an alias for the firebase function call
  const signout = () => signOut(auth);

  // Handle the request for forgot password
  const sendForgotPassword = (email) => sendPasswordResetEmail(auth, email);

  // Sign in with email and password
  const signin = (email, password) => signInWithEmailAndPassword(auth, email, password);

  // Receives an array of roles, returning true if any of them match the user's role
  const hasRole = (providedRoles) => {
      if (!user || !claims) {
        return false;
      }
      const { role } = claims;
      if (!role) {
        return false;
      }
      
      return providedRoles.indexOf(role) !== -1;
  };

  useEffect(() => {
    // Subscribe to firebase auth changes
    const cleanup = onAuthStateChanged(auth, async (firebaseUser) => {
      if (firebaseUser) {
        const tokenResult = await firebaseUser.getIdTokenResult();
        if (tokenResult && tokenResult.claims) {
          setClaims(tokenResult.claims);
        }
        setUser(firebaseUser);
      } else {
        setUser(null)
      }
      if (!initialized) {
        setInitialized(true);
      }
    });
    return () => cleanup();
  }, []);
  
  return {
    user,
    claims,
    hasRole,
    signout,
    signin,
    sendForgotPassword,
    initialized,
  };
}

// Auth provider component (this is boilerplate for context providers)
export function ProvideAuth({ children }) {
  const authData = useProvideAuth();
  return <AuthContext.Provider value={authData}>{children}</AuthContext.Provider>;
}

// Define our hook for child components to use to get access to the auth object
export const useAuth = () => {
  return useContext(AuthContext);
};
