
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import queryString from 'query-string';
import moment from 'moment';
import { useBreadcrumbs } from '../../contexts/breadcrumbs-provider';
import Heading from '../../components/layout/admin/heading';
import { getApiRequest } from '../../utils/request';
import { capFirstLetter } from '../../utils/helpers';
import Loadable from '../../components/data/loadable';
import AdminTable from '../../components/common/admin-table';
import AutoForm from '../../components/form/auto-form';
import Frame from '../../components/layout/frame';


function formatRow(item) {
  const {uid: id, creationTime: created, email, displayName: name, lastSignInTime: lastSignIn, role } = item;
  return {
    name,
    email,
    created: moment(created).format('M/D/Y'),
    lastSignIn: lastSignIn ? moment(lastSignIn).fromNow() : 'Never',
    role: capFirstLetter(role),
    id,
  };
}

export default function AdminUsers() {
  const [results, setResults] = useState([]);
  const [nextPageToken, setNextPageToken] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const [queryParamsString, setQueryParamsString] = useState(null);
  const { setBreadcrumbs } = useBreadcrumbs();
  useEffect(() => {
    setBreadcrumbs([
      {
        href: '/admin/users',
        label: 'Users',
      },
    ]);
  }, []);

  const query = queryString.parse(window.location.search);
  const currentQueryParamsString = queryString.stringify(query || {});

  const loadUsers = async () => {
    if (!loading) {
      setLoading(true);
    }
    const result = await getApiRequest(`/users${queryParamsString ? `?${queryParamsString}` : ''}`);
    // setUsers(result.data);
    // console.log(result, 'result wtf');
    setResults({ data: result.results });
    setNextPageToken(result.pageToken);
    setLoading(false);
  };

  useEffect(() => {
    if (currentQueryParamsString !== queryParamsString) {
      setQueryParamsString(currentQueryParamsString);
    }
  }, [currentQueryParamsString]);


  useEffect(() => {
    if (queryParamsString !== null && queryParamsString === currentQueryParamsString) {
      loadUsers();
    }
  }, [queryParamsString]);

  const columns = [
    {
      key: 'name',
      label: 'Name',
    },
    {
      key: 'email',
      label: 'Email',
    },
    {
      key: 'role',
      label: 'Role',
      isBold: true,
    },
    {
      key: 'created',
      label: 'Created',
    },
    {
      key: 'lastSignIn',
      label: 'Last Sign In',
    },
    {
      key: 'actions',
      label: '',
      isRight: true,
      render: (row) => (
        <Link
          to={`/admin/user/${row.id}`}
          className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Edit
        </Link>
      ),
    },
  ];

  const actionLinks = [
    {
      label: 'Create User',
      href: '/admin/user',
    },
  ];

  const baseUrl = '/admin/users';

  const searchForm = {
    type: 'get',
    noContainer: true,
    successRedirect: '/admin/users',
    fields: [
      {
        name: 'email',
        label: 'Email',
        type: 'email',
      },
    ],
    submitLabel: 'Search Users',
  };

  return (
      <Frame>
        
        <Heading title="Users" links={actionLinks} />
        <Loadable loading={loading}>
          <AdminTable results={results} columns={columns} baseUrl={baseUrl} rowFormatter={formatRow} searchTitle="Search Users" searchForm={<AutoForm {...searchForm} />} />
        </Loadable>
      </Frame>
  );
}
